import { RepositoryFactory } from "@/api/repositoryFactory"
const repository = RepositoryFactory.get("achievements")
import { defineStore } from "pinia"
import { useSnackbarStore } from "./snackbar"

export const useAchievementsStore = defineStore("achievements", {
  state: () => ({
    achievements: [],
    userAchievements: [],
    userActivityCount: [],
    loading: false,
    achievementModal: [],
    showAchievement: false,
  }),
  actions: {
    async fetchAchievements() {
      try {
        this.loading = true
        const response = await repository.fetchAll()
        if (response.status === 200 && response.data) {
          this.userAchievements = response.data.userAchievements
          this.achievements = response.data.achievements
          this.userActivityCount = response.data.userActivityCount
        }
        this.loading = false
      } catch (e) {
        console.log(e)
        this.loading = false
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async openAchievement(payload) {
      try {
        this.achievementModal = payload["achievement"]
        this.setShowAchievementModal(true)
      } catch (e) {
        console.log(e)
      }
    },
    setShowAchievementModal(boolean) {
      this.showAchievement = boolean
    },
  },
})
