<template>
  <Emoji
    :emoji="emoji"
    :data="index"
    :size="size"
    :style="{height: size + 'px'}"
    set="apple"
    :data-emoji="emoji"
  />
</template>

<script>
import data from 'emoji-mart-vue-fast/data/all.json'
import { Emoji, EmojiIndex } from 'emoji-mart-vue-fast/src'
let index = new EmojiIndex(data)

export default {
  name: 'ShowEmoji',
  components: {
    Emoji
  },
  props: {
    emoji: { type: String, default: '' },
    size: { type: Number, default: 17 }
  },
  data () {
    return {
      index: index
    }
  }
}
</script>

<style lang="scss">
  .emoji-mart-emoji {
    padding: 1px 2px;
  }
</style>
