<template>
  <div class="flex-col text-custom-purple-500 space-y-3 lg:-mt-10">
    <div class="text-3xl font-extrabold text-center">
      Verification
    </div>
    <div v-if="isVerified === false">
      <div class=" font-medium text-center">
        Please verify your identity. Proceed by verifying yourself with either of these methods below.
      </div>
      <div class=" flex flex-col justify-center items-center h-[37rem] ">
        <CircleLoader v-if="loading === true" />
        <div v-if="verificationMethod === 'devcode'">
          <iframe
            id="Devcode"
            class="h-[37rem]"
            name="Devcode Iframe"
            title="Insert your personal number"
            :src="url"
          />
        </div>
        <div
          v-if="verificationMethod === 'freja'"
          class="pt-4 flex flex-col items-center h-96"
        >
          <img
            class="w-24 mb-4"
            :src="'/img/freja_black.svg'"
          >
          <CircleLoader
            v-if="frejaLoading"
            small
          />
          <div
            v-else
            class="h-full w-full flex justify-center mb-4"
          >
            <iframe
              id="Freja"
              class="overflow-visible flex w-1/2 h-full pl-2 md:pl-20 lg:pl-2 xl:pl-20"
              name="Freja Iframe"
              title="Verify your Freja Id"
              :src="url"
            />
          </div>
          <span
            v-if="!frejaLoading"
            class="text-xs"
          >Note: Scan the QR code in Freja App to authenticate. please do not
            reload the page else you need to restart the authentication process</span>
          <SquareButton
            class="mt-8 bg-gray-400 rounded-lg text-base text-custom-purple-500 font-bold border-none px-6 py-2 "
            @click="$emit('skip', $event)"
          >
            Skip for now
          </SquareButton>
        </div>
        <VerificationButtons
          v-else-if="verificationMethod === ''"
          @verify="$emit('verify', $event)"
          @skip="$emit('skip', $event)"
        />
        <div
          v-else-if="verificationMethod === 'trulioo'"
          class=" h-144 "
        >
          <TruliooContainer :pending-verification="pendingVerification" />
        </div>
      </div>
    </div>

    <div
      v-else-if="isVerified === true"
      class="flex justify-center flex-col items-center space-y-8 pt-5"
    >
      <div class=" font-medium text-center">
        <span class="font-bold">Congrats!!</span> You've been verified successfully.
      </div>
      <ActiveButton
        class="bg-custom-orange-400 sm:h-12 h-11 rounded-xl mt-2 sm:w-40 w-28"
        @click="$emit('continue', $event)"
      >
        <span class="text-white">
          Continue
        </span>
      </ActiveButton>
    </div>

    <div v-if="verificationFailure === true">
      <AlertModalContainer
        :create="verificationFailure === true"
        :title="'Verification Failed'"
        body="Sorry, we were unable to verify your identity. Please try again."
        type=""
        :show-confirm-button="verificationFailure === true"
        @toggle="$emit('toggle', $event)"
        @agree="$emit('apply', $event)"
      >
        <template #agree>
          {{ $t('Yes, I will try again') }}
        </template>
      </AlertModalContainer>
    </div>
  </div>
</template>

<script>
import TruliooContainer from '../../trulioo'
import AlertModalContainer from '../../alertModal'
import VerificationButtons from './VerificationButtons.vue'

export default {
  name: 'CompanyVerification',
  components: {
    TruliooContainer,
    AlertModalContainer,
    VerificationButtons
  },
  props: {
    email: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: ''
    },
    frejaLoading: { type: Boolean, default: false },
    verificationMethod: {
      type: String,
      default: ''
    },
    isVerified: {
      type: Boolean,
      default: false
    },
    verificationFailure: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    pendingVerification: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'verify',
    'skip',
    'continue',
    'toggle',
    'apply'
  ]
}
</script>
