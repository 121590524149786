<template>
  <button
    class="dark:text-custom-purple-100 cursor-pointer"
    :class="[noPadding ? '' : smallPadding? 'py-1 px-4' : 'py-2 px-4' , noShadow ? '' : 'text-shadow ', bold ? 'font-bold' : '', rounded,opacity]"
    :disabled="locked===true ?'':false"
    :type="type"
    :title="title"
    @click="$emit('click', $event)"
  >
    <slot />
  </button>
</template>

<script>

export default {
  name: 'BaseButton',
  props: {
    noPadding: { type: Boolean, default: false },
    smallPadding: { type: Boolean, default: false },
    noShadow: { type: Boolean, default: false },
    bold: { type: Boolean, default: true },
    locked: { type: Boolean, default: false },
    rounded: { type: String, default: 'rounded ' },
    opacity: { type: String, default: '' },
    type: { type: String, default: 'button' },
    title:{ type: String, default: '' },
  },
  emits: [
    'click'
  ]
}
</script>
