import { get, post, destroy } from '../helpers/axiosHelper'

const url = `${process.env.VUE_APP_API_URL}`

const addRooms = async (data) => post(`${url}/rooms/add-room`, data)
const fetch = async (data) => get(`${url}/rooms`, data)
const one = async (id) => get(`${url}/rooms/${id}`)
const follow = async (id) => post(`${url}/rooms/${id}/follow`)
const followers = async (id) => get(`${url}/rooms/${id}/followers`)
const report = async (id) => post(`${url}/rooms/${id}/report`)
const deleteRoom = async (id) => destroy(`${url}/rooms/${id}/delete`)
const scroll = async (link) => get(`${link}`)
const updateRoom = async (roomId, data) => post(`${url}/rooms/${roomId}/update`, data)

export default {
  scroll,
  followers,
  addRooms,
  updateRoom,
  fetch,
  one,
  follow,
  report,
  deleteRoom
}
