import { RepositoryFactory } from "@/api/repositoryFactory"
const repository = RepositoryFactory.get("insiders")
import { defineStore } from "pinia"
import { useSnackbarStore } from "./snackbar"

export const useInsidersStore = defineStore("insiders", {
  state: () => ({
    insiders: [],
    loading: false,
    loadingMore: false,
    nextPageUrl: "",
  }),
  actions: {
    async fetch(payload) {
      this.loading = true
      const response = await repository.list(payload)
      if (response.status === 200 && response.data) {
        this.insiders = [...response.data.data]
        this.nextPageUrl = response.data.next_page_url
      }
      this.loading = false
    },
    async scroll(payload) {
      try {
        if (this.nextPageUrl !== null && !this.loadingNewPost) {
          this.loadingMore = true
          const response = await repository.scroll(this.nextPageUrl, payload)
          if (response.status === 200 && response.data) {
            response.data.data.map((trade) => {
              this.insiders.push(trade)
            })

            this.nextPageUrl = response.data.next_page_url
            this.loadingMore = false
          }
        }
      } catch (e) {
        this.loadingMore = false
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    reset() {
      this.insiders = []
    },
  },
})
