<template>
  <div />
</template>

<script>
export default {
  name: 'Gradiant'
}
</script>

<style scoped>
div {
overflow: hidden;
background: white;
background: linear-gradient(45deg, white, #ffe0a7);
width: 100%;
height: 200px;
position: relative;
z-index: 1;
box-shadow: inset -1px -10px 50px -1px white, inset 0px -1px 0px 0px white;
}
div:before,
div:after {
content: '';
position: absolute;
width: 100%;
height: 100%;
}
div:before {
background: #ffe0a7;
box-shadow: 0 0 240px 64px #00ffa0;
z-index: 2;
top: -100%;
opacity: 0.8;
left: -72%;
opacity: 50%;
}
div:after {
background: white;
z-index: 3;
bottom: -96%;
right: -72%;
box-shadow: -100px 0 100px 134px white;
opacity: 1;
border-radius: 100%;
}
</style>
