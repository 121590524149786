<template>
  <div>
    <img
      :src="selectedDocument.thumbnail"
      class="w-full rounded"
    >
    <p class="font-extrabold text-xl my-2 dark:text-custom-purple-100">
      {{ selectedDocument.title }}
    </p>
    <p class="font-medium dark:text-custom-purple-100">
      {{ selectedDocument.description }}
    </p>
  </div>
</template>

<script>

export default {
  name: 'ImageModal',
  props: {
	selectedDocument: {
      type: Object,
      default: () => {}
	}
  }
}
</script>
