<template>
  <div class="relative">
    <div
      v-if="!showSearch"
      class="cursor-pointer"
      @click="$emit('show-search')"
    >
      <div
        v-if="selectedList && selectedList.length > 1"
        class="w-full flex flex-wrap pr-2 mb-4"
      >
        <span
          v-for="company in selectedList"
          :key="company.id"
          class="cursor-pointer text-xs font-semibold px-2 rounded-sm py-0 overflow-wrap flex items-center mb-1 mr-2 bg-custom-purple-300 hover:bg-custom-purple-400 text-white"
          @click="$emit('remove-company-from-array', company.id)"
        >
          <span class="mr-2">{{ company.name }}</span>
          <Icon
            name="x"
            class="text-lg"
          />
        </span>
      </div>
      <slot />
    </div>
    <template v-else>
      <BaseInput
        ref="searchCompany"
        :model-value="search"
        :classes="inputClass? inputClass : 'w-full rounded shadow-none mb-2 relative'"
        :placeholder="$t('Search Company')"
        @update:model-value="$emit('input-company', $event)"
      />
      <div
        v-if="search !== ''"
        class="absolute w-full z-20 bg-white dark:bg-custom-purple-450 rounded-xl left-0"
      >
        <div
          v-if="loadingCompanies"
          class="flex justify-center p-8"
        >
          <CircleLoader />
        </div>
        <div v-else-if="searchedCompanies && searchedCompanies.length > 0">
          <TaggableCompanyCard
            v-for="company in searchedCompanies"
            :id="company.short_name"
            :key="company.id"
            :name="company.name"
            :followers="company.all_followers"
            :stats="company.stats"
            :company="company"
            @chosen-company="$emit('select-company', company)"
          />
        </div>
      </div>
      <div
        v-if="selectedList && selectedList.length > 0"
        class="w-full flex flex-wrap pr-2 mb-4"
      >
        <span
          v-for="company in selectedList"
          :key="company.id"
          class="cursor-pointer text-xs font-semibold px-2 rounded-sm py-0 overflow-wrap flex items-center mb-1 mr-2 bg-custom-purple-300 hover:bg-custom-purple-400 text-white"
          @click="$emit('remove-company-from-array', company.id)"
        >
          <span class="mr-2">{{ company.name }}</span>
          <Icon
            name="x"
            class="text-lg"
          />
        </span>
      </div>
      <slot name="options" />
      <div
        v-if="!hideOptions"
        class="flex justify-end mt-2 mb-6"
      >
        <NeutralButton
          class="text-xs mr-2"
          :rounded="'rounded'"
          @click="$emit('add-companies-to-list')"
        >
          <template v-if="hasPreList">
            {{ $t('Update') }}
          </template>
          <template v-else>
            {{ $t('Add') }}
          </template>
        </NeutralButton>
        <NeutralButton
          class="text-xs"
          :rounded="'rounded'"
          @click="$emit('cancel')"
        >
          {{ $t('Cancel') }}
        </NeutralButton>
      </div>
    </template>
  </div>
</template>

<script>

import TaggableCompanyCard from './TaggableCompanyCard.vue'

export default {
  name: 'TaggableCompanySearch',
  components: {
    TaggableCompanyCard
  },
  props: {
    search: {
      type: String,
      default: ''
    },
    showSearch: {
      type: Boolean,
      default: false
    },
    hasPreList: {
      type: Boolean,
      default: false
    },
    loadingCompanies: {
      type: Boolean,
      default: false
    },
    searchedCompanies: {
      type: Array,
      default: () => []
    },
    selectedList: {
      type: Array,
      default: () => []
    },
    hideOptions: {
      type: Boolean,
      default: false
    },
    inputClass: {
      type: String,
      default: ''
    }
  },
  emits: [
  'input-company',
  'show-search',
  'input-company',
  'select-company',
  'remove-company-from-array',
  'add-companies-to-list',
  'cancel'
  ]
}
</script>
