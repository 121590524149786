<template>
  <BaseButton
    :no-padding="noPadding"
    :class="isSecondary ? 'border border-primary hover:border-primary text-primary hover:text-primary' : 'bg-primary hover:bg-primary text-white'"
    @click="$emit('click', $event)"
  >
    <slot />
  </BaseButton>
</template>

<script>

export default {
  name: 'AlertButton',
  props: {
    secondary: { type: Boolean, default: false },
    noPadding: { type: Boolean, default: false }
  },
  emits: [
    'click'
  ],
  computed: {
    isSecondary () {
      return (this.secondary !== false)
    }
  }
}
</script>
