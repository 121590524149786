<template>
  <div>
    <div class="py-4 lg:px-12 sm:px-5 max-w-lg mx-auto ">
      <div class="sm:grid-cols-1">
        <div class=" p-3 bg-white dark:bg-custom-purple-500 shadow rounded-lg px-5 py-6">
          <div class="text-md my-10 text-center text-gray-900 font-light">
            {{ $t('Warning') }}
          </div>
          <div class="text-center mt-8">
            <FontAwesomeIcon
              icon="exclamation-circle"
              class="mb-3 text-8xl text-custom-orange-400"
            />
          </div>
          <!-- <p
            v-if="warnings.length > 1"
            class="mt-4 text-center text-sm"
          >
            {{ $t('Your Count posts are reported as unappropreate. please do not voilate our policy or your account will be blocked.', {Count: warnings.length}) }}
          </p> -->
          <p
            v-for="warning in warnings"
            :key="warning.id"
            class="mt-4 text-center text-sm"
          >
            {{ $t(warning.message) }}
            <!-- {{ $t('Your post is reported as unappropreate. please do not voilate the our policy or your account will be blocked.') }} -->
          </p>
          <!-- <p
            v-for="warning in warnings"
            :key="warning.id"
            class="mt-8 text-center text-sm"
          >
            {{ warning.type }}
          </p> -->
          <div class="font-normal my-8 text-center">
            <NeutralButton
              class="z-100"
              @click="$emit('close')"
            >
              {{ $t('Continue') }}
            </NeutralButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'WarningModal',
  props: {
    warnings: { type: Array, default: () => [] }
  },
emits: [
'close'
]
}
</script>
