<template>
  <div
    class="bg-gray-400 bg-center rounded-lg border bg-no-repeat border-gray-300 dark:border-custom-purple-600 bg-contain"
    :style="'background-image: url(\'' + src + '\');'"
    :class="big ? 'w-28 h-28' : 'w-14 h-14'"
    @click="$emit('click', $event)"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'SquareImage',
  props: {
    src: {
      type: String,
      default: ''
    },
    big: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'click'
  ]
}
</script>
