<template>
  <div>
    <div class="text-lg font-semibold">
      {{ $t('Login Details') }}
    </div>
    <div class="grid lg:grid-cols-2 gap-4 my-4">
      <div>
        <label>
          {{ $t('Email') }}
        </label>
        <!-- :placeholder="$t('Email')" -->
        <BaseInput
          :type="'email'"
          :locked="true"
          class="placeholder-custom-purple-500 h-14 w-full font-semibold"
          :has-dark-mode="false"
          shadow="shadow-md"
          :model-value="email"
          @update:model-value="$emit('email', $event)"
        />
      </div>
     
      <div>
        <label>
          {{ $t('Username') }}
        </label>
        <!-- :placeholder="$t('Username')" -->
        <BaseInput
          :locked="true"
          class="placeholder-custom-purple-500 h-14 w-full font-semibold"
          :has-dark-mode="false"
          shadow="shadow-md"
          :model-value="username"
          @update:model-value="$emit('username', $event)"
        />
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: 'LoginDetailsForm',
  props: {

    email: {
      type: String,
      default: ''
    },
    username: {
      type: String,
      default: ''
    },

    baseSelectClass: {
      type: String,
      default: 'h-14 px-4 py-4 w-full bg-transparent border-none shadow-md font-semibold rounded-sm'
    }
  },

  emits: [
    'email',
    'username'
  ]
}
</script>
  