import { Capacitor } from "@capacitor/core"

import { isIphone } from "@/js/helpers/mobile"
import { useAppsStore } from "@/stores/app"

export default {
  setup() {
    const appsStore = useAppsStore()

    return { appsStore }
  },
  computed: {
    useMenu() {
      return this.appsStore.menu
    },
    insideCompanyPage() {
      return this.appsStore.insideCompanyPage
    },
    insidePrivateChat() {
      return this.appsStore.insidePrivateChat
    },
    platformPadding() {
      if (Capacitor.getPlatform() === "ios" || isIphone()) {
        return "ios-padding"
      }

      return ""
    },
  },
}
