import { get, post, put, destroy } from '../helpers/axiosHelper'

const url = `${process.env.VUE_APP_API_URL}/blogs`
const fetchBlogs = async () => get(`${url}`)
const fetchBlog = async (slug) => get(`${url}/${slug}`)
const createBlog = async (data) => post(`${url}/create`, data)
const updateBlog = async (data) => put(`${url}/update/${data.slug_url}`, data)
const deleteBlog = async (slugUrl) => destroy(`${url}/delete/${slugUrl}`)
const fetchFooter = async () => get(`${process.env.VUE_APP_API_URL}/hubspot`)


export default {
  fetchBlogs,
  fetchBlog,
  createBlog,
  updateBlog,
  deleteBlog,
  fetchFooter
}
