<template>
  <div
    class="modal-container dark:text-custom-purple-100 mx-auto z-50 overflow-y-auto"
    :class="[height, position, extraClass, width, rounded, background]"
  >
    <slot />
  </div>
</template>

<script>

export default {
  name: 'ModalContainer',
  props: {
    height: {
      type: String,
      default: 'h-3/4'
    },
    background: {
      type: String,
      default: ' bg-white dark:bg-custom-purple-500 shadow-sm dark:shadow-none '
    },
    width: {
      type: String,
      default: 'w-full md:max-w-md'
    },
    position: {
      type: String,
      default: 'absolute bottom-0'
    },
    rounded: {
      type: String,
      default: ' ',
    },
    extraClass: {
      type: String,
      default: ''
    }
  }
}
</script>
