<template>
  <div
    v-if="!edit"
    class="z-10 relative"
  >
    <div
      v-for="(line, index) in address"
      :key="line + index"
    >
      {{ line }}
    </div>
  </div>
  <div
    v-else
    class="z-10 relative flex flex-col mt-4"
  >
    <BaseInput
      v-for="(line, index) in address"
      :key="line + index"
      :model-value="line"
      class="mb-1"
      @update:model-value="$emit('update', { address, index, value: $event.target.value})"
    />
  </div>
</template>

<script>

export default {
  name: 'CompanyAddress',
  props: {
    address: {
      type: Array,
      default: () => []
    },
    edit: {
      type: Boolean,
      default: false
    }
  },
emits: [
'update'
]
}
</script>
