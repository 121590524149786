<template>
  <div class="m-8 md:m-12">
    <h1 class="text-2xl text-custom-purple-700 dark:text-custom-purple-100 font-bold">
      {{ $t('Session is about to expire in ') + reminder.time + $t(' minutes') }}
    </h1>
    <p class="text-base mt-4 dark:text-custom-purple-100">
      {{ $t(`Due to inactivity, and for your security, your session is about to expire.`) }}
    </p>
    <p class="mt-4 text-base dark:text-custom-purple-100">
      {{ $t('Would you like to remain logged in and continue your activities?') }}
    </p>
    <p class="mt-4 text-base dark:text-custom-purple-100">
      {{ $t('Please click ') }} <span class="font-bold">“{{ $t('Stay Logged In') }}”</span> {{ $t('to extend your session.') }}
    </p>
    <div class="flex justify-center gap-6 mx-2 md:mx-8 mt-20">
      <button
        class="border border-custom-purple-500 dark:border-custom-purple-450 font-bold rounded-full h-12 w-2/4"
        @click="$emit('logout')"
      >
        {{ $t('Log out') }}
      </button>
      <button
        class="bg-custom-purple-500 dark:bg-custom-purple-450 text-custom-purple-100 font-bold rounded-full w-2/4 h-12"
        @click="$emit('close')"
      >
        {{ $t('Stay Logged In') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Reminder',
  props: {
    reminder: { type: Object, default: () => { } }
  },
  emits: [
    'close', 'logout',
  ],

}

</script>
