<template>
  <div class="h-full">
    <button
      class="cursor-pointer text-shadow font-bold flex justify-between items-center mb-4"
      type="button"
      @click="$emit('back')"
    >
      <Icon
        class="text-2xl"
        name="left-arrow-alt"
        @click="$emit('back')"
      />
    </button> 

    <BaseCard
      :background="'bg-transparent'"
      :shadow="'shadow-lg'"
      :border="'border'"
      :padding="'p-6'"
      :header-margin="'mt-0'"
    >
      <template #body>
        <div class="font-medium ">
          <div> For your information </div>
          <p class="mb-2">
            You are now minutes away from your own trading account on eucaps.com.
          </p>
          <p class="mb-2">
            Your account is a cash account and does not offer margin capabilities. This means that you will only be able
            to trade if you have cash available in your account. The trading is provided by Placing Corporate Finance
            AB, regulated by the Swedish Financial supervisory authority identification number with the Swedish Financial Supervisory Authority (Finansinspektionen) 65616 and the execution
            of the trades, the safekeeping of money and shares is provided by Interactive Brokers Ireland Limited,
            regulated by the Irish Central Bank (identification number C423427).
          </p>
          <p class="mb-2">
            The disclosures and agreements below are directly with them.
          </p>

          <p>
            To set up your account please confirm the below:
          </p>
          <ul class="list-disc list-outside  ml-4 mb-4">
            <li>
              I am not a "<a
                href="https://en.wikipedia.org/wiki/Politically_exposed_person"
                class="underline cursor-pointer"
              >Politically Exposed Person</a>";
            </li>
            <li>
              I am not representing a corporation or similar entity;
            </li>
            <li>
              I am not a financial professional or representing a financial institution;
            </li>
            <li>
              I will not use other person's money or represent other investors;
            </li>
            <li>
              I will not use my trading account for dependents (family, wife, etc);
            </li>
            <li>
              I am not - nor have I immediate family residing in the same household who are - registered as a
              broker-dealer or an employee, director or owner of a securities or commodities brokerage firm;
            </li>
            <li>
              I am not i) a director, ii) a 10 percent shareholder; or iii) a policy-making officer of a publicly traded company.
            </li>
          </ul>
        </div>
        <div class="grid mt-2 p-2 border">
          <label
            for="checkInput1"
            class="flex font-bold"
          >
            <input
              id="checkInput1"
              type="checkbox"
              :checked="agreeCheck1"
              class="w-6 mr-4"
              @change="$emit('agree-check1')"
            >

            I declare that I apply to open the account for non-professional use and I confirm all of the above statements

          </label>
        </div>



        <!-- <div class="w-full flex justify-between items-center gap-6">
          <button
            class="cursor-pointer py-2 px-4 text-shadow font-bold rounded bg-primary 
          hover:bg-primary text-white rounded-md disabled:opacity-50 "
            type="button"
            :disabled="loading"
            @click="$emit('back')"
          >
            {{ $t('Back') }}
          </button>

          <button
            class="text-white cursor-pointer py-2 px-4 text-shadow font-bold rounded bg-primary 
          hover:bg-primary bg-custom-orange-400 rounded-md disabled:opacity-50 "
            type="button"
            :disabled="loading"
            @click="$emit('continue')"
          >
            {{ $t('Agree') }}
          </button>
        </div> -->

        <div class="font-medium mt-8 pt-8">
          <p class="mb-2">
            The following regulatory disclosures and notices are related to your trading account application:
          </p>
          <ul class="list-disc list-outside ml-4 mb-4">
            <li>
              <a
                class="underline cursor-pointer "
                target="_blank"
                :href="formStorageUrl + 'Form5013' + '.pdf'"
              >Certification Regarding Trading Control and Ownership of the
                Account
                (5013)</a>
            </li>
            <li>
              <a
                class="underline cursor-pointer"
                target="_blank"
                :href="formStorageUrl + 'Form4762' + '.pdf'"
              >Multicurrency and Forex Disclosure (4762)</a>
            </li>
            <li>
              <a
                class="underline cursor-pointer"
                target="_blank"
                :href="formStorageUrl + 'Form4757' + '.pdf'"
              >Client
                Assets Key Information Document (4757)</a>
            </li>
            <li>
              <a
                class="underline cursor-pointer"
                target="_blank"
                :href="formStorageUrl + 'Form4758' + '.pdf'"
              >Client
                Consent to Accept Electronic Delivery (4758)</a>
            </li>
            <li>
              <a
                class="underline cursor-pointer"
                target="_blank"
                :href="formStorageUrl + 'Form4756' + '.pdf'"
              >Description
                of Our Services and Fees (4756)</a>
            </li>
            <li>
              <a
                class="underline cursor-pointer"
                target="_blank"
                :href="formStorageUrl + 'Form4759' + '.pdf'"
              >Warnings and
                Info on Financial Instruments (4759)</a>
            </li>
            <li>
              <a
                class="underline cursor-pointer"
                target="_blank"
                :href="formStorageUrl + 'Form4754' + '.pdf'"
              >MiFIR
                Enriched and Delegated Transaction Reporting (4754)</a>
            </li>
            <li>
              <a
                class="underline cursor-pointer"
                target="_blank"
                :href="formStorageUrl + 'Form4760' + '.pdf'"
              >Interactive
                Brokers Ireland Limited Order Execution Policy (4760)</a>
            </li>
            <li>
              <a
                class="underline cursor-pointer"
                target="_blank"
                :href="formStorageUrl + 'Form4783' + '.pdf'"
              >Stock Stop
                Order Disclosure (4783)</a>
            </li>
            <li>
              <a
                class="underline cursor-pointer"
                target="_blank"
                :href="formStorageUrl + 'Form4750' + '.pdf'"
              >Retail
                Client Notification (4750)</a>
            </li>
            <li>
              <a
                class="underline cursor-pointer"
                target="_blank"
                :href="formStorageUrl + 'Form2109' + '.pdf'"
              >Legal
                Acknowledgement (2109)</a>
            </li>
            <li>
              <a
                class="underline cursor-pointer"
                target="_blank"
                :href="formStorageUrl + 'Form3081' + '.pdf'"
              >Notice
                Regarding USA Patriot Act Section 31 (3081)</a>
            </li>
            <li>
              <a
                class="underline cursor-pointer"
                target="_blank"
                :href="formStorageUrl + 'Form4763' + '.pdf'"
              >Risk
                Disclosure For Structured Products with 1B Ireland Ltd (4763)</a>
            </li>
            <li>
              <a
                class="underline cursor-pointer"
                target="_blank"
                :href="formStorageUrl + 'Form9130' + '.pdf'"
              >US Stock
                Stop Order Disclosure (9130)</a>
            </li>
          </ul>
        </div>
        <div class="grid mt-2 p-2 border">
          <label
            for="checkInput2"
            class="flex font-bold"
          >
            <input
              id="checkInput2"
              type="checkbox"
              :checked="agreeCheck2"
              class="w-6 mr-4"
              @change="$emit('agree-check2')"
            >

            I declare that I have received and read the above disclosures and notices

          </label>
        </div>


        <div class="font-semibold mt-8 pt-8">
          <p class="mb-2">
            Please confirm that you are not a US resident or US tax payer:
          </p>
          <ul class="list-disc  list-outside  ml-4 mb-4">
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                class="underline cursor-pointer"
                :href="formStorageUrl + 'Form5001' + '.pdf'"
              >
                W8BEN</a>
            </li>
          </ul>
          <div class="grid mt-2 p-2 border">
            <label
              for="checkInput3"
              class="flex font-semibold"
            >
              <input
                id="checkInput3"
                type="checkbox"
                :checked="agreeCheck3"
                class="w-6 mr-4"
                @change="$emit('agree-check3')"
              >
              I declare that submitted information is correct and may be used to complete the form attached above

            </label>
          </div>
        </div>

        <div class="font-semibold mt-8 pt-8">
          <div class="grid mt-2 p-2 border">
            <label
              for="checkInput4"
              class="flex font-semibold text-justify"
            >
              <input
                id="checkInput4"
                type="checkbox"
                :checked="agreeCheck4"
                class="w-28 mr-4"
                @change="$emit('agree-check4')"
              >

              <a
                target="_blank"
                rel="noopener noreferrer"
                class="underline cursor-pointer"
                :href="formStorageUrl + 'TradingTerms' + '.pdf'"
              > I agree to: Authorization to create a 3 Tier Account
                under a Financial Advisor and an Introducing Broker,
                Global Financial
                Information Services Subscriber Agreement, Client Financial Advisor Agreement, EMIR Delegated
                Transaction
                Reporting
                Agreement, Notice and Acknowledgement of Clearing Agreement, Interactive Brokers Group Privacy Statement
                Policy,
                Interactive Brokers Ireland Ltd Customer Agreement and the Specific Terms of Service of Eucaps Trading
                Account
              </a>


            </label>
          </div>
        </div>

        <div class="mt-8 pt-8 text-sm  rounded font-medium">
          <p class="text-justify">
            By typing my full name as a signature, I acknowledge that I am bound by the terms of the agreements and the
            disclosures above. I also acknowledge this signature as the equivalent of my handwritten signature.
          </p>


          <div>
            <span class="font-bold">Dated :</span> <span>{{ currentDateTime }}</span>
          </div>

          <div class="flex mt-4 gap-6 justify-between items-center sm:w-full">
            <div class="mt-2">
              {{ $t('Signature') }}
            </div>
            <div class="w-full">
              <div class="pl-2">
                {{ fullname || '' }}
              </div>
              <BaseInput
                :has-dark-mode="false"
                :classes="'w-full'"
                :shadow="'shadow-sm'"
                :padding="'px-2 py-2'"
                :model-value="signature"
                :placeholder="'Type your full name here'"
                @update:model-value="$emit('signature', $event)"
              />
            </div>
          </div>

          <div class="flex w-full items-center justify-end">
            <ActiveButton
              :locked="lockSubmit"
              class="bg-custom-orange-400 h-12 rounded-xl disabled:opacity-50 mt-8"
              type="button"
              @click="$emit('continue')"
            >
              <span class="text-white">
                {{ $t('Complete process') }}
              </span>
            </ActiveButton>
          </div>
        </div>
      </template>
    </BaseCard>
  </div>
</template>
<script>
export default {
  name: 'UserKycAgreementForm',
  props: {
    username: {
      type: String,
      default: ''
    },
    currentDateTime: {
      type: String,
      default: ''
    },

    fullname: {
      type: String,
      default: ''
    },
    lockSubmit: {
      type: Boolean,
      default: false
    },
    agreeCheck1: {
      type: Boolean,
      default: false
    },
    agreeCheck2: {
      type: Boolean,
      default: false
    },
    agreeCheck3: {
      type: Boolean,
      default: false
    },
    agreeCheck4: {
      type: Boolean,
      default: false
    },
    formStorageUrl: {
      type: String,
      default: ''
    }
  },
  emits: [
    'continue',
    'back',
    'signature',
    'agree-check1',
    'agree-check2',
    'agree-check3',
    'agree-check4'
  ]
}
</script>
<style scoped>
.contain {
  height: 880px;
}
</style>