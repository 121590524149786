<template>
  <div>
    <div class="text-lg font-semibold">
      {{ $t('Source of Wealth') }}
    </div>
    <div class="grid my-4">
      <div>
        <label>
          {{ $t('Choose Source Type') }}
        </label>
        <BaseSelect
          :appearance-none="false"
          :has-dark-mode="false"
          :required="true"
          :classes="sourceWealthType ? baseSelectClass : baseSelectClass + ' text-gray-400 focus:text-primary !text-sm'"
          :upper-class="false"
          :model-value="sourceWealthType"
          @change="$emit('source-wealth-type', $event)"
        >
          <option
            value=""
            disabled
            selected
            hidden
          >
            {{ $t('Ex. Income from Employment') }}
          </option>
          <template v-if="employType === 'EMPLOYED'">
            <option
              selected
              value="SOW-IND-Income"
            >
              {{ $t('Income from Employment') }}
            </option>
          </template>
          <template v-else>
            <option
              value="SOW-IND-Income"
              :selected="sourceWealthType === 'SOW-IND-Income' ? true:false"
            >
              {{ $t('Income from Employment') }}
            </option>
            <option
              value="SOW-IND-Interest"
              :selected="sourceWealthType === 'SOW-IND-Interest' ? true:false"
            >
              {{ $t('Interest/Dividend Income') }}
            </option>
            <option
              value="SOW-IND-MarketProfit"
              :selected="sourceWealthType === 'SOW-IND-MarketProfit' ? true:false"
            >
              {{ $t('Market Trading Profits') }}
            </option>
            <option
              value="SOW-IND-Property"
              :selected="sourceWealthType === 'SOW-IND-Property' ? true:false"
            >
              {{ $t('Property') }}
            </option>

            <option
              value="SOW-IND-Inheritance"
              :selected="sourceWealthType === 'SOW-IND-Inheritance' ? true:false"
            >
              {{ $t('Inheritance/Gift') }}
            </option>
            
            <option
              value="SOW-IND-Pension"
              :selected="sourceWealthType === 'SOW-IND-Pension' ? true:false"
            >
              {{ $t('Pension/Government Retirement benefit') }}
            </option>
            <option
              value="SOW-IND-Allowance"
              :selected="sourceWealthType === 'SOW-IND-Allowance' ? true:false"
            >
              {{ $t('Allowance/Spousal Income') }}
            </option>

            <option
              value="SOW-IND-Disability"
              :selected="sourceWealthType === 'SOW-IND-Disability' ? true:false"
            >
              {{ $t('Disability/Severance/Unemployment') }}
            </option>
          </template>
        </BaseSelect>
      </div>
    </div>

    <div
      v-if="sourceWealthType ==='SOW-IND-Other'"
      class="grid my-4"
    >
      <div>
        <label>
          {{ $t('Decribe Your Source Of Income') }}
        </label>
        <BaseInput
          :has-dark-mode="false"
          class="placeholder-custom-purple-500 h-14 w-full font-semibold"
          :placeholder="$t('Ex. Income from interest')"
          shadow="shadow-md"
          :model-value="customSourceOfIncome"
          @update:model-value="$emit('custom-source-of-income', $event)"
        />
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: 'SourceOfWealthForm',
  props: {
    sourceWealthType: {
      type: String,
      default: ''
    },
    customSourceOfIncome: {
      type: String,
      default: ''
    },
    usedForFunds: {
      type: Boolean,
      default: false
    },
    incomeSourcePercent:{
      type: String,
      default: ''
    },
    baseSelectClass: {
      type: String,
      default: 'h-14 px-4 py-4 w-full bg-transparent border-none shadow-md font-semibold rounded-sm'
    },
    employType:{
      type: String,
      default: ''
    },
  },

  emits: [
    'source-wealth-type',
    'custom-source-of-income',
    'used-for-funds',
    'income-source-percent'
  ]
}
</script>
  