<template>
  <div v-if="notification.notifiable">
    <Notification
      :pivot="notification.pivot"
      @remove="$emit('remove', { id: notification.id })"
    >
      <div @click="$parent.redirect({ name: 'companyContacts', id: notification.notifiable.company_contact.company.slug_url, notificationId: notification.id})">
        <AvatarSlot
          :stock-user="notification.notifiable.stock_user"
        >
          <div class="text-xs font-medium mr-6">
            <span class="font-bold">
              <RouterLink :to="{name: 'userFlow', params: {id: notification.notifiable.stock_user.id}}">{{ notification.notifiable.stock_user.full_name }}</RouterLink>
            </span> {{ $t('added contact') }} <span class="font-bold">'{{ notification.notifiable.company_contact.full_name }}'</span>
            <span v-if="notification.notifiable.company_contact.company">
              {{ $t('for') }}
              <RouterLink
                class="font-bold"
                :to="{name: 'companyOverview', params: {id: notification.notifiable.company_contact.company.slug_url}}"
              > {{ notification.notifiable.company_contact.company.name }}</RouterLink>
            </span>
            <div>{{ time }}</div>
          </div>
        </AvatarSlot>
      </div>
    </Notification>
  </div>
</template>

<script>

import Notification from './Notification'

export default {
  name: 'NotificationCompanyContact',
  components: {
    Notification
  },
  props: {
    notification: { type: Object, default: () => {} },
    time: { type: String, default: '' }
  },
emits: [
'remove'
]
}
</script>
