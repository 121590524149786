<template>
  <div class="pt-3 pb-4 border-b border-gray-400 relative">
    <Icon
      name="x"
      class="absolute top-0 right-0 -mt-2 -mr-1 bg-custom-orange-400 w-4 h-4 text-white rounded-full flex items-center justify-center"
      @click="$emit('remove')"
    />
    <BaseInput
      placeholder="Text"
      :model-value="text"
      class="mb-1 w-full"
      @update:model-value="$emit('update', {text: $event.target.value, link})"
    />
    <BaseInput
      placeholder="Link"
      :model-value="link"
      class="w-full"
      @update:model-value="$emit('update', {text, link: $event.target.value})"
    />
  </div>
</template>

<script>

export default {
  name: 'TextAndLinkButtonAdder',
  props: {
    text: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    }
  },
  emits: [
    'remove',
    'update'
  ]
}
</script>
