<template>
  <ModalContainer
    v-if="forcedVersionAvailable"
    disable-close
    full-width
    full-size
    hide-close
    :padding="false"
  >
    <ForcedUpgradeMessage />
  </ModalContainer>
</template>

<script>
import ModalContainer from '@/modules/modal'
import ForcedUpgradeMessage from './_components/ForcedUpgradeMessage'
import { useAppsStore } from '../../stores/app'

export default {
  name: 'ForcedUpgradeModalModule',
  components: {
    ModalContainer,
    ForcedUpgradeMessage
  },
  setup(){
    const appStore = useAppsStore()
    return { appStore }
  },
  computed: {
    forcedVersionAvailable(){
      return this.appStore.forcedVersionAvailable
    }
  }
}
</script>
