import { get, post } from '../helpers/axiosHelper'

const url = `${process.env.VUE_APP_API_URL}`

const getPaymentMethods = async (countryCode) => get(`${url}/fetch-payment-methods/${countryCode}`)
const getCompanyPaymentMethods = async (companyId) => get(`${url}/subscription/${companyId}/get-payment-methods`)
const fetchSubscriptions = async (companyId) => get(`${url}/subscription/${companyId}/get-subscriptions`)
const cancelSubscription = async (subscriptionId) => post(`${url}/subscription/${subscriptionId}/cancel-subscription`)
const setDefaultPaymentMethod = async (subscriptionId, data) => post(`${url}/subscription/${subscriptionId}/update-payment-method`, data)
const createPaymentInvoice = async(payload) => post(`${url}/${payload.companyId}/company-premium-invoice-payment`, payload.body)

export default {
  getCompanyPaymentMethods,
  getPaymentMethods,
  fetchSubscriptions,
  cancelSubscription,
  setDefaultPaymentMethod,
  createPaymentInvoice
}
