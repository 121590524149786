<template>
  <div v-if="notification.notifiable">
    <Notification
      :pivot="notification.pivot"
      @remove="$emit('remove', { id: notification.id })"
    >
      <AvatarSlot
        :stock-user="notification.notifiable.stock_user"
      >
        <div
          class="text-xs font-medium mr-6"
          @click="$parent.redirectCompany({ page: 'companyOverview', company: notification.notifiable.company, notificationId: notification.id})"
        >
          <span class="font-bold">
            <RouterLink :to="{name: 'userFlow', params: {id: notification.notifiable.stock_user.id}}">{{ notification.notifiable.stock_user.full_name }}</RouterLink>
          </span>
          <span v-if="notification.total>1"> {{ $t('and') }}  {{ notification.total - 1 }} {{ $t('people') }}</span>
          {{ $t('has commented on your trigger') }}
          <span
            v-if="notification.notifiable.company"
            class="whitespace-nowrap"
          >
            {{ $t('for') }}
            <RouterLink
              class="font-bold"
              :to="{name: 'companyOverview', params: {id: notification.notifiable.company.slug_url}}"
            > {{ notification.notifiable.company.name }}</RouterLink>
          </span>
          <div>{{ time }}</div>
        </div>
      </AvatarSlot>
    </Notification>
  </div>
</template>

<script>

import Notification from './Notification'

export default {
  name: 'NotificationTriggerComment',
  components: {
    Notification
  },
  props: {
    notification: { type: Object, default: () => {} },
    time: { type: String, default: '' }
  },
emits: [
'remove'
]
}
</script>
