<template>
  <div class="flex flex-col justify-center items-center h-full">
    <!--     -->
    <div v-if="loading">
      <div class="w-full flex justify-center">
        <Loader />
      </div>
      <div class="dark:text-white text-lg">
        {{ $t('Loading') }}
      </div>
    </div>


    <div v-else>
      <div
        v-if="status === 'PEP Failed'"
        class="w-full flex justify-center items-center"
      >
        <Icon :name="'error'" />
        <div class="ml-2 dark:text-white text-lg">
          {{ $t('Sorry you are not allowed to proceed, as we have found that you are a Politically Exposed Person.') }}
        </div>
      </div>
      <div
        v-else-if="status === 'O'"
        class="w-full flex justify-center items-center"
      >
        <Icon :svg="successSvg" />
        <div class="ml-2 dark:text-white text-lg">
          {{ $t('Your account is been verified successfully') }}
        </div>
      </div>
      <div
        v-else-if="status === 'E'"
        class="w-full flex justify-center items-center"
      >
        <Icon
          :name="'error'"
          class="text-lg"
        />
        <div class="ml-2 dark:text-white text-lg">
          {{ $t('Something went wrong, please try again later.') }}
        </div>
      </div>
      <div
        v-else-if="status === 'A'"
        class="w-full flex justify-center items-center"
      >
        <Icon :svg="deletedSvg" />
        <div class="ml-2 dark:text-white text-lg">
          {{ $t('Your application was deleted, need to reverify the KYC') }}
        </div>
      </div>
      <div
        v-else-if="status === 'N'"
        class="w-full flex justify-center items-center"
      >
        <Icon :svg="processingSvg" />
        <div class="ml-2 dark:text-white text-lg">
          {{ $t('Your account is in processing, no Funding Details have been provided.') }}
        </div>
      </div>
      <div
        v-else-if="status === 'C'"
        class="w-full flex justify-center items-center"
      >
        <Icon :svg="deletedSvg" />
        <div class="ml-2 dark:text-white text-lg">
          {{ $t('Your account is been closed') }}
        </div>
      </div>

      <div
        v-else-if="status === 'P'"
        class="w-full flex justify-center items-center"
      >
        <Icon :svg="processingSvg" />
        <div class="ml-2 dark:text-white text-lg">
          {{ $t('Your account is in processing') }}
        </div>
      </div>
      <div
        v-else-if="status === 'R'"
        class="w-full flex justify-center items-center"
      >
        <Icon :svg="deletedSvg" />
        <div class="ml-2 dark:text-white text-lg">
          {{ $t('Your application is rejected, need to reverify the KYC') }}
        </div>
      </div>
      <div
        v-else-if="status === 'Already processed'"
        class="w-full flex justify-center items-center"
      >
        <Icon :svg="deletedSvg" />
        <div class="ml-2 dark:text-white text-lg">
          {{ $t('Your application already processed, please wait while we sending you towards profile settings') }}
        </div>
      </div>

      <div
        v-else-if="status === 'Error'"
        class="w-full "
      >
        <div class="flex justify-center items-center mb-4">
          <Icon
            :name="'error'"
            class="text-2xl"
          />
          <div class="ml-2 dark:text-white text-xl">
            {{ $t('Errors') }}
          </div>
        </div>


        <div class="shadow-md rounded p-2">
          <div
            v-for="(error, index) in errors"
            :key="index"
            class="flex justify-center items-center"
          >
            <Icon
              :name="'x'"
              class="text-xl"
            />
            <div class="ml-2 dark:text-white text-md">
              {{ error }}
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="mt-8">
      <div class="flex mt-8 gap-6 justify-between items-end">
        <button
          v-if="!loading && (status === 'Error' || status === 'PEP Failed')"
          class="cursor-pointer py-2 px-4 text-shadow font-bold rounded bg-primary 
          hover:bg-primary text-white rounded-xl disabled:opacity-50 flex items-center justify-center"
          type="button"
          :disabled="loading"
          @click="$emit('back-to-form')"
        >
          <Icon name="arrow-left-alt" /> {{ $t('Back to form') }}
        </button>

        <button
          v-if="!loading && (status === 'A' || status === 'C' || status === 'R' || status === 'E' || status === 'Error' || status === 'PEP Failed')"
          class="cursor-pointer py-2 px-4 text-shadow font-bold rounded bg-primary 
          hover:bg-primary text-white rounded-xl disabled:opacity-50 "
          type="button"
          :disabled="loading"
          @click="$router.push({ name: 'myEucapsSettings', query: { navigate: 'tradingRef' } })"
        >
          {{ $t('Continue later') }}
        </button>


        <button
          v-else-if="!loading && (status === 'O' || status === 'N' || status === 'P')"
          class="text-white cursor-pointer py-2 px-4 text-shadow font-bold rounded bg-primary 
          hover:bg-primary rounded-xl disabled:opacity-50 "
          type="button"
          :disabled="loading"
          @click="$emit('continue')"
        >
          {{ $t('Close') }}
        </button>


        <button
          v-if="!loading && (status === 'O' || status === 'N' || status === 'P')"
          class="text-white cursor-pointer py-2 px-4 text-shadow font-bold rounded bg-primary 
          hover:bg-primary bg-custom-orange-400 rounded-xl disabled:opacity-50 flex items-center"
          type="button"
          :disabled="loading"
          @click="$router.push({ name: 'myEucapsSettings', query: { navigate: 'tradingRef' } })"
        >
          {{ $t('Go to Profile') }}
          <Icon
            class="text-xl"
            :name="'chevrons-right'"
          />
        </button>


        <button
          v-if="!loading && (status === 'A' || status === 'C' || status === 'R' || status === 'E' || status === 'Error')"
          class="text-white cursor-pointer py-2 px-4 text-shadow font-bold rounded bg-primary 
          hover:bg-primary bg-custom-orange-400 rounded-xl disabled:opacity-50 "
          type="button"
          :disabled="loading"
          @click="$emit('retry')"
        >
          {{ $t('Retry') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressModal',
  props: {
    loading: {
      type: String,
      default: ''
    },
    errors: {
      type: Array,
      default: () => []
    },
    status: {
      type: String,
      default: ''
    },
    successSvg: {
      type: String,
      default: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);transform: ;msFilter:;"><path d="M20.995 6.9a.998.998 0 0 0-.548-.795l-8-4a1 1 0 0 0-.895 0l-8 4a1.002 1.002 0 0 0-.547.795c-.011.107-.961 10.767 8.589 15.014a.987.987 0 0 0 .812 0c9.55-4.247 8.6-14.906 8.589-15.014zM12 19.897C5.231 16.625 4.911 9.642 4.966 7.635L12 4.118l7.029 3.515c.037 1.989-.328 9.018-7.029 12.264z"></path><path d="m11 12.586-2.293-2.293-1.414 1.414L11 15.414l5.707-5.707-1.414-1.414z"></path></svg>'
    },
    deletedSvg: {
      type: String,
      default: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);transform: ;msFilter:;"><path d="M20.5 5A1.5 1.5 0 0 0 19 6.5V11h-1V4.5a1.5 1.5 0 0 0-3 0V11h-1V3.5a1.5 1.5 0 0 0-3 0V11h-1V5.5a1.5 1.5 0 0 0-3 0v10.81l-2.22-3.6a1.5 1.5 0 0 0-2.56 1.58l3.31 5.34A5 5 0 0 0 9.78 22H17a5 5 0 0 0 5-5V6.5A1.5 1.5 0 0 0 20.5 5z"></path></svg>'
    },
    processingSvg: {
      type: String,
      default: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);transform: ;msFilter:;"><path d="M2 11h5v2H2zm15 0h5v2h-5zm-6 6h2v5h-2zm0-15h2v5h-2zM4.222 5.636l1.414-1.414 3.536 3.536-1.414 1.414zm15.556 12.728-1.414 1.414-3.536-3.536 1.414-1.414zm-12.02-3.536 1.414 1.414-3.536 3.536-1.414-1.414zm7.07-7.071 3.536-3.535 1.414 1.415-3.536 3.535z"></path></svg>'
    }
  },

  emits: [
    'continue',
    'retry',
    'close',
    'back-to-form'
  ]
}
</script>
<style lang="scss" scoped>
ol {
  list-style: auto;

  li {
    list-style: inherit;

  }
}
</style>