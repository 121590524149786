import { RepositoryFactory } from "@/api/repositoryFactory"
const repository = RepositoryFactory.get("companies")
import { defineStore } from "pinia"
import { useSnackbarStore } from "./snackbar"
import { useCompaniesStore } from "./companies"

export const useBoardMembersStore = defineStore("boardMembers", {
  state: () => ({
    selectedCompanyId: null,
    companyBoardMembers: {},
    loading: false,
    newLoading: false,
    editableId: null,
    alert: {
      title: "",
      create: false,
      body: "",
      type: "",
      showConfirmButton: false,
    },
  }),
  getters: {
    boardMembers(state) {
      return state.companyBoardMembers[state.selectedCompanyId]
    },
  },
  actions: {
    async fetch() {
      try {
        this.selectedCompanyId = useCompaniesStore().selectedCompanyId
        this.loading = true
        const response = await repository.boardMemberList(
          useCompaniesStore().selectedCompanySN
        )
        this.loading = false
        if (response.status === 200 && response.data) {
          this.companyBoardMembers = {
            ...this.companyBoardMembers,
            [this.selectedCompanyId]: response.data,
          }
        }
      } catch (e) {
        this.loading = false
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async pushBoardMembers(payload) {
      this.companyBoardMembers = {
        ...this.companyBoardMembers,
        [payload[0].company.id]: [payload],
      }
    },
    async refetch() {
      try {
        this.selectedCompanyId = useCompaniesStore().selectedCompanyId
        this.newLoading = true
        const response = await repository.boardMemberList(
          useCompaniesStore().selectedCompanySN
        )
        this.newLoading = false
        if (response.status === 200 && response.data) {
          this.companyBoardMembers = {
            ...this.companyBoardMembers,
            [this.selectedCompanyId]: response.data,
          }
        }
      } catch (e) {
        this.newLoading = false
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async deleteRequest(contactId) {
      try {
        const response = await repository.boardMemberDeleteRequest(
          useCompaniesStore().selectedCompanyId,
          contactId
        )
        if (response.status === 204) {
          useSnackbarStore().snack({
            text: "Delete request added successfully",
            type: "success",
            show: true,
            autoclose: true,
          })
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    resetAlert() {
      this.alert = {
        title: "",
        create: false,
        body: "",
        type: "",
        showConfirmButton: false,
      }
    },
    setAlert(payload) {
      this.alert.title = payload.title
      this.alert.body = payload.body
      this.alert.create = payload.create
      this.alert.type = payload.type
      this.alert.showConfirmButton = payload.showConfirmButton
    },
    async delete(boardMemberId) {
      try {
        const response = await repository.boardMemberDelete(
          useCompaniesStore().selectedCompanyId,
          boardMemberId
        )
        if (response.status === 204) {
          const index = this.companyBoardMembers[
            this.selectedCompanyId
          ].findIndex((member) => member.id === boardMemberId)
          this.companyBoardMembers[this.selectedCompanyId].splice(index, 1)
          this.companyBoardMembers = { ...this.companyBoardMembers }
          // useSnackbarStore().snack({ text: 'Delete request added successfully', type: 'success', show: true, autoclose: true })
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message,
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async editBoardMember(payload) {
      try {
        this.editableId = payload.boardMemberId
        const response = await repository.editBoardMember(
          this.selectedCompanyId,
          payload.boardMemberId,
          payload
        )
        if (response.status === 200) {
          let responsePayload = [response.data]

          let members = this.companyBoardMembers[this.selectedCompanyId]
          responsePayload.find((response) => {
            const flag = members.findIndex((asset) => {
              return response.id === asset.id
            })
            if (flag > -1) {
              members[flag] = response
            } else {
              members.push(response)
            }
          })
          this.companyBoardMembers = { ...this.companyBoardMembers }

          this.editableId = null
        }
      } catch (e) {
        this.editableId = null
        useSnackbarStore().snack({
          text: e.response.data.message,
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async addBoardMember(payload) {
      try {
        this.newLoading = true
        const response = await repository.addBoardMember(
          this.selectedCompanyId,
          payload
        )
        if (response.status === 204) {
          useSnackbarStore().snack({
            text: "Board member added successfully",
            type: "success",
            show: true,
            autoclose: true,
          })
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    reset() {
      this.companyBoardMembers = {}
    },
  },
})
