<template>
  <div class="fixed bottom-0 left-0 w-full bg-white shadow-md z-50">
    <div class="w-full flex items-center justify-between p-4">
      <div class="mr-4 flex items-center gap-x-2">
        <img
          v-if="thumbnail"
          :src="thumbnail"
          alt="Audio thumbnail"
          class="h-12 w-12 object-cover rounded"
        >
        <div
          v-else
          class="h-12 w-12 bg-gray-200 rounded"
        />
        <!-- Title & Subtitle-->
        <div class="flex flex-col">
          <h3 class="font-semibold">
            {{ title }}
          </h3>
          <p class="text-xs text-gray-500">
            {{ subtitle }}
          </p>
        </div>
      </div>
      <div class="flex-1">
        <audio
          ref="audioElement"
          :src="source"
          @play="onPlay"
          @pause="onPause"
          @timeupdate="onTimeUpdate"
          @volumechange="onVolumeChange"
          @loadedmetadata="onLoadedMetadata"
        />
        <div class="flex items-center gap-4 w-full justify-between">
          <div class="flex items-center gap-x-2 w-full">
            <span>{{ formattedCurrentTime }}</span>
            <input
              v-model="currentTime"
              type="range"
              min="0"
              :max="duration"
              step="0.1"
              class="seek-slider w-full"
              @input="seek($event.target.value)"
            >
            <span>{{ formattedDuration }}</span>
            <button @click="togglePlay">
              <Icon
                :name="isPlaying ? 'pause' : 'play'"
                class="text-3xl text-custom-purple-500"
              />
            </button>
          </div>
          <div class="flex items-center">
            <Icon
              name="volume"
              solid
              class="text-xl text-custom-purple-500"
            />
            <input
              v-model="volume"
              type="range"
              min="0"
              max="1"
              step="0.01"
              class="volume-slider"
              @input="changeVolume($event.target.value)"
            >
          </div>
          <button @click="$emit('close')">
            <Icon
              name="x"
              class="text-2xl text-custom-purple-500"
            />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue'

export default {
  props: {
    source: {
      type: String,
      default: ''
    },
    thumbnail: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    }
  },
  emits: ['close'],
  setup() {
    const audioElement = ref(null)
    const isPlaying = ref(false)
    const currentTime = ref(0)
    const duration = ref(0)
    const volume = ref(1) // Default volume is 100%

    const formatTime = (seconds) => {
      const h = Math.floor(seconds / 3600)
        .toString()
        .padStart(2, '0')
      const m = Math.floor((seconds % 3600) / 60)
        .toString()
        .padStart(2, '0')
      const s = Math.floor(seconds % 60)
        .toString()
        .padStart(2, '0')
      return h + ':' + m + ':' + s
    }

    const formattedCurrentTime = computed(() => formatTime(currentTime.value))
    const formattedDuration = computed(() => formatTime(duration.value))

    const togglePlay = () => {
      if (isPlaying.value) {
        audioElement.value.pause()
      } else {
        audioElement.value.play()
      }
    }

    const onPlay = () => {
      isPlaying.value = true
    }

    const onPause = () => {
      isPlaying.value = false
    }

    const onTimeUpdate = () => {
      currentTime.value = audioElement.value.currentTime
    }

    const onLoadedMetadata = () => {
      duration.value = audioElement.value.duration
    }

    const onVolumeChange = () => {
      volume.value = audioElement.value.volume
    }

    const seek = (time) => {
      audioElement.value.currentTime = time
    }

    const changeVolume = (newVolume) => {
      audioElement.value.volume = newVolume
    }

    watch(volume, (newVolume) => {
      if (audioElement.value) {
        audioElement.value.volume = newVolume
      }
    })

    return {
      audioElement,
      isPlaying,
      currentTime,
      duration,
      volume,
      formattedCurrentTime,
      formattedDuration,
      togglePlay,
      onPlay,
      onPause,
      onTimeUpdate,
      onLoadedMetadata,
      onVolumeChange,
      seek,
      changeVolume
    }
  }
}
</script>

<style scoped>
/* Custom styles for the volume slider */
.volume-slider {
  accent-color: #311c3b; /* Using the theme color from Tailwind configuration */
  height: 5px;
}

.volume-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background: #311c3b;
}

.volume-slider::-moz-range-thumb {
  background: #311c3b;
}

.volume-slider::-ms-thumb {
  background: #311c3b;
}

/* Custom styles for the seek slider */
.seek-slider {
  accent-color: #311c3b;
  height: 5px;
}

.seek-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background: #311c3b;
}

.seek-slider::-moz-range-thumb {
  background: #311c3b;
}

.seek-slider::-ms-thumb {
  background: #311c3b;
}
</style>
