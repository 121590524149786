<template>
  <BaseButton
    :type="type"
    :no-padding="noPadding"
    :locked="locked"
    :class="isSecondary ? 'border border-primary hover:border-primary text-primary hover:text-primary' : 'bg-primary hover:bg-primary text-primary-dark'"
    @click="$emit('click', $event)"
  >
    <slot />
  </BaseButton>
</template>

<script>

export default {
  name: 'ActiveButton',
  props: {
    secondary: { type: Boolean, default: false },
    noPadding: { type: Boolean, default: false },
    text: { type: String, default: '' },
    locked: { type: Boolean, default: false },
    type: { type: String, default: 'button' },
  },
  emits: [
    'click'
  ],
  computed: {
    isSecondary () {
      return (this.secondary !== false)
    }
  }
}
</script>
