import { get, post } from '../helpers/axiosHelper'

const url = `${process.env.VUE_APP_API_URL}`

const searchTags = async (data) => get(`${url}/search-filter/search/tags`, data)
const fetchFilterData = async () => get(`${url}/screener-filter-data`)
const getResult = async (data) => get(`${url}/search-filter/get/result`, data)
const scroll = async (link, data) => get(`${link}`, data)
const setHistory = async (data) => post(`${url}/search-filter/set/history`, data)
const getHistory = async (userId) => get(`${url}/search-filter/get/history/user/${userId}`)
const premiumCompanies = async (payload) => get(`${url}/landing-page/list/latest-premium-companies?limit=${payload}`)


export default {
  searchTags,
  fetchFilterData,
  getResult,
  scroll,
  setHistory,
  getHistory,
  premiumCompanies
}
