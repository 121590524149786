<template>
  <div class="flex justify-center items-center">
    <img
      :src="darkMode ? '/svg/spinners/SpinnerDarkmode.svg' : '/svg/spinners/SpinnerDefault.svg'"
      alt="Loading..."
      :class="['animate-spin', small ? 'w-6 h-6' : 'w-16 h-16']"
    >
  </div>
</template>

<script>
import {useUsersStore} from '@/stores/users'

export default {
  name: 'Spinner',
  props: {
    small: {
      type: Boolean,
      default: false
    },
  },
  setup () {
  const usersStore = useUsersStore()
  return {usersStore}
},
computed: {
  darkMode() {
    return this.usersStore.activeTheme === 'dark'
  }
}
};
</script>