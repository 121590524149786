<template>
  <BaseCard class="dark:text-custom-purple-100">
    <template #body>
      <div class="flex justify-center py-6">
        <!-- <div
        class="w-16 h-16 rounded-full flex justify-center items-center"
        :class="bgColor"
      >
        <Icon
          :name="icon"
          class="text-3xl"
          :class="iconColor"
        />
        
      </div> -->
        <img
          src="/svg/conversation2.svg"
          class="w-20 h-20 p-1 rounded-full bg-custom-orange-100 cursor-pointer mr-1"
        >
      </div>
      <div class="flex flex-col justify-end items-center px-5 mb-10 z-10">
        <p class="font-bold text-center mt-3 text-lg font-medium">
          {{ title }}
        </p>
        <p class="text-xs font-semibold my-2 text-center">
          {{ description }}
        </p>
        <RouterLink
          :to="{ name: link }"
          class="text-white text-sm px-6 py-2 rounded-2xl mt-2 cursor-pointer"
          :class="bgColor"
        >
          {{ buttonText }}
        </RouterLink>
      </div>
    </template>
  </BaseCard>
</template>

  <script>
  export default {
    name: 'SideLongBanner',
    props: {
      icon: {
        type: String,
        default: 'pencil'
      },
      color: {
        type: String,
        default: 'orange'
      },
      title: {
        type: String,
        default: ''
      },
      description: {
        type: String,
        default: ''
      },
      buttonText: {
        type: String,
        default: 'Button'
      },
      link: {
        type: String,
        default: ''
      },
    },
    computed: {
      bgColor() {
        return `bg-custom-${this.color}-400`
      },
      iconColor() {
        if(this.color === 'green') return 'text-custom-purple-500'
        return 'text-white'
      }
    }
  }
  </script>
  
  <style scoped>
  .text-dark-purple {
    color: #A091A7;
  }
  </style>