import { defineStore } from "pinia"

export const useTodosStore = defineStore("todo", {
  state: () => ({
    list: [],
  }),
  getters: {
    get: (state) => state.list,
  },
  actions: {
    reset() {
      this.list = []
    },
    add(payload) {
      const todoAlreadyAdded = this.list.find(
        (todo) => todo.text === payload.text
      )

      if (todoAlreadyAdded === undefined) {
        this.list = [...this.list, payload]
      }
    },
  },
})
