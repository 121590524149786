import { defineStore } from "pinia"

export const useSnackbarStore = defineStore("snackbar", {
  state: () => ({
    originalSnack: {
      text: null,
      show: false,
      time: 5000,
      type: "info",
      autoclose: true,
      test: false,
    },
  }),
  actions: {
    startTimer() {
      if (this.originalSnack.autoclose) {
        const self = this
        setTimeout(() => {
          self.close()
        }, 5000)
      }
    },
    snack(options) {
      
      if (options && options.e) {
        if (options.e.response) {
          console.log("SNACK ERROR", options)
          if (
            options.e.response.status === 403 ||
            options.e.response.status === "403"
          ) {
            this.originalSnack = {
              text: options.e.response.data.message,
              type: "error",
              show: true,
              autoclose: true,
            }
            return
          }
          // if (options.e.response.status === 401) {
          // } else
          if (options.e.response.status === 422) {
            if (options.e.response.data.errors) {
              this.originalSnack = {
                text: options.e.response.data.errors[
                  Object.keys(options.e.response.data.errors)[0]
                ][0],
                type: "error",
                show: true,
                autoclose: true,
              }
            } else {
              this.originalSnack = {
                text: "Please add all required data",
                type: "error",
                show: true,
                autoclose: true,
              }
            }
            this.startTimer()
          } else if (options.e.response.status === 404) {
            this.originalSnack = {
              text: options.text ?? options.e.response.data.message,
              type: "error",
              show: true,
              autoclose: true,
            }
            this.startTimer()
          } else if (options.e.response.message) {
            this.originalSnack = {
              text: options.e.response.data.message,
              type: "error",
              show: true,
              autoclose: true,
            }
            this.startTimer()
          } else {
            this.originalSnack = {
              text: "An error occurred. Please contact support if it remains",
              type: "error",
              show: true,
              autoclose: true,
            }
            this.startTimer()
          }
        } else {
          if (options.e.toString().indexOf("401") > -1) {
            this.startTimer()
            return
          }
          if (options.e.toString().indexOf("422") > -1) {
            this.originalSnack = {
              text: "Please add all required data",
              type: "error",
              show: true,
              autoclose: true,
            }
            this.startTimer()
            return
          }

          if (options.e.toString().indexOf("404") > -1) {
            this.originalSnack = {
              text: options.e.response.data.message,
              type: "error",
              show: true,
              autoclose: true,
            }
            this.startTimer()
            return
          }
        }

        console.log(options)

        // this.snack = { text: 'An error occurred. Please contact support if it remains', type: 'error', show: true, autoclose: true }
        this.startTimer()
        return
      }

      this.originalSnack = { ...this.originalSnack, ...options }

      this.startTimer()
    },
    close() {

      this.originalSnack.show = false
      this.originalSnack = { ...this.originalSnack }
    },
  },
})
