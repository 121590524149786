<template>
  <div>
    <EmailVerification
      :pin1="pin1"
      :pin2="pin2"
      :pin3="pin3"
      :email="user.email"
      :loading-otp="loadingOTPCheck"
      :is-mobile="isMobile"
      :hide-back="hideBack"
      :onboarding-loading="onboardingLoading"
      :otp-error-message="otpErrorMessage"
      :edit-email="editEmail"
      :already-registered="alreadyRegistered"
      :loading-email="loading"
      @edit-email="handleEditEmail"
      @pin1="pin1 = $event"
      @pin2="pin2 = $event"
      @pin3="pin3 = $event"
      @submit="pinVerification"
      @resend-otp="resendOtp"
      @go-back="logout"
      @paste-code="pasteCode"
      @update-email="editedEmail = $event.target.value"
      @save-email="saveEmail"
    />
  </div>
</template>

<script>

import EmailVerification from './_components/EmailVerification.vue'
import { isMobile } from '@/js/helpers/mobile'
import { useUsersStore } from '../../stores/users'
import { useOnboardingsStore } from '../../stores/onboarding'
import { useSnackbarStore } from '../../stores/snackbar'

export default {
  name: 'EmailVerificationModule',
  components: {
    EmailVerification
  },
  props: {
    hideBack: {
      type: Boolean,
      default: false
    },
    process: {
      type: String,
      default: ''
    },
    email: {
      type: String,
      default: ''
    },
    onboardingLoading: {
      type: Boolean,
      default: false
    },
    userType: {
      type: String,
      default: ''
    }
  },
emits: [
'resend-otp',
'email-verified'
],
  setup(){
    const usersStore = useUsersStore()
    const onboardingStore = useOnboardingsStore()
    const snackbarStore = useSnackbarStore()
    return {usersStore, onboardingStore, snackbarStore }
  },
  data () {
    return {
      pin1: null,
      pin2: null,
      pin3: null,
      otpErrorMessage: '',
      editEmail: false,
      editedEmail: ''
    }
  },
  computed: {
    loadingOTPCheck(){
      return this.usersStore.loadingOTPCheck
    },
    user(){
      return this.usersStore.user
    },
    alreadyRegistered(){
      return this.usersStore.alreadyRegistered
    },
    loading(){
      return this.usersStore.loading
    },
    isMobile () {
      return isMobile()
    }
  },
  beforeCreate () {
    // console.log('here?')
    // if (localStorage.getItem('accessToken')) {
    //   this.$router.push({ name: 'generalfeed' })
    // }
  },
  mounted () {
    if (this.process !== 'onboarding') {
      this.resendOtp()
    }
  },
  methods: {
    handleEditEmail () {
      if (this.editEmail === false) { this.editedEmail = this.user.email }

      this.editEmail = !this.editEmail
    },
    saveEmail () {
      this.editEmail = false
      if (this.user.email !== this.editedEmail) {
        this.usersStore.edit( {
          id: this.user.id,
          email: this.editedEmail,
          username: this.user.profile.username,
          country: this.user.country ? this.user.country : null,
          process: 'verifyingEmail'
        })
      }
    },
    pasteCode (event) {
      let pin = event.clipboardData.getData('text/plain')
      if (pin.length >= 3) {
        this.pin1 = pin[0]
        this.pin2 = pin[1]
        this.pin3 = pin[2]
      } else if (pin.length === 2) {
        this.pin1 = pin[0]
        this.pin2 = pin[1]
      } else this.pin1 = pin[0]
    },
    resendOtp () {
      if (this.process === 'onboarding') {
        this.$emit('resend-otp')
      } else if (this.process === 'secondary-email') {
        this.usersStore.sendSecondaryEmailVerification( { email: this.email })
      } else {
        this.usersStore.sendEmailVerification()
      }
    },
    async pinVerification () {
      this.otpErrorMessage = ''
      if (this.pin1 > 9 || this.pin2 > 9 || this.pin3 > 9) {
        this.snackbarStore.snack({ type: 'error', e: 'Please enter a valid varification pin', show: true, autoclose: true })
      } else {
        let code = String(this.pin1) + String(this.pin2) + String(this.pin3)
        if (this.process === 'secondary-email') {
          await this.usersStore.otpSecondaryEmailVerification( { token: code, email: this.email })
        } else if (this.process === 'onboarding') {
          const response = await this.onboardingStore.otpVerification({ token: code }, this.user.profile.id)
          if (response === 'success') {
            this.$emit('email-verified')
          } else if (response.status === 'error') {
            this.pin1 = null
            this.pin2 = null
            this.pin3 = null
            this.otpErrorMessage = response.message

            // this.snackbarStore.snack({ type: 'error', e: response.message, show: true, autoclose: true })
          }
        } else {
          this.usersStore.otpVerification( { token: code, process: 'signupEmailVerification', userType: this.userType })
        }
      }
    },
    logout () {
      this.usersStore.logout()
    }
  }
}
</script>
