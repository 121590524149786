<template>
  <div
    class="p-4 text-center"
  >
    <div v-if="addSecondaryEmail">
      <div>
        <div v-if="!secondaryEmailSent">
          <p class="mb-2">
            Verify a secondary email:
          </p>
          <BaseInput
            type="email"
            :model-value="secondaryEmail"
            required
            class="w-full mb-2"
            @update:model-value="$emit('secondary-email', $event.target.value)"
          />
          <ActiveButton
            class="bg-custom-orange-400 h-12 self-center rounded-xl w-full disabled:opacity-50"
            :disabled="!validSecondaryEmail"
            @click="$emit('send-secondary-email', $event)"
          >
            <span class="text-white">
              Send verification email
            </span>
          </ActiveButton>
          <SquareButton
            class=" bg-gray-400 w-full rounded-xl text-base text-custom-purple-500 font-bold border-none px-6 py-2 mt-8"
            @click="$emit('add-secondary-email', $event)"
          >
            <span class="text-white">
              Cancel
            </span>
          </SquareButton>
        </div>
        <EmailVerificationContainer
          v-else
          :hide-back="true"
          :email="secondaryEmail"
          process="secondary-email"
          @resend-otp="$emit('resend-otp', $event)"
        />
      </div>
    </div>
    <div v-else>
      <p class="py-4 font-semibold font-lg">
        Your email {{ email }} do not match {{ company.name }} main domain {{ website }}.
      </p>
      <ActiveButton
        class="bg-custom-orange-400 h-12 self-center rounded-xl w-full"
        @click="$emit('add-secondary-email', $event)"
      >
        <span class="text-white">
          Add a secondary email
        </span>
      </ActiveButton>
      <div class="p-4">
        or
      </div>
      <SquareButton
        class=" bg-gray-400 w-full rounded-xl text-base text-custom-purple-500 font-bold border-none px-6 py-2 "
        @click="$emit('contact-sales', $event)"
      >
        <span class="text-white">
          Contact sales
        </span>
      </SquareButton>
    </div>
  </div>
</template>

<script>

import EmailVerificationContainer from '@/modules/emailVerification'

export default {
  name: 'EmailMismatch',
  components: {
    EmailVerificationContainer
  },
  props: {
    company: {
      type: Object,
      default: () => {}
    },
    website: {
      type: String,
      default: ''
    },
    email: {
      type: String,
      default: ''
    },
    secondaryEmail: {
      type: String,
      default: ''
    },
    validSecondaryEmail: {
      type: Boolean,
      default: false
    },
    addSecondaryEmail: {
      type: Boolean,
      default: false
    },
    secondaryEmailSent: {
      type: Boolean,
      default: false
    },
    contactSales: {
      type: Boolean,
      default: false
    }
  },
emits: [
'secondary-email',
'send-secondary-email',
'add-secondary-email',
'resend-otp',
'contact-sales'
]
}
</script>
