function formatNumber(value) {
  if (value > -1 && value < 1) {
    let newValue = Number(Number(value).toFixed(3))
    if (value > 0 && newValue === 0) {
      newValue = Number(Number(value).toFixed(5))
    }
    return newValue
  } else {
    return Number(Number(value).toFixed(2))
  }
}

function formatPercentage(value) {
  return `${Number(value).toFixed(2)}%`
}

export default {
  install(app) {
    app.config.globalProperties.$number = formatNumber
    app.config.globalProperties.$percentage = formatPercentage
  }
}
