<template>
  <div>
    <div class="text-lg font-semibold">
      {{ $t('Personal Information') }}
    </div>

    <div class="grid gap-4 grid-cols-5 my-4">
      <div>
        <label>
          {{ $t('Salutation') }}
        </label>
        <BaseSelect
          :has-dark-mode="false"
          :appearance-none="false"
          :required="true"
          :classes="salutation ? baseSelectClass : baseSelectClass + ' text-gray-400 focus:text-primary !text-sm'"
          :upper-class="false"
          :model-value="salutation"
          @change="$emit('salutation', $event)"
        >
          <option value="Mr.">
            {{ $t('Mr.') }}
          </option>
          <option value="Mrs">
            {{ $t('Mrs.') }}
          </option>
          <option value="Ms.">
            {{ $t('Ms.') }}
          </option>
          <option value="Dr.">
            {{ $t('Dr.') }}
          </option>
          <option value="Ind.">
            {{ $t('Ind.') }}
          </option>
          <option value="Mx.">
            {{ $t('Mx.') }}
          </option>
        </BaseSelect>
      </div>

      <div class="col-span-2 ">
        <label>
          {{ $t('First Name') }}
        </label>

        <BaseInput
          :has-dark-mode="false"
          :required="true"
          class="h-14 font-semibold w-full"
          :placeholder="$t('Ex. Robin')"
          shadow="shadow-md"
          :maxlength="50"
          :minlength="1"
          :model-value="firstName"
          @update:model-value="$emit('first-name', $event)"
        />
      </div>
      <div class="col-span-2 ">
        <label>
          {{ $t('Last Name') }}
        </label>

        <BaseInput
          :has-dark-mode="false"
          :maxlength="50"
          :minlength="1"
          
          class=" h-14 font-semibold w-full"
          :required="true"
          :placeholder="$t('Ex. Henrikson')"
          shadow="shadow-md"
          :model-value="lastName"
          @update:model-value="$emit('last-name', $event)"
        />
      </div>
    </div>


    <!-- <div class="grid lg:grid-cols-2 gap-4 my-4">
      <div>
        <label>
          {{ $t('Middle Name') + ' ' }} ({{ $t('Optional') }})
        </label>


        <BaseInput
          :pattern="`[\x00-\x7F]+`"
          :has-dark-mode="false"
          :maxlength="18"
          :minlength="1"
          class=" h-14 w-full  font-semibold"
          :placeholder="$t('Ex. Torwald')"
          shadow="shadow-md"
          :model-value="middleName"
          @update:model-value="$emit('middle-name', $event)"
        />
      </div>

    
    </div> -->


    <div
      :ref="'dobPickerDiv'"
      class="grid lg:grid-cols-2 gap-4 my-4"
    >
      <div>
        <label>
          {{ $t('Date of Birth') }}
        </label>

        <!-- <DatePicker
        
          :popup-class="theme"
          :value="dob"
          class="h-14"
          type="date"
          format="YYYY-MM-DD"
          required
          value-type="format"
          :placeholder="$t('Ex. 1996-11-24')"
          @change="$emit('dob', $event)"
        /> -->
        
        <BaseInput
          :model-value="dob"
          :has-dark-mode="false"
          :required="true"
          class=" h-14 font-semibold w-full"
          :type="'date'"
          :title="'Please enter Date of Birth'"
          shadow="shadow-md"
          @update:model-value="$emit('dob', $event)"
        />
      </div>

      <div>
        <label>
          {{ $t('Country Of Birth') }}
        </label>
        <BaseSelect
          :has-dark-mode="false"
          :appearance-none="false"
          :required="true"
          :classes="countryOfBirth ? baseSelectClass : baseSelectClass + ' text-gray-400 focus:text-primary !text-sm'"
          :upper-class="false"
          :model-value="countryOfBirth"
          @change="$emit('country-of-birth', $event)"
        >
          <option
            value=""
            disabled
            selected
            hidden
          >
            {{ $t('Ex. Sweden') }}
          </option>

          <option
            v-for="country in countriesWithStates"
            :key="country.id"
            :value="country.ibkr_code"
            :selected="country.ibkr_code === countryOfBirth"
          >
            {{ country.name }}
          </option>
        </BaseSelect>
      </div>
    </div>
    <div class="grid lg:grid-cols-2 gap-4 my-4">
      <div>
        <label>
          {{ $t('Marital Status') }}
        </label>
        <BaseSelect
          :has-dark-mode="false"
          :appearance-none="false"
          :required="true"
          :classes="maritalStatus ? baseSelectClass : baseSelectClass + ' text-gray-400 focus:text-primary !text-sm'"
          :upper-class="false"
          :model-value="maritalStatus"
          @change="$emit('marital-status', $event)"
        >
          <option
            value=""
            disabled
            selected
            hidden
          >
            {{ $t('Ex. Single') }}
          </option>

          <option
            :selected="maritalStatus === 'M'"
            value="M"
          >
            {{ $t('Married') }}
          </option>
          <option
            :selected="maritalStatus === 'S'"
            value="S"
          >
            {{ $t('Single') }}
          </option>
          <option
            :selected="maritalStatus === 'D'"
            value="D"
          >
            {{ $t('Divorced') }}
          </option>
          <option
            :selected="maritalStatus === 'W'"
            value="W"
          >
            {{ $t('Widow') }}
          </option>
          <option
            :selected="maritalStatus === 'C'"
            value="C"
          >
            {{ $t('Common law partner') }}
          </option>
        </BaseSelect>
      </div>


      <div>
        <label>
          {{ $t('Children in the household') }}
        </label>
        <BaseInput
          :required="true"
          :has-dark-mode="false"
          :min="0"
          :step="1"
          class="h-14 w-full font-semibold text-right"
          :type="'number'"
          :placeholder="$t('Ex. 2')"
          shadow="shadow-md"
          :model-value="dependentNo"
          @update:model-value="$emit('dependent-no', $event)"
        />
      </div>
    </div>

    <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-4 my-4">
      <div>
        <label>
          {{ $t('Country Of Contact') }}
        </label>
        <BaseSelect
          :appearance-none="false"
          :has-dark-mode="false"
          :required="true"
          :classes="countryOfContact ? baseSelectClass : baseSelectClass + ' text-gray-400 focus:text-primary !text-sm'"
          :upper-class="false"
          :model-value="countryOfContact"
          @change="$emit('country-of-contact', $event)"
        >
          <option
            value=""
            disabled
            selected
            hidden
          >
            {{ $t('Ex. (+46) Sweden') }}
          </option>

          <option
            v-for="countryIsd in countriesWithIsd"
            :key="countryIsd.id"
            :value="countryIsd.country.ibkr_code"
            :selected="countryOfContact === countryIsd.country.ibkr_code"
          >
            ({{ countryIsd.isd_code }}) {{ countryIsd.country.name }}
          </option>
        </BaseSelect>
      </div>
      <div class="md:col-span-2">
        <label>
          {{ $t('Contact No') }}
        </label>
        <BaseInput
          :has-dark-mode="false"
          class=" h-14 w-full font-semibold  "
          :type="'number'"
          :placeholder="$t('Ex. 0046730912345')"
          shadow="shadow-md"
          :model-value="contactNo"
          @update:model-value="$emit('contact-no', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PersonalDetailsForm',

  props: {


    salutation: {
      type: String,
      default: ''
    },
    firstName: {
      type: String,
      default: ''
    },
    middleName: {
      type: String,
      default: ''
    },
    lastName: {
      type: String,
      default: ''
    },
    maritalStatus: {
      type: String,
      default: ''
    },
    dob: {
      type: String,
      default: ''
    },
    countryOfBirth: {
      type: String,
      default: ''
    },

    dependentNo: {
      type: String,
      default: ''
    },

    countryOfContact: {
      type: String,
      default: ''
    },

    contactNo: {
      type: String,
      default: ''
    },
    baseSelectClass: {
      type: String,
      default: 'h-14 px-4 py-4 w-full bg-transparent border-none shadow-md font-semibold rounded-sm'
    },
    countriesWithStates: {
      type: Array,
      default: () => []
    },
    countriesWithIsd: {
      type: Array,
      default: () => []
    }
  },

  emits: [
    'salutation',
    'first-name',
    'middle-name',
    'last-name',
    'marital-status',
    'dob',
    'country-of-birth',
    'dependent-no',
    'country-of-contact',
    'contact-no'
  ]
}
</script>
<style lang="scss">
.mx-datepicker {
  @apply h-14;
  @apply rounded-none;
}

.mx-input {

  @apply h-14;
  @apply rounded-none;
  @apply shadow-md;
}

.dark {
  .mx-input {
    @apply bg-transparent;
    @apply border-none;
    @apply shadow-md;
    @apply text-custom-purple-500;
    @apply placeholder-custom-purple-200;

  }

  .mx-icon-calendar {
    @apply text-custom-purple-200;
  }
}
</style>
