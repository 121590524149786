<template>
  <div
    v-if="showLikeComponent"
    :class="'bg-none z-10 ' + padding"
    @mouseleave="$emit('show-like-emoji', false)"
  >
    <div
      v-if="requireLogin && !isLoggedIn"
      class="bg-custom-purple-500 rounded-full text-white px-2 py-1"
    >
      <p>Login to react</p>
    </div>
    <div
      v-else
      class="flex rounded-full items-center justify-between"
      :class="[background, paddingX, paddingY, gap]"
    >
      <div
        v-for="emoji in usersStore.emojiList"
        :key="emoji"
        :class="paddingY === 'py-3' ? 'pt-1' : 'pt-0.5'"
      >
        <ShowEmoji
          :emoji="emoji"
          :size="emojiSize"
          class="cursor-pointer"
          @click="$emit('add-emoji', { colons: emoji })"
        />
      </div>
      <div
        class="relative cursor-pointer"
        @click="$emit('toggle-emoji-picker')"
      >
        <Icon
          name="smile"
          class="relative text-white"
          :class="[paddingY === 'py-3' ? 'text-xl' : 'text-sm']"
        />
        <Icon
          name="plus"
          class="text-white top-0 right-0 absolute flex items-center justify-center"
          :class="[
            background,
            paddingY === 'py-3' ? 'text-sm h-2 w-3 -mr-1 mt-1 pb-1' : 'text-xs h-2 w-2.5 -mr-1 mt-0.5 pb-0'
          ]"
        />
      </div>
      <div
        v-if="showClose"
        class="cursor-pointer ml-1"
        @click="$emit('close-like-component')"
      >
        <Icon
          name="x-circle"
          class="text-white"
          :class="[paddingY === 'py-3' ? 'text-xl' : 'text-base']"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { useUsersStore } from '@/stores/users'

export default {
  name: 'ReactionsMenu',
  props: {
    showLikeComponent: { type: Boolean, default: false },
    padding: { type: String, default: 'pb-8' },
    background: { type: String, default: 'bg-custom-purple-400' },
    emojiSize: { type: Number, default: 20 },
    paddingY: { type: String, default: 'py-3' },
    paddingX: { type: String, default: 'px-3' },
    gap: { type: String, default: 'gap-2' },
    showClose: { type: Boolean, default: false },
    requireLogin: { type: Boolean, default: false }
  },
  emits: ['show-like-emoji', 'toggle-emoji-picker', 'add-emoji', 'close-like-component'],
  setup() {
    const usersStore = useUsersStore()
    return { usersStore }
  },
  computed: {
    isLoggedIn() {
      return !(this.usersStore.user.profile_type === 'App\\Models\\GuestUser')
    }
  }
}
</script>
