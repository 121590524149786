import { get } from '../helpers/axiosHelper'

const url = `${process.env.VUE_APP_API_URL}/messages`

const list = async () => get(`${url}`)
const fetchMessages = async (id) => get(`${process.env.VUE_APP_API_URL}/users/${id}/messages`)
const fetchFeeds = async (id) => get(`${process.env.VUE_APP_API_URL}/users/${id}/feeds`)
const scroll = async (link, data) => get(`${link}`, data)
const fetchFeed = async (id) => get(`${process.env.VUE_APP_API_URL}/feeds/${id}/get`)
const fetchInternalAds = async () => get(`${process.env.VUE_APP_API_URL}/premium-company-internal-ads`)

export default {
  list,
  fetchInternalAds,
  fetchFeed,
  fetchMessages,
  scroll,
  fetchFeeds
}
