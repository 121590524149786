<template>
  <div
    class="flex justify-center"
    :class="!noBorder ? 'dark:bg-custom-purple-400 border-t dark:border-custom-purple-300 rounded-b-xl' : ''"
  >
    <BaseButton
      class="text-sm font-extrabold my-3"
      :class="[
        locked ? 'text-red-500 dark:text-red-500' : 'text-custom-lightPurple-400 dark:text-custom-purple-100',
        margin
      ]"
      :locked="locked"
      no-shadow
      @click="$emit('click', $event)"
    >
      <slot />
    </BaseButton>
  </div>
</template>

<script>
export default {
  name: 'WidgetFooterButton',
  props: {
    locked: { type: Boolean, default: false },
    margin: { type: String, default: 'my-3' },
    noBorder:{ type: Boolean, default: false },
  },
  emits: ['click']
}
</script>
