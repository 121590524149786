import { post, get } from '../helpers/axiosHelper'

const url = `${process.env.VUE_APP_API_URL}/verifications`

const authenticate = async (data) => post(`${url}/sweden/init`, data)
const verify = async (data) => post(`${url}/sweden/verify`, data)
const fetchFrejaQRUrl = async () => get(`${process.env.VUE_APP_API_URL}/freja/get-freja-QR-url`)
const getFrejaOneResult = async (data) => post(`${process.env.VUE_APP_API_URL}/freja/get-one-result`, data)
const cancelFrejaAuthentication = async (data) => post(`${process.env.VUE_APP_API_URL}/freja/cancel-authentication`, data)

export default {
  cancelFrejaAuthentication,
  getFrejaOneResult,
  fetchFrejaQRUrl,
  authenticate,
  verify
}
