<template>
  <Container
    wide
    bottom-padding
  >
    <div class="dark:text-custom-purple-100 text-justify">
      <Title secondary>
        General Terms of Service of Eucaps website
      </Title>
      <p class="mb-3 text-sm">
        Dated: March 1st, 2022
      </p>
      <Title>Introduction:</Title>
      <p class="mb-3 text-sm">
        Thank you for using the Eucaps platform from Eucaps Holding AB, and the products, services and features we make available to you as part of the platform (collectively, the “Service”).
      </p>
      <Title>Our Service</Title>
      <p class="mb-3 text-sm">
        The Service allows you to discover and/or edit content, provides a forum for companies and investors to connect, inform, and inspire others across the globe, and acts as a distribution platform for original content creators and advertisers large and small.
      </p>
      <Title>Your Service Provider</Title>
      <p class="mb-3 text-sm">
        The entity providing the Service is Eucaps Holding AB, a company operating under the laws of Sweden, located at SANDBACKSGATAN 3, 116 21 Stockholm, Sweden (referred to as “Eucaps”, “Eucaps Holding AB”, “we”, “us”, or “our”). References to Eucaps Holding AB’s “Affiliates” in these terms means the other companies within the Eucaps Holding AB corporate group (now or in the future).
      </p>
      <Title>Applicable Terms</Title>
      <p class="mb-3 text-sm">
        Your use of the Service is subject to these terms which may be updated from time to time (together, this "Agreement"). Any other links or references provided in these terms are for informational use only and are not part of the Agreement. Please read this Agreement carefully and make sure you understand it. If you do not understand the Agreement, or do not accept any part of it, then you may not use the Service.
      </p>
      <Title>Who may use the Service?</Title>
      <p class="text-sm">
        <b>Age Requirements</b>
      </p>
      <p class="mb-3 text-sm">
        You must be at least 18 years old to use the Service and legally be able to exercise your civil rights.
      </p>
      <p class=" text-sm">
        <b>Businesses</b>
      </p>
      <p class="mb-3 text-sm">
        If you are using the Service on behalf of a company or organisation, you represent that you have authority to act on behalf of that entity, and that such entity accepts this Agreement.
      </p>
      <Title>Your Use of the Service</Title>
      <p class="mb-3 text-sm">
        By using eucaps.com, you are consenting to the terms and conditions (“Privacy & Legal Policy”) of Eucaps Holding AB (SANDBACKSGATAN 3, 116 21 Stockholm, Sweden), “Eucaps Holding AB”, herein.
      </p>
      <Title>Content on the Service</Title>
      <p class="mb-3 text-sm">
        The content on the Service includes stock information, company information, graphics, photos, text (such as comments and scripts), branding (including trade names, trademarks, service marks, or logos), interactive features such as for instance company and stock lists, software, metrics, or even video and audio and other materials whether provided by you, Eucaps Holding AB or a third-party (collectively, "Content”).
      </p>
      <p class="mb-3 text-sm">
        Content is the responsibility of the person or entity that provides it to the Service, which is not Eucaps Holding AB. Eucaps Holding AB is under no obligation to host or serve Content. If you see any Content you believe does not comply with this Agreement, including by violating the law, you can report this to Eucaps Holding AB.
      </p>
      <Title>Eucaps User Accounts and features</Title>
      <p class="mb-3 text-sm">
        You can use parts of the Service, such as browsing and searching for Content, without having a Eucaps account. However, you do need a Eucaps user account to use some features. With a Eucaps user account, you may for instance be able to like companies, subscribe to companies, create your own lists, add companies to favorites, analyze companies and more. Creating a list will give you access to additional features and functions, such as following stock prices and stock news (where available).
      </p>
      <p class="mb-3 text-sm">
        To protect your Eucaps user account, keep your password confidential. You should not reuse your Eucaps user account password on third-party applications.
      </p>
      <Title>Your Information</Title>
      <p class="mb-3 text-sm">
        Our Terms of Service (see section “personal data”) explains how we treat your personal data and protect your privacy when you use the Service.
      </p>
      <p class="mb-3 text-sm">
        We will process any content uploaded or created by you to the Service in accordance with the Terms of Service here.
      </p>
      <Title>Permissions and Restrictions</Title>
      <p class="mb-3 text-sm">
        You may access and use the Service as made available to you, as long as you comply with this Agreement and applicable law. You may view or listen to Content for your personal, noncommercial use.
      </p>
      <p class="mb-3 text-sm">
        The following restrictions apply to your use of the Service. You are not allowed to:
      </p>
      <p class="mb-3 text-sm">
        1. access, reproduce, download, distribute, transmit, broadcast, display, sell, license, alter, modify or otherwise use any part of the Service or any Content except: (a) as expressly authorized by the Service; or (b) with prior written permission from Eucaps Holding AB and, if applicable, the respective rights holders;
      </p>
      <p class="mb-3 text-sm">
        2. circumvent, disable, fraudulently engage with, or otherwise interfere with any part of the Service (or attempt to do any of these things), including securityrelated features or features that (a) prevent or restrict the copying or other use of Content or (b) limit the use of the Service or Content;
      </p>
      <p class="mb-3 text-sm">
        3. access the Service using any automated means (such as robots, botnets or scrapers) except (a) in the case of public search engines, in accordance with Eucaps Holding AB’s robots.txt file; or (b) with Eucaps Holding AB’s prior written permission;
      </p>
      <p class="mb-3 text-sm">
        4. collect or harvest any information that might identify a person (for example, usernames or faces), unless permitted by that person or allowed under section (3) above;
      </p>
      <p class="mb-3 text-sm">
        5. use the Service to distribute unsolicited promotional or commercial content or other unwanted or mass solicitations;
      </p>
      <p class="mb-3 text-sm">
        6. cause or encourage any inaccurate measurements of genuine user engagement with the Service, including by paying people or providing them with incentives to increase a page’s views, likes, or dislikes, or to increase a channel’s subscribers, or otherwise manipulate metrics in any manner;
      </p>
      <p class="mb-3 text-sm">
        7. misuse any reporting, flagging, complaint, dispute, or appeals process, including by making groundless, vexatious, or frivolous submissions;
      </p>
      <p class="mb-3 text-sm">
        8. run contests on or through the Service that do not comply with Terms of Service here.
      </p>
      <p class="mb-3 text-sm">
        9. use the Service to view or listen to Content other than for personal, non-commercial use (for example, you may not publicly screen videos or stream music from the Service); or
      </p>
      <p class="mb-3 text-sm">
        10. use the Service to (a) sell any advertising, sponsorships, or promotions placed on, around, or within the Service or Content, other than those allowed in Terms of Service here (such as compliant product placements); or (b) sell advertising, sponsorships, or promotions on any page of any website or application that only contains Content from the Service or where Content from the Service is the primary basis for such sales (for example, selling ads on a webpage where content are the main draw for users visiting the webpage).
      </p>
      <Title>Reservation</Title>
      <p class="mb-3 text-sm">
        Using the Service does not give you ownership of or rights to any aspect of the Service, including user names or any other Content posted by others or Eucaps Holding AB.
      </p>
      <Title>Changes to the Service</Title>
      <p class="mb-3 text-sm">
        Eucaps Holding AB is constantly changing and improving the Service. We may also need to alter or discontinue the Service, or any part of it, in order to make performance or security improvements, change functionality and features, make changes to comply with law, or prevent illegal activities on or abuse of our systems. These changes may affect all users, some users or even an individual user. Whenever reasonably possible, we will provide notice when we discontinue or make material changes to our Service that will have an adverse impact on the use of our Service. However, you understand and agree that there will be times when we make such changes without notice, such as where we feel we need to take action to improve the security and operability of our Service, prevent abuse, or comply with legal requirements.
      </p>
      <Title>Your Content and Conduct</Title>
      <p class="mb-3 text-sm">
        <b>Uploading or creating Content</b>
      </p>
      <p class="mb-3 text-sm">
        You may be able to upload and/or create Content to the Service. You may use your Content to promote the business your represent. If you choose to upload and/or create Content, you must not submit to the Service any Content that does not comply with these Terms of Service here or the law. For example, the Content you submit must not include third-party intellectual property (such as copyrighted material) unless you have permission from that party or are otherwise legally entitled to do so. You are legally responsible for the Content you submit to the Service. We may use automated systems that analyze your Content to help detect infringement and abuse, such as spam, malware, and illegal content.
      </p>
      <p class="mb-3 text-sm">
        <b>Rights you Grant</b>
      </p>
      <p class="mb-3 text-sm">
        You retain ownership rights in your Content. However, we do require you to grant certain rights to Eucaps Holding AB and other users of the Service, as described below.
      </p>
      <p class="mb-3 text-sm">
        <b>License to Eucaps Holding AB</b>
      </p>
      <p class="mb-3 text-sm">
        By providing Content to the Service, you grant to Eucaps Holding AB a worldwide, non-exclusive, royalty-free, sublicensable and transferable license to use that Content (including to reproduce, distribute, prepare derivative works, display and perform it) in connection with the Service and Eucaps Holding AB’s (and its successors' and Affiliates') business, including for the purpose of promoting and redistributing part or all of the Service.
      </p>
      <p class="mb-3 text-sm">
        <b>License to Other Users</b>
      </p>
      <p class="mb-3 text-sm">
        You also grant each other user of the Service a worldwide, nonexclusive, royalty-free license to access your Content through the Service, and to use that Content, including to reproduce, distribute, prepare derivative works, display, and perform it, only as enabled by a feature of the Service (such as video playback or embeds). For clarity, this license does not grant any rights or permissions for a user to make use of your Content independent of the Service.
      </p>
      <p class="mb-3 text-sm">
        <b>Duration of License</b>
      </p>
      <p class="mb-3 text-sm">
        The licenses granted by you continue for a commercially reasonable period of time after you remove or delete your Content from the Service. You understand and agree, however, that Eucaps Holding AB may retain, but not display, distribute, or perform, server copies of your content that have been removed or deleted.
      </p>
      <p class="mb-3 text-sm">
        <b>Right to Monetize</b>
      </p>
      <p class="mb-3 text-sm">
        You grant to Eucaps Holding AB the right to monetize your Content on the Service (and such monetization may include displaying ads on or within Content or charging users a fee for access). This Agreement does not entitle you to any payments.
      </p>
      <p class="mb-3 text-sm">
        <b>Removing Your Content</b>
      </p>
      <p class="mb-3 text-sm">
        You may remove your Content from the Service at any time. You also have the option to make a copy of your Centent before removing it. You must remove your Content if you no longer have the rights required by these terms.
      </p>
      <p class="mb-3 text-sm">
        <b>Removal of Content By Eucaps Holding AB</b>
      </p>
      <p class="mb-3 text-sm">
        If we reasonably believe that any Content is in breach of this Agreement or may cause harm to Eucaps Holding AB, our users, or third parties, we may remove or take down that Content in our discretion. We will notify you with the reason for our action unless we reasonably believe that to do so: (a) would breach the law or the direction of a legal enforcement authority or would otherwise risk legal liability for Eucaps Holding AB or our Affiliates; (b) would compromise an investigation or the integrity or operation of the Service; or (c) would cause harm to any user, other third party, Eucaps Holding AB or our Affiliates.
      </p>
      <p class="mb-3 text-sm">
        <b>Copyright Protection</b>
      </p>
      <p class="mb-3 text-sm">
        If you believe your copyright has been infringed on the Service, send us a notice.
      </p>
      <Title>Personal data</Title>
      <p class="mb-3 text-sm">
        Eucaps Holding AB is committed to protecting your privacy and strives to protect your personal data in the best way possible. Eucaps Holding AB strives to comply with applicable laws and regulations for personal data protection. This policy will help you to understand what kind of information Eucaps Holding AB collects and how it is used. By accepting the Terms and Conditions in connection with making a purchase or providing information, you consent to the processing of your personal data in accordance with what is set forth below.
      </p>
      <p class="mb-3 text-sm">
        Eucaps Holding AB (SANDBACKSGATAN 3, 116 21 Stockholm, Sweden) is a data controller for the processing of your personal data.
      </p>
      <p class="mb-3 text-sm">
        Eucaps Holding AB and its partners process your personal data to enable you to view personalized content and dissiminate content using Eucaps Holding AB and to simplify payment processes. Eucaps Holding AB processes personal data in order to verify your identity, to manage customer relationships, to prevent misuse or misappropriation of the payment service, to perform customer analytics, credit assessment, risk analysis, risk management, and business development, and for commercial and marketing purposes, as well as to comply with applicable legal requirements. For the purposes of marketing research, your data are anonymized.
      </p>
      <p class="mb-3 text-sm">
        Personal data means any kind of information that directly or indirectly can be linked to you. The following are examples of personal data that Eucaps Holding AB processes about you or your representatives:
      </p>
      <p class="mb-3 text-sm">
        1. Contact information – name, postal address, email address, telephone number, etc.
      </p>
      <p class="mb-3 text-sm">
        2. Corporate and or identity number
      </p>
      <p class="mb-3 text-sm">
        3. Payment information – card number, invoice information, etc.
      </p>
      <p class="mb-3 text-sm">
        4. Financial information – includes information on possible payment defaults (used in cases where there is a credit check)
      </p>
      <p class="mb-3 text-sm">
        5. Item information – information about the purchases you make
      </p>
      <p class="mb-3 text-sm">
        6. Historical information – information about previous purchases, how you have previously complied with the terms of payment, any rejections, etc.
      </p>
      <p class="mb-3 text-sm">
        7. Information about your computer/device – IP address, language settings, browser settings, etc.
      </p>
      <p class="mb-3 text-sm">
        8. Publicly available information on the internet
      </p>
      <p class="mb-3 text-sm">
        Corporate and personal identity numbers are used for identification and as customer numbers in Eucaps Holding AB’s customer management system. If you have previously used a Eucaps user account or Eucaps Premium, Eucaps Holding AB might hold saved data about you for the purposes of, for example, customer management, documentation requirements, or similar approved purposes. Eucaps Holding AB will use any such information to verify your identity and to facilitate your use of a Eucaps user account or Eucaps Premium. Eucaps Holding AB may also use electronic communications (communications via channels such as SMS and email) in its contacts with you. Eucaps Holding AB may disclose information to other companies within the Eucaps Holding AB Group, which can also use this information in the manner and for the purposes described here. For administrative purposes, data may also be disclosed to the online merchant where you made your purchase. In addition, information may be disclosed in connection with the transfer of a claim to external creditors, collection agencies, or the equivalent.
      </p>
      <p class="mb-3 text-sm">
        Eucaps Holding AB may disclose information to partners who provide payment options in Eucaps Holding AB or other companies in the Eucaps Holding AB Group, who may also use this information in the manner and for the purposes described here.
      </p>
      <p class="mb-3 text-sm">
        Your email address(es) may be disclosed to a third party in order to be used for the electronic notification and distribution of a copy of credit data. If you choose to purchase by invoice a credi check is carried out, which means that a credit report may be ordered. In this case, you will receive a copy of the information disclosed about you. The distribution of this credit data copy is done electronically through a web portal, and login details will be sent to you via email
      </p>
      <p class="mb-3 text-sm">
        The data may be used for the purposes of identification, customer surveys, newsletters, and statistics. The data may also be used for mailings by a postal service, SMS, and email. The data may further be used to analyze purchasing patterns in order to provide you with relevant information and to improve the website, payment solutions, and payment processes. Processing for the purpose of improving payment solutions and payment processes can include processing in which your personal data, including your corporate or personal identity number, will be transferred to other companies within the Eucaps Holding AB Group and to third-party providers in order to be included as data for analysis. Personal data may also be combined together with data from other records, such as a credit data register.
      </p>
      <p class="mb-3 text-sm">
        Your corporate or personal identity number will also be processed in connection with purchases on behalf of (or because of requirements from) payment and credit companies that need this information for credit reference purposes. The use of the data may include combining it with other registers, both within and outside the EU, and may mean that your personal data is transferred to and processed by other companies within the Eucaps Holding AB Group and by Eucaps Holding AB’s partners for the above-mentioned purposes in accordance with applicable laws and regulations. The data may also be combined or be shared with government agencies or with our partners, for example for printing and distribution, as well as other payment intermediaries and credit companies for the purposes of payment or credit checks. Eucaps Holding AB always observes all precautions on such occasions.
      </p>
      <p class="mb-3 text-sm">
        Eucaps Holding AB never discloses information to an external party relating to your membership in political organizations, associations, etc. Eucaps Holding AB processes personal data solely for the purposes of carrying out payments and improving the ease of use of Eucaps Holding AB.
      </p>
      <p class="mb-3 text-sm">
        If all or part of Eucaps Holding AB’s business operations are sold or integrated with another business, your personal data may be disclosed to our advisors, any buyer, and the buyer’s advisor and be forwarded to the new owner.
      </p>
      <p class="mb-3 text-sm">
        By using Eucaps Holding AB, you agree to our use of your personal data in the way described here, including any transfer to another company or to a state outside the EU (European Union)/EES (European Economic Area), as well as to the ability to use your data for electronic communications.
      </p>
      <Title>How do we protect your personal data?</Title>
      <p class="mb-3 text-sm">
        To keep the personal data we process secure, we have taken several security measures: we have implemented security measures and appropriate technical and organizational measures to protect your personal data, such as encryption and certification technology for web browsers; we have appropriate firewalls and anti-virus software to safeguard and prevent unauthorized access to our network; and we have limited the access to areas where the personal data is stored and require that employees identify themselves to obtain access. Eucaps Holding AB uses SSL (Secure Socket Layer), a protocol for secure transmission of data over the internet or other network. You, the customer, must verify that SSL is enabled in your browser settings. In order to prevent unauthorized access during the processing of your debit or credit card number, oneway encryption is also used.
      </p>
      <Title>Access, updates, and corrections to your personal data</Title>
      <p class="mb-3 text-sm">
        You have, in accordance with applicable data protection legislation, the right to once per calendar year and free of charge obtain answers about which personal data records are processed about you, regardless of how the data were collected. If you want to receive such information, you must submit a written request to us. The request must, in accordance with current data protection legislation, be signed by you and submitted by postal mail to the address listed at www.eucaps.com. It may not be sent by email. You have the right, at any time, to revoke consent to the processing of your personal data. Exercising this right would however not impact the use of your personal data for permitted uses like accounting and invoice archiving during the legally required terms.
      </p>
      <Title>User Account Suspension & Termination</Title>
      <p class="mb-3 text-sm">
        <b>Terminations by You</b>
      </p>
      <p class="mb-3 text-sm">
        You may stop using the Service at any time. You also have the option to receive a copy of your data first.
      </p>
      <p class="mb-3 text-sm">
        Terminations and Suspensions by Eucaps Holding AB for Cause Eucaps Holding AB may suspend or terminate your access, your Eucaps user account, or your Eucaps user account’s access to all or part of the Service if (a) you materially or repeatedly breach this Agreement; (b) we are required to do so to comply with a legal requirement or a court order; or (c) we believe there has been conduct that creates (or could create) liability or harm to any user, other third party, Eucaps Holding AB or our Affiliates.
      </p>
      <p class="mb-3 text-sm">
        Terminations by Eucaps Holding AB for Service Changes Eucaps Holding AB may at its sole discretion terminate your access, or your Eucaps user account or part of the Service.
      </p>
      <p class="mb-3 text-sm">
        <b>Notice for Termination or Suspension</b>
      </p>
      <p class="mb-3 text-sm">
        We will notify you with the reason for termination or suspension by Eucaps Holding AB unless we reasonably believe that to do so: (a) would violate the law or the direction of a legal enforcement authority, or would otherwise risk legal liability for Eucaps Holding AB or our Affiliates; (b) would compromise an investigation or the integrity or operation of the Service; or (c) would cause harm to any user, other third party, Eucaps Holding AB or our Affiliates. Where Eucaps Holding AB is terminating your access for Service changes, where reasonably possible, you will be provided with sufficient time to export your Content from the Service.
      </p>
      <p class="mb-3 text-sm">
        <b>Effect of Account Suspension or Termination</b>
      </p>
      <p class="mb-3 text-sm">
        If your Eucaps account is terminated or your Eucaps account’s access to the Service is restricted, you may continue using certain aspects of the Service (such as viewing only) without an account, and this Agreement will continue to apply to such use.
      </p>
      <Title>Other Legal Terms</Title>
      <p class="mb-3 text-sm">
        <b>Warranty Disclaimer</b>
      </p>
      <p class="mb-3 text-sm">
        OTHER THAN AS EXPRESSLY STATED IN THIS AGREEMENT OR AS REQUIRED BY LAW, THE SERVICE IS PROVIDED “AS IS” AND EUCAPS HOLDING AB DOES NOT MAKE ANY SPECIFIC COMMITMENTS OR WARRANTIES ABOUT THE SERVICE. FOR EXAMPLE, WE DON’T MAKE ANY WARRANTIES ABOUT: (A) THE CONTENT PROVIDED THROUGH THE SERVICE; (B) THE SPECIFIC FEATURES OF THE SERVICE, OR ITS ACCURACY, RELIABILITY, AVAILABILITY, OR ABILITY TO MEET YOUR NEEDS; OR (C) THAT ANY CONTENT YOU SUBMIT WILL BE ACCESSIBLE ON THE SERVICE.
      </p>
      <p class="mb-3 text-sm">
        <b>Limitation of Liability</b>
      </p>
      <p class="mb-3 text-sm">
        EXCEPT AS REQUIRED BY APPLICABLE LAW, EUCAPS HOLDING AB, ITS AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES AND AGENTS WILL NOT BE RESPONSIBLE FOR ANY LOSS OF PROFITS, REVENUES, BUSINESS OPPORTUNITIES, GOODWILL, OR ANTICIPATED SAVINGS; LOSS OR CORRUPTION OF DATA; INDIRECT OR CONSEQUENTIAL LOSS; PUNITIVE DAMAGES CAUSED BY:
      </p>
      <p class="mb-3 text-sm">
        1. ERRORS, MISTAKES, OR INACCURACIES ON THE SERVICE;
      </p>
      <p class="mb-3 text-sm">
        2. PERSONAL INJURY OR PROPERTY DAMAGE RESULTING FROM YOUR USE OF THE SERVICE;
      </p>
      <p class="mb-3 text-sm">
        3. ANY UNAUTHORIZED ACCESS TO OR USE OF THE SERVICE;
      </p>
      <p class="mb-3 text-sm">
        4. ANY INTERRUPTION OR CESSATION OF THE SERVICE;
      </p>
      <p class="mb-3 text-sm">
        5. ANY VIRUSES OR MALICIOUS CODE TRANSMITTED TO OR THROUGH THE SERVICE BY ANY THIRD PARTY;
      </p>
      <p class="mb-3 text-sm">
        6. ANY CONTENT WHETHER SUBMITTED BY A USER OR EUCAPS HOLDING AB, INCLUDING YOUR USE OF CONTENT; AND/OR
      </p>
      <p class="mb-3 text-sm">
        7. THE REMOVAL OR UNAVAILABILITY OF ANY CONTENT.
      </p>
      <p class="mb-3 text-sm">
        THIS PROVISION APPLIES TO ANY CLAIM, REGARDLESS OF WHETHER THE CLAIM ASSERTED IS BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY.
      </p>
      <p class="mb-3 text-sm">
        EUCAPS HOLDING AB AND ITS AFFILIATES’ TOTAL LIABILITY FOR ANY CLAIMS ARISING FROM OR RELATING TO THE SERVICE IS LIMITED TO THE GREATER OF: (A) THE AMOUNT OF REVENUE THAT EUCAPS HOLDING AB WAS PAID BY YOU FROM YOUR USE OF THE SERVICE IN THE 12 MONTHS BEFORE THE DATE OF YOUR NOTICE; OR (B) EUR €100.
      </p>
      <p class="mb-3 text-sm">
        <b>Indemnity</b>
      </p>
      <p class="mb-3 text-sm">
        To the extent permitted by applicable law, you agree to defend, indemnify and hold harmless Eucaps Holding AB, its Affiliates, officers, directors, employees and agents, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees) arising from: (i) your use of and access to the Service; (ii) your violation of any term of this Agreement; (iii) your violation of any third party right, including without limitation any copyright, property, or privacy right; or (iv) any claim that your Content caused damage to a third party. This defense and indemnification obligation will survive this Agreement and your use of the Service.
      </p>
      <p class="mb-3 text-sm">
        <b>Third-Party Links</b>
      </p>
      <p class="mb-3 text-sm">
        The Service may contain links to third-party websites and online services that are not owned or controlled by Eucaps Holding AB. Eucaps Holding AB has no control over, and assumes no responsibility for, such websites and online services. Be aware when you leave the Service; we suggest you read the terms and
      </p>
      <Title>About this Agreement</Title>
      <p class="mb-3 text-sm">
        <b>Modifying this Agreement</b>
      </p>
      <p class="mb-3 text-sm">
        We may modify this Agreement, for example, to reflect changes to our Service or for legal, regulatory, or security reasons. Eucaps Holding AB will provide reasonable advance notice of any material modifications to this Agreement and the opportunity to review them, except that modifications addressing newly available features of the Service or modifications made for legal reasons may be effective immediately without notice. Modifications to this Agreement will only apply going forward. If you do not agree to the modified terms, you should remove any Content you have uploaded and discontinue your use of the Service.
      </p>
      <p class="mb-3 text-sm">
        <b>Continuation of this Agreement</b>
      </p>
      <p class="mb-3 text-sm">
        If your use of the Service ends, the following terms of this Agreement will continue to apply to you: “Other Legal Terms”, “About This Agreement”, and the licenses granted by you will continue as described under “Duration of License”.
      </p>
      <p class="mb-3 text-sm">
        <b>Severance</b>
      </p>
      <p class="mb-3 text-sm">
        If it turns out that a particular term of this Agreement is not enforceable for any reason, this will not affect any other terms.
      </p>
      <p class="mb-3 text-sm">
        <b>No Waiver</b>
      </p>
      <p class="mb-3 text-sm">
        If you fail to comply with this Agreement and we do not take immediate action, this does not mean that we are giving up any rights that we may have (such as the right to take action in the future).
      </p>
      <p class="mb-3 text-sm">
        <b>Interpretation</b>
      </p>
      <p class="mb-3 text-sm">
        In these terms, “include” or “including” means “including but not limited to,” and any examples we give are for illustrative purposes.
      </p>
      <p class="mb-3 text-sm">
        <b>Governing Law</b>
      </p>
      <p class="mb-3 text-sm">
        All claims arising out of or relating to these terms or the Service will be governed by Swedish law, except Swedish’ conflict of laws rules, and will be litigated exclusively in the federal or state courts of Stockholm, Sweden. You and Eucaps Holding AB consent to personal jurisdiction in those courts.
      </p>
      <p class="mb-3 text-sm">
        <b>Limitation on Legal Action</b>
      </p>
      <p class="mb-3 text-sm">
        YOU AND EUCAPS HOLDING AB AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO THE SERVICES MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.
      </p>
    </div>
    <!-- <RulesContainer /> -->
  </Container>
</template>

<script>
// import RulesContainer from '@/modules/rules'

export default {
  name: 'Terms',
  metaInfo () {
    return {
      title: this.$t('Terms')
    }
  }
  //   components: {
  //     RulesContainer
  //   }
}
</script>
