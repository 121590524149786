<template>
  <div
    class="fixed w-full top-16 right-0 z-70"
  >
    <div v-show="internalSnack.show">
      <div
        v-if="internalSnack.type === 'info'"
        class="bg-blue-500 text-white px-4 py-2 rounded-sm text-xs flex justify-center"
      >
        <div>{{ internalSnack.text }}</div>
        <button
          class="ml-8 absolute bottom-0 right-5 focus:outline-none"
          @click="close"
        >
          <Icon
            name="x"
            class="text-xl mb-px"
          />
        </button>
      </div>
      <div
        v-if="internalSnack.type === 'success'"
        class="bg-green-500 text-white px-4 py-2 rounded-sm text-xs flex justify-center"
      >
        <div>{{ internalSnack.text }}</div>
        <button
          class="ml-8 absolute bottom-0 right-5 focus:outline-none"
          @click="close"
        >
          <Icon
            name="x"
            class="text-xl mb-px"
          />
        </button>
      </div>
      <div
        v-if="internalSnack.type === 'error'"
        class="bg-pink-700 text-white px-4 py-2 rounded-sm text-xs flex justify-center"
      >
        <div>{{ internalSnack.text }}</div>
        <button
          class="ml-8 absolute bottom-0 right-5 focus:outline-none"
          @click="close"
        >
          <Icon
            name="x"
            class="text-xl mb-px"
          />
        </button> -->
      </div>
    </div>
  </div>
</template>

<script>
import { useSnackbarStore } from '../stores/snackbar'

export default {
  name: 'SnackBar',

  setup () {
    const snackbarStore = useSnackbarStore()

    return { snackbarStore }
  },
  computed: {
    internalSnack () {
      if (this.snackbarStore.originalSnack.type !== 'notification') {
        return this.snackbarStore.originalSnack
      }

      return { show: false }
    }
  },
  methods: {
    close () {
      this.snackbarStore.close()
    }
  }
}
</script>
<style>
 .custom-icon {
    color: red;
  }
</style>
