<template>
  <div class="space-y-6 text-black my-4">
    <div class="space-y-4">
      <div class="font-bold text-4xl">
        {{ $t('Verify your email') }}
      </div>

      <div class="font-normal text-base">
        <div>
          {{ $t('We’ve sent a verification code to your address') }}
        </div>
        <div>
          {{ email }}
        </div>
      </div>
      <div class="font-normal text-base">
        {{ $t('Please fill that code below to continue forward.') }}
      </div>
    </div>
    <form
      class="grid gap-5"
      @submit.prevent="$emit('submit')"
    >
      <div
        v-if="isMobile"
        class="flex space-x-2 justify-center text-center w-full"
      >
        <input
          v-for="(pin, index) in pins"
          :key="index"
          :ref="'pin' + index"
          :value="pin"
          type="number"
          required
          min="0"
          max="9"
          :class="verifyOtpError ? 'border-custom-red-500' : 'border-custom-gray-300'"
          class="border h-12 w-max-12 text-center rounded-md focus:outline-none focus:border-custom-purple-500"
          @input="$emit('on-input', { event: $event, index: index })"
          @paste="$emit('paste-code', $event)"
          @keydown.backspace="$emit('on-backspace', index)"
          @keydown.delete="$emit('on-delete', index)"
        >
      </div>

      <div
        v-else
        class="flex space-x-2 justify-center text-center w-full"
      >
        <input
          v-for="(pin, index) in pins"
          :key="index"
          :ref="'pin' + index"
          :value="pin"
          type="text"
          required
          maxlength="1"
          :class="verifyOtpError ? 'border-custom-red-500' : 'border-custom-gray-300'"
          class="border h-12 w-max-12 text-center rounded-md focus:outline-none "
          @paste="$emit('paste-code', $event)"
          @input="$emit('on-input', { event: $event, index: index })"
          @keydown.backspace="$emit('on-backspace', index)"
          @keydown.delete="$emit('on-delete', index)"
          @focus="$emit('reset-error')"
        >
      </div>


      <div
        v-if="verifyOtpError"
        class="text-custom-red-500 font-semibold text-center"
      >
        {{ verifyOtpError }}
      </div>

      <NeutralButton
        type="submit"
        :locked="loadingOtp"
        class="w-full text-md focus:outline-none disabled:opacity-50  flex justify-center items-center"
      >
        <div
          v-if="loadingOtp"
          class="flex items-center gap-2"
        >
          <CircleLoader small /> <div> {{ $t("Verifing...") }}</div>
        </div>
        <div v-else>
          {{ $t("Verify") }}
        </div>
      </NeutralButton>
    </form>



    <div class="w-full flex justify-center items-center ">
      <button
        :disabled="resendEmailLoading"
        class="mt-5 flex  justify-center items-center focus:outline-none text-gray-900 font-bold"
        @click="$emit('resend-otp')"
      >
        <div
          v-if="resendEmailLoading"
          class="flex items-center justify-center w-full space-x-2"
        >
          <CircleLoader small />
          <div>Resending code...!</div>
        </div>
        <div v-else>
          {{ $t("Send code again") }}
        </div>
      </button>
    </div>

    <div class="w-full flex justify-center items-center ">
      <button
        type="button"
        class="flex justify-center items-center focus:outline-none text-gray-900 gap-2"
        @click="$emit('go-back')"
      >
        <div class="font-medium">
          {{ $t('Already have an account?') }}
        </div>
        <div class="font-semibold flex item-center">
          {{ $t('Login') }}
        </div>
      </button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'EmailVerification',
  props: {
    email: { type: String, default: null },
    loadingOtp: { type: Boolean, default: false },
    isMobile: { type: Boolean, default: false },
    resendEmailLoading: { type: Boolean, default: false },
    pins: { type: Array, default: () => ["", "", ""] },
    verifyOtpError: { type: String, default: '' }
  },
  emits: [
    'paste-code',
    'go-back',
    'resend-otp',
    'submit',
    'on-input',
    'on-backspace',
    'on-delete',
    'reset-error'
  ],


}
</script>
