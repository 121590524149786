<template>
  <div
    class="mt-1 cursor-pointer"
    @click="redirectAttachment(attachment)"
  >
    <BaseCard
      padding="pr-2 py-1"
      :background="background"
      :border="border"
      shadow="shadow-none"
      class="w-full"
    >
      <template #body>
        <div
          class="w-full flex items-center text-2xs relative"
        >
          <p class="font-semibold p-1 pl-3">
            <Icon
              name="paperclip"
              class="text-xl"
            />
          </p>
          <div
            v-if="attachment.linkable_type === 'App\\Models\\CompanyTrigger'"
            class="text-left pl-2"
          >
            <p class="font-semibold text-3xs text-custom-light-header dark:text-custom-dark-header">
              {{ attachment.linkable.linkable.company.name }} > {{ $t('Trigger') }}
            </p>
            <p class="font-semibold">
              {{ attachment.linkable.title }}
            </p>
          </div>
          <div
            v-if="attachment.linkable_type === 'App\\Models\\InsiderTrade'"
            class="text-left pl-2"
          >
            <p class="font-semibold text-3xs text-custom-light-header dark:text-custom-dark-header">
              {{ attachment.linkable.company.name }} > {{ $t('Insider trades') }}
            </p>
            <p
              class="text-3xs"
            >
              ({{ toDate(attachment.linkable.publish_date) }})
            </p>
            <div>
              <p class="font-semibold">
                {{ attachment.linkable.person }} - {{ attachment.linkable.type }} <template v-if="attachment.linkable.status === 'Reviderad'">
                  (Reviderad)
                </template>
              </p>
              <p
                class="text-3xs font-semibold"
              >
                ({{ attachment.linkable.occupation.indexOf('Annan') > -1 ? 'Annan' : attachment.linkable.occupation }})
              </p>
              <p>
                {{ attachment.linkable.price }} {{ attachment.linkable.currency }} /
                {{ parseFloat(attachment.linkable.volume).toLocaleString('en-US', {maximumFractionDigits: 2}).replace(/,/g, ' ') }} {{ attachment.linkable.volume_unit }}
              </p>
            </div>
          </div>
          <div
            v-if="attachment.linkable_type === 'App\\Models\\CompanyAsset'"
            class="text-left pl-2"
          >
            <p class="font-semibold text-3xs text-custom-light-header dark:text-custom-dark-header">
              {{ attachment.linkable.linkable.company.name }} > {{ $t('Asset') }}
            </p>
            <p class="font-semibold">
              {{ attachment.linkable.name }}
            </p>
          </div>
          <div
            v-if="attachment.linkable_type === 'App\\Models\\CompanyBoardMember'"
            class="text-left pl-2"
          >
            <p class="font-semibold text-3xs text-custom-light-header dark:text-custom-dark-header">
              {{ attachment.linkable.linkable.company.name }} > {{ $t('Board Member') }}
            </p>
            <p class="font-semibold">
              {{ attachment.linkable.full_name }}
            </p>
            <p>{{ attachment.linkable.title }}</p>
          </div>
          <div
            v-if="attachment.linkable_type === 'App\\Models\\CompanyContact'"
            class="text-left pl-2"
          >
            <p class="font-semibold text-3xs text-custom-light-header dark:text-custom-dark-header">
              {{ attachment.linkable.linkable.company.name }} > {{ $t('Contact') }}
            </p>
            <p class="font-semibold">
              {{ attachment.linkable.full_name }}
            </p>
            <p>{{ attachment.linkable.title }}</p>
          </div>
          <div
            v-if="attachment.linkable_type === 'App\\Models\\CompanyPressRelease'"
            class="text-left pl-2"
          >
            <p class="font-semibold text-3xs text-custom-light-header dark:text-custom-dark-header">
              {{ attachment.linkable.linkable.company.name }} > {{ $t('Press Release') }}
            </p>
            <p class="font-semibold">
              {{ attachment.linkable.headline }}
            </p>
            <p class="">
              {{ diffFromNow(attachment.linkable.external_timestamp) }}
            </p>
            <!-- <p :class="'text-2xs'">
              <span v-if="attachment.linkable.content.length > 50"><span v-html="attachment.linkable.content.slice(0, 50)" /></span>
              <span v-else><span v-html="attachment.linkable.content" /></span>
            </p> -->
          </div>
          <div
            v-if="attachment.linkable_type === 'App\\Models\\Media'"
            class="text-left pl-2"
          >
            <p class="font-semibold text-3xs text-custom-light-header dark:text-custom-dark-header">
              {{ attachment.linkable.company.name }} > {{ $t('Media') }}
            </p>
            <Thumbnail
              v-if="attachment.linkable.mediable.image"
              :src="attachment.linkable.mediable.media_gallery[0].src"
            />
            <ThumbnailAudio
              v-if="attachment.linkable.mediable.audio"
              :src="attachment.linkable.mediable.media_gallery[0].src"
            />
            <ThumbnailDocument
              v-if="attachment.linkable.mediable.document"
              :src="attachment.linkable.mediable.media_gallery[0].src"
            />
            <ThumbnailVideo
              v-if="attachment.linkable.mediable.video"
              :controls="false"
              :src="attachment.linkable.mediable.media_gallery[0].src"
            />
          </div>
        </div>
      </template>
    </BaseCard>
  </div>
</template>

<script>

import { format } from 'date-fns'
import time from '@/js/helpers/time'
import { useAssetsStore } from '../stores/assets'
import { useBoardMembersStore } from '../stores/boardmembers'
import { useContactsStore } from '../stores/contacts'
import { usePressReleasesStore } from '../stores/pressreleases'
import { useMediasStore } from '../stores/medias'

export default {
  name: 'AttachedData',
  props: {
    attachment: { type: Object, default: () => {} },
    border: { type: String, default: 'border dark:border-custom-purple-400' },
    background: { type: String, default: 'rounded rounded-lg bg-white dark:bg-custom-purple-600' }
  },
  setup() {
    const companiesStore = useAssetsStore()

    return { companiesStore }
  },
  methods: {
    toDate (datetime) {
      return format(new Date(datetime), 'yyyy-MM-dd kk:mm')
    },
    diffFromNow (datetime) {
      if (datetime) {
        return time.diffFromNow(datetime)
      }
    },
    async redirect(payload) {
      const response = await usePressReleasesStore().fetchRoomPostId(payload.linkable_id)
      if (response) {
        this.$router.push({ name: 'posts', params: { id: response } })
      } 
    },
    redirectAttachment (attachment) {
      if (attachment.linkable_type === 'App\\Models\\CompanyAsset') {
        useAssetsStore().pushAssets(attachment.linkable)
        this.$router.push({ name: 'companyAssets', params: { id: attachment.linkable.linkable.company.slug_url } })
      }
      if (attachment.linkable_type === 'App\\Models\\CompanyBoardMember') {
        useBoardMembersStore().pushBoardMembers(attachment.linkable)
        this.$router.push({ name: 'companyBoard', params: { id: attachment.linkable.linkable.company.slug_url } })
      }
      if (attachment.linkable_type === 'App\\Models\\CompanyContact') {
        useContactsStore().pushContacts(attachment.linkable)
        this.$router.push({ name: 'companyContacts', params: { id: attachment.linkable.linkable.company.slug_url } })
      }
      if (attachment.linkable_type === 'App\\Models\\CompanyPressRelease') {
        // usePressReleasesStore().pushPressRelease(attachment.linkable)
        this.redirect(attachment)
        // this.$router.push({ name: 'posts', params: { id: attachment.linkable.room_post_id } })
      }
      if (attachment.linkable_type === 'App\\Models\\Media') {
        useMediasStore().pushMedia(attachment.linkable)
        this.$router.push({ name: 'companyMedia', params: { id: attachment.linkable.company.slug_url } })
      }
    }
  }
}
</script>
