<template>
  <div>
    <ModalContainer
      v-if="achievementsStore.showAchievement"
      full-size
      @toggle="close"
      @on-back-button="close"
    >
      <div class="py-4 lg:px-12 sm:px-5 max-w-lg mx-auto ">
        <div class="sm:grid-cols-1">
          <div class="confetti ">
            <div class="confetti-piece1" />
            <div class="confetti-piece2" />
            <div class="confetti-piece1" />
            <div class="confetti-piece2" />
            <div class="confetti-piece1" />
            <div class="confetti-piece2" />
            <div class="confetti-piece1" />
            <div class="confetti-piece2" />
            <div class="confetti-piece1" />
            <div class="confetti-piece2" />
            <div class="confetti-piece1" />
            <div class="confetti-piece2" />
            <div class="confetti-piece1" />
          </div>
          <div class=" p-3 bg-white dark:bg-custom-purple-500 shadow rounded-lg px-5 py-6">
            <div class="text-md my-10 text-center text-gray-400 font-light">
              {{ (achievementsStore.achievementModal.achievement_level) ? $t(achievementsStore.achievementModal.achievement_level.achievement.title) : '' }}
              {{ $t('Achievement') }}
            </div>
            <div class="text-center mt-8">
              <FontAwesomeIcon
                icon="medal"
                class="mb-3 text-8xl text-purple-500"
              />
            </div>
            <div class="font-normal text-xl my-8 text-center">
              {{ $t('Level') }}
              {{ (achievementsStore.achievementModal.achievement_level) ? achievementsStore.achievementModal.achievement_level.level : '' }}
            </div>
            <p class="mt-8 text-center text-sm">
              {{ $t('Hurray!!') }}
            </p>
            <p class="text-center text-xs">
              {{ (achievementsStore.achievementModal.achievement_level) ? $t(achievementsStore.achievementModal.achievement_level.description, {level: achievementsStore.achievementModal.achievement_level.level}) : '' }}
            </p>
            <div class="font-normal my-8 text-center">
              <NeutralButton
                class="z-100"
                @click="close"
              >
                {{ $t('Continue') }}
              </NeutralButton>
            </div>
          </div>
        </div>
      </div>
    </ModalContainer>
  </div>
</template>
<script>

import ModalContainer from '@/modules/modal'
import { useAchievementsStore } from '@/stores/achievements';

export default {
  name: 'AchievementModal',
  components: {
    ModalContainer
  },
  setup() {
    const achievementsStore = useAchievementsStore()

    return { achievementsStore }
  },
  methods: {
    close () {
      this.achievementsStore.setShowAchievementModal(false)
    }
  }
}
</script>

<style lang="css">
    .confetti {
        display: flex;
        justify-content: center;
        align-items: center;
        position: inherit;
        height: 70%;
        overflow: hidden;
        z-index: 1;
    }

    .confetti-piece1 {
        position: absolute;
        width: 10px;
        height: 30px;
        background: #ffd300;
        top: 0;
        opacity: 0;
        border-radius: 1rem 0.5rem;
    }

    .confetti-piece2 {
        position: absolute;
        width: 10px;
        height: 30px;
        background: #ffd300;
        top: 0;
        opacity: 0;
        border-radius: 0.5rem 1rem;
    }

    .confetti-piece1:nth-child(1) {
        left: 7%;
        -webkit-transform: rotate(-40deg);
        -webkit-animation: makeItRain 1000ms infinite ease-out;
        -webkit-animation-delay: 182ms;
        -webkit-animation-duration: 1116ms;
    }

    .confetti-piece2:nth-child(1) {
        left: 7%;
        -webkit-transform: rotate(-40deg);
        -webkit-animation: makeItRain 1000ms infinite ease-out;
        -webkit-animation-delay: 182ms;
        -webkit-animation-duration: 1116ms;
    }

    .confetti-piece1:nth-child(2) {
        left: 14%;
        -webkit-transform: rotate(4deg);
        -webkit-animation: makeItRain 1000ms infinite ease-out;
        -webkit-animation-delay: 161ms;
        -webkit-animation-duration: 1076ms;
    }

    .confetti-piece2:nth-child(2) {
        left: 14%;
        -webkit-transform: rotate(4deg);
        -webkit-animation: makeItRain 1000ms infinite ease-out;
        -webkit-animation-delay: 161ms;
        -webkit-animation-duration: 1076ms;
    }

    .confetti-piece1:nth-child(3) {
        left: 21%;
        -webkit-transform: rotate(-51deg);
        -webkit-animation: makeItRain 1000ms infinite ease-out;
        -webkit-animation-delay: 481ms;
        -webkit-animation-duration: 1103ms;
    }

    .confetti-piece2:nth-child(3) {
        left: 21%;
        -webkit-transform: rotate(-51deg);
        -webkit-animation: makeItRain 1000ms infinite ease-out;
        -webkit-animation-delay: 481ms;
        -webkit-animation-duration: 1103ms;
    }

    .confetti-piece1:nth-child(4) {
        left: 28%;
        -webkit-transform: rotate(61deg);
        -webkit-animation: makeItRain 1000ms infinite ease-out;
        -webkit-animation-delay: 334ms;
        -webkit-animation-duration: 708ms;
    }

    .confetti-piece2:nth-child(4) {
        left: 28%;
        -webkit-transform: rotate(61deg);
        -webkit-animation: makeItRain 1000ms infinite ease-out;
        -webkit-animation-delay: 334ms;
        -webkit-animation-duration: 708ms;
    }

    .confetti-piece1:nth-child(5) {
        left: 35%;
        -webkit-transform: rotate(-52deg);
        -webkit-animation: makeItRain 1000ms infinite ease-out;
        -webkit-animation-delay: 302ms;
        -webkit-animation-duration: 776ms;
    }

    .confetti-piece2:nth-child(5) {
        left: 35%;
        -webkit-transform: rotate(-52deg);
        -webkit-animation: makeItRain 1000ms infinite ease-out;
        -webkit-animation-delay: 302ms;
        -webkit-animation-duration: 776ms;
    }

    .confetti-piece1:nth-child(6) {
        left: 42%;
        -webkit-transform: rotate(38deg);
        -webkit-animation: makeItRain 1000ms infinite ease-out;
        -webkit-animation-delay: 180ms;
        -webkit-animation-duration: 1168ms;
    }

    .confetti-piece2:nth-child(6) {
        left: 42%;
        -webkit-transform: rotate(38deg);
        -webkit-animation: makeItRain 1000ms infinite ease-out;
        -webkit-animation-delay: 180ms;
        -webkit-animation-duration: 1168ms;
    }

    .confetti-piece1:nth-child(7) {
        left: 49%;
        -webkit-transform: rotate(11deg);
        -webkit-animation: makeItRain 1000ms infinite ease-out;
        -webkit-animation-delay: 395ms;
        -webkit-animation-duration: 1200ms;
    }

    .confetti-piece2:nth-child(7) {
        left: 49%;
        -webkit-transform: rotate(11deg);
        -webkit-animation: makeItRain 1000ms infinite ease-out;
        -webkit-animation-delay: 395ms;
        -webkit-animation-duration: 1200ms;
    }

    .confetti-piece1:nth-child(8) {
        left: 56%;
        -webkit-transform: rotate(49deg);
        -webkit-animation: makeItRain 1000ms infinite ease-out;
        -webkit-animation-delay: 14ms;
        -webkit-animation-duration: 887ms;
    }

    .confetti-piece2:nth-child(8) {
        left: 56%;
        -webkit-transform: rotate(49deg);
        -webkit-animation: makeItRain 1000ms infinite ease-out;
        -webkit-animation-delay: 14ms;
        -webkit-animation-duration: 887ms;
    }

    .confetti-piece1:nth-child(9) {
        left: 63%;
        -webkit-transform: rotate(-72deg);
        -webkit-animation: makeItRain 1000ms infinite ease-out;
        -webkit-animation-delay: 149ms;
        -webkit-animation-duration: 805ms;
    }

    .confetti-piece2:nth-child(9) {
        left: 63%;
        -webkit-transform: rotate(-72deg);
        -webkit-animation: makeItRain 1000ms infinite ease-out;
        -webkit-animation-delay: 149ms;
        -webkit-animation-duration: 805ms;
    }

    .confetti-piece1:nth-child(10) {
        left: 70%;
        -webkit-transform: rotate(10deg);
        -webkit-animation: makeItRain 1000ms infinite ease-out;
        -webkit-animation-delay: 351ms;
        -webkit-animation-duration: 1059ms;
    }

    .confetti-piece2:nth-child(10) {
        left: 70%;
        -webkit-transform: rotate(10deg);
        -webkit-animation: makeItRain 1000ms infinite ease-out;
        -webkit-animation-delay: 351ms;
        -webkit-animation-duration: 1059ms;
    }

    .confetti-piece1:nth-child(11) {
        left: 77%;
        -webkit-transform: rotate(4deg);
        -webkit-animation: makeItRain 1000ms infinite ease-out;
        -webkit-animation-delay: 307ms;
        -webkit-animation-duration: 1132ms;
    }

    .confetti-piece2:nth-child(11) {
        left: 77%;
        -webkit-transform: rotate(4deg);
        -webkit-animation: makeItRain 1000ms infinite ease-out;
        -webkit-animation-delay: 307ms;
        -webkit-animation-duration: 1132ms;
    }

    .confetti-piece1:nth-child(12) {
        left: 84%;
        -webkit-transform: rotate(42deg);
        -webkit-animation: makeItRain 1000ms infinite ease-out;
        -webkit-animation-delay: 464ms;
        -webkit-animation-duration: 776ms;
    }

    .confetti-piece2:nth-child(12) {
        left: 84%;
        -webkit-transform: rotate(42deg);
        -webkit-animation: makeItRain 1000ms infinite ease-out;
        -webkit-animation-delay: 464ms;
        -webkit-animation-duration: 776ms;
    }

    .confetti-piece1:nth-child(13) {
        left: 91%;
        -webkit-transform: rotate(-72deg);
        -webkit-animation: makeItRain 1000ms infinite ease-out;
        -webkit-animation-delay: 429ms;
        -webkit-animation-duration: 818ms;
    }

    .confetti-piece2:nth-child(13) {
        left: 91%;
        -webkit-transform: rotate(-72deg);
        -webkit-animation: makeItRain 1000ms infinite ease-out;
        -webkit-animation-delay: 429ms;
        -webkit-animation-duration: 818ms;
    }

    .confetti-piece1:nth-child(odd) {
        background: #7431e8;
    }

    .confetti-piece2:nth-child(even) {
        z-index: 1;
    }

    .confetti-piece2:nth-child(odd) {
        background: #7431e8;
    }

    .confetti-piece2:nth-child(even) {
        z-index: 1;
    }

    .confetti-piece1:nth-child(4n) {
        width: 5px;
        height: 12px;
        -webkit-animation-duration: 2000ms;
    }

    .confetti-piece2:nth-child(4n) {
        width: 5px;
        height: 12px;
        -webkit-animation-duration: 2000ms;
    }

    .confetti-piece1:nth-child(3n) {
        width: 3px;
        height: 10px;
        -webkit-animation-duration: 2500ms;
        -webkit-animation-delay: 1000ms;
    }

    .confetti-piece2:nth-child(3n) {
        width: 3px;
        height: 10px;
        -webkit-animation-duration: 2500ms;
        -webkit-animation-delay: 1000ms;
    }

    .confetti-piece1:nth-child(4n-7) {
        background: red;
    }

    .confetti-piece2:nth-child(4n-7) {
        background: red;
    }

    @-webkit-keyframes makeItRain {
        from {
            opacity: 0;
        }

        50% {
            opacity: 1;
        }

        to {
            -webkit-transform: translateY(350px);
        }
    }

    /* Extra small devices (phones, 600px and down) */
    @media (max-width: 600px) {
        .confetti {
            width: 90%;
        }
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) {
        .confetti {
            width: 80%;
        }
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
        .confetti {
            width: 56%;
        }
    }

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 992px) {
        .confetti {
            width: 35%;
        }
    }

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
        .confetti {
            width: 28%;
        }
    }
</style>
