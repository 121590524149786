<template>
  <div />
</template>

<script>
import { useTodosStore } from '../stores/todos'


export default {
  name: 'Todo',
  setup() {
    const todosStore = useTodosStore()

    return { todosStore }
  },
  data () {
    return {
      enabled: process.env.VUE_APP_SHOW_TODO
    }
  },
  mounted () {
    if (this.enabled) {
      this.todosStore.add({ id: this._uid, route: this.$route.path, text: this.$slots.default[0].text, inComponent: this.$parent.$options._componentTag })
    }
  }
}
</script>
