<template>
  <highcharts
    ref="chart"
    :options="chartOptions"
  />
</template>


<script>

import { Chart } from 'highcharts-vue'

export default {
  name: 'TrendChart',
  components: {
    highcharts: Chart
  },
  props: {
    chartData: { type: Array, default: () => [] },
    down: { type: Boolean, default: false }
  },
  computed: {
    chartOptions () {
        return {
          chart: {
            height: 64,
            backgroundColor: 'transparent'
          },
          legend: {
            enabled: false
          },
          navigator: {
            enabled: false
          },
          scrollbar: {
            enabled: false
          },
          title: {
            text: ''
          },
          xAxis: {
            visible: false
          },
          yAxis: {
            visible: false
          },
          exporting: {
            enabled: false
          },
          rangeSelector: {
            enabled: false
          },
          plotOptions: {
            areaspline: {
              fillColor: {
                linearGradient: {
                  x1: 0,
                  y1: 0,
                  x2: 0,
                  y2: 1
                },
                stops: [
                  [0, this.down? 'rgba(230, 69,69, 0.26)' : 'rgba(100,248,171,0.26)'],
                  [1, 'rgba(255,255,255,0)']
                ]
              },
              lineWidth: 2,
              marker: {
                enabled: false,
                states: {
                  hover: {
                    enabled: false
                  }
                }
              },
              threshold: null
          }
        },
        credits: {
          enabled: false
        },
        series: [
          {
            type: 'areaspline',
            name: 'Stock price',
            showInLegend: false,
            data: this.chartData,
            color: this.down ? 'rgba(230, 69,69)' : 'rgba(0,250,154)'
          },
        ]
      }
    }
  }
}
</script>
  