<template>
  <div>
    <div class="text-lg font-semibold">
      {{ $t('Stock investment - Product knowledge & objective') }}
    </div>
    <div class="grid lg:grid-cols-2 gap-4 my-4">
      <div>
        <label>
          {{ $t('Trades Per Year') }}
        </label>
        <BaseInput
          :has-dark-mode="false"  
          class="  h-14 w-full font-semibold"
          :placeholder="$t('Ex. 3')"
          shadow="shadow-md"
          :type="'number'"
          :step="1"
          :required="true"
          :model-value="tradesPerYear"
          @update:model-value="$emit('trades-per-year', $event)"
        />
      </div>
      <div>
        <label>
          {{ $t('Years of Trading Experience') }}
        </label>
        
        <BaseInput
          :has-dark-mode="false"
          class="  h-14 w-full font-semibold"
          :placeholder="$t('Ex. 3')"
          shadow="shadow-md"
          :type="'number'"
          :step="1"
          :required="true"
          :model-value="yearsTrading"
          @update:model-value="$emit('years-trading', $event)"
        />
      </div>
    </div>
    
    <div class="grid lg:grid-cols-2 gap-4 my-4">
      <div>
        <label>
          {{ $t('Investment Objective') }}
        </label>
        <BaseSelect
          :appearance-none="false"
          :has-dark-mode="false"
          :required="true"
          :classes="investmentObjective ? baseSelectClass : baseSelectClass + ' text-gray-400 focus:text-primary !text-sm'"
          :upper-class="false"
          :model-value="investmentObjective"
          @change="$emit('investment-objective', $event)"
        >
          <option
            value=""
            disabled
            selected
            hidden
          >
            {{ $t('Ex. Growth') }}
          </option>
          <option
            :selected="investmentObjective==='Income'"
            value="Income"
          >
            {{ $t('Income') }}
          </option>
          <option
            :selected="investmentObjective==='Growth'"
            value="Growth"
          >
            {{ $t('Growth') }}
          </option>
          <option
            :selected="investmentObjective==='Trading'"
            value="Trading"
          >
            {{ $t('Trading Profits') }}
          </option>
          <option
            :selected="investmentObjective==='Speculation'"
            value="Speculation"
          >
            {{ $t('Speculation') }}
          </option>
          <option
            :selected="investmentObjective==='Hedging'"
            value="Hedging"
          >
            {{ $t('Hedging') }}
          </option>
        </BaseSelect>
      </div>
      <div>
        <label>
          {{ $t('Investment Knowledge') }}
        </label>
        <BaseSelect
          :appearance-none="false"
          :has-dark-mode="false"
          :required="true"
          :classes="knowledgeLevel ? 'h-14 px-4 py-4 w-full bg-transparent border-none shadow-md font-semibold rounded-sm' : 'h-14 px-4 py-4 w-full bg-transparent border-none shadow-md font-semibold rounded-sm text-gray-400 focus:text-primary !text-sm'"
          :upper-class="false"
          :model-value="knowledgeLevel"
          @change="$emit('knowledge-level', $event)"
        >
          <option
            value=""
            disabled
            selected
            hidden
          >
            {{ $t('Ex. Good') }}
          </option>
          <template v-if="!(tradesPerYear < 10 && yearsTrading =='1')">
            <option
              value="Limited"
              :selected="knowledgeLevel ==='Limited'? true:false"
            >
              {{ $t('Limited') }}
            </option>
            <option
              value="None"
              :selected="knowledgeLevel ==='None'? true:false"
            >
              {{ $t('None') }}
            </option>
          </template>
          <option
            value="Good"
            :selected="knowledgeLevel ==='Good'? true:false"
          >
            {{ $t('Good') }}
          </option>
          <option
            value="Extensive"
            :selected="knowledgeLevel ==='Extensive'? true:false"
          >
            {{ $t('Extensive') }}
          </option>
        </BaseSelect>
      </div>
    </div>


    <div class="grid gap-4 my-4" />
  </div>
</template>
  
<script>
export default {
  name: 'InvestmentDetailsForm',
  props: {

    
    investmentObjective: {
      type: String,
      default: ''
    },
    assetClass: {
      type: String,
      default: ''
    },
    knowledgeLevel: {
      type: String,
      default: ''
    },
    tradesPerYear: {
      type: String,
      default: ''
    },
    yearsTrading: {
      type: String,
      default: ''
    },


    baseSelectClass: {
      type: String,
      default: 'h-14 px-4 py-4 w-full bg-transparent border-none shadow-md font-semibold rounded-sm'
    }
  },

  emits: [
    'investment-objective',
    'asset-class',
    'knowledge-level',
    'trades-per-year',
    'years-trading'
  ]
}
</script>
<style lang="scss">
.mx-datepicker {
  @apply rounded-none;
  @apply h-12;
}
.mx-input {
  @apply rounded-none;
  @apply h-12;
}
</style>

  