<template>
  <ProfileMenu
    :is-verified="isVerified"
    :is-kyc-pending="isKycPending"
    :should-send-poi="shouldSendPoi"
    :should-send-poa="shouldSendPoa"
    :unread-messages="unreadMessages"
    :user="user"
    :sso-loading="damStore.loading"
    :step-loading="stepLoading"
    @submit-bank-details="damStore.requestSession()"
    @log-out="logout"
    @toggle="$emit('toggle', $event)"
    @redirect-to-kyc="redirectToKyc"
  />
</template>
<script>
import ProfileMenu from './_components/ProfileMenu'
import { useUsersStore } from '@/stores/users'
import { useChatsStore } from '@/stores/chats'
import { useDamStore } from '@/stores/dam'
import { useOnboardingsStore } from '@/stores/onboarding'
export default {
  name: 'NavProfileMenu',
  components: {
    ProfileMenu
  },
  emits: ['toggle'],
  setup () {
    const chatsStore = useChatsStore()
    const usersStore = useUsersStore()
    const damStore = useDamStore()
    const onboardingsStore = useOnboardingsStore()

    return { chatsStore, usersStore, damStore, onboardingsStore }
  },
  data () {
    return {
      stepLoading: false
    }
  },
  computed: {
    allMessages () {
      return this.chatsStore.allMessages
    },
    generalChats () {
      return this.chatsStore.generalChats
    },
    user () {
      return this.usersStore.user
    },
    unreadMessages () {
      if (!this.chatsStore.unreadMessages || this.chatsStore.unreadMessages.length === 0) return 0
      let messageCount = 0
      this.chatsStore.unreadMessages.forEach(chat => {
        if (chat) {
          messageCount += chat
        }
      })
      return messageCount
    },
    isVerified () {
      return this.user && this.user.profile && this.user.profile.is_verified
    },
    isKycPending () {
      return this.user && this.user.profile && this.user.is_kyc_pending
    },
    shouldSendPoi () {
      return this.user && this.user.should_send_poi
    },
    shouldSendPoa () {
      return this.user && this.user.should_send_poa
    },
    kycStep () {

      return this.usersStore?.user?.user_detail?.step ?? 0
    }

  },
  methods: {
    logout () {
      this.usersStore.logout()
      this.$emit('toggle')
    },
    async redirectToKyc () {
      try {
        this.stepLoading = true
        if (this.kycStep === 1) {
          this.onboardingsStore?.setKycPayload('step', 0)
          await this.onboardingsStore.storeUserDetails()
        }

        this.$router.push({ name: 'kyc' })
        this.stepLoading = false
      } catch (e) {
        console.error(e)
        this.stepLoading = false
      }
    }
  }
}
</script>
