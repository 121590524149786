<template>
  <div class="hidden md:block ">
    <DesktopMenu
      :open="open"
      :loaded="loaded"
      @toggle-navbar="$emit('toggle-navbar', $event)"
    />
  </div>
  <div class="block md:hidden">
    <MobileNav
      :open="open"
      :loaded="loaded"
      @toggle-navbar="$emit('toggle-navbar', $event)"
    />
  </div>
</template>
<script>
import MobileNav from './MobileNav.vue'
import DesktopMenu from './DesktopMenu.vue'
// import TopCompanySearchContainer from '@/modules/topCompanySearch'

export default {
  name: 'FrontHeaderInternal',
  components: {
    MobileNav,
    DesktopMenu,
    // TopCompanySearchContainer
  },
  props: {
    open: {
      type: Boolean,
      default: false
    },
    loaded: {
      type: Boolean,
      default: false
    },
  },
emits: [
'toggle-navbar'
]
}
</script>

<style lang="scss" scoped>
.router-link-active {
  @apply border-custom-green-500;
}
.menu-slider {
  transform: translateX(100%);
}
.slide-in {
  animation: slide-in 0.5s forwards;
}
.slide-out {
  animation: slide-out 0.9s backwards;
}
@keyframes slide-in {
  100% {
    transform: translateX(0%);
  }
}
@keyframes slide-out {
  0% {
    transform: translateX(-50%);
  }
  100% {
    width: translateX(100%);
  }
}
</style>
