import { RepositoryFactory } from "@/api/repositoryFactory"
const companyRepository = RepositoryFactory.get("companies")
const userRepository = RepositoryFactory.get("users")
import { defineStore } from "pinia"
import { useSnackbarStore } from "./snackbar"
import { useCompaniesStore } from "./companies"

export const useMembersStore = defineStore("members", {
  state: () => ({
    selectedCompanyId: null,
    companyMembers: {},
    userList: [],
    loading: false,
    nextPageUrl: "",
    scrollLoading: false,
    canScrollMore: false,
  }),
  getters: {
    members(state) {
      return state.companyMembers[state.selectedCompanyId]
    },
    users(state) {
      return state.userList
    },
  },
  actions: {
    async fetch(isCompany = true) {
      try {
        let response
        if (isCompany) {
          this.selectedCompanyId = useCompaniesStore().selectedCompanyId
          this.loading = true
          response = await companyRepository.memberList(
            useCompaniesStore().selectedCompanySN
          )
          this.loading = false
          if (response.status === 200 && response.data) {
            this.companyMembers = {
              ...this.companyMembers,
              [this.selectedCompanyId]: response.data.data,
            }
            this.nextPageUrl = response.data.next_page_url
          }
        } else {
          this.loading = true
          response = await userRepository.memberList()
          if (response.status === 200 && response.data) {
            this.userList = [...response.data.data]
          }
          this.loading = false
        }
      } catch (e) {
        this.loading = false
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async scroll() {
      try {
        if (this.nextPageUrl !== null && !this.loading) {
          this.scrollLoading = true
          const response = await companyRepository.scroll(this.nextPageUrl)
          if (response.status === 200 && response.data) {
            response.data.data.map((member) => {
              this.companyMembers[this.selectedCompanyId].push(member)
            })
            this.nextPageUrl = response.data.next_page_url
            this.scrollLoading = false
          }
        }
      } catch (e) {
        this.scrollLoading = false
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    reset() {
      this.companyMembers = {}
    },
  },
})
