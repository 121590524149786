import { post } from '../../api/helpers/axiosHelper';

const url = `${process.env.VUE_APP_API_URL}/s3/presigned`

export const uploadFiles = async (files) => {
  const filePromises = files.map(async (file) => {
    try {
      // Step 1: Get presigned URL from backend
      const response = await post(url, {
        path: file.filename
      });
      const uploadURL = response.data;
      // Step 2: Upload file to S3 using the presigned URL
      await fetch(uploadURL, {
        method: 'PUT',
        body: file.data
      })
      
      return { success: true, filename: file.name, uploadURL: uploadURL.split('?')[0] };
    } catch (error) {
      return { success: false, error: error.message };
    }
  });

  const results = await Promise.all(filePromises);
  return results;
};

export default { uploadFiles };


// Old code
// import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3'

// const creds = {
//   accessKeyId: process.env.VUE_APP_AWS_ACCESS_KEY_ID,
//   secretAccessKey: process.env.VUE_APP_AWS_SECRET_ACCESS_KEY
// }
// // a client can be shared by different commands.
// const s3 = new S3Client({
//   region: 'eu-west-2',
//   credentials: creds
// })

// export const uploadFiles = async (files) => {
//   const filePromises = files.map((file) => {
//     // Return a promise per file
//     return new Promise((resolve, reject) => {
//       const reader = new FileReader()
//       reader.onload = async () => {
//         try {
//           const response = s3.send(new PutObjectCommand({
//             Bucket: process.env.VUE_APP_BUCKET_NAME,
//             Key: file.filename, // File name you want to save as in S3
//             Body: new Uint8Array(reader.result),
//             ACL: 'public-read'
//           }))

//           // console.log('Success')
//           // Resolve the promise with the response value
//           resolve(response)
//         } catch (err) {
//           reject(err)
//         }
//       }
//       reader.onerror = (error) => {
//         reject(error)
//       }
//       reader.readAsArrayBuffer(file.data)
//     })
//   })

//   // Wait for all promises to be resolved
//   const fileInfos = await Promise.all(filePromises)

//   // console.log('COMPLETED')

//   // Profit
//   return fileInfos
// }

// export default { uploadFiles }
