<template>
  <div
    v-if="showUserCongrats"
    class="flex flex-col lg:w-1/2 w-full bg-image"
    :style="`background-image: url('svg/congrats-1.svg')`"
  >
    <div class="flex cursor-pointer  bg-transparent  p-10 pl-20">
      <Logo
        large
        class="cursor-pointer"
        @click="$emit('redirect-home', $event)"
      />
    </div>
  </div>
  <div
    v-else
    class="flex flex-col lg:w-1/2 w-full p-10 pl-20 md:bg-custom-purple-500 bg-image"
  >
    <div class="flex cursor-pointer bg-transparent">
      <Logo
        large
        class="cursor-pointer"
        @click="$emit('redirect-home', $event)"
      />
    </div>
  </div>
</template>

<script>

export default {
  name: 'SignupTextSection',

  props: {
    user: {
      type: String,
      default: ''
    },
    showUserCongrats:{
        type: Boolean,
        default: false
    },
  },
  emits: [
    'redirect-home'
  ]
}
</script>
<style scoped>
@media (min-width: 1024px) {
  .bg-image {
    background-position: center;
    background-image: url('~@/assets/img/world.jpg')
  }

}
</style>