import { useUsersStore } from "../stores/users"

export default {
  computed: {
    setup() {
      const usersStore = useUsersStore()

      return { usersStore }
    },
    followedCompanyIds() {
      if (!this.usersStore.user || !this.usersStore.user.profile) {
        return 0
      }
      return this.usersStore.user.profile.followed_company_ids
    },
  },
}
