<template>
  <div class=" space-y-6">
    <img
      v-if="isMobile"
      src="/svg/congrats-1.svg"
    >
    <div class="flex-col text-custom-purple-500 space-y-10 p-4">
      <div class="space-y-4">
        <div class="text-3xl font-extrabold ">
          You are verified
        </div>
        <div class=" font-medium ">
          Now you can explore your Eucaps profile page or set up your trading account.
        </div>
      </div>

      <div class="space-y-2.5">
        <NeutralButton
          type="button"
          :locked="stepLoading"
          class="w-full text-md focus:outline-none disabled:opacity-50"
          @click="$router.push({ name: 'myEucapsOverview' })"
        >
          {{ $t("Go to my Eucaps") }}
        </NeutralButton>
        <NeutralButton
          type="button"
          :locked="stepLoading"
          class="w-full text-md focus:outline-none disabled:opacity-50"
          @click="$emit('redirect-to-kyc')"
        >
          <div
            v-if="stepLoading"
            class="flex items-center justify-center gap-2"
          >
            <CircleLoader small />
            <div>Please wait..!</div>
          </div>
          <div v-else>
            {{ $t("Set up trading account") }}
          </div>
        </NeutralButton>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CongratsUser',
  props: {
    isMobile: {
      type: Boolean,
      default: false
    },
    stepLoading: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'password',
    'on-submit',
    'toggle-accept-terms',
    'reset-error',
    'back-to-signup-form',
    'toggle-show-password',
    'redirect-to-kyc'
  ]

}
</script>