<template>
  <div>
    <div class="text-lg font-semibold">
      {{ $t('Employment Details') }}
    </div>
    <div class="grid  my-4">
      <div>
        <label>
          {{ $t('Employment Type') }}
        </label>
        <BaseSelect
          :appearance-none="false"
          :has-dark-mode="false"
          :required="true"
          :classes="employType ? baseSelectClass : baseSelectClass + ' text-gray-400 focus:text-primary !text-sm'"
          :upper-class="false"
          :model-value="employType"
          @change="$emit('employ-type', $event)"
        >
          <option
            value=""
            disabled
            selected
            hidden
          >
            {{ $t('Ex. Employed') }}
          </option>

          <option
            value="EMPLOYED"
            :selected="employType === 'EMPLOYED' ? true : false"
          >
            {{ $t('Employed') }}
          </option>
          <option
            value="RETIRED"
            :selected="employType === 'RETIRED' ? true : false"
          >
            {{ $t('Retired') }}
          </option>
          <option
            value="SELFEMPLOYED"
            :selected="employType === 'SELFEMPLOYED' ? true : false"
          >
            {{ $t('Self Employed') }}
          </option>
          <option
            value="UNEMPLOYED"
            :selected="employType === 'UNEMPLOYED' ? true : false"
          >
            {{ $t('Un Employed') }}
          </option>
          <option
            value="STUDENT"
            :selected="employType === 'STUDENT' ? true : false"
          >
            {{ $t('Student') }}
          </option>
          <!-- <option value="INTERN">
            {{ $t('Intern') }}
          </option> -->
          <option
            value="ATHOMETRADER"
            :selected="employType === 'ATHOMETRADER' ? true : false"
          >
            {{ $t('At Home Trader') }}
          </option>
          <option
            value="HOMEMAKER"
            :selected="employType === 'HOMEMAKER' ? true : false"
          >
            {{ $t('Homemaker') }}
          </option>
        </BaseSelect>
      </div>
    </div>
    <div v-if="employType === 'EMPLOYED' || employType === 'SELFEMPLOYED'">
      <div
        :ref="'occupationDiv'"
        class="grid my-4 focus:p-1 focus:border-2 focus:rounded focus:shadow-lg dark:focus:border-white"
      >
        <label>
          {{ $t('Choose Occupation') }}
        </label>
        <Multiselect
          :ref="'occupationSelect'"
          :required="true"
          :model-value="occupation"
          :options="occupationList ? occupationList : []"
          :multiple="false"
          group-values="occupationLabel"
          group-label="occupationGroup"
          :group-select="false"
          :searchable="true"
          :close-on-select="true"
          :clear-on-select="true"
          :preserve-search="true"
          :placeholder="$t('Ex. Civil Engineer')"
          class="text-xs"
          label="name"
          track-by="name"
          :preselect-first="false"
          @update:model-value="$emit('occupation', $event)"
        />
      </div>
      <div class="grid my-4">
        <div>
          <label>
            {{ $t('Company Name') }}
          </label>
          <BaseInput
            :has-dark-mode="false"
            :required="true"
            :maxlength="128"
            :minlength="1"
            class="placeholder-custom-purple-500 h-14 w-full font-semibold"
            :placeholder="$t('Ex. Gusto')"
            shadow="shadow-md"
            :model-value="employer"
            @update:model-value="$emit('employer', $event)"
          />
        </div>
      </div>
      <div
        :ref="'employerBusinessDiv'"
        class="grid my-4"
      >
        <label>
          {{ $t('Choose Employer`s Business') }}
        </label>
        <Multiselect
          :ref="'employerBusinessSelect'"
          :required="true"
          :model-value="employerBusiness"
          :options="occupationList ? occupationList : []"
          :multiple="false"
          group-values="occupationLabel"
          group-label="occupationGroup"
          :group-select="false"
          :searchable="true"
          :close-on-select="true"
          :clear-on-select="true"
          :preserve-search="true"
          :placeholder="$t('Ex. Engineering')"
          class="text-xs"
          label="name"
          track-by="name"
          :preselect-first="false"
          @update:model-value="$emit('employer-business', $event)"
        />
      </div>
      
      <div v-if="employType === 'SELFEMPLOYED'">
        <div class="flex justify-between items-center w-full">
          <div class="flex border-2 rounded   px-4 py-2 w-full">
            <input
              id="empAddressSameAsResidenceCheckbox"
              type="checkbox"
              :checked="empAddressSameAsResidence"
              class="w-5 mr-2"
              @change="$emit('emp-address-same-as-residence')"
            >

            <label for="empAddressSameAsResidenceCheckbox">
              {{ $t('Employer Address is same as Residence') }}
            </label>
          </div>
        </div>


        <div
          v-if="!empAddressSameAsResidence"
          class="grid my-4"
        >
          <div>
            <label>
              {{ $t('Company Address') }}
            </label>
            <BaseInput
              :has-dark-mode="false"
              class="placeholder-custom-purple-500 h-14 w-full  font-semibold"
              :required="true"
              :maxlength="36"
              :minlength="1"
              :placeholder="$t('Ex. 42 State')"
              shadow="shadow-md"
              :model-value="employerStreet"
              @update:model-value="$emit('employer-street', $event)"
            />
          </div>


          <div class="grid lg:grid-cols-2 gap-4 my-4">
            <div>
              <label>
                {{ $t('Company City') }}
              </label>
              <BaseInput
                :has-dark-mode="false"
                :maxlength="24"
                :minlength="1"
                class="placeholder-custom-purple-500 h-14 w-full  font-semibold"
                :required="true"
                :placeholder="$t('Ex. Monacco')"
                shadow="shadow-md"
                :model-value="employerCity"
                @update:model-value="$emit('employer-city', $event)"
              />
            </div>


            <div>
              <label>
                {{ $t('Company Postal Code') }}
              </label>
              <BaseInput
                :has-dark-mode="false"
                class="placeholder-custom-purple-500 h-14 w-full  font-semibold"
                :required="true"
                :placeholder="$t('Ex. 987235')"
                shadow="shadow-md"
                :model-value="employerPostal"
                @update:model-value="$emit('employer-postal', $event)"
              />
            </div>
          </div>

          <div class="grid lg:grid-cols-2 gap-4 my-4">
            <div>
              <label>
                {{ $t('Choose Employer`s Country') }}
              </label>
              <BaseSelect
                :appearance-none="false"
                :has-dark-mode="false"
                :required="true"
                :classes="employerCountry ? baseSelectClass : baseSelectClass + ' text-gray-400 focus:text-primary !text-sm'"
                :upper-class="false"
                :model-value="employerCountry"
                @change="$emit('employer-country', $event)"
              >
                <option
                  value=""
                  disabled
                  selected
                  hidden
                >
                  {{ $t('Ex. Sweden') }}
                </option>

                <option
                  v-for="country in countriesWithStates"
                  :key="country.id"
                  :value="country.ibkr_code"
                  :selected="employerCountry === country.ibkr_code ? true : false"
                >
                  {{ country.name }}
                </option>
              </BaseSelect>
            </div>


            <div>
              <label>
                {{ $t('Choose Employer`s State') }}
              </label>
              <BaseSelect
                :appearance-none="false"
                :has-dark-mode="false"
                :required="true"
                :classes="employerState ? baseSelectClass : baseSelectClass + ' text-gray-400 focus:text-primary !text-sm'"
                :upper-class="false"
                :model-value="employerState"
                @change="$emit('employer-state', $event)"
              >
                <option
                  value=""
                  disabled
                  selected
                  hidden
                >
                  {{ $t('Ex. Stockholm') }}
                </option>

                <option
                  v-for="state in employerStateList"
                  :key="state.id"
                  :value="state.ibkr_code"
                  :selected="employerState === state.ibkr_code ? true : false"
                >
                  {{ state.name }}
                </option>
              </BaseSelect>
            </div>
          </div>
        </div>
      </div>

      <div
        v-else
        class="grid my-4"
      >
        <div>
          <label>
            {{ $t('Company Address') }}
          </label>
          <BaseInput
            :has-dark-mode="false"
            class="placeholder-custom-purple-500 h-14 w-full  font-semibold"
            :required="true"
            :maxlength="36"
            :minlength="1"
            :placeholder="$t('Ex. 42 State')"
            shadow="shadow-md"
            :model-value="employerStreet"
            @update:model-value="$emit('employer-street', $event)"
          />
        </div>

        <div class="grid lg:grid-cols-2 gap-4 my-4">
          <div>
            <label>
              {{ $t('Company City') }}
            </label>
            <BaseInput
              :has-dark-mode="false"
              :maxlength="24"
              :minlength="1"
              class="placeholder-custom-purple-500 h-14 w-full  font-semibold"
              :required="true"
              :placeholder="$t('Ex. Monacco')"
              shadow="shadow-md"
              :model-value="employerCity"
              @update:model-value="$emit('employer-city', $event)"
            />
          </div>


          <div>
            <label>
              {{ $t('Company Postal Code') }}
            </label>
            <BaseInput
              :has-dark-mode="false"
              class="placeholder-custom-purple-500 h-14 w-full  font-semibold"
              :required="true"
              :placeholder="$t('Ex. 987235')"
              shadow="shadow-md"
              :model-value="employerPostal"
              @update:model-value="$emit('employer-postal', $event)"
            />
          </div>
        </div>

        <div class="grid lg:grid-cols-2 gap-4 my-4">
          <div>
            <label>
              {{ $t('Choose Employer`s Country') }}
            </label>
            <BaseSelect
              :appearance-none="false"
              :has-dark-mode="false"
              :required="true"
              :classes="employerCountry ? baseSelectClass : baseSelectClass + ' text-gray-400 focus:text-primary !text-sm'"
              :upper-class="false"
              :model-value="employerCountry"
              @change="$emit('employer-country', $event)"
            >
              <option
                value=""
                disabled
                selected
                hidden
              >
                {{ $t('Ex. Sweden') }}
              </option>

              <option
                v-for="country in countriesWithStates"
                :key="country.id"
                :value="country.ibkr_code"
                :selected="employerCountry === country.ibkr_code ? true : false"
              >
                {{ country.name }}
              </option>
            </BaseSelect>
          </div>


          <div>
            <label>
              {{ $t('Choose Employer`s State') }}
            </label>
            <BaseSelect
              :appearance-none="false"
              :has-dark-mode="false"
              :required="true"
              :classes="employerState ? baseSelectClass : baseSelectClass + ' text-gray-400 focus:text-primary !text-sm'"
              :upper-class="false"
              :model-value="employerState"
              @change="$emit('employer-state', $event)"
            >
              <option
                value=""
                disabled
                selected
                hidden
              >
                {{ $t('Ex. Stockholm') }}
              </option>

              <option
                v-for="state in employerStateList"
                :key="state.id"
                :value="state.ibkr_code"
                :selected="employerState === state.ibkr_code ? true : false"
              >
                {{ state.name }}
              </option>
            </BaseSelect>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmployerDetailsForm',
  props: {
    countriesWithStates: {
      type: Array,
      default: () => []
    },

    employerStateList: {
      type: Array,
      default: () => []
    },

    employType: {
      type: String,
      default: ''
    },
    occupation: {
      type: String,
      default: ''
    },
    employer: {
      type: String,
      default: ''
    },
    employerBusiness: {
      type: String,
      default: ''
    },
    employerStreet: {
      type: String,
      default: ''
    },
    employerCity: {
      type: String,
      default: ''
    },
    employerState: {
      type: String,
      default: ''
    },
    employerPostal: {
      type: String,
      default: ''
    },
    employerCountry: {
      type: String,
      default: ''
    },

    occupationList: {
      type: Array,
      default: () => []
    },

    baseSelectClass: {
      type: String,
      default: 'h-14 px-4 py-4 w-full bg-transparent shadow-md font-semibold rounded-sm'
    },

    empAddressSameAsResidence: {
      type: Boolean,
      default: true
    }
  },

  emits: [
    'employ-type',
    'occupation',
    'employer',
    'employer-business',
    'employer-street',
    'employer-city',
    'employer-state',
    'employer-postal',
    'employer-country',
    'emp-address-same-as-residence'
  ]
}
</script>
<style lang="scss">

.multiselect {
  @apply border-gray-300 #{!important};
  @apply dark:border-gray-300 #{!important};
}

.multiselect__content-wrapper {
  @apply dark:bg-white #{!important};
  @apply text-primary #{!important};
  @apply dark:text-primary #{!important};
}

.multiselect__placeholder {
  color: black;
  height: 1.9rem;
  @apply dark:text-primary #{!important};
}
.multiselect__tags {
  padding-top: 0.54rem;
  @apply dark:bg-white #{!important};
  @apply border-gray-300 #{!important};
  @apply dark:border-gray-300 #{!important};
  @apply text-primary #{!important};
  @apply dark:text-primary #{!important};
}

.multiselect__single,
.multiselect__input {
  @apply dark:bg-white #{!important};
  @apply text-primary #{!important};
  @apply dark:text-primary #{!important};
  @apply py-1 #{!important};
}
.multiselect__option {
  @apply text-sm;
}
</style>