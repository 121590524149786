<template>
  <BaseButton
    no-shadow
    no-padding
    :bold="bold"
    class="py-2 px-4 flex items-center font-semibold"
    rounded="rounded-none"
    :class="
      [
        eucaps ? active ? 'border-custom-orange-400 border-b-2' : 'border-b-2 border-transparent' : 'border-b-2 border-gray-300 dark:border-custom-dark-header',
        !eucaps && active ? 'bg-none hover:border-gray-500 text-black active dark:text-white border-black dark:border-white border-b-2' : 'text-custom-purple-400 dark:text-custom-dark-header bg-none',
        size
      ]"
    @click="$emit('click', $event)"
  >
    <!-- <Icon
      class="text-base"
      :name="active ? 'check' : 'minus'"
      :class="active ? 'check-margin' : ''"
    /> -->
    <div
      class="py-0"
      :class="active ? 'text-black dark:text-white' : ''"
    >
      <slot />
    </div>
  </BaseButton>
</template>

<script>

export default {
  name: 'TabButton',
  props: {
    noPadding: { type: Boolean, default: true },
    eucaps: { type: Boolean, default: false },
    noShadow: { type: Boolean, default: true },
    active: { type: Boolean, default: false },
    size: { type: String, default: ' text-2xs' },
    bold: { type: Boolean, default: true }
  },
  emits: [
    'click'
  ]
}
</script>

<style lang="scss" scoped>
.check-margin {
  padding-bottom: 1px;
}

.active {
  // margin-bottom: -2px;
}
</style>
