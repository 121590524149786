<template>
  <BaseCard class="rounded-none z-10 overflow-auto shadow-none">
    <template #body>
      <AvatarSlot :stock-user="usersStore.profile">
        <!-- LANGUAGE -->
        <BaseInput
          :model-value="message"
          :disabled="disable"
          class="flex-shrink flex-grow rounded-lg text-xs inline-flex"
          :placeholder="placeholder"
          @submit="$emit('submit')"
          @update:model-value="$emit('message', $event)"
        />
        <slot />
      </AvatarSlot>
    </template>
  </BaseCard>
</template>

<script>

import { useUsersStore } from '../stores/users';

export default {
  name: 'AvatarInput',
  props: {
    message: { type: String, default: '' },
    placeholder: { type: String, default: 'Write here' },
    disable: { type: Boolean, default: false }
  },
  emits: [
    'submit',
    'message'
  ],
  setup() {
    const usersStore = useUsersStore()

    return { usersStore }
  }
}
</script>
