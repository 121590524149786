<template>
  <div
    v-if="linkableAvatarOnly"
    class="flex "
    :class="(reverse) ? ' flex-row-reverse' : ''"
  >
    <component
      :is="linkableAvatar ? 'RouterLink' : 'div'"
      :to="linkableAvatar ? to : null"
      class="inline"
      :class="alignment"
    >
      <Avatar
        :size="size"
        :margins="margins"
        :default-class="defaultClass"
        :rounded="rounded"
        :stock-user="stockUser"
        :company="company"
        :use-premium="usePremium"
        :use-reputation="useReputation"
      />
    </component>
    <slot />
  </div>
  <component
    :is="linkableAvatar ? 'RouterLink' : 'div'"
    v-else
    :to="linkableAvatar ? to : null"
    :class="alignment"
  >
    <Avatar
      :size="avatarSize"
      :default-class="customDefaultClass"
      :stock-user="stockUser"
      :company="company"
      :rounded="rounded"
      :margins="margins"
      :use-premium="usePremium"
      :use-reputation="useReputation"
    />
    <slot />
  </component>
</template>

<script>
import Avatar from './Avatar.vue'

export default {
  name: 'AvatarSlot',
  components: {
    Avatar
  },
  props: {
    reverse: { type: Boolean, default: false },
    rounded: { type: String, default: 'rounded-full' },
    stockUser: { type: Object, default: () => { } },
    company: { type: Object, default: () => { } },
    size: { type: Number, default: 40 },
    defaultClass: { type: String, default: 'w-10 h-10' },
    alignment: { type: String, default: 'flex w-full relative' },
    margins: { type: String, default: 'mr-2' },
    linkableAvatar: { type: Boolean, default: false },
    linkableAvatarOnly: { type: Boolean, default: false },
    bigAvatar: { type: Boolean, default: false },
    useReputation: { type: Boolean, default: false },
    postId: { type: Number, default: null },
    usePremium: { type: Boolean, default: false }
  },
  computed: {
    to () {
      if (this.postId) {
        return { name: 'posts', params: { id: this.postId } }
      } else if (this.stockUser) {
        return { name: 'userFlow', params: { id: this.stockUser.id } }
      } else {
        return { name: 'companyOverview', params: { id: this.company.slug_url } }
      }
    },
    avatarSize () {
      if (this.bigAvatar) {
        return 50
      }
      return this.size
    },
    customDefaultClass () {
      if (this.bigAvatar) {
        return 'w-12 h-12'
      }

      return this.defaultClass
    }
  }
}
</script>
