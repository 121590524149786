import { Capacitor } from '@capacitor/core'
import { detectBrowser, isMobile } from '@/js/helpers/mobile'
import { RepositoryFactory } from '@/api/repositoryFactory'
import platform from 'platform'
import LATEST_VERSION from '@/version.json'
import { defineStore } from 'pinia'

const repository = RepositoryFactory.get('app')
import { useUsersStore } from './users'

export const useAppsStore = defineStore('app', {
  state: () => ({
    version: LATEST_VERSION,
    latestVersion: '1',
    forcedVersion: '1',
    versionFetched: false,
    menu: false,
    useHeader: true,
    useFooter: true,
    loadingVersion: false,
    loadingMaintenance: false,
    maintenance: false,
    maintenanceTo: null,
    insideCompanyPage: false,
    insidePrivateChat: false,
    fixedHash: 'aurja23987',
    browser: localStorage.browser ? localStorage.browser : detectBrowser,
    originalMyEucapsMenu: [
      // {
      //   routeName: 'myEucapsOverview',
      //   label: 'My Home'
      // },
      // {
      //   routeName: 'myEucapsLists',
      //   label: 'My Lists'
      // },
      // {
      //   routeName: 'myEucapsFavorites',
      //   label: 'My Favorites'
      // },
      // {
      //   routeName: 'myEucapsMarketplace',
      //   label: 'My Marketplace'
      // }
      {
        id: 0,
        name: 'Overview',
        icon: '',
        routerName: 'myEucapsOverview'
      },
      {
        id: 1,
        name: 'My feed',
        icon: '',
        routerName: 'myFeed'
      },
      {
        id: 2,
        name: 'My portfolio',
        icon: '',
        routerName: 'myEucapsPortfolio'
      },
      {
        id: 3,
        name: 'My Lists',
        icon: '',
        routerName: 'myEucapsLists'
      },
      {
        id: 4,
        name: 'Following',
        icon: '',
        routerName: 'myFollowings'
      },
      {
        id: 5,
        name: 'Followers',
        icon: '',
        routerName: 'myFollowers',
        params: 1
      },
     
      {
        id: 7,
        name: 'Settings',
        icon: '',
        routerName: 'myEucapsSettings'
      }
    ]
  }),
  getters: {
    myEucapsMenu: (state) => {
      const menu = [...state.originalMyEucapsMenu]

      if (useUsersStore().user?.profile?.company_user) {
        menu.push({
          // routeName: 'myEucapsMyCompany',
          // label: 'My Company'
          id: 6,
          name: 'My Company',
          icon: '',
          routerName: 'myEucapsMyCompany'
        })
      }

      return menu
    },
    platformKey: () => {
      if (!isMobile()) {
        return (platform.name + platform.version + platform.os).replace(/\s\S/g, '')
      }

      return (platform.product + platform.os).replace(/\s\S/g, '')
    },
    platform: () => Capacitor.getPlatform(),
    isNative: () => Capacitor.isNativePlatform(),
    newVersionAvailable(state) {
      if (!state.versionFetched || state.loadingVersion) {
        return false
      }

      if (!useUsersStore().user) {
        return false
      }

      const currentVersion = Number(state.version)
      const latestVersion = Number(useUsersStore().user.latestVersion)

      return currentVersion !== latestVersion && currentVersion < latestVersion
    },
    forcedVersionAvailable() {
      if (!this.versionFetched || this.loadingVersion) {
        return false
      }

      if (!useUsersStore().user) {
        return false
      }

      const currentVersion = Number(this.version)
      const forcedVersion = Number(useUsersStore().user.forced_version)

      return currentVersion < forcedVersion
    }
  },
  actions: {
    useMenu(useMenu) {
      this.menu = useMenu
    },
    setUseHeader(useHeader) {
      this.useHeader = useHeader
    },
    setUseFooter(useFooter) {
      this.useFooter = useFooter
    },
    setInsideCompanyPage(insideCompanyPage) {
      this.insideCompanyPage = insideCompanyPage
    },
    insidePrivateChat(insidePrivateChat) {
      this.insidePrivateChat = insidePrivateChat
    },
    async getLatestVersion() {
      this.versionFetched = false
      this.loadingVersion = true
      const response = await repository.latestVersion()
      this.loadingVersion = false
      if (response && response.status === 200) {
        this.latestVersion = response.data
        this.forcedVersion = response.data.forced_version ? response.data.forced_version : 1
        this.versionFetched = true
      } else {
        this.versionFetched = false
      }
    },
    async getMaintenance() {
      this.maintenanceFetched = false
      this.loadingMaintenance = true
      const response = await repository.maintenance()
      this.loadingMaintenance = false
      if (response && response.status === 200) {
        if (!response.data.maintenance || response.data.maintenance === false) {
          this.maintenance = false
          this.maintenanceTo = null
        } else {
          this.maintenance = true
          this.maintenanceTo = response.data.maintenance_to
        }

        this.maintenanceFetched = true
      } else {
        this.maintenanceFetched = false
      }
    },
    async lead(payload) {
      repository.lead(payload)
    }
  },
})
