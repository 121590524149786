<template>
  <div>
    <div class="text-lg font-semibold">
      {{ $t('Identification') }}
    </div>

    <div class="grid gap-4 my-4">
      <div>
        <label>
          {{ $t('Citizenship') }}
        </label>

        <BaseSelect
          :appearance-none="false"
          :has-dark-mode="false"
          :required="true"
          :classes="citizenship ? baseSelectClass : baseSelectClass + ' text-gray-400 focus:text-primary !text-sm'"
          :upper-class="false"
          :model-value="citizenship"
          @change="$emit('citizenship', $event)"
        >
          <option
            value=""
            disabled
            selected
            hidden
          >
            {{ $t('Ex. Sweden') }}
          </option>

          <option
            v-for="country in countriesWithStates"
            :key="country.id"
            :value="country.ibkr_code"
            :selected="citizenship===country.ibkr_code"
          >
            {{ country.name }}
          </option>
        </BaseSelect>
      </div>
    </div>
    <div class="grid lg:grid-cols-2 gap-4 my-4">
      <div>
        <label>
          {{ $t('Identification Type') }}
        </label>
        <BaseSelect
          :appearance-none="false"
          :has-dark-mode="false"
          :required="true"
          :classes="issuedDocumentType ? baseSelectClass : baseSelectClass + ' text-gray-400 focus:text-primary !text-sm'"
          :upper-class="false"
          :model-value="issuedDocumentType"
          @change="$emit('issued-document-type', $event)"
        >
          <option
            value=""
            disabled
            selected
            hidden
          >
            {{ $t('Ex. National Card') }}
          </option>

          <option
            v-for="(docType,index) in issuedDocumentTypeList"
            :key="index"
            :value="docType.code"
            :selected="issuedDocumentType===docType.code || issuedDocumentTypeList.length ===1"
          >
            {{ docType.name }}
          </option>
        </BaseSelect>
      </div>
      <div>
        <label>
          {{ $t('Identification Number') }}
        </label>
        
        <input
          v-if="selectedDocumentType.pattern"
          type="text"
          :placeholder="selectedDocumentType.example"
          :pattern="selectedDocumentType.pattern"
          :title="selectedDocumentType.title"
          :value="issuedDocumentNumber"
          class="border border-gray-300 rounded-none px-4 py-2 shadow-md px-4 py-2 text-sm rounded-sm  focus:outline-none focus:ring-0  h-14 w-full font-semibold text-right"
          @input="$emit('issued-document-number', $event)"
        > 
        <input
          v-else
          type="text"
          :placeholder="$t('Ex. 987654321')"
          :title="$t('Give Valid Document Number')"
          :value="issuedDocumentNumber"
          class="border border-gray-300 rounded-none px-4 py-2 shadow-md px-4 py-2 text-sm rounded-sm  focus:outline-none focus:ring-0  h-14 w-full font-semibold text-right"
          @input="$emit('issued-document-number', $event)"
        >
      </div>
    </div>
    <div class="grid lg:grid-cols-2 gap-4 my-4">
      <div>
        <label>
          {{ $t('Issuing Country') }}
        </label>

        <BaseSelect
          :appearance-none="false"
          :has-dark-mode="false"
          :required="true"
          :classes="issuingCountry ? baseSelectClass : baseSelectClass + ' text-gray-400 focus:text-primary !text-sm'"
          :upper-class="false"
          :model-value="issuingCountry"
          @change="$emit('issuing-country', $event)"
        >
          <option
            value=""
            disabled

            :selected="''===issuingCountry ? true : false"
            hidden
          >
            {{ $t('Ex. Sweden') }}
          </option>

          <option
            v-for="country in countriesWithStates"
            :key="country.id"
            :value="country.ibkr_code"
            :selected="issuingCountry===country.ibkr_code"
          >
            {{ country.name }}
          </option>
        </BaseSelect>
      </div>
      <div v-if="issuingCountry === 'AUS' && issuedDocumentType === 'DriversLicense'">
        <label>
          {{ $t('Issuing State') }}
        </label>

        <BaseSelect
          :appearance-none="false"
          :has-dark-mode="false"
          :required="true"
          :classes="issuingState ? baseSelectClass : baseSelectClass + ' text-gray-400 focus:text-primary !text-sm'"
          :upper-class="false"
          :model-value="issuingState"
          @change="$emit('issuing-state', $event)"
        >
          <option
            value=""
            disabled
            selected
            hidden
          >
            {{ $t('Ex. Skane Lan') }}
          </option>

          <option
            v-for="state in issuingStateList"
            :key="state.id"
            :value="state.ibkr_code"
            :selected="issuingState===state.ibkr_code"
          >
            {{ state.name }}
          </option>
        </BaseSelect>
      </div>
    </div>

    <div
      v-if="issuingCountry === 'AUS' && issuedDocumentType === 'DriversLicense'"
      class="grid my-4"
    >
      <div>
        <label>
          {{ $t('RTA') }}
        </label>
        <BaseInput
          :has-dark-mode="false"
          class="h-14 w-full font-semibold text-right"
         
          :placeholder="$t('Ex. 987654321')"
          shadow="shadow-md"
          :model-value="issuingRta"
          @update:model-value="$emit('issuing-rta', $event)"
        />
      </div>
    </div>

    <div
      v-if="issuingCountry === 'AUS' && issuedDocumentType === 'MedicareCard'"
      class="grid lg:grid-cols-2 gap-4 my-4"
    >
      <div>
        <label>
          {{ $t('Medicare Card Reference Number') }}
        </label>
        <BaseInput
          :has-dark-mode="false"
          class=" h-14 w-full font-semibold text-right"
         
          :placeholder="$t('Ex. 987465231')"
          shadow="shadow-md"
          :model-value="citizenMediCareRefNo"
          @update:model-value="$emit('citizen-medi-care-ref-no', $event)"
        />
      </div>

      <div>
        <label>
          {{ $t('Medicare Card Color') }}
        </label>
        <BaseSelect
          :appearance-none="false"
          :has-dark-mode="false"
          :required="true"
          :classes="citizenMediCardColor ? baseSelectClass : baseSelectClass + ' text-gray-400 focus:text-primary !text-sm'"
          :upper-class="false"
          :model-value="citizenMediCardColor"
          @change="$emit('citizen-medi-card-color', $event)"
        >
          <option
            value=""
            disabled
            selected
            hidden
          >
            {{ $t('Ex. Blue') }}
          </option>

          <option
            value="BLUE"
            :selected="citizenMediCardColor==='BLUE' ? true : false"
          >
            {{ $t('Blue') }}
          </option>
          <option
            value="GREEN"
            :selected="citizenMediCardColor==='GREEN' ? true : false"
          >
            {{ $t('Green') }}
          </option>
          <option
            value="YELLOW"
            :selected="citizenMediCardColor==='YELLOW' ? true : false"
          >
            {{ $t('Yellow') }}
          </option>
        </BaseSelect>
      </div>
    </div>
   
    <div class="grid my-4">
      <div class="flex justify-center border-2 rounded">
        <input
          type="checkbox"
          :checked="checkCitizenExpDate"
          class="w-6 mx-4"
          @change="$emit('toggle-citizen-exp-date')"
        >


        <DatePicker
          :open="openCitizenExpDate"
          :popup-class="theme"
          :value="citizenExpDate"
          :disabled="!checkCitizenExpDate"
          type="date"
          format="YYYY-MM-DD"
          :required="checkCitizenExpDate"
          value-type="format"
          :placeholder="$t('Identification document expiration date')"
          @change="$emit('citizen-exp-date', $event)"
          @blur="$emit('toggle-open-citizen-exp-date', $event)"
        />
      </div>

      <span class="text-xs font-medium">
        {{ $t('Tick above if your identification document has expiration date') }}
      </span>
    </div>
    <div class="grid  gap-4 my-4">
      <div class="grid  ">
        <label>
          {{ $t('Tax Residence Country') }}
        </label>
        <BaseSelect
          :appearance-none="false"
          :has-dark-mode="false"
          :required="true"
          :classes="taxCountry ? baseSelectClass : baseSelectClass + ' text-gray-400 focus:text-primary !text-sm'"
          :upper-class="false"
          :model-value="taxCountry"
          @change="$emit('tax-country', $event)"
        >
          <option
            value=""
            disabled
            selected
            hidden
          >
            {{ $t('Ex. Sweden') }}
          </option>

          <option
            v-for="country in countriesWithStates"
            :key="country.id"
            :value="country.ibkr_code"
            :selected="taxCountry===country.ibkr_code"
          >
            {{ country.name }}
          </option>
        </BaseSelect>
        <span class="text-xs font-medium">
          {{ $t('Country where you pay taxes.') }}
        </span>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: 'IdentificationForm',
  props: {
    citizenship: {
      type: String,
      default: ''
    },


    issuingCountry: {
      type: String,
      default: ''
    },

    issuingState: {
      type: String,
      default: ''
    },
    issuingRta: {
      type: String,
      default: ''
    },
    citizenMediCardColor: {
      type: String,
      default: ''
    },
    citizenMediCareRefNo: {
      type: String,
      default: ''
    },


    issuedDocumentType: {
      type: String,
      default: ''
    },
    issuedDocumentNumber: {
      type: String,
      default: ''
    },
    checkCitizenExpDate: {
      type: Boolean,
      default: false
    },
    citizenExpDate: {
      type: String,
      default: ''
    },
    openCitizenExpDate: {
      type: Boolean,
      default: false
    },

    countriesWithStates: {
      type: Array,
      default: () => []
    },
    issuingStateList: {
      type: Array,
      default: () => []
    },

    issuedDocumentTypeList: {
      type: Array,
      default: () => []
    },

    baseSelectClass: {
      type: String,
      default: 'h-14 px-4 py-4 w-full bg-transparent border-none shadow-md font-semibold rounded-sm'
    },
    theme: {
      type: String,
      default: ''
    },
    selectedDocumentType:{
      type: Object,
      default: ()=>{
        return {
          example :'',
          pattern:'',
          title:''
        }
      }
    },

    taxCountry:{
      type: String,
      default: ''
    }
  },

  emits: [
    'citizenship',

    'issuing-state',
    'issuing-rta',
    'citizen-medi-card-color',
    'citizen-medi-care-ref-no',

    'issuing-country',
    'issued-document-type',
    'issued-document-number',
    'toggle-citizen-exp-date',
    'citizen-exp-date',
    'toggle-open-citizen-exp-date',

    'tax-country'
  ]
}
</script>
<style lang="scss">
.mx-datepicker {
  @apply rounded-none;
}

.mx-input {
  @apply rounded-none;
}


</style>
  