<template>
  <div
    class="flex items-center text-sm cursor-pointer"
  >
    <Icon
      :name="selected ? 'radio-circle-marked' : 'radio-circle'"
      :class="selected ? 'text-custom-orange-400' : 'dark:text-custom-orange-400'"
      class="text-3xl"
    />
    <div class="dark:text-custom-purple-100">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  name: 'BaseRadioButton',
  props: {
    selected: {
      type: Boolean,
      default: false
    }
  }
}
</script>