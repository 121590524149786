<template>
  <div class="w-full h-full">
    <AppsMenu
      v-if="showAppsMenu"
      :unread-messages="unreadMessages"
      @menu-clicked="showAppsMenu = false"
    />
    <!-- <Menu
      :nav="nav"
      :user="user"
      :active-menu="activeMenu"
      :unread-messages="unreadMessages"
      @reload="reload"
      @show-my-eucaps-menu="showMyEucapsMenu = !showMyEucapsMenu"
    /> -->
    <MyEucapsMenuModalContainer
      v-if="showMyEucapsMenu"
      :show-my-eucaps-menu="showMyEucapsMenu"
      @toggle-my-eucaps-menu="showMyEucapsMenu = false"
    />
  </div>
</template>

<script>

import Menu from './_components/Menu'
import AppsMenu from './_components/AppsMenu'
import notificationMixin from '@/mixins/notificationMixin'
import MyEucapsMenuModalContainer from '@/modules/myEucapsMenuModal'
import { useUsersStore } from '../../stores/users'
import { useChatsStore } from '../../stores/chats'
import { useNotificationsStore } from '../../stores/notifications'
import { useCompaniesStore } from '../../stores/companies'
import { usePostsStore } from '../../stores/posts'

export default {
  name: 'MenuModule',
  components: {
    Menu,
    AppsMenu,
    MyEucapsMenuModalContainer
  },
  mixins: [notificationMixin],
  setup() {
    const usersStore = useUsersStore()
    const chatsStore = useChatsStore()
    const notificationsStore = useNotificationsStore()
    const companiesStore = useCompaniesStore()
    const postsStore = usePostsStore()
    
    return { postsStore, usersStore, chatsStore, notificationsStore, companiesStore }
  },
  data () {
    return {
      showMyEucapsMenu: false,
      showAppsMenu: false,
      nav: [
        {
          link: { name: 'myFeed' },
          text: 'General',
          type: 'link',
          icon: 'home',
          hasSolid: true
        },
        {
          link: { name: 'exploreCompanies' },
          text: 'Explore',
          type: 'link',
          icon: 'compass',
          hasSolid: false
        },
        {
          link: { name: 'myEucapsOverview' },
          text: 'My Home',
          type: 'link',
          icon: 'altered-avatar',
          hasSolid: true
        },
        {
          link: { name: 'notifications' },
          text: 'Notifications',
          type: 'link',
          icon: 'bell',
          hasSolid: true
        },
        {
          link: 'extended-menu',
          text: 'Menu',
          type: 'modal',
          icon: 'dots-horizontal-rounded',
          hasSolid: true
        }
      ]
    }
  },
  computed: {
    isLoggedIn() {
      return this.usersStore.isLoggedin
    },
    allMessages() {
      return this.chatsStore.allMessages
    },
    user() {
      return this.usersStore.user
    },
    generalChats() {
      return this.chatsStore.generalChats
    },
    notifications() {
      return this.notificationsStore.list
    },
    companies() {
      return this.companiesStore.companies
    },
    activeMenu () {
      if (this.showAppsMenu) {
        return this.nav.find(n => {
          return n.text === 'Apps'
        })
      }

      return this.nav.find(n => {
        return this.$route.fullPath.match(n.link)
      })
    },
    unreadMessages () {
      if (!this.allMessages) return 0
      let unreadMessageArray = 0
      Object.values(this.allMessages).filter(chat => {
        chat.filter(message => {
          if (this.generalChats.find(chat => Number(chat.id) === Number(message.chat_id))) {
            const data = message.seen_by.find(user => Number(user.id) === Number(this.user.profile.id) && user.pivot.seen === 0)
            if (data) {
              unreadMessageArray++
            }
          }
        })
      })
      return unreadMessageArray
    }
  },
  methods: {
    reload (menuItem) {
      if (menuItem.text === 'General') {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        this.postsStore.refetch()
      }

      if (menuItem.link === 'extended-menu') {
        this.showMyEucapsMenu = !this.showMyEucapsMenu
      } else {
        this.showMyEucapsMenu = false
      }
    }
  }
}
</script>
