<template>
  <div class="quill-editor-container">
    <div
      class="flex items-start"
      :class="padding"
    >
      <div class="flex items-center z-20 mt-1">
        <slot />
      </div>
      <div :class="`container relative z-30 flex flex-col justify-center items-end w-full ${maxHeight}`">
        <div
          v-if="maxLengthReached"
          class="bg-pink-100 border-custom-orange-400 text-custom-orange-400 p-1 text-xs absolute left-0 -top-6"
        >
          {{ $t('Max length reached') }}
        </div>
        <div
          v-if="loginRequired || usersStore.user.profile_type === 'App\\Models\\GuestUser' || !usersStore.user.profile || (usersStore.user.profile && !usersStore.user.profile.is_verified)"
          class="bg-blue-500 text-white p-0 text-xs absolute left-0 -top-7 z-20"
        >
          <RouterLink
            v-if="usersStore.user.profile && usersStore.user.profile_type === 'App\\Models\\GuestUser'"
            class="p-1 flex items-center"
            :to="{name: 'login'}"
          >
            <Icon
              name="log-in-circle"
              solid
              class="text-lg mr-1"
            />
            <div>{{ $t('Login to write something') }}</div>
            <Icon
              name="chevron-right"
              class="text-lg"
            />
          </RouterLink>
          <RouterLink
            v-else-if="usersStore.user.profile && !usersStore.user.profile.is_verified"
            class="p-1 flex items-center"
            :to="{name: 'signup'}"
          >
            <Icon
              name="id-card"
              solid
              class="text-lg mr-1"
            />
            <div>{{ $t('Verify your account to write something') }}</div>
            <Icon
              name="chevron-right"
              class="text-lg"
            />
          </RouterLink>
        </div>
        <vue-editor
          :id="elementId ? elementId : null"
          :ref="elementId ? elementId : 'editor'"
          :model-value="content"
          :class="'rounded-xl w-full dark:text-custom-purple-100 dark:bg-custom-purple-450' + iphoneClass"
          :placeholder="placeholder"
          :editor-options="editorSettings"
          :disabled="locked"
          @update:model-value="checkInput"
          @blur="$emit('blur', $event)"
        />
        <!-- <Icon
          class="text-2xl absolute top-1 right-2 font-semibold text-custom-purple-400 dark:text-custom-purple-100"
          name="happy"
          :locked="locked"
          @click="$emit('emoji')"
        /> -->
        <div class="justify-end text-2xs mr-1 dark:text-custom-purple-100">
          {{ contentLength }} {{ $t('of') }} {{ maxLength }}
        </div>
      </div>
      <div class="flex flex-col items-center mt-1">
        <Icon
          v-if="withSendButton"
          class="text-2xl cursor-pointer pl-2 font-semibold text-custom-purple-400 dark:text-custom-purple-100"
          name="send"
          :locked="locked"
          @click="$emit('submit')"
        />
        <Icon
          v-if="editMode"
          class="text-3xl cursor-pointer pl-2 -mt-2 font-bold text-custom-purple-400 dark:text-custom-purple-100"
          name="x"
          :locked="locked"
          @click="$emit('clear')"
        />
      </div>
    </div>
    <Picker
      v-if="emoji"
      :data="emojiIndex"
      title="Pick your emoji..."
      emoji="point_up"
      :class="`width ${pickerPosition}`"
      set="apple"
      :emoji-size="36"
      class="mt-4"
      theme="dark"
      :disabled="locked"
      @select="addEmoji"
    />
  </div>
</template>

<script>

import data from 'emoji-mart-vue-fast/data/all.json'
import { VueEditor, Quill } from 'vue3-editor'
import mention from 'quill-mention'
import MentionBlot from '@/js/mention-blot'
import PlainClipboard from '@/js/quill-paste'
import DisableKeyboard from '@/js/quill-keyboard'
import eventBus from '@/js/eventbus'
import { Picker, EmojiIndex } from 'emoji-mart-vue-fast/src'
import '@nutrify/quill-emoji-mart-picker/emoji.quill.css'

import { Capacitor } from '@capacitor/core'
import { isIphone } from '@/js/helpers/mobile'
import { useUsersStore } from '@/stores/users'
import { useCompaniesStore } from '@/stores/companies'

let emojiIndex = new EmojiIndex(data)
Quill.register('modules/mention', mention)
Quill.register({
  'formats/mention': MentionBlot,
  'modules/clipboard': PlainClipboard,
  'modules/keyboard': DisableKeyboard
})

export default {
  components: {
    VueEditor,
    Picker
  },
  props: {
    postId: { type: Number, default: null },
    postComment: { type: Boolean, default: false },
    pickerPosition: { type: String, default: '' },
    withSendButton: { type: Boolean, default: false },
    editMode: { type: Boolean, default: false },
    placeholder: { type: String, default: 'Write here...' },
    content: { type: String, default: '' },
    maxLength: { type: Number, default: 3000 },
    maxHeight: { type: String, default: 'small' },
    emoji: { type: Boolean, default: false },
    locked: { type: Boolean, default: false },
    elementId: { type: String, default: 'quillEditor' },
    padding: { type: String, default: 'p-1' },
    toolbar: { type: Array, default: () => [] }
  },
  emits: [
    'blur',
    'submit',
    'clear',
    'input',
  ],
  setup () {
    const usersStore = useUsersStore()
    const companiesStore = useCompaniesStore()
    return { usersStore, companiesStore }
  },
  data () {
    return {
      maxLengthReached: false,
      loginRequired: false,
      emojiIndex: emojiIndex,
      contentLength: 0,
      quill: null,
      searchTimer: null,
      editorSettings: {
        modules: {
          toolbar: this.toolbar.length < 1 ? false : this.toolbar,
          mention: {
            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
            mentionDenotationChars: ['@', '$'],
            // fixMentionsToQuill: true,
            defaultMenuOrientation: 'top',
            // spaceAfterInsert: false,
            source: async (searchTerm, renderList, mentionChar) => {
              let values
              clearTimeout(this.searchTimer)
              this.searchTimer = await setTimeout(async () => {
                if (mentionChar === '@') {
                  await this.usersStore.fetchAll({ search: searchTerm })
                  let array = []
                  this.usersStore.filteredUserList.forEach((user) => {
                    array.push({ id: user.id, value: user.name, search: user.name, type: 'user' })
                  })
                  values = array
                } else {
                  await this.companiesStore.fetchAll({ search: searchTerm })
                  let array = []
                  this.companiesStore.filteredCompanyList.forEach((company) => {
                    if (company.name && company.slug_url) {
                      array.push({ id: company.id, value: company.name, search: company.slug_url, type: 'company' })
                    }
                  })
                  values = array
                }

                if (searchTerm.length === 0) {
                  renderList(values, searchTerm)
                } else {
                  const matches = []
                  if (values.length > 0) {
                    for (let i = 0; i < values.length; i++) {
                      if (
                        ~(
                          values[i].search.toLowerCase() +
                          values[i].value.toLowerCase()
                        ).indexOf(searchTerm.toLowerCase())
                      ) {
                        matches.push(values[i])
                      }
                    }
                  }

                  renderList(matches, searchTerm)
                }
              }, 600)
            }
          }
        }
      }
    }
  },

  computed: {
    iphoneClass () {
      if ((Capacitor.getPlatform() === 'ios') || isIphone()) {
        return 'input-iphone-size'
      }

      return ''
    }

  },
  mounted () {
	this.$router.currentRoute._value.name === 'posts' && this.setFocus()
    this.maxLengthReached = false
    const self = this
    eventBus.$on('on-user-tag', (payload) => {
      // console.log('payload', payload)
      // console.log('this.postId ', this.postId)
      if (payload.type !== 'post') {
        this.addText('@')
      } else if (this.postId === payload.postId && payload.type === 'post') {
        this.addText('@')
      }

      // eventBus.$emit('on-tag-end')
    })

    eventBus.$on('on-company-tag', (payload) => {
      if (payload.type !== 'post') {
        this.addText('$')
      } else if (this.postId === payload.postId && payload.type === 'post') {
        this.addText('$')
      }
      // eventBus.$emit('on-tag-end')
    })
    eventBus.$on('insert-custom-tag', (button) => {
      setTimeout(() => {
        self.setHtml(button)
      }, 0)
    })
  },
  methods: {
	setFocus() {
      this.$nextTick(() => {
        const editorRef = this.elementId ? this.$refs[this.elementId] : this.$refs.editor;
        if (editorRef && editorRef.$el) {
          const inputElement = editorRef.$el.querySelector('textarea, [contenteditable="true"]');
          if (inputElement) {
            inputElement.focus();
          }
        }
      })
    },
    checkInput (event) {
      // let key = this.postComment ? 'quillEditor' + this.postId : 'quillEditor'
      const quill = this.$refs[this.elementId].quill
      this.maxLengthReached = false
      this.loginRequired = false

      if (!this.usersStore.user || this.usersStore.user.profile === undefined || this.usersStore.user.profile_type === 'App\\Models\\GuestUser' || (this.usersStore.user.profile && !this.usersStore.user.profile.is_verified)) {
        quill.deleteText(0, quill.getLength())
        this.loginRequired = true
        return
      }

      if (quill.getLength() - 1 > this.maxLength) {
        console.log('stop editing')
        quill.deleteText(this.maxLength, quill.getLength())
        this.maxLengthReached = true
        return
      }

      this.contentLength = quill.getLength() - 1

      this.$emit('update:model-value', event)
    },
    addEmoji (emoji) {
      // let key = this.postComment ? 'quillEditor' + this.postId : 'quillEditor'
      var caretPosition = this.$refs[this.elementId]
      if (caretPosition) {
        caretPosition = caretPosition.quill
        caretPosition.insertText(caretPosition.getSelection(true).index, emoji.native)
      }
    },
    addText (newInput) {
      // let key = this.postComment ? 'quillEditor' + this.postId : 'quillEditor'
      console.log('this.elementId', this.elementId)
      var caretPosition = this.$refs[this.elementId]
      if (caretPosition) {
        caretPosition = caretPosition.quill
        caretPosition.insertText(caretPosition.getSelection(true).index, newInput)
      }
    },
    setHtml (newInput) {
      this.$nextTick(() => {
        // let key = this.postComment ? 'quillEditor' + this.postId : 'quillEditor'
        let caretPosition = this.$refs[this.elementId]
        if (caretPosition) {
          caretPosition = caretPosition.quill
          const delta = caretPosition.clipboard.convert(newInput)
          caretPosition.setContents(delta)
          caretPosition.setSelection(caretPosition.getLength(), 0)
          caretPosition.focus()
        }
      })
    }
  }
}
</script>

<style lang="scss">
.container {
  max-width: 100%;
}
.quill-editor-container .ql-mention-list-container {
  width: 200px;
  border-radius: 4px;
  @apply bg-white;
  @apply text-gray-900;
  @apply dark:bg-custom-purple-500;
  @apply dark:text-white;
  @apply dark:border-custom-purple-300;
  box-shadow: 0 2px 12px 0 rgba(30, 30, 30, 0.08);
  font-size: 14px;
  z-index: 9001;
  overflow: auto;
}

.tiny .ql-editor {
  @apply w-full;
  min-height: 20px !important;
  @apply rounded-xl;
  font-size: 16px;
  max-height: 75px !important;
}

.small .ql-editor {
  min-height: 57px !important;
  @apply rounded-xl;
  font-size: 16px;
  max-height: 100px !important;
  @apply pr-8;
}

.medium .ql-editor {
  min-height: 57px !important;
  @apply rounded-xl;
  font-size: 16px;
  max-height: 220px !important;
}

.large .ql-editor {
  min-height: 57px !important;
  @apply rounded-xl;
  font-size: 16px;
  max-height: 330px !important;
}

.tiny #texteditor {
  min-height: 20px !important;
  @apply rounded-2xl;
  @apply overflow-hidden;
  @apply break-words;
  max-height: 75px !important;
}

.small #texteditor {
  min-height: 57px !important;
  @apply rounded-2xl;
  @apply overflow-hidden;
  @apply break-words;
  max-height: 100px !important;
}

.medium #texteditor {
  min-height: 57px !important;
  @apply rounded-2xl;
  @apply overflow-hidden;
  @apply break-words;
  max-height: 220px !important;
}

.large #texteditor {
  min-height: 57px !important;
  @apply rounded-2xl;
  @apply overflow-hidden;
  @apply break-words;
  max-height: 330px !important;
}

/* .quill-editor-container #texteditor {
    min-height: 50px;
    padding-bottom: 5px;
    @apply rounded-2xl;
    @apply overflow-hidden;
    max-height: 100px;
} */

.quill-editor-container .ql-mention-loading {
  line-height: 44px;
  padding: 0 20px;
  vertical-align: middle;
  font-size: 16px;
}

.quill-editor-container .ql-mention-list {
  list-style: none;
  margin: 0;
  max-height: 10rem;
  scrollbar-width: thin;
  padding: 0;
  overflow-y: scroll;
}

.quill-editor-container .ql-mention-list-item {
  cursor: pointer;
  line-height: 36px;
  font-size: 14px;
  padding: 0 15px;
  vertical-align: middle;
}

.quill-editor-container .ql-mention-list-item.disabled {
  cursor: auto;
}

.quill-editor-container .ql-mention-list-item.selected {
  @apply bg-custom-purple-300;
  @apply text-white;
  text-decoration: none;
}

.quill-editor-container .mention {
  height: 24px;
  width: 65px;
  @apply rounded-sm;
  @apply bg-purple-100;
  @apply text-custom-light-header;
  @apply dark:text-custom-dark-header;
  @apply dark:bg-custom-purple-400;
  padding: 3px 0;
  margin-right: 2px;
  user-select: all;
}

/* .quill-editor-container .ql-editor {
    min-height: 57px !important;
    @apply rounded-xl;
    font-size: 14px;
    max-height: 100px;
} */
.dark .ql-editor.ql-blank::before {
  /* border: 1px solid #ccc; */
  @apply text-custom-purple-200;
}

.quill-editor-container .ql-container.ql-snow {
  /* border: 1px solid #ccc; */
  @apply rounded;
  @apply rounded-sm;
  @apply border;
  @apply border-gray-300;
}
.dark .quill-editor-container .ql-container.ql-snow {
  /* border: 1px solid #ccc; */
  @apply border-custom-purple-300;
}

.quill-editor-container .mention > span {
  margin: 0 3px;
}

.input-iphone-size {
  font-size: 16px !important;
}

.dark .ql-link {
  /* border: 1px solid #ccc; */
  color: #fff !important;
  stroke: #fff;
}
</style>
