<template>
  <div>
    <SquareImage
      v-if="thumbnail"
      :big="big"
      :src="thumbnail"
      class="flex text-gray-500 items-center justify-center text-4xl"
    >
      <Icon name="play-circle" />
    </SquareImage>
    <SquareImage
      v-else  
      src="/img/video.png"
      :big="big"
    />
  </div>
</template>

<script>
export default {
  name: 'ThumbnailVideo',
  props: {
    big: {
      type: Boolean,
      default: false
    },
    thumbnail: { type: String, default: null}
  }
}
</script>
