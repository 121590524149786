<template>
  <BaseButton
    no-shadow
    no-padding
    :bold="bold"
    class="py-2 px-4 flex items-center font-semibold"
    rounded="rounded-none"
    :class="
      [
        active ? 'bg-white dark:bg-custom-purple-400' : '',
        size
      ]"
    @click="$emit('click', $event)"
  >
    <!-- <Icon
      class="text-base"
      :name="active ? 'check' : 'minus'"
      :class="active ? 'check-margin' : ''"
    /> -->
    <div
      class="py-0 dark:text-white"
      :class="active ? 'text-black' : 'text-gray-400'"
    >
      <slot />
    </div>
  </BaseButton>
</template>

<script>

export default {
  name: 'FollowingTabButton',
  props: {
    noPadding: { type: Boolean, default: true },
    noShadow: { type: Boolean, default: true },
    active: { type: Boolean, default: false },
    size: { type: String, default: ' text-2xs' },
    bold: { type: Boolean, default: true }
  },
  emits: [
    'click'
  ]
}
</script>

<style lang="scss" scoped>
.check-margin {
  padding-bottom: 1px;
}
</style>
