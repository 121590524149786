<template>
  <div class="flex mt-2 w-full">
    <div class="flex mb-1 w-full">
      <span class="flex items-start">
        <AvatarSlot
          :stock-user="stockUser"
          :size="24"
          default-class="w-6 h-6"
        />
      </span>
      <div class="w-full">
        <span class="text-xs font-bold mt-1">{{ name }}</span>
        <slot />
        <template
          v-if="showComment"
        >
          <TextMessage
            v-if="comment"
            :message="comment"
            class="py-3"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AvatarNameComment',
  props: {
    stockUser: { type: Object, default: () => {} },
    name: { type: String, default: '' },
    comment: { type: String, default: '' },
    showComment: { type: Boolean, default: true }
  },
  emits: [
    'redirect'
  ],
  methods: {
    redirect (data) {
      this.$emit('redirect', data)
    }
  }
}
</script>
