<template>
  <textarea
    :maxlength="maxlength"
    :minlength="minlength"
    :value ="modelValue"
    :class="[classes,resize,'dark:text-white px-4 py-2 dark:shadow-none border border-gray-300 dark:bg-custom-purple-450 dark:border-custom-purple-300 dark:placeholder-custom-purple-200 focus:outline-none focus:ring-0']"
    :placeholder="placeholder"
    @update:model-value="$emit('update:model-value', $event)"
  />
</template>

<script>

export default {
  name: 'BaseTextarea',
  props: {
    modelValue: { type: String, default: '' },
    resize: { type: String, default: 'resize' },
    placeholder: { type: String, default: '' },
    classes: { type: String, default: 'rounded text-sm' },
    maxlength: { type: Number, default: 500 },
    minlength: { type: Number, default: 0 }
  },
  emits: [
    'update:model-value'
  ]
}
</script>
