<template>
  <div>
    <div class="flex justify-between items-center w-full">
      <div class="flex border-2 rounded   px-4 py-2 w-full">
        <input
          id="mailSameAsResidenceCheckbox"
          type="checkbox"
          :checked="mailSameAsResidence"
          class="w-5 mr-2"
          @change="$emit('mail-same-as-residence')"
        >
 
        <label for="mailSameAsResidenceCheckbox">
          {{ $t('Postal Address is same as Residence') }}
        </label>
      </div>
    </div>

   
    <div v-if="!mailSameAsResidence">
      <div class="grid my-4">
        <div>
          <label>
            {{ $t('Postal City') }}
          </label>
          <BaseInput
            :has-dark-mode="false"
            :maxlength="100"
            :minlength="1"
            class="  h-14 w-full  font-semibold"
      
            :required="true"
            :placeholder="$t('Ex. Soderkoping')"
            shadow="shadow-md"
            :model-value="cityOfMail"
            @update:model-value="$emit('city-of-mail', $event)"
          />
        </div>
      </div>

      <div class="grid lg:grid-cols-2 gap-4 my-4">
        <div>
          <label>
            {{ $t('Postal Street name and number') }}
          </label>
          <BaseInput
            :has-dark-mode="false"
            class="  h-14 w-full  font-semibold"
    
            
            :required="true"
            :maxlength="200"
            :minlength="1"
            :placeholder="$t('Ex. Magasinsgatan 6A')"
            shadow="shadow-md"
            :model-value="streetOfMail"
            @update:model-value="$emit('street-of-mail', $event)"
          />
        </div>
        <div>
          <label>
            {{ $t('Postal Code') }}
          </label>
          <BaseInput
            :has-dark-mode="false"
            class="  h-14 w-full  font-semibold"

            :required="true"
            :maxlength="20"
            :placeholder="$t('Ex. 654789')"
            shadow="shadow-md"
            :model-value="postalCodeOfMail"
            @update:model-value="$emit('postal-code-of-mail', $event)"
          />
        </div>
      </div>

      <div class="grid lg:grid-cols-2 gap-4 my-4">
        <div>
          <label>
            {{ $t('Postal Country') }}
          </label>
          <BaseSelect
            :appearance-none="false"
            :has-dark-mode="false"
            :required="true"
            :classes="countryOfMail ? baseSelectClass : baseSelectClass + ' text-gray-400 focus:text-primary !text-sm'"
            :upper-class="false"
            :model-value="countryOfMail"
            @change="$emit('country-of-mail', $event)"
          >
            <option
              value=""
              disabled
              selected
              hidden
            >
              {{ $t('Ex. Sweden') }}
            </option>

            <option
              v-for="country in countriesWithStates"
              :key="country.id"
              :selected="country.ibkr_code === countryOfMail"
              :value="country.ibkr_code"
            >
              {{ country.name }}
            </option>
          </BaseSelect>
        </div>
        <div>
          <label>
            {{ $t('Postal State') }}
          </label>
          <BaseSelect
            :appearance-none="false"
            :has-dark-mode="false"
            :required="true"
            :classes="stateOfMail ? baseSelectClass : baseSelectClass + ' text-gray-400 focus:text-primary !text-sm'"
            :upper-class="false"
            :model-value="stateOfMail"
            @change="$emit('state-of-mail', $event)"
          >
            <option
              value=""
              disabled
              selected
              hidden
            >
              {{ $t('Ex. Skane Lan') }}
            </option>

            <option
              v-for="state in mailStateList"
              :key="state.id"
              :value="state.ibkr_code"
              :selected="stateOfMail===state.ibkr_code"
            >
              {{ state.name }}
            </option>
          </BaseSelect>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: 'MailForm',
  props: {

    countriesWithStates: {
      type: Array,
      default: () => []
    },

    mailStateList: {
      type: Array,
      default: () => []
    },
    countryOfMail: {
      type: String,
      default: ''
    },
    streetOfMail: {
      type: String,
      default: ''
    },
    cityOfMail: {
      type: String,
      default: ''
    },
    stateOfMail: {
      type: String,
      default: ''
    },
    postalCodeOfMail:{
      type: String,
      default: ''
    },
    mailSameAsResidence:{
      type: Boolean,
      default: false
    },

    baseSelectClass: {
      type: String,
      default: 'h-14 px-4 py-4 w-full bg-transparent border-none shadow-md font-semibold rounded-sm'
    }
  },

  emits: [
    'street-of-mail',
    'city-of-mail',
    'country-of-mail',
    'state-of-mail',
    'postal-code-of-mail',
    'mail-same-as-residence'
  ]
}
</script>
  