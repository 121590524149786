<template>
  <div>
    <ModalContainer
      v-if="create"
      popup
      :title="title"
      @toggle="$emit('toggle')"
      @on-back-button="$emit('on-back-button')"
    >
      <AlertModal
        :body="body"
        :type="type"
        :show-confirm-button="showConfirmButton"
        :disable-agree-button="disableAgreeButton"
        @toggle="$emit('toggle')"
        @agree="$emit('agree', $event)"
      >
        <template
          v-if="$slots.message - input"
          #message
        >
          <slot name="message-input" />
        </template>
        <template #agree>
          <slot name="agree" />
        </template>
      </AlertModal>
    </ModalContainer>
  </div>
</template>

<script>

import AlertModal from './_components/AlertModal'
import ModalContainer from '@/modules/modal'

export default {
  name: 'AlertModalModule',
  components: {
    AlertModal,
    ModalContainer
  },
  props: {
    create: { type: Boolean, default: false },
    title: { type: String, default: '' },
    body: { type: String, default: '' },
    type: { type: String, default: '' },
    showConfirmButton: { type: Boolean, default: false },
    disableAgreeButton: { type: Boolean, default: false }
  },
  emits: [
    'toggle',
    'on-back-button',
    'agree'
  ],
  methods: {
    // deprecated in Vue 3 hence commmented
    // slotExist (type) {
    //   return this.$slots[type] !== undefined && this.$slots[type].length > 0
    // },
  }
}
</script>
