<template>
  <button
    class="w-28 py-3 rounded-full shadow-sm font-medium"
    :class="active ? 'bg-custom-purple-500 text-white' : 'bg-white text-custom-purple-500 hover:bg-custom-purple-50'"
    @click="$emit('click', $event)"
  >
    <slot />
  </button>
</template>
<script>
export default {
  name: "HorizontalNavButton",
  props: {
    active: { type: Boolean, default: false },
  },
  emits: [
    'click'
  ]
}
</script>