<template>
  <div v-if="notification.notifiable">
    <div>
      <Notification
        :pivot="notification.pivot"
        @remove="$emit('remove', { id: notification.id })"
      >
        <div @click="$parent.redirectPressRelease({ name: 'posts',threadId: notification?.notifiable?.room_post_id, notificationId: notification?.id})">
          <AvatarSlot
            :company="notification?.notifiable?.company_press_release?.companies[0]"
          >
            <div class="text-xs mr-6">
              <span class="font-bold">
                <RouterLink :to="{name: 'companyOverview', params: {id: company.slug_url}}">{{ company.name }}</RouterLink>
                PR:</span> {{ notification.notifiable.company_press_release.headline }}
              <div>{{ time }}</div>
            </div>
          </AvatarSlot>
        </div>
      </Notification>
    </div>
  </div>
</template>

<script>

import Notification from './Notification'

export default {
  name: 'NotificationPressRelease',
  components: {
    Notification
  },
  props: {
    notification: { type: Object, default: () => {} },
    time: { type: String, default: '' }
  },
emits: [
'remove'
],
  computed: {
    company () {
      return this.notification.notifiable.company_press_release.companies[0]
    }
  }
}
</script>
