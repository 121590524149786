<template>
  <input
    :type="type"
    :value="modelValue"
    :placeholder="placeholder"
    class="px-0 py-2 text-sm dark:bg-custom-purple-500 focus:outline-none"
    :class="[classes, noBorder ? 'border-none' : 'border border-gray-100 dark:border-custom-purple-400']"
    @keyup.enter="$emit('submit')"
    @input="$emit('update:model-value', $event)"
    @focus="$emit('focus', $event)"
  >
</template>

<script>

export default {
  name: 'CleanInput',
  props: {
    type: { type: String, default: 'text' },
    modelValue: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    classes: { type: String, default: '' },
    noBorder: { type: Boolean, default: false }
  },
  emits: [
    'submit',
    'update:model-value',
    'focus'
  ]
}
</script>
