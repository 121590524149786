<template>
  <div
    class="rounded-full flex items-center"
    :class="[colors, fontSize, paddings]"
  >
    <Icon
      :name="isPositive ? 'up-arrow-alt' : isNeutral ? 'left-arrow-alt' : 'down-arrow-alt'"
      class="mr-1"
      :class="secondary ? 'text-2xl' : 'text-lg'"
    />
    <p
      class=""
      :class="secondary ? 'text-lg font-extrabold' : 'text-xs font-medium'"
    >
      {{ modelValue!==null ? $number(modelValue) : '' }} %
    </p>
  </div>
</template>

<script>

export default {
  name: 'NumberBadge',
  props: {
    modelValue: {
      type: Number,
      default: 0
    },
    secondary: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isPositive () {
      return this.modelValue > 0
    },
    isNeutral () {
      return this.modelValue === 0
    },
    colors () {
      if (this.secondary) {
        return 'text-custom-purple-500 dark:text-custom-purple-100 dark:bg-custom-purple-400'
      }
      if (this.isPositive) {
        return 'bg-custom-green-100 text-custom-green-700'
      } else if (this.isNeutral) {
        return 'bg-gray-200 text-gray-800'
      }
      return 'bg-custom-yellow-300 text-custom-orange-400'
    },
    paddings () {
      if (this.secondary) {
        return ''
      }

      return 'px-2'
    },
    fontSize () {
      if (this.secondary) {
        return 'text-2xl font-extrabold'
      }

      return ''
    }
  }
}
</script>
