<template>
  <FadeInOut
    :duration="500"
    :delay="100"
    class=" hidden"
  >
    <div v-show="internalSnack.show">
      <div
        class="bg-gray-900 text-white rounded-sm text-xs flex flex-col justify-center cursor-pointer"
        @click="link(internalSnack.link)"
      >
        <Container class="relative">
          <div class="font-medium">
            {{ internalSnack.title }}
          </div>
          <div>{{ internalSnack.body }}</div>
          <button
            class="ml-8 absolute rounded-full bg-white top-2 w-6  h-6 right-0 flex items-center justify-center focus:outline-none"
            @click="close"
          >
            <Icon
              name="x"
              class="text-xl mb-px text-custom-orange-400"
            />
          </button>
        </Container>
      </div>
    </div>
  </FadeInOut>
</template>

<script>
import { FadeInOut } from 'vue3-transitions'
import { useSnackbarStore } from '@/stores/snackbar'


export default {
  name: 'SnackBar',
  components: {
    FadeInOut,
  },
  setup () {
    const snackbarStore = useSnackbarStore()
    
    return { snackbarStore }
  },
  computed: {
    internalSnack () {
      if (this.snackbarStore.originalSnack.show) {
        return this.snackbarStore.originalSnack
      }

      return { show: false }
    }
  },
  watch: {
    internalSnack(newSnack) {
      if(newSnack.show) {
        this.$toast.open({
        message: newSnack.text,
        type: newSnack.type,
        duration: 5000,
        dismissible: newSnack.autoclose,
        style: {
          fontFamily: "Urbanist",
          fontWeight: 200,
          backgroundColor: "#0C0C0DCC",
          color: "#f8fafc",
          position: "fixed",        
          bottom: "20px",            
          left: "50%",               
          transform: "translateX(-50%)",  
          zIndex: 9999,
          borderRadius: "12px",  
           
        }
      })
      }
    },
  },
  methods: {
    close (event) {
      event.stopPropagation()
      this.snackbarStore.close()
      
    },
    link (link) {
      this.$router.push(link)
      this.snackbarStore.close()
    }
  }
}
</script>
<style>
 .custom-icon {
    color: red;
  }
</style>
