<template>
  <CompleteOnboardingSteps
    v-show="show && onboarding"
    :padding="globalPadding"
    :company="company"
    @click="goToAxyer"
  />
</template>

<script>
import CompleteOnboardingSteps from './_components/CompleteOnboardingSteps'
import headerPaddingMixin from '@/mixins/headerPaddingMixin'
import { useAppsStore } from '../../stores/app'

export default {
  name: 'CompleteCompanyOnboarding',
  components: {
    CompleteOnboardingSteps
  },
  mixins: [headerPaddingMixin],
  props: {
    padding: {
      type: String,
      default: ''
    }
  },
  setup() {
    const appStore = useAppsStore()

    return { appStore }
  },
  computed: {
    globalPadding () {
      return this.platformPadding
    },
    onboarding () {
      if (sessionStorage.getItem('onboarding')) {
        const onboarding = JSON.parse(sessionStorage.getItem('onboarding'))
        if (onboarding.agreementSent === true && onboarding.otpVerified === true) {
          return true
        } else {
          return false
        }
      }
      return false
    },
    company () {
      if (sessionStorage.getItem('onboarding')) {
        const onboarding = JSON.parse(sessionStorage.getItem('onboarding'))
        if (onboarding) {
          return onboarding.company
        } else {
          return null
        }
      }
      return null
    },
    show () {
        if (this.$route.name !== 'onboarding-company' && this.$route.name !== 'onboarding') {
          return true
        } else return false
    },
  },
  methods: {
    goToAxyer () {
      this.$router.push({name: 'onboarding-company'})
    }
  }
}
</script>
