<template>
  <FrontFooter 
    :footer-data="footer"
  />
</template>

<script>
import FrontFooter from './_components/FrontFooter'
import { useUsersStore } from '../../stores/users'
import { useAppsStore } from '../../stores/app'
import { useBlogsStore } from '../../stores/blogs'


export default {
  name: 'FrontFooterModule',
  components: {
    FrontFooter
  },
  setup () {
    const usersStore = useUsersStore()
    const appsStore = useAppsStore()
    const blogsStore = useBlogsStore()
    return { usersStore, appsStore, blogsStore }
  },
  computed: {
    user () {
      return this.usersStore.user
    },
    blogs () {
      return this.blogsStore.blogsList
    },
    footer () {
      return this.blogsStore.footer
    },
    useFooter () {
      return this.appsStore.useFooter
    },
    isLoggedIn () {
      return this.user && this.user.id && this.user.profile_type !== 'App\\Models\\GuestUser'
    },
  },
  async created () {
    await this.blogsStore.fetchBlogs()
    await this.blogsStore.fetchFooter()
  },
}
</script>

<style lang="scss">
.footer_eucaps-logo {
    height: 60px !important;
}
.footer {
    padding-bottom: 20px !important;
}

@media only screen and (min-width: 600px) {
  .map-footer {
    margin-top: 850px;
  }
}
@media only screen and (min-width: 900px) {
  .map-footer {
    margin-top: 800px;
  }
}
</style>