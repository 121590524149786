<template>
  <div class="flex lg:flex-row flex-col w-full lg:w-3/4 h-full">
    <OnboardingForm
     
      :slot-name="slotName"
      :current-step="currentStep"
      :steps="userType === 'normal' ? userSteps : companySteps"
      @clicks="clickStep"
    >
      <template #contentSlot>
        <div class="text-custom-purple-500">
          <UserText
            v-if="userType === 'normal'"
            :step="currentStep"
          />
          <CompanyText
            v-else-if="userType === 'companyUser'"
            :step="currentStep"
            :done="finishedOnboarding"
          />
        </div>
      </template>
      <template #componentSlot>
        <UserVerificationForm
          v-if="slotName === 'normal-2'"
          
          :firstname="firstname"
          :lastname="lastname"
          :email="user.email"
          :url="iframeSrc"
          :get-freja-status="getFrejaStatus"
          :is-verified="user && user.profile && user.profile.is_verified"
          :verification-method="verificationMethod"
          :verification-failure="verificationFailure"
          :pending-verification="pendingVerification"
          :loading="loading"
          :trulioo-status="truliooStatus"
          :verify-data="verifyData"
          :freja-loading="frejaLoading"
          @skip="skipStep"
          @continue="progressStep"
          @verify="loadIframe"
          @toggle="cancelVerification"
          @apply="resetVerification"
          @firstname="firstname = $event.target.value"
          @lastname="lastname = $event.target.value"
        />

        <UserKYCForm
          v-if="slotName === 'normal-3'"
          :loading-store-user-details="loadingStoreUserDetails"
          @continue="storeUserDetails"
        />
        <UserKycAgreementForm
          v-if="slotName === 'normal-4'"
          :signature="signature"
          :fullname="usersStore.user.ibkr_name"
          :current-date-time="currentDateTime()"
          :lock-submit="lockSubmit"
          :agree-check1="agreeCheck1"
          :agree-check2="agreeCheck2"
          :agree-check3="agreeCheck3"
          :agree-check4="agreeCheck4"
          :form-storage-url="formStorageUrl"
          @back="backStep"
          @continue="submitForm"
          @signature="signature = $event.target.value"
          @agree-check1="agreeCheck1 = !agreeCheck1"
          @agree-check2="agreeCheck2 = !agreeCheck2"
          @agree-check3="agreeCheck3 = !agreeCheck3"
          @agree-check4="agreeCheck4 = !agreeCheck4"
        />
        <CompanyVerificationForm
          v-if="slotName === 'companyUser-2'"
          :email="user.email"
          :url="iframeSrc"
          :get-freja-status="getFrejaStatus"
          :is-verified="user && user.profile && user.profile.is_verified"
          :verification-method="verificationMethod"
          :verification-failure="verificationFailure"
          :pending-verification="pendingVerification"
          :loading="loading"
          :verify-data="verifyData"
          :freja-loading="frejaLoading"
          @continue="progressStep"
          @verify="loadIframe"
          @toggle="cancelVerification"
          @apply="resetVerification"
        />
        <CompanySelectForm
          v-if="slotName === 'companyUser-3'"
          :verified-companies="user && user.verified_companies ? user.verified_companies : []"
          :user="user"
          :pre-list="selectedCompanies"
          @continue="progressStep"
          @select-pre-list="preSelectCompanies"
        />
        <ContactSales
          v-if="contactSales && slotName === 'companyUser-4'"
          :user="user"
          :phone="phone"
          :loading-contact="loadingContact"
          :sales-contacted="salesContacted"
          :company="selectedCompanies[selectedCompanyIndexForAgreement]"
          @phone="phone = $event"
          @contact-me="contactMe"
          @go-to-home="skipStep"
          @go-back="contactSales = false"
        />
        <CompanyAgreementForm
          v-else-if="slotName === 'companyUser-4' && selectedCompanies && selectedCompanies[selectedCompanyIndexForAgreement]"
          :selected-companies="selectedCompanies"
          :selected-company-index-for-agreement="selectedCompanyIndexForAgreement"
          :email-match="emailMatch"
          :has-company-users="selectedCompanies && selectedCompanies[selectedCompanyIndexForAgreement] ? selectedCompanies[selectedCompanyIndexForAgreement].has_company_users : false"
          :agreement="onboarding.agreementChecked"
          :custom-address="customAddress"
          :loading="loading"
          :loading-requested="loadingRequested"
          :join-requested="joinRequested"
          :user="user"
          :secondary-email-sent="secondaryEmailSent"
          :add-secondary-email="addSecondaryEmail"
          :secondary-email="secondaryEmail"
          :valid-secondary-email="validSecondaryEmail"
          :edit-address="editAddress"
          :contact-sales="contactSales"
          :select-company-open="selectCompanyOpen"
          :agreement-sent="onboarding.agreementSent"
          :company="selectedCompanies && selectedCompanies[selectedCompanyIndexForAgreement]"
          :otp-verified="onboarding.otpVerified"
          :loading-new="loadingNew"
          @email-verified="emailVerified"
          @agreement="checkAgreement"
          @assign-user-role="assignUserRole"
          @continue="progressStep(selectedCompanies)"
          @send-pdf="sendPDF"
          @toggle-selection="selectCompanyOpen = !selectCompanyOpen"
          @select-company-index="selectedCompanyIndexForAgreement = $event"
          @edit-address="editAddress = !editAddress"
          @custom-address="onCustomAddress"
          @resend-otp="sendPDF"
          @add-secondary-email="addSecondaryEmail = !addSecondaryEmail"
          @secondary-email="secondaryEmail = $event"
          @contact-sales="onContactSales"
          @send-secondary-email="secondaryEmailSent = true"
          @go-to-home="skipStep"
          @request-join="onRequestJoin"
        />
        <CompanyPaymentForm
          v-if="slotName === 'companyUser-5'"
          :company="selectedCompanies && selectedCompanies[selectedCompanyIndexForAgreement]"
          :payment-methods="paymentMethods"
          :loading="loadingInvoice"
          @continue="progressStep"
        />
        <CompanyCongratsForm
          v-if="slotName === 'companyUser-6'"
          :company="selectedCompanies && selectedCompanies[selectedCompanyIndexForAgreement]"
          @continue="redirect"
        />
      </template>
    </OnboardingForm>

    <ModalContainer
      v-if="showProgessModal"
      full-size
      :use-footer="false"
      full-width
      :hide-close="true"
      @toggle="close"
      @on-back-button="close"
    >
      <ProgressModal
        :errors="onboardingsStore.submitFormErrors"
        :status="onboardingsStore.submitFormStatus"
        :loading="onboardingsStore.loadingKYC"
        @back-to-form="backToForm"
        @continue="progressStep"
        @close="showProgessModal = false"
        @retry="submitForm"
      />
    </ModalContainer>
  </div>
</template>

<script>
import OnboardingForm from './_components/OnboardingForm'
//import VerificationText from './_components/VerificationText'
import UserVerificationForm from './_components/UserVerificationForm'
import UserText from './_components/UserText'
import CompanyCongratsForm from './_components/CompanyCongratsForm'
import CompanyText from './_components/CompanyText'
import CompanySelectForm from './_components/CompanySelectForm'
import CompanyVerificationForm from './_components/CompanyVerificationForm'
import CompanyAgreementForm from './_components/CompanyAgreementForm'
import ContactSales from './_components/ContactSales'
import CompanyPaymentForm from './_components/CompanyPaymentForm'
import UserKycAgreementForm from './_components/UserKycAgreementForm'
import ProgressModal from './_components/ProgressModal'
import i18n from '@/js/language'
import { format } from 'date-fns'
import { v4 as uuidv4 } from 'uuid'
import { useOnboardingsStore } from '../../stores/onboarding'
import { useCompaniesStore } from '../../stores/companies'
import { useUsersStore } from '../../stores/users'
import { useVerifysStore } from '../../stores/verify'
import { useAppsStore } from '../../stores/app'
import { usePaymentsStore } from '../../stores/payments'
import { useSnackbarStore } from '../../stores/snackbar'
import UserKYCForm from '@/modules/userKYCForm'
import ModalContainer from '@/modules/modal'
import { useTrulioosStore } from '../../stores/trulioo'
export default {
  name: 'OnboardingModule',
  components: {
    OnboardingForm,
    //VerificationText,
    UserVerificationForm,
    ProgressModal,
    ModalContainer,
    UserKYCForm,
    UserText,
    CompanyText,
    CompanySelectForm,
    CompanyVerificationForm,
    CompanyAgreementForm,
    ContactSales,
    CompanyPaymentForm,
    UserKycAgreementForm,
    CompanyCongratsForm
  },
  props: {
    userType: {
      type: String,
      default: 'normal'
    }
  },
  setup () {
    const onboardingsStore = useOnboardingsStore()
    const companiesStore = useCompaniesStore()
    const usersStore = useUsersStore()
    const verifyStore = useVerifysStore()
    const appsStore = useAppsStore()
    const paymentsStore = usePaymentsStore()
    const snackBarStore = useSnackbarStore()
    const truliooStore = useTrulioosStore()

    return { onboardingsStore, companiesStore, usersStore, verifyStore, appsStore, paymentsStore, snackBarStore, truliooStore }
  },
  data () {
    return {
      authRef: null,
      getFrejaStatus: false,
      frejaLink: null,
      defaultStep: 2,
      selectedCompanies: [],
      customAddress: {},
      selectedCompanyIndexForAgreement: 0,
      selectCompanyOpen: false,
      editAddress: false,
      cardName: '',
      cardNumber: '',
      expireDate: '',
      cvv: '',
      iframeSrc: '',
      verified: false,
      verificationMethod: '',
      phone: '',
      verificationFailure: false,
      pendingVerification: false,
      addSecondaryEmail: false,
      secondaryEmailSent: false,
      secondaryEmail: '',
      agreementSent: false,
      contactSales: false,
      requestJoin: false,
      onboarding: {},
      finishedOnboarding: false,
      userSteps: [
        {
          id: 1,
          name: 'Account'
        },
        {
          id: 2,
          name: 'Verification'
        },
        {
          id: 3,
          name: 'KYC'
        },
        {
          id: 4,
          name: 'Terms'
        }
      ],
      companySteps: [
        {
          id: 1,
          name: 'Account'
        },
        {
          id: 2,
          name: 'Verification'
        },
        {
          id: 3,
          name: 'Company'
        },
        {
          id: 4,
          name: 'Agreement'
        },
        {
          id: 5,
          name: 'Payment'
        }
      ],
      showProgessModal: false,
      signature: '',
      agreeCheck1: false,
      agreeCheck2: false,
      agreeCheck3: false,
      agreeCheck4: false,
      firstname: '',
      lastname: ''
    }
  },
  computed: {
    truliooStatus () {
      return this.truliooStore.truliooStatus === 'REJECTED'
    },
    isVerified () {
      if (!this.usersStore.user && !this.usersStore.user.profile) return false
      return this.usersStore.user?.profile?.is_verified
    },
    loading () {
      return this.onboardingsStore.loading
    },
    loadingContact () {
      return this.onboardingsStore.loadingContact
    },
    loadingRequested () {
      return this.onboardingsStore.loadingRequested
    },
    salesContacted () {
      return this.onboardingsStore.salesContacted
    },
    joinRequested () {
      return this.onboardingsStore.joinRequested
    },
    loadingNew () {
      return this.companiesStore.loadingNew
    },
    loadingInvoice () {
      return this.paymentsStore.loading
    },
    user () {
      return this.usersStore.user
    },
    otpVerified () {
      return this.onboardingsStore.otpVerified
    },
    status () {
      return this.verifyStore.status
    },
    frejaLoading () {
      return this.verifyStore.loading
    },
    verifyData () {
      return this.verifyStore.data
    },
    frejaUrl () {
      return this.verifyStore.redirectUrl
    },
    showVerificationModal () {
      return this.usersStore.showVerificationModal
    },
    paymentMethods () {
      return this.paymentsStore.onboardingPaymentMethods
    },
    emailMatch () {
      const userDomain = this.user.email.split('@')[1]

      if (!this.selectedCompanies) {
        return false
      }
      if (!this.selectedCompanies[this.selectedCompanyIndexForAgreement]) {
        return false
      }

      const website = this.selectedCompanies[this.selectedCompanyIndexForAgreement].company_detail.website

      if (!website) {
        return false
      }

      if (this.hostName && userDomain && (this.hostName.toLowerCase() === userDomain.toLowerCase())) {
        return true
      }

      let match = false
      this.user.secondary_emails.map(secondaryEmail => {
        if (!secondaryEmail.verified) {
          return
        }
        const userDomain = secondaryEmail.email.split('@')[1]

        if (this.hostName && userDomain && (this.hostName.toLowerCase() === userDomain.toLowerCase())) {
          match = true
        }
      })

      return match
    },
    hostName () {
      if (!this.selectedCompanies) {
        return false
      }
      if (!this.selectedCompanies[this.selectedCompanyIndexForAgreement]) {
        return false
      }

      let website = this.selectedCompanies[this.selectedCompanyIndexForAgreement].company_detail.website
      if (website.indexOf('http') === -1) {
        website = 'https://' + website
      }

      var a = document.createElement('a')
      a.setAttribute('href', website)
      const host = a.hostname
      a.remove()
      return host.replace('www.', '')
    },
    validSecondaryEmail () {
      if (!this.selectedCompanies) {
        return false
      }
      if (!this.selectedCompanies[this.selectedCompanyIndexForAgreement]) {
        return false
      }

      const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})/gm

      const userDomain = this.secondaryEmail.split('@')[1]

      if (this.secondaryEmail.match(regex) && this.hostName && userDomain && (this.hostName.toLowerCase() === userDomain.toLowerCase())) {
        return true
      }

      return false
    },
    slotName () {
      return `${this.userType}-${this.currentStep}`
    },
    currentStep () {
      if (!this.onboarding || !this.onboarding.step) {
        return this.defaultStep
      }

      return this.onboarding.step
    },

    lockSubmit () {
      if (this.agreeCheck1 === false || this.agreeCheck2 === false || this.agreeCheck3 === false || this.agreeCheck4 === false) {
        return true
      }
      else if (this.signature !== this.usersStore.user.ibkr_name) {
        return true
      } else {
        return false
      }
    },
    formStorageUrl () {
      return process.env.VUE_APP_S3_BUCKET + '/forms/'
    }
  },
  mounted () {
    this.customAddress = {}

    this.getProfileInfo()
    if (this.showVerificationModal) this.usersStore.setShowVerificationModal(false)
    // listening to response from iframe
    window.addEventListener('message', (e) => {
      if (e.data) {
        try {
          const data = JSON.parse(e.data)
          if (data.href && data.action === 'gii::location_href') {
            // try to open the universal link
            location.href = data.href
          }
        } catch (err) {
          // nothing
        }
      }
    })
    // There's no way for the user to close the iframe, so this is not initiated.
    // For abandoned BankID sessions (caused by end-user action/inaction) are not automatically cancelled.
    // let cancelURL = ''
    // window.addEventListener('message', (e) => {
    //   if (e.data) {
    //     try {
    //       const data = JSON.parse(e.data)
    //       if (data.href && data.action === 'gii::cancel_href') {
    //         // store the href so it can be triggered when needed
    //         cancelURL = data.href
    //       }
    //     } catch (err) {
    //       // nothing
    //     }
    //   }
    // }, false)
    //
    // navigator.sendBeacon(cancelURL);

    const self = this
    window.onmessage = function (e) {
      if (e.data.status === 'successful') {
        self.getProfileInfo()
      } else if (e.data.status === 'failed') {
        self.verificationFailure = true
      }
    }


    if (localStorage.kycPayload) {
      this.onboardingsStore.kycPayload = JSON.parse(localStorage.kycPayload)
    }

    if(this.usersStore.user && this.usersStore.user.first_name){
      this.firstname=this.usersStore.user.first_name
    }
    if(this.usersStore.user && this.usersStore.user.last_name){
      this.lastname=this.usersStore.user.last_name
    }
  },
  beforeUnmount () {
    window.removeEventListener('message', this.receiveMessage)
    if (this.currentStep === 2 && !this.isVerified && this.authRef !== null) {
      this.verifyStore.cancelFrejaAuthentication(this.authRef)
    }
    if (this.verificationMethod === 'trulioo') {
      var element = document.getElementById("helloflow-loaded");
      element.parentNode.removeChild(element);
    }
  },
  methods: {
    emailVerified () {
      this.onboarding.otpVerified = true
      this.updateOnboarding(this.onboarding)
    },
    redirect () {
      sessionStorage.removeItem("onboarding")
      this.$router.push('/')
    },
    async assignUserRole () {
      const response = await this.companiesStore.assignRole(this.selectedCompanies[this.selectedCompanyIndexForAgreement].slug_url)
      if (response) {
        await this.usersStore.fetch()
        this.progressStep(this.selectedCompanies)
      }
    },
    async verifyFreja () {
      if (this.user && this.user.profile && !this.user.profile.is_verified) {
        await this.usersStore.fetch()
        setTimeout(() => {
          this.verifyFreja()
        }, 2000)
      }
    },
    preSelectCompanies (companyList) {
      console.log('preselect Companies', companyList)
      this.selectedCompanies = [...companyList]
    },
    async getProfileInfo () {
      this.onboarding = JSON.parse(sessionStorage.getItem('onboarding'))

      if (this.onboarding && this.onboarding.step >= 2) {

        if (this.user && this.user.profile.is_verified === false) {

          this.onboarding.step = 2
          this.pendingVerification = true
          this.updateOnboarding(this.onboarding)
        }

        if (this.user && this.user.trulioo_status === 'pending') {
          this.onboarding.step = 2
          this.pendingVerification = true
          this.verificationMethod = 'trulioo'
          this.updateOnboarding(this.onboarding)
        }


        if (this.status === 'freja-verify') {
          this.solution = 'freja'

          this.verifyFreja()
          // this.stepThreeAndAHalf()
        }
        this.updateOnboarding(this.onboarding)
      }

      if (!this.onboarding || !this.onboarding.step || !this.onboarding.status) {
        this.updateOnboarding({
          status: 'ongoing',
          step: 2,
          agreementChecked: false
        })
      }

      await this.usersStore.fetch()

      if (this.user?.profile && this.user.profile.is_verified && this.userType === 'companyUser') {
        if (this.onboarding.step < 3) {
          console.log('THIS SHALL HAPPEN')
          // this.onboarding.step = 3
          this.updateOnboarding({
            status: 'ongoing',
            step: 3,
            agreementChecked: false
          })
        }
      }

      let condition = this.user && this.user.profile && this.user.profile_type === 'App\\Models\\GuestUser'

      if (condition || condition === undefined) {
        this.$router.push({ name: 'signup' })
      }

      // if you want to fetch user onboarding data from localstorage

      if (this.onboarding.status === 'ongoing') {
        if (this.userType === 'companyUser') {
          this.selectedCompanies = this.onboarding.company

          if (this.onboarding.step === 5) {
            if (this.paymentMethods && this.paymentMethods.length == 0) {
              await this.paymentsStore.fetchPaymentMethods(this.selectedCompanies[this.selectedCompanyIndexForAgreement].country)
            }
          }


        }
      }
    },
    updateOnboarding (data) {
      this.onboarding = { ...data }
      console.log('updateOnboarding', data)
      sessionStorage.onboarding = JSON.stringify(this.onboarding)
    },
    getIframe (id, hash, nonce) {
      return (
        `${process.env.VUE_APP_DEVCODE_DOMAIN}/api/oauth/auth` +
        `?client_id=${process.env.VUE_APP_DEVCODE_CLIENT_ID}` + // your-client is the client ID provided by DID
        '&redirect_uri=' +
        encodeURIComponent(`${process.env.VUE_APP_URL}/verification?nonce=${nonce}`) +
        '&response_type=code' +
        '&scope=openid' +
        `&sign_id=${id}` + // ID retrieved from post above
        `&state=${hash}` + // generate state on your side, using e.g. a HMAC
        '&identity_provider=bankid-se' +
        '&display=popup'
      )
    },
    initiateFrejaeID () {
      this.solution = 'freja'
      this.verifyStore.setSession('freja')
      // if (this.isNative) {
      const url = process.env.VUE_APP_FREJA_LINK + uuidv4() + '&redirect_uri=' + process.env.VUE_APP_SCHEME + 'freja'

      if (this.appsStore.isNative) {
        window.open(url, '_blank')
      } else {
        window.location.href = url
      }
    },
    async loadIframe (verifyingMethod) {
      console.log('verifyingMethod', verifyingMethod)
      const namePattern = /^[A-Za-z]+(?:\s[A-Za-z]+)*$/; // Updated regex for validating first name and last name

      if (!this.firstname && !namePattern.test(this.firstname) && this.firstname.length < 3) {
        this.snackBarStore.snack({ text: i18n.global.t('Please add valid First name'), type: 'error', show: true, autoclose: true })
        return
      } else if (!this.lastname && !namePattern.test(this.lastname) && this.lastname.length < 3) {
        this.snackBarStore.snack({ text: i18n.global.t('Please add valid Last name'), type: 'error', show: true, autoclose: true })
        return
      }

      this.onboardingsStore.kycPayload = {
        ...this.onboardingsStore.kycPayload,
        'firstName': this.firstname
      }

      this.onboardingsStore.kycPayload = {
        ...this.onboardingsStore.kycPayload,
        'lastName': this.lastname
      }

      await this.onboardingsStore.storeUserDetails()


      if (this.verificationFailure) this.verificationFailure = false
      this.verificationMethod = verifyingMethod

      if (verifyingMethod === 'devcode') {
        const data = await this.onboardingsStore.iframe()
        const iframe = this.getIframe(data.id, data.hash, data.nonce)

        this.iframeSrc = iframe
      } else if (verifyingMethod === 'freja') {
        // this.initiateFrejaeID()
        const response = await this.verifyStore.fetchFrejaQRUrl()
        if (response) {
          const iframe = response.frejaQRCodeUrl
          this.authRef = response.authRef
          this.iframeSrc = iframe
          this.getFrejaStatus = true
          this.fetchFrejaAuthenticationStatus()
        }
      }
    },
    async fetchFrejaAuthenticationStatus () {
      if (this.getFrejaStatus && ['onboarding', 'onboarding-company'].includes(this.$route.name)) {
        const data = await this.verifyStore.fetchFrejaAuthenticationStatus(this.authRef)
        if (data === 'APPROVED') {
          this.getFrejaStatus = false
          this.authRef = null
          this.usersStore.fetch()
          this.snackBarStore.snack({
            type: 'success',
            text: 'Profile verified successfully!',
            show: true,
            autoclose: true
          })
        } else if (data === 'CANCELED' || data === 'EXPIRED') {
          this.getFrejaStatus = false
          this.verificationFailure = true

          this.snackBarStore.snack({
            type: 'error',
            text: 'Session Expired or canceled please verify again!',
            show: true,
            autoclose: true
          })
        } else {
          setTimeout(this.fetchFrejaAuthenticationStatus, 5000)
        }
      }
    },
    onContactSales () {
      this.contactSales = !this.contactSales
      this.resetSales()
    },
    resetJoin () {
      this.onboardingsStore.resetJoinRequest()
    },
    onRequestJoin () {
      const confirm = window.confirm("We will send your details to the user's connected to the company. Please confirm.")
      if (confirm) {
        this.onboardingsStore.requestJoin({ company_id: this.selectedCompanies[this.selectedCompanyIndexForAgreement].id })
      }
      // this.requestJoin = !this.requestJoin
      // this.resetJoin()
    },
    resetSales () {
      this.onboardingsStore.resetPhoneForm()
    },
    async sendPDF () {
      let payload = {
        company: this.selectedCompanies[this.selectedCompanyIndexForAgreement]
      }

      if (this.customAddress[this.selectedCompanyIndexForAgreement]) {
        payload.address = this.customAddress[this.selectedCompanyIndexForAgreement]
      }

      const data = await this.onboardingsStore.sendPDF(payload, this.user.profile.id)
      // console.log(data)
      if (data === 'success') {
        this.onboarding.agreementSent = true
        this.updateOnboarding(this.onboarding)
      }
    },
    resetVerification () {

      this.verificationFailure = false
      this.verified = false
      if (this.verificationMethod === 'trulioo') {
        var element = document.getElementById("helloflow-loaded");
        element.parentNode.removeChild(element);
      }
      this.verificationMethod = ''
      this.truliooStore.truliooStatus = null
    },
    cancelVerification () {
      this.verificationFailure = false
      this.verified = false
      this.verificationMethod = ''
      this.truliooStore.truliooStatus = null
      this.$router.push({ name: 'myFeed' })
    },
    hasntSelectedCompanies () {
      return (!this.selectedCompanies || this.selectedCompanies.length === 0)
    },
    clickStep (payload) {
      console.log('payload', payload)

      this.getProfileInfo()
      this.resetSales()
      this.resetJoin()

      if (payload.step.id !== 1) {
        // if(this.onboarding.step === 3  && this.userType === 'normal'){
        //   return
        // }

        if (this.userType === 'normal' && this.onboarding.step === 3 && payload.step.id === 4) {
          return
        }


        this.onboarding.step = payload.step.id

        if (this.onboarding.step > 2 && !this.isVerified) {
          this.onboarding.step = 2
        }


        else if (this.onboarding.step > 3 && this.userType === 'companyUser') {
          // console.log('this.selectedCompanies', this.selectedCompanies)
          if (this.hasntSelectedCompanies()) {
            this.onboarding.step = 3
          }
        } else if (this.onboarding.step > 4 && this.userType === 'companyUser') {
          if (!this.user || !this.user.role_list.includes(this.selectedCompanies[this.selectedCompanyIndexForAgreement].slug_url)) {
            this.onboarding.step = 4
          }

          if (this.hasntSelectedCompanies()) {
            this.onboarding.step = 3
          }
        }

        this.updateOnboarding(this.onboarding)
      }
    },
    checkAgreement () {
      console.log('checkingAgreement')
      this.onboarding.agreementChecked = !this.onboarding.agreementChecked
      this.updateOnboarding(this.onboarding)
    },
    async contactMe () {
      await this.onboardingsStore.contactSales({ phone: this.phone, company_id: this.selectedCompanies[this.selectedCompanyIndexForAgreement].id })
    },
    async progressStep (companyList, selectedPaymentMethod) {
      console.log('processStep 1')
      this.getProfileInfo()
      console.log('processStep 2')
      this.resetSales()
      console.log('processStep 3')
      let onboarding = {}
      console.log('processStep 4')
      console.log('this.userType', this.userType)
      console.log('this.onboarding.step', this.onboarding.step)
      if ((this.userType === 'normal' && this.onboarding.step < 2) || (this.userType === 'companyUser' && this.onboarding.step < 6)) {
        console.log('processStep 5')
        if (this.onboarding.step === 2 && this.userType === 'companyUser') {
          console.log('processStep 6')
        } else if (this.onboarding.step === 3 && this.userType === 'companyUser') {
          console.log('processStep 7')
          companyList = companyList.filter(company => {
            console.log('processStep 8')
            if (this.user.role_list.includes(company.slug_url)) {
              alert('You are already a company user of ' + company.name)
              return false
            }
            return true
          })
          console.log('processStep 9')
          console.log('companyList : ', companyList)
          if (companyList.length === 0) {
            console.log('processStep 10')
            onboarding.step = 3
            this.updateOnboarding(onboarding)
            return
          }
          console.log('processStep 11')
          this.selectedCompanies = companyList
          onboarding.company = companyList
          this.paymentsStore.fetchPaymentMethods(this.selectedCompanies[this.selectedCompanyIndexForAgreement].country)
          // if (companyList.length === 1 && !companyList[0].agreement_required) {
          //   console.log('processStep xyz')
          //   onboarding.step = 4
          //   onboarding.agreementSent = true
          //   onboarding.otpVerified = true
          //   onboarding.company = companyList
          //   this.updateOnboarding(onboarding)
          // }
        } else if (this.onboarding.step === 4) {
          console.log('processStep 12')
          onboarding.agreementSent = true
          onboarding.otpVerified = true
          onboarding.company = this.onboarding.company
          if (!this.paymentMethods) {
            this.paymentsStore.fetchPaymentMethods(this.selectedCompanies[this.selectedCompanyIndexForAgreement].country)
          }
        } else if (this.onboarding.step === 5) {

          console.log('processStep 13')

          console.log('payment method : ', selectedPaymentMethod)

          if (selectedPaymentMethod === 'stripe') {
            this.usersStore.createCompanyCheckoutSession(this.selectedCompanies[this.selectedCompanyIndexForAgreement].id)
            this.usersStore.setSuccessUrl({ type: 'marketplace' })
          } else if (selectedPaymentMethod === 'invoice') {
            const response = await this.paymentsStore.createPaymentInvoice({
              companyId: this.selectedCompanies[this.selectedCompanyIndexForAgreement].id,
              body: {
                payment_type: "invoice"
              }
            })


            if (response === 'success') this.finishedOnboarding = true
          }
        }

        if (this.onboarding.step < 5) this.onboarding.step = this.onboarding.step + 1
        else {
          if (this.finishedOnboarding) this.onboarding.step = this.onboarding.step + 1
        }
        onboarding.status = 'ongoing'
        onboarding.step = this.onboarding.step

        this.updateOnboarding(onboarding)
        // await this.usersStore.update( payload)
      }
      else if (this.userType === 'normal' && (this.onboarding.step === 2 || this.onboarding.step === 3)) {
        console.log('in this if line 895')
        this.onboarding.step += 1
        onboarding = this.onboarding
        this.updateOnboarding(onboarding)

      }
      else {
        this.updateOnboarding({})
        this.$router.push({ name: 'myFeed' })
      }
      console.log('this.onboarding.step', this.onboarding.step)
    },
    skipStep () {
      this.$router.push({ name: 'home' })
    },
    backStep () {
      if (this.onboarding.step > 1) {
        this.onboarding.step = this.onboarding.step - 1
        this.updateOnboarding(this.onboarding)
      }

    },
    backToForm () {
      this.showProgessModal = false
      if (this.onboarding.step > 1) {
        this.onboarding.step = 3
        this.updateOnboarding(this.onboarding)
      }
    },
    onCustomAddress (payload) {
      this.customAddress[this.selectedCompanyIndexForAgreement] = payload.address
      this.customAddress[this.selectedCompanyIndexForAgreement][payload.index] = payload.value

      this.customAddress = { ...this.customAddress }
    },


    currentDateTime () {
      let date = new Date();
      let formatedDate = format(date, 'dd MMMM yyyy K:mm');

      let timezone = "";
      try {
        // Chrome, Firefox
        timezone = /.*\s(.+)/.exec((date).toLocaleDateString(navigator.language, { timeZoneName: 'short' }))[1];
      } catch (e) {
        // IE, some loss in accuracy due to guessing at the abbreviation
        // Note: This regex adds a grouping around the open paren as a
        //       workaround for an IE regex parser bug
        timezone = (date).toTimeString().match(new RegExp("[A-Z](?!.*[\\(])", "g")).join('');
      }

      return formatedDate + ' ' + timezone
    },

    async storeUserDetails () {
      try {
        await this.onboardingsStore.storeUserDetails()
        this.progressStep()
      } catch (e) {
        console.log(e)
      }
      //  this.$emit('continue')
    },
    submitForm () {
      this.showProgessModal = true
      console.log('this.onboardingsStore.kycPayload', this.onboardingsStore.kycPayload)
      this.onboardingsStore.submitForm()
      //  this.$emit('continue')
    },


  }
}
</script>
