<template>
  <div class="w-full md:w-6/12 lg:w-3/12 mb-6 px-6 sm:px-6 lg:px-4">
    <div class="flex flex-col">
      <!-- Avatar -->
      <div class="p-0">
        <img
          class="rounded w-60 h-60 object-cover object-center mx-auto"
          :src="member.src"
        >
      </div>
      <!-- Details -->
      <div class="text-center mt-2">
        <!-- Name -->
        <h1 class="text-custom-purple-500 font-bold mb-1">
          {{ member.name }}
        </h1>
        <!-- Title -->
        <div
          v-if="member.title"
          class="text-custom-purple-300 text-xs font-medium mb-2"
        >
          {{ member.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TeamMember',
  props: {
    member: {
      type: Object,
      default: () => { }
    }
  }
}
</script>

<style>
</style>
