<template>
  <!-- <div
    :style="colorBackground"
    class="rounded-3xl flex items-center justify-center flex-shrink-0"
    :class="company.color ? '' : 'bg-custom-purple-500'"
  >
    <div class="flex w-full text-gray-200 justify-center p-2">
      <RectangleImage
        v-if="company.logo || imageUrl"
        :src="imageUrl?? company.logo? company.logo : ''"
        :big="big"
        class="relative"
      />
    </div>
  </div> -->
  <div
    v-if="(company && company?.logo) || imageUrl"
    class="bg-custom-purple-500 flex-shrink-0 flex items-center justify-center ring-white ring-inset  relative"
    :class="[list? ' w-6 h-6 rounded-md' : header? 'ring-1 ring-offset-1 ring-offset-white w-[48px] h-[48px] rounded-xl' : premiumCard? `ring-4 ring-offset-2 ring-offset-white rounded-3xl w-[85px] h-[85px]` : 'ring-4 ring-offset-2 ring-offset-white rounded-3xl w-16 h-16 md:w-[125px] md:h-[125px]', list? '' : 'bg-custom-purple-500 shadow-md dark:shadow-md-dark']"
  >
    <img
      v-if="company?.logo || imageUrl"
      :src="imageUrl? imageUrl : company?.logo? company.logo : ''"
    
      class="absolute object-cover"
      :class="[list? 'p-[1px] rounded-md' : header? 'p-[3px] rounded-xl' : 'p-[7px] rounded-3xl']" 
    >
  </div>
</template>

<script>

export default {
  name: 'CompanyLogoModule',
  props: {
    company: {
      type: Object,
      default: () => {}
    },
    big: {
      type: Boolean,
      default: false
    },
    header: {
      type: Boolean,
      default: false
    },
    minified: {
      type: Boolean,
      default: false
    },
    list: {
      type: Boolean,
      default: false
    },
    size: {
      type: Number,
      default: 40
    },
    imageUrl: {
      type: String, 
      default: ''
    },
    premiumCard: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    style () {
      const style = {
        width: `${this.size}px`,
        height: `${this.size}px`
      }
      return style
    },
    colorBackground () {
      if (!this.company) {
        return null
      }

      // if (this.company.color) {
      //   return 'background-color: ' + this.company.color
      // }

      return null
    }
  }
}
</script>

<style scoped>
</style>
