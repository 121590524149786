import { RepositoryFactory } from '@/api/repositoryFactory'
import i18n from '../js/language'
const repository = RepositoryFactory.get('notifications')
import { defineStore } from 'pinia'
import { useSnackbarStore } from './snackbar'

export const useNotificationsStore = defineStore('notifications', {
  state: () => ({
    notifications: [],
    loading: false,
    defaultNotificationSettings: [],
    userNotificationSettings: {},
    totalPublicNotificationSettings: 0,
    nextPageUrl: null,
    scrollLoading: false
  }),
  getters: {
    list: (state) => state.notifications
  },
  actions: {
    pushNotifications(payload) {
      if (this.notifications.length === 0) {
        this.notifications = [...payload]
      } else {
        if (payload.length > 0) {
          payload.forEach((data) => {
            const findIndex = this.notifications.findIndex(
              (notification) => Number(notification.id) === Number(data.id)
            )
            if (findIndex === -1) {
              this.notifications.push(data)
            }
          })
        }
      }
      this.notifications.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    },
    resetNotifications() {
      this.notifications = []
    },
    async inAppPushNotification(payload) {
      useSnackbarStore().snack({
        type: 'notification',
        show: true,
        autoclose: true,
        title: payload.title,
        body: payload.body,
        link: payload.data.link.replace('axyer://', '/')
      })
    },
    async fetch() {
      try {
        this.loading = true
        const response = await repository.list()
        this.loading = false
        if (response.status === 200 && response.data) {
          this.pushNotifications(response.data.data)
          this.nextPageUrl = response.data.next_page_url
        }
      } catch (e) {
        this.loading = false
        console.log('Error : ' + e)
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    clearCounter() {
      try {
        let unseenNotifications = []
        this.notifications.forEach((notification) => {
          if (notification.pivot.seen === 0) {
            unseenNotifications.push(notification.id)
            notification.pivot.seen = 1
          }
        })
        this.notifications = [...this.notifications]
        if (unseenNotifications.length > 0) {
          const response = repository.notificationSeen({
            notifications: unseenNotifications
          })
          if (response.status === 204) {
            // console.log('notification seen')
          }
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message,
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    newNotification(payload) {
      // console.log('WOHO NOTIFICATION WORKS')
      if (this.notifications.length === 0) {
        this.notifications = [...payload]
        this.unreadNotifications = payload.length
      } else {
        if (payload.length > 0) {
          payload.forEach((data) => {
            const findIndex = this.notifications.findIndex(
              (notification) => Number(notification.id) === Number(data.id)
            )
            if (findIndex === -1) {
              this.notifications.unshift(data)
              this.unreadNotifications += 1
            }
          })
        }
      }

      this.notifications.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
      useSnackbarStore().snack({
        text: i18n.global.t('Got new notifications'),
        type: 'success',
        show: true,
        autoclose: true
      })
    },
    async read(id) {
      if (this.notifications.length > 0) {
        const findIndex = this.notifications.findIndex((notification) => notification.id === id)
        if (findIndex > -1) {
          this.notifications[findIndex].pivot.read = 1
        }
      }
      this.notifications = [...this.notifications]
      const response = await repository.read(id)
      if (response.status === 204) {
        // console.log('notification status updated')
      }
    },
    async hide(payload) {
      if (this.notifications.length > 0) {
        const findIndex = this.notifications.findIndex((notification) => Number(notification.id) === Number(payload.id))
        if (findIndex > -1) {
          this.notifications.splice(findIndex, 1)
        }
      }

      const response = await repository.hide(payload.id)
      if (response.status === 204) {
        // console.log('notification status updated')
      }
    },
    async hideAll() {
      this.loading = true
      const response = await repository.removeAll()
      this.loading = false
      if (response.status === 204) {
        this.resetNotifications()
        // console.log('notification status updated')
      }
    },
    async remove(payload) {
      const idx = this.notifications.find((notification) => Number(notification.id) === Number(payload.id))
      this.notifications.splice(this.notifications.indexOf(idx), 1)
      const response = await repository.remove(payload.id)
      if (response.status === 204 && response.data) {
        // console.log('Notification removed successfully')
      }
    },
    async fetchSettings() {
      this.loading = true
      const response = await repository.fetchSettings()
      this.loading = false
      if (response.status === 200 && response.data) {
        let totalPublicNotifications = 0
        for (let i = 0; i < response.data.length; i++) {
          const setting = response.data[i]
          if (setting.isPrivate !== true) {
            totalPublicNotifications += 1
          }
        }
        this.totalPublicNotificationSettings = totalPublicNotifications
        this.defaultNotificationSettings = response.data
      }
    },
    setSettings(payload) {
      let settings = []
      this.defaultNotificationSettings.forEach((setting) => {
        if (payload.id === setting.id) {
          settings.push(payload)
        } else {
          settings.push(setting)
        }
      })
      this.defaultNotificationSettings = settings
    },
    async changeSettings(payload) {
      await repository.setNotificationSettings({
        settings: this.defaultNotificationSettings,
        entity: payload
      })
    },
    async fetchUserNotificationSettings(payload) {
      try {
        this.loading = true
        const response = await repository.fetchNotificationSettings(payload)
        this.loading = false
        if (response.status === 200 && response.data) {
          this.userNotificationSettings = response.data
          if (response.data.length !== 0) {
            let notificationList = []
            this.defaultNotificationSettings.forEach((defaultNotification) => {
              response.data.forEach((userNotification) => {
                if (userNotification.notification_setting_type === defaultNotification.type) {
                  defaultNotification.isEnabled = userNotification.isEnabled
                }
              })
              notificationList.push(defaultNotification)
            })
            this.defaultNotificationSettings = notificationList
          }
        }
      } catch (e) {
        console.log(e)
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async scroll() {
      try {
        if (this.nextPageUrl === null) {
          this.scrollLoading = false
        } else if (this.nextPageUrl !== null && !this.loading) {
          this.scrollLoading = true
          const response = await repository.scroll(this.nextPageUrl)
          this.scrollLoading = false

          if (response.status === 200 && response.data && response.data.data) {
            this.pushNotifications(response.data.data)
            // payload.infiniteLoader.loaded()
            this.nextPageUrl = response.data.next_page_url
          }
        }
      } catch (e) {
        // commit('SET_LOADING_NEW', false)
        console.log('Error Occured : ' + e)
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    reset() {
      this.notifications = [...[]]
    }
  }
})
