<template>
  <div class="px-4 py-2 border rounded-md dark:border-custom-purple-400 flex items-center">
    <div class="relative w-14 h-14 mr-4 flex items-center justify-center flex-shrink-0">
      <Icon
        name="id-card"
        class="text-4xl"
      />
      <Icon
        name="badge-check"
        class="absolute bottom-0 right-0 text-lg text-custom-orange-400"
        style="margin: 0 2px 2px 0;"
        solid
      />
    </div>
    <div>
      <div
        v-if="user.first_name"
        class="leading-5 text-lg font-medium"
      >
        {{ user.first_name }} {{ user.last_name }}
      </div>
      <div
        v-else
        class="leading-5 text-lg font-medium"
      >
        {{ user.profile.full_name }}
      </div>
      <div class="leading-5">
        {{ user.email }}
      </div>
      <div class="leading-5 text-sm font-reputation text-custom-purple-300">
        {{ user.cropped_ssn }}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'VerifiedUserCard',
  props: {
    user: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
