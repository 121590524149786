<template>
  <div>
    <div
      v-if="!isVerified"
      class="flex-col text-custom-purple-500 space-y-6"
    >
      <div class="space-y-4">
        <div class="text-3xl font-bold ">
          Your email is verified
        </div>
        <div>
          As a verified user you will be able to interact with other users and get the full Eucaps experience.
        </div>
      </div>

      <div class=" space-y-4">
        <div>
          <label
            class="font-bold"
            for="first-name-input"
          >
            {{ $t('First name') }}
          </label>
          <BaseInput
            :id="'first-name-input'"
            autocomplete="First name"
            type="text"
            :has-dark-mode="false"
            :required="true"
            :minlength="1"
            class="placeholder-custom-purple-300 w-full font-semibold !rounded-md"
            :placeholder="'Ex. Shaera'"
            shadow="shadow-md"
            :model-value="firstname"
            @update:model-value="$emit('firstname', $event)"
          />
        </div>
        <div>
          <label class="font-bold">
            {{ $t('Last name') }}
          </label>
          <BaseInput
            autocomplete="Last name"
            type="text"
            :has-dark-mode="false"
            :required="true"
            :minlength="1"
            class="placeholder-custom-purple-300 w-full font-semibold !rounded-md"
            :placeholder="'Ex. Cater'"
            shadow="shadow-md"
            :model-value="lastname"
            @update:model-value="$emit('lastname', $event)"
          />
        </div>
      </div>



      <div
        v-if="verificationMethod === 'devcode'"
        class="flex flex-col items-center h-[37rem] "
      >
        <CircleLoader v-if="loading" />
        <div class="flex justify-center">
          <iframe
            id="Devcode"
            class=" h-[37rem] "
            name="Devcode Iframe"
            title="Insert your personal number"
            :src="url"
          />
        </div>
      </div>
      <div
        v-if="verificationMethod === 'freja'"
        class="pt-4 flex flex-col items-center h-96"
      >
        <img
          class="w-24 mb-4"
          :src="'/img/freja_black.svg'"
        >
        <CircleLoader
          v-if="frejaLoading"
          small
        />
        <div
          v-else
          class="h-full w-full flex justify-center mb-4"
        >
          <iframe
            id="Freja"
            class="overflow-visible flex w-1/2 h-full pl-2 md:pl-20 lg:pl-2 xl:pl-20"
            name="Freja Iframe"
            title="Verify your Freja Id"
            :src="url"
          />
        </div>
        <span
          v-if="!frejaLoading"
          class="text-xs"
        >Note: Scan the QR code in Freja App to authenticate. please do not
          reload the page else you need to restart the authentication process</span>
        <SquareButton
          class="mt-8 bg-gray-400 rounded-lg text-base text-custom-purple-500 font-bold border-none px-6 py-2 "
          @click="$emit('skip', $event)"
        >
          Skip for now
        </SquareButton>
      </div>
      <div
        v-if="verificationMethod === 'trulioo'"
        class=" h-144 "
      >
        <TruliooContainer :pending-verification="pendingVerification" />
      </div>
      <div
        v-if="verificationMethod === ''"
        class="space-x-8 pt-4"
      >
        <VerificationButtons
          @verify="$emit('verify', $event)"
          @skip="$emit('skip', $event)"
        />
      </div>
    </div>
  

    <div v-if="verificationFailure || truliooStatus">
      <AlertModalContainer
        :create="verificationFailure || truliooStatus"
        :title="'Verification Failed'"
        body="Sorry, we were unable to verify your identity. Please try again."
        type=""
        :show-confirm-button="verificationFailure || truliooStatus"
        @toggle="$emit('toggle', $event)"
        @agree="$emit('apply', $event)"
      >
        <template #agree>
          {{ $t('Yes, I will try again') }}
        </template>
      </AlertModalContainer>
    </div>
  </div>
</template>

<script>
import TruliooContainer from '../../trulioo'
import AlertModalContainer from '../../alertModal'
import VerificationButtons from './VerificationButtons.vue'

export default {
  name: 'UserVerificationForm',
  components: {
    TruliooContainer,
    AlertModalContainer,
    VerificationButtons
  },
  props: {
    email: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: ''
    },
    verificationMethod: {
      type: String,
      default: ''
    },
    verifyData: {
      type: Object,
      default: () => { }
    },
    isVerified: {
      type: Boolean,
      default: false
    },
    verificationFailure: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    frejaLoading: {
      type: Boolean,
      default: false
    },
    pendingVerification: {
      type: Boolean,
      default: false
    },
    truliooStatus: {
      type: Boolean,
      default: false
    },
    firstname: {
      type: String,
      default: ''
    },
    lastname: {
      type: String,
      default: ''
    }
  },
  emits: [
    'skip',
    'verify',
    'continue',
    'toggle',
    'apply',
    'firstname',
    'lastname'
  ]
}
</script>
