<template>
  <div class="lg:space-y-10 space-y-5">
    <div
      v-if="step === 3"
      class="font-medium sm:text-2xl text-xl"
    >
      Hey Company - start your 30 days free trial!
    </div>
    <div
      v-else-if="step === 4 || step === 5"
      class="font-medium sm:text-2xl text-xl"
    >
      Start your 30 days free trial!
    </div>
    <div
      v-else
      class="font-medium sm:text-2xl text-xl"
    >
      Hey growth company!
    </div>
    <div
      v-if="step === 3"
      class="space-y-10"
    >
      <div class="xl:text-5xl xl:leading-tight sm:leading-tight leading-tight sm:text-5xl text-4xl font-extrabold ">
        What company do you want to share with <span class="text-custom-orange-400">Global investors</span>?
      </div>
    </div>
    <div
      v-else-if="step === 2"
      class="space-y-10"
    >
      <div class="xl:text-6xl xl:leading-tight sm:leading-tight leading-tight sm:text-5xl text-4xl font-extrabold ">
        Please verify that you're <span class="text-custom-orange-400">you</span>.
      </div>
      <div class="lg:text-custom-purple-200 text-custom-purple-300 font-medium xl:text-2xl sm:text-xl text-lg pr-10">
        We want to make sure that we connect your growing company to the right people.
      </div>
    </div>
    <div
      v-else-if="step === 4"
      class="space-y-10"
    >
      <div class=" xl:text-6xl xl:leading-tight sm:leading-tight leading-tight sm:text-5xl text-4xl font-extrabold ">
        Let's <span class="text-custom-orange-400">shake</span> hands
      </div>
      <div class="text-custom-purple-200 font-medium xl:text-2xl sm:text-xl text-lg pr-10">
        You're almost there now. Just a read through of the Eucaps agreement first.
      </div>
      <div class="lg:absolute lg:bottom-16 text-sm font-semibold">
        *You are free to opt-out within the trial period.
      </div>
    </div>
    <div
      v-else-if="step === 5"
      class="space-y-10 "
    >
      <div class=" xl:text-7xl sm:text-6xl text-5xl xl:leading-tight sm:leading-tight leading-tight font-extrabold ">
        How do you want to <span class="text-custom-orange-400">pay</span>?
      </div>
      <div class="text-custom-purple-200 font-medium xl:text-2xl sm:text-xl text-lg pr-10">
        The last step to get growing.
      </div>
      <div class="lg:absolute lg:bottom-16 text-sm font-semibold">
        *You can always choose opt-out within the 30 day trial period. Then no charges to your card will be made.
      </div>
    </div>
    <div
      v-else-if="done"
      class="space-y-10 "
    >
      <div class=" xl:text-7xl sm:text-6xl text-5xl xl:leading-tight sm:leading-tight leading-tight font-extrabold ">
        <span class="text-custom-orange-400">Onboarding</span> complete!
      </div>
      <div class="text-custom-purple-200 font-medium xl:text-2xl sm:text-xl text-lg pr-10">
        Good to have you with us!
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompanyText',
  props: {
    step: {
      type: Number,
      default: -1
    },
    done: {
      type: Boolean,
      default: false
    }
  }
}
</script>
