<template>
  <div>
    <div
      v-if="loading || !showTrulio"
      class="flex justify-center pt-10"
    >
      <CircleLoader />
    </div>
    <div v-else>
      <!-- <div id="trulioo-embedid" /> -->
      <div
        class="helloflow-container"
        :style="{ height: '550px' }"
        :data-form="formUrl"
        data-type="standard"
        data-text="Launch me"
      />
    </div>
    <!-- <div
      v-else
      class="flex justify-center items-center md:pt-28 pt-10 flex-col space-y-5"
    >
      <div class="text-lg text-center font-medium">
        <span v-if="automatic === true">
          Verifying you automatically, please wait...
        </span>
        <span v-else-if="pendingVerification && !automatic">
          Your verification is already in process. Please wait till we confirm your verification...
        </span>
        <span v-else>
          Automatic verification failed. Verifying you manually, please wait...
        </span>
      </div>
      <div
        v-if="timerOn"
        class=" text-6xl"
      >
        {{ minute }} : {{ second }}
      </div>
      <div
        v-if="pendingVerification || timerOn"
        class="flex items-center justify-center"
      >
        <ActiveButton
          class="bg-custom-orange-400 rounded-xl flex "
          @click="redirect()"
        >
          <span class="text-white p-1">
            Notify me later
          </span>
        </ActiveButton>
      </div>
    </div> -->
  </div>
</template>

<script>
import CircleLoader from '../../components/CircleLoader.vue'
import i18n from '../../js/language'
import { useUsersStore } from '../../stores/users'
import { useSnackbarStore } from '../../stores/snackbar'
import { useTrulioosStore } from '../../stores/trulioo'

export default {
  name: 'Trulioo',
  components: { CircleLoader },
  props: {
    pendingVerification: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    const usersStore = useUsersStore()
    const snackbarStore = useSnackbarStore()
    const truliooStore = useTrulioosStore()

    return { usersStore, snackbarStore, truliooStore }
  },
  data () {
    return {
      showTrulio: false,
      publicKey: '142e0deaac664d7cb7b712cef2a620de',
      accessTokenURL: process.env.VUE_APP_API_URL,
      timerOn: false,
      minute: 0,
      second: 59,
      loading: false,
      automatic: false,
      timer: null,
      resultInterval: null,
      experienceTransactionId: null
    }
  },
  computed: {
    user () {
      return this.usersStore.user
    },
    formUrl () {
      return this.truliooStore.formUrl
    }
  },
  async mounted () {
    // if (this.pendingVerification === true) {
    //   this.checkTruliooStatus()
    // }
    await this.truliooStore.getFormUrl()
    this.showTrulio = true
    this.loadScript()
  },
  beforeUnmount () {
    clearTimeout(this.timer)
    clearInterval(self.resultInterval)
    clearInterval(self.resultUserInterval)
  },
  async created () {
    // this.experienceTransactionId = null

    // let trulioo = document.createElement('script')
    // trulioo.setAttribute('id', 'Trulioo')
    // trulioo.setAttribute('src', 'https://js.trulioo.com/latest/main.js')
    // document.head.appendChild(trulioo)
    // const self = this
    // trulioo.onload = () => {
    //   // eslint-disable-next-line
    //   const truliooClient = new window.TruliooClient({
    //     publicKey: '142e0deaac664d7cb7b712cef2a620de',
    //     accessTokenURL: process.env.VUE_APP_API_URL,
    //     handleResponse: self.response,
    //     onInitialRenderComplete: self.initialRenderComplete
    //   })
    // console.log(truliooClient)
    // }

    // 49a92ec4837343888ff365fe8dd33481

    // this.startTimer('49a92ec4837343888ff365fe8dd33481', 119980, 1, 59)

    // this.fetchResult('7c2ae801e0794e738daec38f32999ece')
  },
  methods: {
    loadScript () {
      this.loading = true
      try {
        if (!document.getElementById("helloflow-loaded")) {
          const script = document.createElement("script");
          script.id = "helloflow-loaded";
          script.src = "https://launch-workflow.trulioo.com/src/embed.js";
          const css = document.createElement("link");
          css.rel = "stylesheet";
          css.href = "https://launch-workflow.trulioo.com/src/embed.css";
          document.head.appendChild(css);
          document.head.appendChild(script);
          this.loading = false
        }
        this.loading = false
      } catch (error) {
        console.log(error)
        this.loading = false
      }
      this.loading = false
    },
    async startTimer (experienceTransactionId, time, minutes, seconds) {
      const self = this
      this.experienceTransactionId = experienceTransactionId
      this.countDown(minutes, seconds)
      clearTimeout(this.timer)
      clearInterval(self.resultInterval)
      clearInterval(self.resultUserInterval)

      this.resultInterval = setInterval(async () => {
        // console.log('result begin')
        // clearInterval(self.resultInterval)

        const result = await self.handleInitialVerificationRequest(experienceTransactionId)
        // console.log('result oms', result)

        if (result.status !== 'pending') {
          let postData = {
            messageName: 'verificationStatus',
            status: result.transactionResult === 'match' ? 'successful' : 'failed'
          }
          window.parent.postMessage(postData, '*')
          self.timerOn = false
          clearTimeout(self.timer)
          clearInterval(self.resultInterval)
          clearInterval(self.resultUserInterval)
        }
      }, 5000)

      this.checkTruliooStatus()

      this.timer = setTimeout(async () => {
        await this.usersStore.fetch()
        // console.log('test', result)
        if (self.user && self.user.trulioo_status === 'pending') {
          // console.log(result)
          if (self.automatic) {
            self.startTimer(experienceTransactionId, 600000, 9, 59)
            self.automatic = false
          }
        } else {
          let postData = {
            messageName: 'verificationStatus',
            status: self.user && self.user.trulioo_status === 'match' ? 'successful' : 'failed'
          }
          window.parent.postMessage(postData, '*')
        }
      }, time)
    },
    async response (eventResponse) {
      const { status, experienceTransactionId } = eventResponse
      // console.log(eventResponse)
      if (status === 200) {
        this.automatic = true
        await this.handleInitialVerificationRequest(experienceTransactionId)
        this.startTimer(experienceTransactionId, 119980, 1, 59)
      } else {
        console.log('error')
        let postData = {
          messageName: 'verificationStatus',
          status: 'failed'
        }
        window.parent.postMessage(postData, '*')
      }
    },
    countDown (minutes, seconds) {
      this.minute = minutes.toString().padStart(2, '0')
      this.second = seconds
      if (this.timerOn === false) this.timerOn = true
      let intervalId = setInterval(() => {
        this.minute = this.minute.toString().padStart(2, '0')

        this.second = (Number(this.second) - 1).toString().padStart(2, '0')

        if (Number(this.second) === 0) {
          if (Number(this.minute) - 1 === -1) {
            clearInterval(intervalId)
            return
          }
          this.minute = (Number(this.minute) - 1).toString().padStart(2, '0')
          this.second = 59
        }
      }, 1000)
    },
    initialRenderComplete () {
      this.loading = false
      // console.log(event.status, event.statusText)
    },
    async handleInitialVerificationRequest (experienceTransactionId) {
      try {
        return await this.truliooStore.result(experienceTransactionId)
      } catch (e) {
        await this.snackbarStore.snack({ text: i18n.global.t('Something went wrong. Please try again'), type: 'error', show: true, autoclose: false })
      }
    },
    redirect () {
      this.$router.push({ name: 'myFeed' })
    },
    async checkTruliooStatus () {
      const self = this
      this.resultUserInterval = setInterval(async () => {
        console.log('result begin')
        // clearInterval(self.resultInterval)
        await this.usersStore.fetch()
        if (self.user && self.user.trulioo_status !== 'pending') {
          self.timerOn = false
          clearTimeout(self.timer)
          clearInterval(self.resultInterval)
          clearInterval(self.resultUserInterval)
          let postData = {
            messageName: 'verificationStatus',
            status: self.user && self.user.trulioo_status === 'match' ? 'successful' : 'failed'
          }
          window.parent.postMessage(postData, '*')
        }
      }, 10000)
    }
  }
}
</script>
