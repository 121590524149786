<template>
  <div>
    <BaseCard class="p-8">
      <template #body>
        <h1 class="text-xl text-custom-purple-700 dark:text-custom-purple-100 font-bold">
          {{ $t('Your session has expired') }}
        </h1>
        <p class="text-sm font-semibold mt-2 dark:text-custom-purple-100">
          {{ $t('We want to inform you that you have been logged out for security reasons. This measure is taken to ensure the safety of you account and personal information.') }}
        </p>
        <p class="text-sm mt-2 font-semibold dark:text-custom-purple-100">
          {{ $t('We kindly ask you to verify your email address.') }}
        </p>
        <div class="py-2 px-4 items-center border-dashed border-2 border-gray-900 flex flex-col rounded-sm mt-6 dark:text-custom-purple-100">
          <span class="text-xs">{{ $t('Code sent to') }}</span>
          <div
            class="inline-flex"
          >
            <p
              class="font-bold"
            >
              {{ email }}
            </p>
          </div>
          <span class="text-xs">({{ $t('Also check junk mail') }})</span>
        </div>
        <div class="text-center mt-4 dark:text-custom-purple-100">
          {{ $t('Write code here') }}:
        </div>
        <div
          v-if="loadingOtp"
          class="flex justify-center py-4"
        >
          <Loader />
        </div>
        <div
          v-else
          id="otp"
          class="flex justify-center text-center px-2 mt-5"
        >
          <input
            ref="first"
            class="h-12 w-12 border-2 border-custom-purple-700 text-center form-control rounded-md dark:bg-custom-purple-500 dark:text-custom-purple-100"
            :type="isMobile ? 'number' : 'text'"
            max="9"
            maxlength="1"
            :value="pin1"
            @paste="$emit('paste-code', $event)"
            @input="first"
          >
          <input
            ref="second"
            class="h-12 w-12 border-2 mx-4 border-custom-purple-700 text-center form-control rounded-md dark:bg-custom-purple-500 dark:text-custom-purple-100"
            :type="isMobile ? 'number' : 'text'"
            max="9"
            maxlength="1"
            :value="pin2"
            @paste="$emit('paste-code', $event)"
            @input="second"
            @keyup="secondKey"
          >
          <input
            ref="third"
            class="h-12 w-12 border-2 border-custom-purple-700 text-center form-control rounded-md dark:bg-custom-purple-500 dark:text-custom-purple-100"
            :type="isMobile ? 'number' : 'text'"
            max="9"
            maxlength="1"
            :value="pin3"
            @paste="$emit('paste-code', $event)"
            @input="third"
            @keyup="thirdKey"
          >
        </div>
        <div
          v-if="otpErrorMessage !== ''"
          class="text-center mt-4 text-sm font-semibold text-red-600"
        >
          {{ $t(otpErrorMessage) }}
        </div>
        <NeutralButton
          :rounded="'rounded-full'"
          class="w-full text-md mt-12 h-12 focus:outline-none bg-custom-purple-700"
          @click="$emit('submit')"
        >
          {{ $t('Verify') }}
        </NeutralButton>
        <div class="flex justify-center mt-4">
          <button
            class="flex items-center focus:outline-none text-custom-purple-700 dark:text-custom-purple-100"
            @click="$emit('resend-otp')"
          >
            <span class="font-medium">{{ $t('Resend code') }}</span>
          </button>
        </div>
      </template>
    </BaseCard>
  </div>
</template>
  
  <script>
  
  export default {
    name: 'TradingEmailVerification',
    props: {
      pin1: { type: String, default: null },
      pin2: { type: String, default: null },
      pin3: { type: String, default: null },
      email: { type: String, default: null },
      loadingOtp: { type: Boolean, default: false },
      isMobile: { type: Boolean, default: false },
      otpErrorMessage: { type: String, default: '' }
    },
  emits: [
  'paste-code',
  'resend-otp',
  'submit',
  'pin1',
  'pin2',
  'pin3'
  ],
    methods: {
      first (event) {
        if (!event.data) {
          return
        }
  
        if (Number(event.target.value) >= 0 && Number(event.target.value) < 10) {
          this.$refs.second.focus()
        }
        this.$emit('pin1', event.target.value)
      },
      second (event) {
        if (!event.data) {
          this.$refs.first.focus()
        } else if (Number(event.target.value) >= 0 && Number(event.target.value) < 10) {
          this.$refs.third.focus()
        }
        this.$emit('pin2', event.target.value)
      },
      secondKey (event) {
        if (!event.target.value && event.key === 'Backspace') {
          this.$emit('pin2', null)
          this.$refs.first.focus()
        }
  
        if (event.key === 'Enter') {
          this.$emit('submit')
        }
      },
      thirdKey (event) {
        if (!event.target.value && event.key === 'Backspace') {
          this.$emit('pin3', null)
          this.$refs.second.focus()
        }
  
        if (event.key === 'Enter') {
          this.$emit('submit')
        }
      },
      third (event) {
        if (!event.data) {
          this.$refs.second.focus()
        } else if (Number(event.target.value) >= 0 && Number(event.target.value) < 10) {
          this.$refs.third.focus()
        }
        this.$emit('pin3', event.target.value)
  
        if (event.key === 'Enter') {
          this.$emit('submit')
        }
      }
    }
  
  }
  </script>
  