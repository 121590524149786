<template>
  <div
    class="font-semibold cursor-pointer text-gray-500 dark:text-custom-purple-200 underline text-sm"
    :class="padding"
    @click="$emit('toggle', $event)"
  >
    {{ translate ? 'See original' : 'Show translation' }}
  </div>
</template>

<script>

export default {
  name: 'TranslateToggle',
  props: {
    translate: {
      type: Boolean,
      default: false
    },
    padding: {
      type: String,
      default: ' pt-2 pb-4'
    }
  },
  emits:[
    'toggle'
  ]
}
</script>
