<template>
  <div class="flex items-center justify-between z-10 py-6 mx-auto px-8">
    <div class="flex items-center justify-between">
      <LogoContainer class="mr-4" />
    </div>
    <div>
      <TopTagSearchContainer />
    </div>
    <div class="flex items-center  text-white text-sm font-medium">
      <RouterLink
        exact
        :to="{name: 'signup'}"
      >
        <button class="mr-4 lg:mr-14 border-b-2 border-transparent py-2">
          Sign up
        </button>
      </RouterLink>
      <RouterLink
        exact
        :to="{name: 'login'}"
        class="bg-custom-orange-400 py-2 px-6 rounded-full"
      >
        Sign in
      </RouterLink>
    </div>
  </div>
</template>
<script>
import LogoContainer from '@/modules/logo'
import TopTagSearchContainer from '@/modules/topTagSearch'

export default {
  name: 'DesktopMenu',
  components: {
    LogoContainer,
    TopTagSearchContainer
  },
  props: {
    open: {
      type: Boolean,
      default: false
    },
    loaded: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.router-link-active {
  @apply border-custom-green-500;
}
.menu-slider {
  transform: translateX(100%);
}
.slide-in {
  animation: slide-in 0.5s forwards;
}
.slide-out {
  animation: slide-out 0.9s backwards;
}
@keyframes slide-in {
  100% {
    transform: translateX(0%);
  }
}
@keyframes slide-out {
  0% {
    transform: translateX(-50%);
  }
  100% {
    width: translateX(100%);
  }
}
</style>
