<template>
  <div>
    <div :class="(minifiedText && !increaseHeight) ? 'h-24' : ''">
      <p class="text-base  whitespace-pre-wrap break-words leading-relaxed dark:text-custom-purple-100">
        <span
          class="text"
          @click="redirect"
          v-html="$t(internalMessage)"
        />

        <!-- <v-runtime-template
          :template="`<div class='text'>${internalMessage}</div>`"
        /> -->
      </p>
    </div>
    <div :class="links ? 'mb-2' : 'pr-2'">
      <TranslateToggle
        v-if="hasEnglish"
        :translate="translateInternal"
        @toggle="translateText"
      />
      <LinkPreview
        v-for="(url, index) in links"
        :key="url + index"
        :url="url"
      />
    </div>
  </div>
</template>

<script>
//import VRuntimeTemplate from "vue3-runtime-template"

import linkHandler from '@/js/helpers/links'
import mentions from '../js/helpers/mentions'
import stringHandler from '@/js/helpers/string'
export default {
  name: 'TextMessage',
  components: {
    //VRuntimeTemplate
  },
  props: {
    type: { type: String, default: 'text' },
    message: { type: String, default: '' },
    messageEnglish: { type: String, default: '' },
    showEnglish: { type: String, default: null },
    truncate: { type: Boolean, default: false },
    translate: { type: Boolean, default: false },
    minified: { type: Boolean, default: false },
    minifiedText: { type: Boolean, default: false },
    truncateSize: { type: Number, default: 50 },
    mentionsArray: {
      type: Array,
      default: () => []
    }
  },
  emits: ['redirect', 'translate', 'redirect-to-thread'],
  data() {
    return {
      template: '',
      translateInternal: false,
      increaseHeight: false,
      links: []
    }
  },
  computed: {
    internalMessage() {
      if (this.translateInternal && this.hasEnglish) {
        return this.handleMessage(this.messageEnglish)
        // return this.messageEnglish
      }

      return this.handleMessage(this.message)
      // return this.message
    },

    hasEnglish() {
      return this.messageEnglish && this.messageEnglish.length > 0
    }
  },
  mounted() {
    this.translateInternal = this.translate
  },
  methods: {
    redirect(event) {
      const name = event.target.getAttribute('data-name')

      if (event.target && name === 'post') {
        this.$emit('redirect-to-thread')
      } else {
        const id = event.target.getAttribute('data-id')
        this.$router.push({ name: name, params: { id: id } })
      }
    },
    maxText(text) {
      return stringHandler.maxLength(text, 50)
    },
    handleMessage(message) {
      let data =
        this.truncate && message.length > this.truncateSize
          ? message.replace('<p>', '').slice(0, this.truncateSize) + '...'
          : message
      let links = linkHandler.linkable(data)
      if (data && links.links && links.links.length > 0) {
        for (let i = 0; i < links.links.length; i++) {
          let videoId = null
          let type = 'video'
          if(links.links[i].includes("youtube.com/shorts")) {
            videoId = links.links[i].split("/").pop().split("?")[0]
            type = 'shorts'
          } else if(links.links[i].includes("youtube.com")) {
            videoId = links.links[i].split("=")[1];
          } else if (links.links[i].includes("youtu.be")) {
            const youtuPattern = /(?:https?:\/\/)?(?:www\.)?youtu\.be\/([a-zA-Z0-9_-]+)/;
            videoId = links.links[i].match(youtuPattern)[1];
          } else {
            continue
          }

          if (videoId !== null && videoId !== undefined) {
            const iframe = `<iframe
              class="rounded-lg ${type === 'shorts' ? 'h-96' : 'h-64'} w-full"
              src="https://www.youtube.com/embed/${videoId}"
              frameborder="0"
              allowfullscreen
              scrolling="no"
              ></iframe>`
            data = data.replace(links.links[i], `${iframe}`)
            links.links.splice(i, 1)
          }
          this.increaseHeight = true
          break
        }
      }
      this.links = links.links
      if (data && (typeof data === 'string' || data instanceof String) && data.match(mentions.MATCHING)) {
        let newText = mentions.parse(data, this.mentionsArray)
        return newText
      } else if (data && this.type === 'press-release') {
        return data
      } else {
        data
      }
    },
    translateText() {
      this.translateInternal = !this.translateInternal
      this.$emit('translate', this.translateInternal)
    }
  }
}
</script>
<style>
.links a {
  @apply text-custom-orange-400 break-all;
}
.text a {
  color: rgb(185 28 28);
}
</style>