<template>
  <div v-if="notification.notifiable">
    <div>
      <Notification
        :pivot="notification.pivot"
        @remove="$emit('remove', { id: notification.id })"
      >
        <div @click="$parent.redirectProfile({ notificationId: notification.id, query: { navigate: 'tradingRef' } })">
          <div>
            <div
              v-if="notification.notifiable.status === 'O'"
              class="w-full flex items-center ml-2 dark:text-white"
            >
              <div class="ml-2 ">
                {{ $t('Your account is been verified successfully') }}
              </div>
            </div>
            <div
              v-if="notification.notifiable.status === 'A'"
              class="w-full flex ml-2 dark:text-white items-center"
            >
              <div class="ml-2">
                {{ $t('Your application was deleted, need to reverify the KYC') }}
              </div>
            </div>
            <div
              v-else-if="notification.notifiable.status === 'N'"
              class="w-full flex ml-2 dark:text-white items-center"
            >
              <div class="ml-2">
                {{ $t('Your account is in processing, no Funding Details have been provided') }}
              </div>
            </div>
            <div
              v-else-if="notification.notifiable.status === 'C'"
              class="w-full flex ml-2 dark:text-white items-center"
            >
              <div class="ml-2">
                {{ $t('Your account is been closed') }}
              </div>
            </div>

            <div
              v-else-if="notification.notifiable.status === 'P'"
              class="w-full flex ml-2 dark:text-white items-center"
            >
              <div class="ml-2">
                {{ $t('Your account is in processing') }}
              </div>
            </div>
            <div
              v-else-if="notification.notifiable.status === 'R'"
              class="w-full flex ml-2 dark:text-white items-center"
            >
              <div class="ml-2">
                {{ $t('Your application is rejected, need to reverify the KYC') }}
              </div>
            </div>
          </div>



          <div class="text-xs mr-6">
            <span class="font-bold">

              {{ notification.notifiable.message }}
              <div>{{ time }}</div>
            </span>
          </div>
        </div>
      </Notification>
    </div>
  </div>
</template>

<script>

import Notification from './Notification'

export default {
  name: 'NotificationUploadDocument',
  components: {
    Notification
  },
  props: {
    notification: { type: Object, default: () => { } },
    time: { type: String, default: '' }
  },
  emits: [
    'remove'
  ],
}
</script>
