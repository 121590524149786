<template>
  <div class=" space-y-6">
    <BaseButton
      class="bg-custom-purple-500 !px-2 !py-1"
      @click="$emit('back-to-signup-form')"
    >
      <Icon
        name="arrow-back"
        class="text-lg text-white"
      />
    </BaseButton>
    <form
      class="grid my-4 text-black space-y-6"
      @submit.prevent="$emit('on-submit')"
    >
      <div class="font-bold text-4xl">
        {{ $t('Create password') }}
      </div>

      <div class="font-medium">
        {{ $t('Create a password that meets the critera below.') }}
      </div>
      <div>
        <label class="font-bold">
          {{ $t('Password') }}
        </label>

        <div
          class="border-gray-300 relative border shadow-md w-full rounded-md flex bg-white"
        >
          <input
            id="password"
            placeholder="**** ****"
            class="appearance-none font-semibold text-sm pl-4 pr-2 py-2 bg-transparent w-full focus:outline-none focus:ring-0 placeholder-custom-purple-300"
            :type="showPassword ? 'text' : 'password'"
            required
            autocomplete="password"
            :value="password"
            @focus="$emit('reset-error')"
            @input="$emit('password', $event)"
          >
          <div class="flex items-center">
            <button
              type="button"
              class="hover:text-black rounded mr-3 text-gray-600 cursor-pointer bg-white"
              @click="$emit('toggle-show-password')"
            >
              <Icon
                :name="showPassword ? 'show' : 'hide'"
                class="text-lg"
              />
            </button>
          </div>
        </div>
      </div>
      <div class="font-medium">
        <div 
          :class="!password ? '' : isLengthValid ? 'text-custom-green-800' : 'text-custom-red-500' "
          class=" flex items-center space-x-2"
        >
          <div v-if="password">
            <Icon 
              v-if="isLengthValid"
              name="check-circle"

              class="text-2xl"
            />
            <Icon 
              v-else
              name="info-circle"
              class="text-2xl"
            />
          </div>
          
          <div class="text-sm">
            {{ $t('Is minimum of 8 characters') }}
          </div>
        </div>

        <div 
          :class="!password ? '' : hasUpperOrLowerCase ? 'text-custom-green-800' : 'text-custom-red-500' "
          class=" flex items-center space-x-2"
        >
          <div v-if="password">
            <Icon 
              v-if="hasUpperOrLowerCase"
              name="check-circle"

              class="text-2xl"
            />
            <Icon 
              v-else
              name="info-circle"
              class="text-2xl"
            />
          </div>
          
          <div class="text-sm">
            {{ $t('Has Uppercase and lowercase letters') }}
          </div>
        </div>

        <div 
          :class="!password ? '' : hasNumbers ? 'text-custom-green-800' : 'text-custom-red-500' "
          class=" flex items-center space-x-2"
        >
          <div v-if="password">
            <Icon 
              v-if="hasNumbers"
              name="check-circle"
              class="text-2xl"
            />
            <Icon 
              v-else
              name="info-circle"
              class="text-2xl"
            />
          </div>
  
          <div class="text-sm">
            {{ $t('Has numbers') }}
          </div>
        </div>

        <div 
          :class="!password ? '' : hasSpecialChars ? 'text-custom-green-800' : 'text-custom-red-500' "
          class=" flex items-center space-x-2"
        >
          <div v-if="password">
            <Icon 
              v-if="hasSpecialChars"
              name="check-circle"
              class="text-2xl"
            />
            <Icon 
              v-else
              name="info-circle"
              class="text-2xl"
            />
          </div>
          
          <div class="text-sm">
            {{ $t('Has special characters') }}
          </div>
        </div>
      </div>
      <NeutralButton
        type="submit"
        :disabled="!password || !isLengthValid || !hasUpperOrLowerCase || !hasNumbers || !hasSpecialChars "
        class="w-full text-md focus:outline-none disabled:opacity-50"
      >
        {{ $t("Create account") }}
      </NeutralButton>
    </form>

    <div class="w-full flex justify-center items-center ">
      <button
        class="mt-5 flex justify-center items-center focus:outline-none text-gray-900 gap-2"
        @click="$router.push({ name: 'login' })"
      >
        <div class="font-medium">
          {{ $t('Already have an account?') }}
        </div>
        <div class="font-semibold flex item-center">
          {{ $t('Login') }}
        </div>
      </button>
    </div>
  </div>
</template>
<script>
export default {
    name: 'SignupFormSection',
    props: {
        password: {
            type: String,
            default: ''
        },
        showPassword: {
            type: Boolean,
            default: false
        },
        hasUpperOrLowerCase: {
            type: Boolean,
            default: false
        },
        isLengthValid: {
            type: Boolean,
            default: false
        },
        hasNumbers: {
            type: Boolean,
            default: false
        },
        hasSpecialChars: {
            type: Boolean,
            default: false
        },
    },
    emits: [
        'password',
        'on-submit',
        'toggle-accept-terms',
        'reset-error',
        'back-to-signup-form',
        'toggle-show-password',
    ]

}
</script>