<template>
  <div v-if="!isMobile">
    <TopTagSearch
      :model-value="search"
      :show-search="showSearch"
      :is-mobile="isMobile"
      :show-search-input="showSearchInput"
      :loading-results="loadingTags"
      :show-results="showResults"
      :tags="filterTags"
      :is-logged-in="isLoggedIn"
      :source="source"
      @search="searchTags"
      @toggle-search-box="onShowSearch"
      @click="handleClick"
      @enter="handleEnterForCustomTag"
    />
  </div>
  <div v-else>
    <TopTagSearchMobile
      :model-value="search"
      :show-search="showSearch"
      :is-mobile="isMobile"
      :show-search-input="showSearchInput"
      :loading-results="loadingTags"
      :show-results="showResults"
      :tags="filterTags"
      :is-logged-in="isLoggedIn"
      :source="source"
      @search="searchTags"
      @toggle-search-box="onShowSearch"
      @click="handleClick"
      @enter="handleEnterForCustomTag"
    />
  </div>
</template>

<script>
import TopTagSearch from './_components/TopTagSearch'
import { isMobile } from '@/js/helpers/mobile'
import { useFiltrationsStore } from '@/stores/filtrations'
import { useUsersStore } from '@/stores/users'
import eventBus from '@/js/eventbus'
import TopTagSearchMobile from './_components/TopTagSearchMobile.vue'

export default {
  name: 'TopTagSearchModule',
  components: {
    TopTagSearch,
    TopTagSearchMobile
  },
  props: {
    source: {
      type: String,
      default: ''
    }
  },
  emits: ['toggle'],
  setup() {
    const filtrationsStore = useFiltrationsStore()
    const usersStore = useUsersStore()

    return { filtrationsStore, usersStore }
  },
  data() {
    return {
      search: '',
      showSearch: false,
      showSearchBox: false
    }
  },
  computed: {
    loadingTags() {
      return this.filtrationsStore.searchTagsLoading
    },
    isLoggedIn() {
      return this.usersStore.isLoggedin
    },
    isMobile() {
      return isMobile()
    },
    showSearchInput() {
      if (!isMobile()) {
        return true
      }

      return this.showSearch
    },
    filterTags() {
      if (!this.showSearch) {
        return []
      }
      if (this.filtrationsStore.searchedTagsList.length === 0) {
        return []
      }
      return this.filtrationsStore.searchedTagsList
    },
    selectedTags() {
      if (this.filtrationsStore.selectedTags.length === 0) {
        return []
      }
      return this.filtrationsStore.selectedTags
    },
    showResults() {
      return !!this.search
    },
    isExplore() {
      if (this.$route.fullPath.includes('/explore')) {
        return true
      } else {
        return false
      }
    },
    isForumAndDiscussions() {
      if (this.$route.fullPath.includes('/forum-and-discussion')) {
        return true
      } else {
        return false
      }
    }
  },
  mounted() {
    eventBus.$on('focusTagInput', () => {
      this.onShowSearch()
    })
  },
  unmounted() {
    eventBus.$off('focusTagInput')
  },
  methods: {
    onShowSearch() {
      this.search = ''
      this.showSearch = !this.showSearch
      this.filtrationsStore.clearSearchedTags()
    },
    searchTags(search) {
      this.search = search
      clearTimeout(this.debounce)
      this.filtrationsStore.searchTagsLoading = true
      this.debounce = setTimeout(() => {
        this.refetch(this.search)
      }, 500)
    },
    refetch(search) {
      if (search !== '') {
        this.filtrationsStore.searchTags({ search })
      } else {
        this.filtrationsStore.clearSearchedTags()
      }
    },
    async handleClick(tag) {
      if (tag.type === 'company') {
        this.filtrationsStore.resetScreenerFilter()
        this.goToCompany(tag.name)
      } else if (tag.type === 'thread') {
        this.filtrationsStore.addForumTag(tag.label, tag.type)
        this.onShowSearch()
        if (!this.isForumAndDiscussions && (!this.isExplore || this.selectedTags.length <= 1)) {
          this.$router.replace({
            name: 'threadResults'
          })
        }
      } else if (tag.type === 'stock_user' || tag.type === 'user') {
        this.filtrationsStore.resetScreenerFilter()
        this.onShowSearch()
        this.$router.push({ name: 'userFlow', params: { id: Number(tag.code) } })
      }  else if (tag.type === 'room') {
        this.filtrationsStore.addForumTag(tag.label, tag.type)
        this.onShowSearch()
        if (!this.isForumAndDiscussions && (!this.isExplore || this.selectedTags.length <= 1)) {
          this.$router.replace({
            name: 'roomResults'
          })
        }
      } else {
        await this.filtrationsStore.setScreenerFilterFromTag(tag)
        this.filtrationsStore.addTag(tag.label)
        this.onShowSearch()
        if (!this.isExplore && !this.isForumAndDiscussions) {
          this.$router.replace({
            name: 'exploreResults'
          })
        }
      }
      if (this.source === 'mobile') this.$emit('toggle')
    },
    goToCompany(slugUrl) {
      this.onShowSearch()
      this.$router.push({ name: 'companyOverview', params: { id: slugUrl } })
    },
    handleEnterForCustomTag() {
      if (this.search !== '') {
        this.filtrationsStore.addCustomTag(`#${this.search}`)
        this.onShowSearch()
        if (!this.isExplore && !this.isForumAndDiscussions) {
          this.$router.replace({
            name: 'exploreResults'
          })
        }
      }
    }
  }
}
</script>
