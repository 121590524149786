import { get, post } from '../helpers/axiosHelper'

const url = `${process.env.VUE_APP_API_URL}/pinpoint`

const fetchCompanyEstimateData = async (payload) => get(`${url}/estimate/get/${payload.id}/${payload.period}`)
const updateCompanyEstimateData = async (payload) => post(`${url}/estimate/update/${payload.id}`, payload.data)
const fetchUserEstimateData = async(id) => get(`${url}/list/user/${id}`)

export default {
  fetchCompanyEstimateData,
  updateCompanyEstimateData,
  fetchUserEstimateData
}
