import { RepositoryFactory } from '@/api/repositoryFactory'
import i18n from '@/js/language/'
const repository = RepositoryFactory.get('companies')
const repositoryFiltrations = RepositoryFactory.get('filtrations')
import { defineStore } from 'pinia'
import { useSnackbarStore } from './snackbar'
import { useUsersStore } from './users'
import { useFinancialsStore } from './financials'
import { useComparablesStore } from './comparables'
import { useScreenersStore } from './screeners'
import { useFiltrationsStore } from './filtrations'
import { useJobsStore } from './jobs'
// import { useRoute } from 'vue-router'

export const useCompaniesStore = defineStore('companies', {
  state: () => ({
    incidentReporting: false,
    selectedCompanyId: null,
    selectedCompanySN: null,
    scrollLoading: false,
    companies: [],
    businesDetails:[],
    businessGrowthDetails: [],
    businessCSRDetails: [],
    businessRndDetails: [],
    businessAnnualReportDetails:[],
    instruments: [],
    fetchedGallery:[],
    fetchedCSRGallery:[],
    fetchedAnnualReportGallery:[],
    fetchedRndGallery:[],
    trendingCompanies: [],
    mostDevelopedCompanies: [],
    runningQueue: {},
    instrumentsTotal: 0,
    totalNumberOfCompanies: 2008, // default in case api struggle
    maxValuation: 0,
    sectors: [],
    instrumentsNextPageUrl: null,
    certifiedCompanies: [],
    ipoCompanies: [],
    privateCompanies: [],
    searchedCompanies: [],
    searchedBrokers: [],
    filteredCompanyList: [],
    originalStatistics: {},
    originalUserRequests: {},
    brokerstats: [],
    boardOfDirectors: [],
    relatedPremiumCompanies: [],
    brokerstatsDate: null,
    brokerStatNextPageUrl: null,
    loading: false,
    loadingCompanies: false,
    loadingTrendingCompanies: false,
    loadingMostDevelopedCompanies: false,
    loadingFinancials: false,
    loadingMarketCap: false,
    newBusinessGrowthDetailLoading:false,
    loadingCompetitors: false,
    loadingBrokerStats: false,
    loadingStatistics: false,
    loadingAttachment: false,
    loadingLogo: false,
    businesDetailsLoading: false,
    businessDetailsCSRLoading: false,
    businessDetailsRndLoading: false,
    businessDetailsAnnualReportLoading: false,
    loadingBanner: false,
    loadingBurnrate: false,
    loadingIPO: false,
    loadingTrial: false,
    loadingPrivate: false,
    loadingCash: false,
    loadingNotification: false,
    loadingDividendHistory: false,
    nextPageUrl: null,
    loadingNew: false,
    companyUsersLoading: false,
    loadingValuation: false,
    loadNewCompanyView: false,
    companyMenuReference: null,
    searchCompaniesDebounce: null,
    originalFinancials: {},
    originalMarketCap: {},
    originalNotificationSettings: {},
    companyMenu: false,
    originalAttachmentDataList: [],
    attachmentNextPageUrl: null,
    companyUsers: [],
    pendingInvitations: [],
    pendingRequests: [],
    originalCompanyDetails: {},
    originalCompanyIPO: {},
    originalCompanyPrivate: {},
    originalMarketGoals: {},
    originalCompetitors: {},
    galleryFilesCsr: [],
    galleryPreviewsCsr: [],
    galleryFilesGrowth: [],
    galleryPreviewsGrowth: [],
    galleryFilesRnd: [],
    galleryPreviewsRnd: [],
    galleryFilesAnnualReport: [],
    galleryPreviewsAnnualReport: [],
    plans: [],
    loadingCoupons: false,
    coupons: [],
    mnemonicsToBeDeleted: [
      "IQ_DILUTED_EPRA_NAV_SHARE_RE",
      "IQ_OPER_INC_RE",
      "IQ_NAV_SHARE_RE",
      "IQ_PROP_MGMT_INCOME",
      "IQ_EBIT_CS",
      "IQ_EBITA",
      "IQ_EBITDA_CSD",
      "IQ_OPER_INC_EST_CIQ",
      "IQ_EBITA_EXCL_SBC",
    ],
    dividendHistory: [],
    topFollowedCompanies: [],
    loadingTopFollowedCompanies: false,
    otherUserTopFollowedCompanies: [],
    loadingOtherUserTopFollowedCompanies: false,
  }),
  getters: {
    attachmentDataList(state) {
      return state.originalAttachmentDataList[state.selectedCompanyId]
    },
    companyStatistics(state) {
      return state.originalStatistics[state.selectedCompanyId]
    },
    financials(state) {
      return state.originalFinancials[state.selectedCompanyId]
    },
    selectedGalleryFilesAnnual(state) {
      if (!state.galleryFilesAnnualReport){
        return []
      }

      return state.galleryFilesAnnualReport
    },
    selectedGalleryPreviewsAnnual(state) {
      if (!state.galleryPreviewsAnnualReport) {
        return []
      }

      return state.galleryPreviewsAnnualReport
    },
    selectedGalleryFilesCsr(state) {
      if (!state.galleryFilesCsr){
        return []
      }

      return state.galleryFilesCsr
    },
    selectedGalleryPreviewsCsr(state) {
      if (!state.galleryPreviewsCsr) {
        return []
      }

      return state.galleryPreviewsCsr
    },
    selectedGalleryFilesRnd(state) {
      if (!state.galleryFilesRnd){
        return []
      }

      return state.galleryFilesRnd
    },
    getBusinessGrowthDetails(state) {
      if (!state.businessGrowthDetails){
        return []
      }

      return state.businessGrowthDetails
    },
    getBusinessCsrDetails(state) {
      if (!state.businessCSRDetails){
        return []
      }

      return state.businessCSRDetails
    },
    getBusinessRndDetails(state) {
      if (!state.businessRndDetails){
        return []
      }

      return state.businessRndDetails
    },
    getBusinessAnnualReports(state) {
      if (!state.businessAnnualReportDetails){
        return []
      }

      return state.businessAnnualReportDetails
    },
    selectedGalleryPreviewsRnd(state) {
      if (!state.galleryPreviewsRnd) {
        return []
      }

      return state.galleryPreviewsRnd
    },
    selectedGalleryFilesGrowth(state) {
      if (!state.galleryFilesGrowth){
        return []
      }

      return state.galleryFilesGrowth
    },
    selectedGalleryPreviewsGrowth(state) {
      if (!state.galleryPreviewsGrowth) {
        return []
      }

      return state.galleryPreviewsGrowth
    },
    marketCap(state) {
      return state.originalMarketCap[state.selectedCompanyId]
    },
    companyNotificationSettings(state) {
      return state.originalNotificationSettings[state.selectedCompanyId]
    },
    selectedCompany(state) {
      return state.companies.find((company) => {
        return Number(company.id) === Number(state.selectedCompanyId)
      })
    },
    userRequests(state) {
      return state.originalUserRequests[state.selectedCompanyId]
    },
    competitors(state) {
      return state.originalCompetitors[state.selectedCompanyId]
    },
    companyDetails(state) {
      if (!state.selectedCompanyId) {
        return {}
      }
      
      if (!state.originalCompanyDetails) {
        return {}
      }

      if (!state.originalCompanyDetails[state.selectedCompanyId]) {
        return {}
      }

      const details = { ...state.originalCompanyDetails[state.selectedCompanyId] }

      return details
    },
    companyIPO(state) {
      if (!state.originalCompanyIPO[state.selectedCompanyId]) {
        return []
      }
      return state.originalCompanyIPO[state.selectedCompanyId]
    },
    companyPrivate(state) {
      if (!state.originalCompanyPrivate[state.selectedCompanyId]) {
        return []
      }
      return state.originalCompanyPrivate[state.selectedCompanyId]
    },
    marketGoals(state) {
      return state.originalMarketGoals[state.selectedCompanyId]
    }
  },
  actions: {

    setPrivate(privateData) {
      this.originalCompanyPrivate = {
        ...this.originalCompanyPrivate,
        [this.selectedCompanyId]: { ...privateData }
      }
      this.originalCompanyPrivate[this.selectedCompanyId] = {
        ...this.originalCompanyPrivate[this.selectedCompanyId]
      }
    },
    setMarketGoals(state, goals) {
      this.originalMarketGoals = {
        ...this.originalMarketGoals,
        [this.selectedCompanyId]: [...goals]
      }
    },
    setCoupons(state, payload) {
      this.coupons = payload
    },
    setIpo(ipo) {
      this.originalCompanyIPO = {
        ...this.originalCompanyIPO,
        [this.selectedCompanyId]: { ...ipo }
      }
      this.originalCompanyIPO[this.selectedCompanyId] = {
        ...this.originalCompanyIPO[this.selectedCompanyId]
      }
    },
    setList(payload) {
      if (this.companies.length < 1) {
        this.companies = payload
      } else {
        payload.find((response) => {
          const companyIndex = this.companies.findIndex((company) => {
            return Number(response.id) === Number(company.id)
          })

          if (companyIndex > -1) {
            this.companies[companyIndex] = {...this.companies[companyIndex], ...response }
          }
          else {
            this.companies.push(response)
          }
        })
      }

      if (this.filteredCompanyList.length < 0) {
        Array.prototype.forEach.call(payload, (response) => {
          this.filteredCompanyList.push({
            ...response,
            type: 'company'
          })
        })
      } else {
        payload.find((response) => {
          const temp = this.filteredCompanyList.find((company) => {
            return response.id === company.id
          })
          if (!temp) {
            this.filteredCompanyList.push({
              ...response,
              type: 'company'
            })
          }
        })
      }
    },
    updateList(updatedCompany) {
      const flag = this.companies.findIndex((company) => {
        return Number(updatedCompany.id) === Number(company.id)
      })
      this.companies[flag] = { ...updatedCompany }
      this.companies = [...this.companies]
    },
    
    updateFollowers(payload) {
      if(payload.company_id === this.selectedCompanyId) {
        this.selectedCompany.followers_count = payload.all_followers
        this.selectedCompany.is_follower = payload.is_follower
      }
      let companyIndex = this.companies.findIndex((company) => Number(payload.company_id) === Number(company.id))
      // let company = this.companies[companyIndex]
      if (companyIndex > -1) {
        this.companies[companyIndex].all_followers = payload.all_followers
        this.companies[companyIndex].is_follower = payload.is_follower
        // this.companies[companyIndex] = company
        this.companies = [...this.companies]
      }

      companyIndex = this.searchedCompanies.findIndex((company) => Number(payload.company_id) === Number(company.id))
      // company = this.companies[companyIndex]
      if (companyIndex > -1) {
        this.searchedCompanies[companyIndex].all_followers = payload.all_followers
        this.searchedCompanies[companyIndex].is_follower = payload.is_follower
        // this.companies[companyIndex] = company
        this.searchedCompanies = [...this.searchedCompanies]
      }
    },
    async reportIncident (payload) {

      this.loading = true
      try {
        const response = await repository.reportIncident(payload)
        if (response.status === 201) {
          useSnackbarStore().snack({
            text: i18n.global.t('Form submitted successfully'),
            type: 'success',
            show: true,
            autoclose: true
          })
        }
        this.loading = false
      } catch (error) {
        this.loading = false
        useSnackbarStore().snack({
          type: 'error',
          e: error,
          show: true,
          autoclose: true
        })
      }
    },
    
    async assignRole(companyId) {
      try {
        this.loadingNew = true
        const response = await repository.assignRole(companyId)
        if (response.status === 201) {
          this.loadingNew = false
          return true
        } else if (response.status === 203) {
          useSnackbarStore().snack({
            text: i18n.global.t('Invalid access.'),
            type: 'error',
            show: true,
            autoclose: true
          })
          this.loadingNew = false
          return false
        }
        this.loadingNew = false
      } catch (e) {
        this.loadingNew = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
        return false
      }
    },
    async setDiscountTillDate(payload) {
      try {
        const response = await repository.setDiscountTillDate(payload.companyId, { months: payload.discountMonths })
        if (response.status === 200 && response.data) {
          this.updateList(response.data)
        } else if (response.status === 203) {
          useSnackbarStore().snack({
            text: i18n.global.t('User does not have permission.'),
            type: 'error',
            show: true,
            autoclose: true
          })
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async toggleTrialPeriodOnBoarding(companyId) {
      try {
        const response = await repository.toggleTrialPeriodOnBoarding(companyId)
        if (response.status === 200 && response.data) {
          this.updateList(response.data)
        } else if (response.status === 203) {
          useSnackbarStore().snack({
            text: i18n.global.t('User does not have permission.'),
            type: 'error',
            show: true,
            autoclose: true
          })
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async toggleAgreementRequired(companyId) {
      try {
        const response = await repository.toggleAgreementRequired(companyId)
        if (response.status === 200 && response.data) {
          this.updateList(response.data)
        } else if (response.status === 203) {
          useSnackbarStore().snack({
            text: i18n.global.t('User does not have permission.'),
            type: 'error',
            show: true,
            autoclose: true
          })
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async fetchBoardOfDirectors() {
      this.loading = true
      const response = await repository.fetchBoardOfDirectors(this.selectedCompanySN)
      
      this.loading = false
      
      if (response.status === 200 && response.data) {
        this.boardOfDirectors = [...response.data]
      }
    },
    async fetchCoupons() {
      try {
        useJobsStore().runEndpoint("fetchCoupons", async () => {
          this.loadingCoupons = true
          const response = await repository.fetchCoupons()
          if (response.status === 200 && response.data) {
            this.setCoupons(response.data)
          }
          this.loadingCoupons = false
        })
      } catch (e) {
        this.loadingCoupons = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async addCouponToSubscription(payload) {
      try {
        this.loadingCoupons = true
        const response = await repository.addCouponToSubscription(payload.subscriptionId, {
          coupon_id: payload.couponId
        })
        if (response.status === 200 && response.data) {
          // this.setCoupons(response.data)
        }
        this.loadingCoupons = false
      } catch (e) {
        this.loadingCoupons = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async getTotalNumberOfCompanies() {
      useJobsStore().runEndpoint("getTotalNumberOfCompanies", async () => {
        const response = await repository.totalNumberOfCompanies()
        if (response.status === 200 && response.data) {
          this.totalNumberOfCompanies = Number(response.data)
        }
      }, 1000 * 60 * 10)
    },
    async sendInvitation(payload) {
      try {
        const response = await repository.sendInvitation(payload.companyId, {
          email: payload.email
        })
        if (response.status === 200 && response.data) {
          this.pendingInvitations = response.data
          useSnackbarStore().snack({
            type: 'success',
            text: 'Invitation sent successfully!',
            show: true,
            autoclose: true
          })
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async deleteInvitation(payload) {
      try {
        const response = await repository.deleteInvitation(payload.companyId, payload.id)
        if (response.status === 200 && response.data) {
          this.pendingInvitations = response.data
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async fetchInvitations(companyId) {
      try {
        const response = await repository.fetchInvitations(companyId)
        if (response.status === 200 && response.data) {
          this.pendingInvitations = response.data
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async fetchRequests(companyId) {
      try {
        const response = await repository.fetchRequests(companyId)
        if (response.status === 200 && response.data) {
          this.pendingRequests = response.data
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async approveRequest(requestId) {
      try {
        const response = await repository.approveRequest(requestId)
        if (response.status === 201) {
          useSnackbarStore().snack({
            type: 'success',
            text: 'Request approved successfully!',
            show: true,
            autoclose: true
          })
          this.fetchRequests(this.selectedCompanySN)
          this.fetchCompanyUsers(this.selectedCompanySN)
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async deleteRequest(requestId) {
      try {
        const response = await repository.deleteRequest(requestId)
        if (response.status === 200) {
          useSnackbarStore().snack({
            type: 'success',
            text: 'Request deleted successfully!',
            show: true,
            autoclose: true
          })
          this.fetchRequests(this.selectedCompanySN)
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async fetchCompanyPlans(companyId) {
      try {
        this.loadingCoupons = true
        const response = await repository.fetchCompanyPlans(companyId)
        if (response.status === 200 && response.data) {
          this.plans = response.data
        }
        this.loadingCoupons = false
      } catch (e) {
        this.loadingCoupons = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    setLoadingCompanies(boolean) {
      this.loadingCompanies = boolean
    },
    clearBrokerStats() {
      this.brokerstats = []
    },
    async fetchCompanyUsers(companySN) {
      try {
        this.companyUsersLoading = true
        useJobsStore().runEndpoint("fetchCompanyUsers" + companySN, async () => {
          const response = await repository.fetchCompanyUsers(companySN)
          this.loadingCompanies = true
          if (response.status === 200 && response.data) {
            this.companyUsers = response.data
          }
          this.loadingCompanies = false
        })
      } catch (e) {
        this.loadingCompanies = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      } finally {
        this.companyUsersLoading = false
      }
    },
    async addUsersToCompany(payload) {
      try {
        this.loadingNew = true
        const response = await repository.addUsersToCompany(payload.company, {
          userList: payload.userList
        })
        if (response.status === 200 && !response.data.invalid) {
          this.companyUsers = response.data
        } else {
          useSnackbarStore().snack({
            text: i18n.global.t('User does not have permission.'),
            type: 'error',
            show: true,
            autoclose: true
          })
        }
        this.loadingNew = false
      } catch (e) {
        this.loadingNew = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async removeUsersRole(payload) {
      try {
        this.loadingNew = true
        const response = await repository.removeUsersRole(payload.companyURL, {
          userId: payload.userId
        })
        if (response.status === 200 && !response.data.invalid) {
          this.companyUsers = response.data
        } else {
          useSnackbarStore().snack({
            text: i18n.global.t('User does not have permission.'),
            type: 'error',
            show: true,
            autoclose: true
          })
        }
        this.loadingNew = false
      } catch (e) {
        this.loadingNew = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async one(selectedCompanySN = null) {
      
      if(selectedCompanySN && this.selectedCompanySN !== selectedCompanySN) {
        this.selectedCompanyId = null
        this.selectCompanySN(selectedCompanySN)
      }

      // console.log("ONE PARAM ID ", useRoute().params.id)

      const hasIndex = this.companies.findIndex((company) => {
        return Number(company.id) === Number(this.selectedCompanyId)
      })

      const override = hasIndex === -1


      this.loadNewCompanyView = true
      useJobsStore().runEndpoint("one" + this.selectedCompanySN, async () => {
      this.loading = true
      const response = await repository.one(this.selectedCompanySN)
        this.loading = false
        if (response.status === 200 && response.data) {
          this.setList([response.data])
          this.selectedCompanyId = response.data.id
        }
      },10000, override)
      //nextTick
      setTimeout(() => {
      this.loadNewCompanyView = false
      },300)

    },
    setLoadingAttachment(boolean) {
      this.loadingAttachment = boolean
    },
    async companyDataList(payload) {
      this.loadingAttachment = true
      const response = await repository.companyDataList(this.selectedCompanySN, payload)
      this.loadingAttachment = false
      if (response.status === 200 && response.data) {
        this.originalAttachmentDataList = {
          ...this.originalAttachmentDataList,
          [this.selectedCompanyId]: response.data
        }
      }
    },
    async statistics() {
      this.loadingStatistics = true
      const response = await repository.statistics(this.selectedCompanySN)
      this.loadingStatistics = false

      if (response.status === 200 && response.data) {
        this.originalStatistics = {
          ...this.originalStatistics,
          [this.selectedCompanyId]: response.data
        }
      }
    },
    async fetchUserRequests() {
      this.loading = true
      const response = await repository.userRequestList(this.selectedCompanySN)
      this.loading = false
      if (response.status === 200 && response.data) {
        this.originalUserRequests = {
          ...this.originalUserRequests,
          [this.selectedCompanyId]: response.data.data
        }
      }
    },
    async fetchFollowedCompanies() {
      this.loading = true
      try {
        useJobsStore().runEndpoint("fetchFollowedCompanies", async () => {
          const response = await repository.fetchFollowedCompanyList(useUsersStore().user.profile_id)
          this.loading = false
          if (response.status === 200 && response.data) {
            this.setList(response.data.data)
            this.nextPageUrl = response.data.next_page_url
          }
        }, 1000 * 60 * 5)
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async getTrendingCompanies() {

      const override = !this.trendingCompanies

      useJobsStore().runEndpoint("getTrendingCompanies", async () => {
        this.loadingTrendingCompanies = true
        try {
          const response = await repository.getTrendingCompanies()
          this.loadingTrendingCompanies = false
          if (response.status === 200 && response.data) {
            this.trendingCompanies = { ...response.data }
          }
        } catch (e) {
          useSnackbarStore().snack({
            type: 'error',
            e: e,
            show: true,
            autoclose: true
          })
        }
      }, 1000 * 60 * 5, override)
    },
    async getMostDevelopedCompanies() {
      this.loadingMostDevelopedCompanies = true
      try {
        const response = await repository.getMostDevelopedCompanies()
        this.loadingMostDevelopedCompanies = false
        if (response.status === 200 && response.data) {
          this.mostDevelopedCompanies = [...response.data]
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async fetchAllBroker(payload) {
      this.loadingCompanies = true
      const response = await repository.brokerList(payload)
      this.loadingCompanies = false
      if (response.status === 200 && response.data) {
        this.searchedBrokers = [...response.data]
      }
    },
    async fetchCertifiedCompanies() {
      this.loading = true
      try {
        const response = await repository.fetchCertifiedCompanyList()
        this.loading = false
        if (response.status === 200 && response.data) {
          this.certifiedCompanies = response.data.data
          this.nextPageUrl = response.data.next_page_url
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async fetchIPOCompanies() {
      this.loading = true
      try {
        const response = await repository.fetchIPOCompanyList()
        this.loading = false
        if (response.status === 200 && response.data) {
          this.ipoCompanies = response.data.data
          this.nextPageUrl = response.data.next_page_url
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async fetchPrivateCompanies() {
      this.loading = true
      try {
        const response = await repository.fetchPrivateCompanyList()
        this.loading = false
        if (response.status === 200 && response.data) {
          this.privateCompanies = response.data.data
          this.nextPageUrl = response.data.next_page_url
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async scrollAttachments(filter) {
      try {
        if (this.attachmentNextPageUrl !== null && !this.loadingAttachment && !this.loadingNew) {
          this.loadingNew = true
          const response = await repository.scroll(this.attachmentNextPageUrl, filter)
          if (response.status === 200 && response.data) {
            const payload = response.data.data
            payload.find((response) => {
              const flag = this.originalAttachmentDataList[this.selectedCompanyId].find((data) => {
                return Number(response.id) === Number(data.id)
              })
              if (!flag) {
                this.originalAttachmentDataList[this.selectedCompanyId].push(response)
              }
            })
            this.originalAttachmentDataList = {
              ...this.originalAttachmentDataList
            }
            this.attachmentNextPageUrl = response.data.next_page_url
          }
          this.loadingNew = false
        }
      } catch (e) {
        this.loadingNew = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async scrollBrokerStats(payload) {
      try {
        if (this.brokerStatNextPageUrl !== null) {
          this.scrollLoading = true
          const response = await repository.scroll(this.brokerStatNextPageUrl, payload)
          if (response.status === 200 && response.data) {
            payload = response.data.data

            if (this.brokerstats.length === 0) {
              this.brokerstats = payload
            } else {
              payload.find((response) => {
                const flag = this.brokerstats.find((broker) => {
                  return Number(response.insref) === Number(broker.insref)
                })
                if (!flag) {
                  this.brokerstats.push(response)
                }
              })
            }

            this.brokerStatNextPageUrl = response.data.next_page_url
          }
          this.scrollLoading = false
        }
      } catch (e) {
        this.scrollLoading = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async scroll() {
      try {
        if (this.nextPageUrl !== null && !this.loadingNew) {
          this.loadingNew = true
          const response = await repository.scroll(this.nextPageUrl)
          if (response.status === 200 && response.data) {
            this.setList(response.data.data)
            this.nextPageUrl = response.data.next_page_url
          }
          this.loadingNew = false
        }
      } catch (e) {
        this.loadingNew = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async fetchSectorAndValuation() {
      try {
        this.loadingValuation = true
        const response = await repository.fetchSectorAndValuation()
        this.loadingValuation = false
        if (response.status === 200 && response.data) {
          this.sectors = response.data.sectors
          this.maxValuation = Math.ceil(Number(response.data.max_valuation) / 100) * 100
        }
      } catch (e) {
        this.loadingNew = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async fetchAll(payload) {
      this.loadingCompanies = true

      const override = this.searchedCompanies.length === 0 || this.companies.length === 0

      useJobsStore().runEndpoint("fetchAll" + JSON.stringify(payload), async () => {
        const response = await repository.list(payload)
        this.loadingCompanies = false
        if (response.status === 200 && response.data) {
          // if (!payload.bubbleSearch) {
          this.searchedCompanies = [...response.data.data]
          this.setList(response.data.data)
          // } else {
          // commit('SET_FILTERED_LIST', response.data.data)
          // }
        }
      }, 1000 * 60 * 5, override)
    },
    async fetchUsersCompanies(payload) {
      this.loadingCompanies = true
      const response = await repository.fetchUsersCompanies(payload)
      this.loadingCompanies = false
      if (response.status === 200 && response.data) {
        this.searchedCompanies = [...response.data.data]
        this.setList(response.data.data)
      }
    },
    async fetchInUniverseCompanies(payload) {
      this.loadingCompanies = true
      const response = await repository.fetchInUniverseCompanies(payload)
      this.loadingCompanies = false
      if (response.status === 200 && response.data) {
        this.searchedCompanies = [...response.data.data]
        this.setList(response.data.data)
      }
    },
    async scrollInstruments() {
      try {
        if (this.instrumentsNextPageUrl !== null && !this.loadingNew) {
          this.loadingNew = true
          let payload = {
            filter: useFiltrationsStore().filtrationPayloadExplore,
            resultType: "companies",
            resultSubType: "companies",
            resultLimit: 35
          }

          if (useScreenersStore().searchCompaniesSortable !== 'default') {
            payload = {
              ...payload,
              resultOrderType: {
                type: useScreenersStore().searchCompaniesSortable,
                order: useScreenersStore().searchCompaniesOrder
              }
            }
          }

          const response = await repositoryFiltrations.scroll(this.instrumentsNextPageUrl, payload)
          if (response.status === 200 && response.data) {
            let payload = response.data.data

            if (this.instruments.length < 1) {
              this.instruments = payload
            } else {
              payload.find((response) => {
                const flag = this.instruments.find((company) => {
                  return Number(response.id) === Number(company.id)
                })
                if (!flag) {
                  this.instruments.push(response)
                }
              })
            }

            // this.instrumentsTotal = response.data.total
            this.instrumentsNextPageUrl = response.data.next_page_url
          }
          this.loadingNew = false
        }
      } catch (e) {
        this.loadingNew = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async mapCompanies() {
      try{
        const payload = {
          filter: useFiltrationsStore().filtrationPayloadExplore,
          resultType: "companies",
          resultSubType: "companyMap",
          resultLimit: 500
        }
        
        // useJobsStore().runEndpoint("mapCompanies" + JSON.stringify(payload), async () => {
          this.loadingCompanies = true
          const response = await repositoryFiltrations.getResult(payload)
          this.loadingCompanies = false
          if (response.status === 200 && response.data) {
            this.instruments = response.data
            // this.instrumentsTotal = response.data.total
            // this.instrumentsNextPageUrl = response.data.next_page_url
          }
        // }, 1000 * 60 * 5)
      } catch (e) {

        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      } finally {
        this.loadingCompanies = false
      }
    },
    async searchCompanies() {
      try {
        this.loadingCompanies = true
        // RENAME instrument stuff
        // const payload = {
        //   filter: useScreenersStore().slimSelectedScreenerFilters,
        //   searchCompaniesPhrase: useScreenersStore().searchCompaniesPhrase,
        //   currency: useScreenersStore().selectedCurrency,
        //   orderBy: useScreenersStore().searchCompaniesSortable,
        //   order: useScreenersStore().searchCompaniesOrder,
        //   inUniverse: useScreenersStore().inUniverse,
        //   relevant: useScreenersStore().relevant
        // }

        let payload = {
          filter: useFiltrationsStore().filtrationPayloadExplore,
          resultType: "companies",
          resultSubType: "companies",
          resultLimit: 35
        }

        if (useScreenersStore().searchCompaniesSortable !== 'default') {
          payload = {
            ...payload,
            resultOrderType: {
              type: useScreenersStore().searchCompaniesSortable,
              order: useScreenersStore().searchCompaniesOrder
            }
          }
        }

        const response = await repositoryFiltrations.getResult(payload)
        this.loadingCompanies = false
        if (response.status === 200 && response.data) {
          this.instruments = response.data.data
          // this.instrumentsTotal = response.data.total
          this.instrumentsNextPageUrl = response.data.next_page_url
        }
      } catch (e) {
        this.loadingNew = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async fetchSelectedCompanies() {
      try {
        this.loadingCompanies = true
        // RENAME instrument stuff
        const payload = {
          filter: useScreenersStore().slimSelectedScreenerFilters,
          activeIds: useComparablesStore().comparables,
          currency: useScreenersStore().selectedCurrency,
          orderBy: useScreenersStore().searchCompaniesSortable,
          order: useScreenersStore().searchCompaniesOrder
        }

        const response = await repository.instrumentsList(payload)
        this.loadingCompanies = false
        if (response.status === 200 && response.data) {
          this.instruments = response.data.result.data
          this.instrumentsTotal = response.data.total
          this.instrumentsNextPageUrl = response.data.result.next_page_url
        }
      } catch (e) {
        this.loadingNew = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    // OBSOLETE, replaces by searchCompanies
    async fetchAllInstruments(payload) {
      try {
        this.loadingCompanies = true
        const response = await repository.instrumentsList(payload)
        this.loadingCompanies = false
        if (response.status === 200 && response.data) {
          this.instruments = response.data.data
          this.instrumentsNextPageUrl = response.data.next_page_url
        }
      } catch (e) {
        this.loadingNew = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    selectCompany(companyId) {
      this.selectedCompanyId = companyId
    },
    selectCompanySN(companySN) {
      this.selectedCompanySN = companySN
    },
    setCompanyMenuReference(reference) {
      this.companyMenuReference = reference
    },
    async follow(companyId = null) {
      try {
        const selectedCompanyId = companyId || this.selectedCompanyId

        const response = await repository.follow(selectedCompanyId)

        if (response.status === 200) {
          this.updateFollowers(response.data)
           
          await useUsersStore().fetchProfile()
          return response.data
          // useSnackbarStore().snack({ text: 'Company added to followed list', type: 'success', show: true, autoclose: true })
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async followMultiple(companyIds = null) {
      try {
        const response = await repository.followMultiple({
          companies: companyIds
        })

        if (response.status === 200) {
          this.updateFollowers(response.data)
          useUsersStore().fetchProfile()
          // useSnackbarStore().snack({ text: 'Company added to followed list', type: 'success', show: true, autoclose: true })
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async rejectContact(payload) {
      try {
        const companyUserRequests = this.originalUserRequests[this.selectedCompanyId]
        const findIndex = companyUserRequests.findIndex((request) => request.deletable.contact_id === payload.id)
        companyUserRequests[findIndex].delete_request_status.push({
          status: 'reject',
          stock_user: useUsersStore().user.profile,
          stock_user_id: useUsersStore().user.profile_id
        })
        const response = repository.rejectContact(this.selectedCompanyId, payload.requestId, payload.id)
        if (response.status === 204) {
          // console.log('Delete request rejected')
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async rejectTrigger(payload) {
      try {
        const companyUserRequests = this.originalUserRequests[this.selectedCompanyId]
        const findIndex = companyUserRequests.findIndex(
          (request) => request.deletable.company_trigger_id === payload.id
        )
        companyUserRequests[findIndex].delete_request_status.push({
          status: 'reject',
          stock_user: useUsersStore().user.profile,
          stock_user_id: useUsersStore().user.profile_id
        })
        const response = repository.rejectTrigger(this.selectedCompanyId, payload.requestId, payload.id)
        if (response.status === 204) {
          // console.log('Delete request approved')
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message,
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async rejectMediaDeleteRequest(payload) {
      try {
        const companyUserRequests = this.originalUserRequests[this.selectedCompanyId]
        const findIndex = companyUserRequests.findIndex((request) => request.deletable.company_media_id === payload.id)
        companyUserRequests[findIndex].delete_request_status.push({
          status: 'reject',
          stock_user: useUsersStore().user.profile,
          stock_user_id: useUsersStore().user.profile_id
        })
        const response = repository.rejectDeleteMedia(this.selectedCompanyId, payload.requestId, payload.id)
        if (response.status === 204) {
          console.log('Delete request rejected successfully')
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message,
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async approveMediaDeleteRequest(payload) {
      try {
        const companyUserRequests = this.originalUserRequests[this.selectedCompanyId]
        const findIndex = companyUserRequests.findIndex((request) => request.deletable.company_media_id === payload.id)
        companyUserRequests[findIndex].delete_request_status.push({
          status: 'approve',
          stock_user: useUsersStore().user.profile,
          stock_user_id: useUsersStore().user.profile_id
        })
        const response = repository.approveDeleteMedia(this.selectedCompanyId, payload.requestId, payload.id)
        if (response.status === 204) {
          console.log('Delete request approved')
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async approveTrigger(payload) {
      try {
        const companyUserRequests = this.originalUserRequests[this.selectedCompanyId]
        const findIndex = companyUserRequests.findIndex(
          (request) => request.deletable.company_trigger_id === payload.id
        )
        companyUserRequests[findIndex].delete_request_status.push({
          status: 'approve',
          stock_user: useUsersStore().user.profile,
          stock_user_id: useUsersStore().user.profile_id
        })
        const response = repository.approveTrigger(this.selectedCompanyId, payload.requestId, payload.id)
        if (response.status === 204) {
          console.log('Delete request approved')
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async approveContact(payload) {
      try {
        const companyUserRequests = this.originalUserRequests[this.selectedCompanyId]
        const findIndex = companyUserRequests.findIndex((request) => request.deletable.contact_id === payload.id)
        companyUserRequests[findIndex].delete_request_status.push({
          status: 'approve',
          stock_user: useUsersStore().user.profile,
          stock_user_id: useUsersStore().user.profile_id
        })
        const response = repository.approveContact(this.selectedCompanyId, payload.requestId, payload.id)
        if (response.status === 204) {
          console.log('Delete request approved')
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async rejectAsset(payload) {
      try {
        const companyUserRequests = this.originalUserRequests[this.selectedCompanyId]
        const findIndex = companyUserRequests.findIndex((request) => request.deletable.company_asset_id === payload.id)
        companyUserRequests[findIndex].delete_request_status.push({
          status: 'reject',
          stock_user: useUsersStore().user.profile,
          stock_user_id: useUsersStore().user.profile_id
        })
        const response = repository.rejectAsset(this.selectedCompanyId, payload.requestId, payload.id)
        if (response.status === 204) {
          console.log('Delete request approved')
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async approveAsset(payload) {
      try {
        const companyUserRequests = this.originalUserRequests[this.selectedCompanyId]
        const findIndex = companyUserRequests.findIndex((request) => request.deletable.company_asset_id === payload.id)
        companyUserRequests[findIndex].delete_request_status.push({
          status: 'approve',
          stock_user: useUsersStore().user.profile,
          stock_user_id: useUsersStore().user.profile_id
        })
        const response = repository.approveAsset(this.selectedCompanyId, payload.requestId, payload.id)
        if (response.status === 204) {
          console.log('Delete request approved')
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async rejectBoardMember(payload) {
      try {
        const companyUserRequests = this.originalUserRequests[this.selectedCompanyId]
        const findIndex = companyUserRequests.findIndex((request) => request.deletable.board_member_id === payload.id)
        companyUserRequests[findIndex].delete_request_status.push({
          status: 'reject',
          stock_user: useUsersStore().user.profile,
          stock_user_id: useUsersStore().user.profile_id
        })
        const response = repository.rejectBoardMember(this.selectedCompanyId, payload.requestId, payload.id)
        if (response.status === 204) {
          console.log('Delete request approved')
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async approveBoardMember(payload) {
      try {
        const companyUserRequests = this.originalUserRequests[this.selectedCompanyId]
        const findIndex = companyUserRequests.findIndex((request) => request.deletable.board_member_id === payload.id)
        companyUserRequests[findIndex].delete_request_status.push({
          status: 'approve',
          stock_user: useUsersStore().user.profile,
          stock_user_id: useUsersStore().user.profile_id
        })
        const response = repository.approveBoardMember(this.selectedCompanyId, payload.requestId, payload.id)
        if (response.status === 204) {
          console.log('Delete request approved')
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async rejectOwner(payload) {
      try {
        const companyUserRequests = this.originalUserRequests[this.selectedCompanyId]
        const findIndex = companyUserRequests.findIndex((request) => request.deletable.owner_id === payload.id)
        companyUserRequests[findIndex].delete_request_status.push({
          status: 'reject',
          stock_user: useUsersStore().user.profile,
          stock_user_id: useUsersStore().user.profile_id
        })
        const response = repository.rejectOwner(this.selectedCompanyId, payload.requestId, payload.id)
        if (response.status === 204) {
          console.log('Delete request approved')
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async approveOwner(payload) {
      try {
        const companyUserRequests = this.originalUserRequests[this.selectedCompanyId]
        const findIndex = companyUserRequests.findIndex((request) => request.deletable.owner_id === payload.id)
        companyUserRequests[findIndex].delete_request_status.push({
          status: 'approve',
          stock_user: useUsersStore().user.profile,
          stock_user_id: useUsersStore().user.profile_id
        })
        const response = repository.approveOwner(this.selectedCompanyId, payload.requestId, payload.id)
        if (response.status === 204) {
          console.log('Delete request approved')
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async fetchLinkables(payload) {
      try {
        this.loadingAttachment = true
        const response = await repository.fetchLinkables(payload)
        this.loadingAttachment = false
        if (response.status === 200) {
          this.originalAttachmentDataList = {
            ...this.originalAttachmentDataList,
            [this.selectedCompanyId]: response.data.data
          }
          this.attachmentNextPageUrl = response.data.next_page_url
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message[0],
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async addCompanyDetails(payload) {
      try {
        this.loading = true

        const response = await repository.addCompanyDetails(this.selectedCompanyId, payload)
        if (response.status === 200) {
          this.originalCompanyDetails = {
            ...this.originalCompanyDetails,
            [this.selectedCompanyId]: { ...response.data }
          }
          this.originalCompanyDetails[this.selectedCompanyId] = {
            ...this.originalCompanyDetails[this.selectedCompanyId]
          }

          this.loading = false
          useSnackbarStore().snack({
            text: 'Company Details added successfully',
            type: 'success',
            show: true,
            autoclose: true
          })
          return response
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message[0],
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async notificationSettings() {
      this.loadingNotification = true
      const response = await repository.notificationSettings(this.selectedCompanyId)
      this.loadingNotification = false
      if (response.status === 200 && response.data) {
        this.originalNotificationSettings = {
          ...this.originalNotificationSettings,
          [this.selectedCompanyId]: response.data
        }
      }
    },
    async updateNotificationSettings(key) {
      this.loadingNotification = true
      const response = await repository.updateNotificationSettings(this.selectedCompanyId, { key })
      this.loadingNotification = false
      if (response.status === 200) {
        this.originalNotificationSettings = {
          ...this.originalNotificationSettings,
          [this.selectedCompanyId]: response.data
        }
      }
    },
    async fetchCompanyDetails() {

      if(this.selectedCompanyId) {
        useJobsStore().runEndpoint("fetchCompanyDetails" + this.selectedCompanySN, async () => {
          // this.loading = true
          const response = await repository.fetchCompanyDetails(this.selectedCompanySN)
          // this.loading = false
          if (response.status === 200 && response.data) {
            this.originalCompanyDetails = {
              ...this.originalCompanyDetails,
              [this.selectedCompanyId]: { ...response.data }
            }
            this.originalCompanyDetails[this.selectedCompanyId] = {
              ...this.originalCompanyDetails[this.selectedCompanyId]
            }
          }
        }, 1000 * 60, true)
      }
    },
    async addCompanyMarketGoals(payload) {
      try {
        this.loading = true
        const response = await repository.addCompanyMarketGoals(this.selectedCompanyId, {
          goals: JSON.stringify(payload.options)
        })
        if (response.status === 200) {
          this.loading = false
          this.setMarketGoals(response.data)

          useSnackbarStore().snack({
            text: 'Market Goals added successfully',
            type: 'success',
            show: true,
            autoclose: true
          })
        } else {
          console.log('Market Goals is not added.')
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message[0],
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async fetchCompanyMarketGoals() {
      this.loading = true
      const response = await repository.fetchCompanyMarketGoals(this.selectedCompanySN)
      this.loading = false
      if (response.status === 200 && response.data) {
        this.setMarketGoals(response.data)
      }
    },
    async addCompanyCompetitors(payload) {
      try {
        this.loading = true
        const response = await repository.addCompanyCompetitors(this.selectedCompanyId, payload.company_id, {
          link: null,
          title: null
        })
        if (response.status === 200) {
          this.loading = false
          this.fetchCompanyCompetitors()
          useSnackbarStore().snack({
            text: 'Competitors added successfully',
            type: 'success',
            show: true,
            autoclose: true
          })
        } else {
          console.log('Competitors is not added.')
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message[0],
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async addUnknownCompanyCompetitors(payload) {
      try {
        this.loading = true
        const response = await repository.addUnknownCompanyCompetitors(this.selectedCompanyId, payload)
        if (response.status === 200) {
          this.loading = false
          this.fetchCompanyCompetitors()
          useSnackbarStore().snack({
            text: 'Competitors added successfully',
            type: 'success',
            show: true,
            autoclose: true
          })
        } else {
          console.log('Competitors is not added.')
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message[0],
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async fetchCompanyCompetitors() {
      this.loadingCompetitors = true
      const response = await repository.fetchCompanyCompetitors(this.selectedCompanySN)
      this.loadingCompetitors = false
      if (response.status === 200 && response.data) {
        this.originalCompetitors = {
          ...this.originalCompetitors,
          [this.selectedCompanyId]: [...response.data]
        }
      }
    },
    async getBrokerStats(payload) {
      this.loadingBrokerStats = true
      try {
        const response = await repository.getBrokerStats(payload.companySURL, payload)
        this.loadingBrokerStats = false
        if (response.status === 200) {
          this.brokerstats = response.data
        }
      } catch (e) {
        this.loadingBrokerStats = false
        useSnackbarStore().snack({
          text: e.response.data.message[0],
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async getBrokerStatsByBroker(payload) {
      this.brokerStatNextPageUrl = null
      this.loadingBrokerStats = true
      try {
        const response = await repository.getBrokerStatsByBroker(payload)
        this.loadingBrokerStats = false
        if (response.status === 200) {
          this.brokerstats = response.data.data
          this.brokerStatNextPageUrl = response.data.next_page_url
        }
      } catch (e) {
        this.loadingBrokerStats = false
        useSnackbarStore().snack({
          text: e.response.data.message[0],
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async saveBurnrate(payload) {
      this.loadingBurnrate = true
      try {
        const response = await repository.saveBurnrate(this.selectedCompanyId, payload)
        this.loadingBurnrate = false
        if (response.status === 201) {
          let burnrates = this.originalStatistics[this.selectedCompanyId].burnrates
          burnrates.unshift(response.data)
          this.originalStatistics = { ...this.originalStatistics }
        }
      } catch (e) {
        this.loadingBurnrate = false
        useSnackbarStore().snack({
          text: e.response.data.message[0],
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async saveCash(payload) {
      this.loadingCash = true
      try {
        const response = await repository.saveCash(this.selectedCompanyId, payload)
        this.loadingCash = false
        if (response.status === 201) {
          let cash = this.originalStatistics[this.selectedCompanyId].cash
          cash.unshift(response.data)
          this.originalStatistics = { ...this.originalStatistics }
        }
      } catch (e) {
        this.loadingCash = false
        useSnackbarStore().snack({
          text: e.response.data.message[0],
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    resetBrokerStats() {
      this.brokerstats = []
    },
    async fetchPrivate() {
      this.loadingPrivate = true
      try {
        const response = await repository.fetchPrivate(this.selectedCompanySN)
        this.loadingPrivate = false
        if (response.status === 200) {
          this.setPrivate(response.data)
        }
      } catch (e) {
        this.loadingPrivate = false
        useSnackbarStore().snack({
          text: e.response.data.message[0],
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    tempSavePrivate(payload) {
      this.setPrivate({ ...this.originalCompanyPrivate, ...payload })
    },
    async savePrivate() {
      this.loadingPrivate = true
      try {
        const response = await repository.savePrivate(this.selectedCompanySN, this.originalCompanyPrivate)
        this.loadingPrivate = false
        if (response.status === 200) {
          this.setPrivate(response.data)
        }
      } catch (e) {
        this.loadingPrivate = false
        useSnackbarStore().snack({
          text: e.response.data.message[0],
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async fetchIPO() {
      this.loadingIPO = true
      try {
        const response = await repository.fetchIPO(this.selectedCompanySN)
        this.loadingIPO = false
        if (response.status === 200) {
          this.setIpo(response.data)
        }
      } catch (e) {
        this.loadingIPO = false
        useSnackbarStore().snack({
          text: e.response.data.message[0],
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async fetchDividendHistory() {
      useJobsStore().runEndpoint('fetchDividendHistory' + this.selectedCompanySN, async () => {
        this.loadingDividendHistory = true

        if(!this.selectedCompanySN) {
          return
        }

        try {
          // console.log('SN', this.selectedCompanySN)
          const response = await repository.fetchDividendHistory(this.selectedCompanySN)
          this.loadingDividendHistory = false
          if (response.status === 200 && response.data && response.data.length > 0) {
            this.dividendHistory = response.data
          }
        } catch (e) {
          this.loadingDividendHistory = false
          useSnackbarStore().snack({
            text: e.response.data.message[0],
            type: 'error',
            show: true,
            autoclose: true
          })
        }
      }, 1000 * 60 * 10)
    },
    async toggleTrialPeriod() {
      this.loadingTrial = true
      try {
        const response = await repository.toggleTrialPeriod(this.selectedCompanySN)
        this.loadingTrial = false
        if (response.status === 200) {
          const payload = response.data
          let companyIndex = this.companies.findIndex((company) => Number(payload.id) === Number(company.id))
          // let company = this.companies[companyIndex]
          if (companyIndex > -1) {
            this.companies[companyIndex].trial_date_to = payload.trial_date_to
            this.companies[companyIndex].has_trial_account = payload.has_trial_account
            // this.companies[companyIndex] = company
            this.companies = [...this.companies]
          }
        }
      } catch (e) {
        this.loadingIPO = false
        useSnackbarStore().snack({
          text: e.response.data.message[0],
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    tempSaveIPO(payload) {
      this.setIpo({ ...this.originalCompanyIPO, ...payload })
    },
    async saveIPO() {
      this.loadingIPO = true
      try {
        const response = await repository.saveIPO(this.selectedCompanySN, this.originalCompanyIPO)
        this.loadingIPO = false
        if (response.status === 200) {
          this.setIpo(response.data)
        }
      } catch (e) {
        this.loadingIPO = false
        useSnackbarStore().snack({
          text: e.response.data.message[0],
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async saveSocialTags(socialTags) {
      try {
        const tags = socialTags.join(',')
        const response = await repository.saveSocialTags(this.selectedCompanySN, { tags })
        if (response.status === 200) {
          this.updateList(response.data)
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message[0],
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async updateContactInfo(updateContactInfo) {
      try {
        const response = await repository.updateContactInfo(this.selectedCompanySN, updateContactInfo)
        if (response.status === 200) {
          this.updateList(response.data)
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message[0],
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async updateLocations(updateLocations) {
      try {
        const response = await repository.updateLocations(this.selectedCompanySN, { locations: updateLocations })
        if (response.status === 200) {
          this.updateList(response.data)
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message[0],
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async deleteLocation(deleteLocation) {
      try {
        const response = await repository.deleteLocation(this.selectedCompanySN, deleteLocation.id)
        if (response.status === 200) {
          this.updateList(response.data)
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message[0],
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async toggleCompanyStrategy(payload) {
      try {
        const response = await repository.toggleCompanyStrategy(this.selectedCompanySN, payload.strategyId)
        if (response.status === 200) {
          this.updateList(response.data)
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message[0],
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async updateCompanySocial(payload) {
      try {
        const response = await repository.updateCompanySocial(this.selectedCompanySN, payload)
        if (response.status === 200) {
          this.updateList(response.data)
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message[0],
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async deleteCompanySocial(id) {
      try {
        const response = await repository.deleteCompanySocial(this.selectedCompanySN, id)
        if (response.status === 200) {
          this.updateList(response.data)
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message[0],
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async updateDocumentListFile(document) {
      try {
        const response = await repository.updateDocumentListFile(this.selectedCompanySN, document)
        if (response.status === 200) {
          this.updateList(response.data)
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message[0],
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async deleteDocumentListFile(document) {
      try {
        const response = await repository.deleteDocumentListFile(this.selectedCompanySN, document.id)
        if (response.status === 200) {
          this.updateList(response.data)
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message[0],
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async newBusinessDetail(newBusinessDetail) {
      try {
        const response = await repository.saveNewBusinessDetail(this.selectedCompanySN, newBusinessDetail)
        if (response.status === 200) {
          this.updateList(response.data)
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message[0],
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async newBusinessGrowthDetail(newBusinessGrowthDetail) {
      this.businesDetailsLoading = true
      try {
        const response = await repository.saveNewBusinessGrowthDetail(this.selectedCompanySN, newBusinessGrowthDetail)
        if (response.status === 200) {
          this.businessGrowthDetails =[response.data]
        }
        this.businesDetailsLoading = false
      } catch (e) {
        this.businesDetailsLoading = false

        useSnackbarStore().snack({
          text: e.response.data.message[0],
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    removeFiles(key, type) {
      if(type==='growth'){
        this.fetchedGallery.splice(key, 1)
        this.fetchedGallery=[... this.fetchedGallery]
        this.galleryFilesGrowth.splice(key, 1)
        this.galleryFilesGrowth=[... this.galleryFilesGrowth]
      }
      if(type==='csr'){
        this.fetchedCSRGallery.splice(key, 1)
        this.fetchedCSRGallery=[... this.fetchedCSRGallery]
        this.galleryFilesCsr.splice(key, 1)
        this.galleryFilesCsr=[... this.galleryFilesCsr]
      }
      if(type==='rnd'){
        this.fetchedRndGallery.splice(key, 1)
        this.fetchedRndGallery=[... this.fetchedRndGallery]
        this.galleryFilesRnd.splice(key, 1)
        this.galleryFilesRnd=[... this.galleryFilesRnd]
      }
      if(type==='annual'){
        this.fetchedAnnualReportGallery.splice(key, 1)
        this.fetchedAnnualReportGallery=[... this.fetchedAnnualReportGallery]
        this.galleryFilesAnnualReport.splice(key, 1)
        this.galleryFilesAnnualReport=[... this.galleryFilesAnnualReport]
      }
      
    },
    setGallery(data, type) {
      // let files = []
      // let previews = []
      if(type==='rnd'){
        this.galleryFilesRnd.push(data.media)
        this.galleryFilesRnd = [...this.galleryFilesRnd ]

        this.fetchedRndGallery.push(data.preview)
  
        this.fetchedRndGallery = [...this.fetchedRndGallery]
      }
      if(type==='csr'){
        this.galleryFilesCsr.push(data.media)
        this.fetchedCSRGallery.push(data.preview)
  
        this.galleryFilesCsr= [ ...this.galleryFilesCsr ]
        this.fetchedCSRGallery = [...this.fetchedCSRGallery]
      }
      if(type==='growth'){
        this.galleryFilesGrowth.push(data.media)
        this.fetchedGallery.push(data.preview)
  
        this.galleryFilesGrowth= [ ...this.galleryFilesGrowth ]
        this.fetchedGallery = [...this.fetchedGallery]
      }
      if(type==='annual'){
        this.galleryFilesAnnualReport=[]
        this.galleryFilesAnnualReport=[]
        this.galleryFilesAnnualReport.push(data.media)
        this.fetchedAnnualReportGallery.push(data.preview)

        this.galleryFilesAnnualReport = [ ...this.galleryFilesAnnualReport ]
        this.fetchedAnnualReportGallery= [...this.fetchedAnnualReportGallery]
      }
    },
    async newBusinessCSRDetail(newBusinessCSRDetail) {
      this.businessDetailsCSRLoading = true
      try {
        const response = await repository.saveNewBusinessCSRDetail(this.selectedCompanySN, newBusinessCSRDetail)
        if (response.status === 200) {
          this.businessCSRDetails=[response.data]
        }
        this.businessDetailsCSRLoading = false

      } catch (e) {
        this.businessDetailsCSRLoading = false

        useSnackbarStore().snack({
          text: e.response.data.message[0],
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    
    async newBusinessAnnualReportDetail(newBusinessAnnualReportDetail) {
      this.businessDetailsAnnualReportLoading = true

      try {
        const response = await repository.saveNewBusinessAnnualReportDetail(this.selectedCompanySN, newBusinessAnnualReportDetail)
        if (response.status === 200) {
          this.businessAnnualReportDetails =[response.data]
        }
        this.businessDetailsAnnualReportLoading = false

      } catch (e) {
        this.businessDetailsAnnualReportLoading = false

        useSnackbarStore().snack({
          text: e.response.data.message[0],
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    
    async newBusinessRndDetail(newBusinessRndDetail) {
      this.businessDetailsRndLoading = true

      try {
        const response = await repository.saveNewBusinessRndDetail(this.selectedCompanySN, newBusinessRndDetail)
        if (response.status === 200) {
          this.businessRndDetails=[response.data]
        }
        this.businessDetailsRndLoading = false

      } catch (e) {
        this.businessDetailsRndLoading = false

        useSnackbarStore().snack({
          text: e.response.data.message[0],
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async fetchBusinessGrowthDetail() {
      try {
		this.newBusinessGrowthDetailLoading = true
        const response = await repository.fetchBusinessGrowthDetail(this.selectedCompanySN)
        if (response.status === 200 && response.data) {
          this.businessGrowthDetails = response.data
          this.fetchedGallery = response.data[0].gallery.map((item) => {
            return {
              file: item.image,
              type: item.type,
            }
          })
        }
		this.newBusinessGrowthDetailLoading = false
      } catch (e) {
		this.newBusinessGrowthDetailLoading = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async fetchBusinessRndDetail() {
      try {
		this.businessDetailsRndLoading = true
        const response = await repository.fetchBusinessRndDetail(this.selectedCompanySN)
        if (response.status === 200 && response.data) {
          this.businessRndDetails = response.data
          this.fetchedRndGallery = response.data[0].gallery.map((item) => {
            return {
              file: item.image,
              type: item.type,
            }
          })
        }
		this.businessDetailsRndLoading = false
      } catch (e) {
		this.businessDetailsRndLoading = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async fetchBusinessCSRDetail() {
      try {
        this.newBusinessGrowthDetailLoading = true
        const response = await repository.fetchBusinessCSRDetail(this.selectedCompanySN)
        if (response.status === 200 && response.data) {
          this.businessCSRDetails = response.data
          this.fetchedCSRGallery = response.data[0].gallery.map((item) => {
            return {
              file: item.image,
              type: item.type,
            }
          })
       }
      this.newBusinessGrowthDetailLoading = false
        } catch (e) {
      this.newBusinessGrowthDetailLoading = false
          useSnackbarStore().snack({
            type: 'error',
            e: e,
            show: true,
            autoclose: true
          })
        }
    },
    
    async fetchBusinessAnnualReportDetail() {
      try {
        this.businessDetailsAnnualReportLoading = true
        const response = await repository.fetchBusinessAnnualReportDetail(this.selectedCompanySN)
        if (response.status === 200 && response.data) {
          console.log(response.data)
          this.businessAnnualReportDetails = response.data
          this.fetchedAnnualReportGallery = response.data[0].gallery.map((item) => {
            return {
              file: item.image,
              type: item.type,
            }
          })
       }
      this.businessDetailsAnnualReportLoading = false
        } catch (e) {
      this.businessDetailsAnnualReportLoading = false
          useSnackbarStore().snack({
            type: 'error',
            e: e,
            show: true,
            autoclose: true
          })
        }
    },
    async updateBusinessDetail(updateBusinessDetail) {
      try {
        const response = await repository.updateBusinessDetail(
          this.selectedCompanySN,
          updateBusinessDetail.id,
          updateBusinessDetail
        )
        if (response.status === 200) {
          this.businesDetails=[response.data]
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message[0],
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async updateBusinessGrwothDetail(updateBusinessDetail) {
      try {
        this.businesDetailsLoading = true
        const response = await repository.updateBusinessDetailGrowth(
          this.selectedCompanySN,
          updateBusinessDetail.id,
          updateBusinessDetail
        )
        if (response.status === 200) {
          this.businessGrowthDetails=[response.data]
        }
        this.businesDetailsLoading = false

      } catch (e) {
        this.businesDetailsLoading = false

        useSnackbarStore().snack({
          text: e.response,
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
     async updateBusinessAnnualReportDetail(updateBusinessDetail) {
      this.businessDetailsAnnualReportLoading = true

      try {
        const response = await repository.updateBusinessDetailAnnualReport(
          this.selectedCompanySN,
          updateBusinessDetail.id,
          updateBusinessDetail
        )
        if (response.status === 200) {
          this.businessAnnualReportDetails=[response.data]
        }
        this.businessDetailsAnnualReportLoading = false

      } catch (e) {
        this.businessDetailsAnnualReportLoading = false

        useSnackbarStore().snack({
          text: e.response,
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async updateBusinessRndDetail(updateBusinessDetail) {
      this.businessDetailsRndLoading = true
      try {
        const response = await repository.updateBusinessRndDetail(
          this.selectedCompanySN,
          updateBusinessDetail.id,
          updateBusinessDetail
        )
        if (response.status === 200) {
          this.businessRndDetails=[response.data]
        }
        this.businessDetailsRndLoading = false
      } catch (e) {
        this.businessDetailsRndLoading = false
        useSnackbarStore().snack({
          text: e.response,
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    
    async updateBusinessCSRDetail(updateBusinessDetail) {
      this.businesDetailsCSRLoading = true

      try {
        const response = await repository.updateBusinessDetailCSR(
          this.selectedCompanySN,
          updateBusinessDetail.id,
          updateBusinessDetail
        )
        if (response.status === 200 && response.data) {
          this.businessCSRDetails=[response.data]
        }
        this.businesDetailsCSRLoading = false

      } catch (e) {
        this.businesDetailsCSRLoading = false

        useSnackbarStore().snack({
          text: e.response,
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async deleteBusinessDetail(deleteBusinessDetail) {
      try {
        const response = await repository.deleteBusinessDetail(this.selectedCompanySN, deleteBusinessDetail.id)
        if (response.status === 200) {
          this.updateList(response.data)
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message[0],
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async deleteBusinessGrowthDetail(deleteBusinessDetail) {
      try {
        const response = await repository.deleteBusinessGrowthDetail(this.selectedCompanySN, deleteBusinessDetail.id)
        if (response.status === 200) {
        this.businessGrowthDetails =[]  
        this.galleryFilesGrowth=[]  
        this.fetchedGallery=[]    
      }
      this.businesDetailsLoading = false

      } catch (e) {
        useSnackbarStore().snack({
          text: e.response,
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async deleteBusinessCSRDetail(deleteBusinessDetail) {
      try {
        const response = await repository.deleteBusinessCSRDetail(this.selectedCompanySN, deleteBusinessDetail.id)
        if (response.status === 200) {
        this.businessCSRDetails =[]
        this.galleryFilesCsr=[]
        this.fetchedCSRGallery=[]        
      }
      this.businesDetailsCSRLoading = false

      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message[0],
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async deleteBusinessRndDetail(deleteBusinessDetail) {
      try {
        const response = await repository.deleteBusinessRndDetail(this.selectedCompanySN, deleteBusinessDetail.id)
        if (response.status === 200) {
        this.businessRndDetails =[] 
        this.galleryFilesRnd =[]
        this.fetchedRndGallery =[]       
      }
      this.businessDetailsRndLoading= false

      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message[0],
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async deleteBusinessAnnualDetail(deleteBusinessDetail) {
      try {
        const response = await repository.deleteBusinessAnnualDetail(this.selectedCompanySN, deleteBusinessDetail.id)
        if (response.status === 200) {
        this.businessAnnualReportDetails =[] 
        this.galleryFilesAnnualReport =[]
        this.fetchedAnnualReportGallery =[]       
      }
      this.businessDetailsAnnualReportLoading= false

      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message[0],
          type: 'error',
          show: true,
          autoclose: true
        })
      }

    },
    async newReasonToInvest(newReasonToInvest) {
      try {
        const response = await repository.saveNewReasonToInvest(this.selectedCompanySN, newReasonToInvest)
        if (response.status === 200) {
          this.updateList(response.data)
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message[0],
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async updateReasonToInvest(updateReasonToInvest) {
      try {
        const response = await repository.updateReasonToInvest(
          this.selectedCompanySN,
          updateReasonToInvest.id,
          updateReasonToInvest
        )
        if (response.status === 200) {
          this.updateList(response.data)
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message[0],
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async deleteReasonToInvest(deleteReasonToInvest) {
      try {
        const response = await repository.deleteReasonToInvest(this.selectedCompanySN, deleteReasonToInvest.id)
        if (response.status === 200) {
          this.updateList(response.data)
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message[0],
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async newManagement(newManagement) {
      try {
        
        const response = await repository.saveNewManagement(this.selectedCompanySN, newManagement)
        if (response.status === 200) {
          this.updateList(response.data)
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message[0],
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async newBoardOfDirectors(payload) {
      try {
        const response = await repository.saveBoardOfDirectors(this.selectedCompanySN, payload)
        if(response.status === 200) {
          await this.fetchBoardOfDirectors()
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message[0],
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async appendFeaturedVideos(payload) {
      let companyIndex = this.companies.findIndex((company) => Number(payload.company_id) === Number(company.id))
      this.companies[companyIndex].featured_videos.push(payload)
      this.companies = [...this.companies]
    },
    async updateFeaturedVideos(payload) {
      let companyIndex = this.companies.findIndex((company) => Number(payload.video.company_id) === Number(company.id))

      let currentVideoIndex = this.companies[companyIndex].featured_videos.findIndex((v) => v.id === payload.video.id)
      let currentVideo = {
        ...this.companies[companyIndex].featured_videos[currentVideoIndex]
      }
      currentVideo.title = payload.title
      currentVideo.description = payload.description
      this.companies[companyIndex].featured_videos[currentVideoIndex] = currentVideo

      this.companies = [...this.companies]
    },
    async deleteFeaturedVideos(payload) {
      let companyIndex = this.companies.findIndex((company) => Number(payload.company_id) === Number(company.id))

      let currentVideoIndex = this.companies[companyIndex].featured_videos.findIndex((v) => v.id === payload.id)
      this.companies[companyIndex].featured_videos.splice(currentVideoIndex, 1)

      this.companies = [...this.companies]
    },
    async updateManagement(updateManagement) {
      try {
        const response = await repository.updateManagement(
          this.selectedCompanySN,
          updateManagement.id,
          updateManagement
        )
        if (response.status === 200) {
          this.updateList(response.data)
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message[0],
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async updateBoardOfDirectors(update) {
      try {
        const response = await repository.updateBoardOfDirectors(
          this.selectedCompanySN,
          update.id,
          update
        )
        if (response.status === 200) {
          await this.fetchBoardOfDirectors()
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message[0],
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async newDocumentList(newDocument) {
      try {

        const response = await repository.newDocumentListFile(this.selectedCompanySN, { document: newDocument })
        if (response.status === 200) {
          this.updateList(response.data)
          useSnackbarStore().snack({
            text: 'Document uploaded successfully',
            type: 'success',
            show: true,
            autoclose: true
          })
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message[0],
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async toggleWidget(payload) {
      try {
        const response = await repository.toggleWidget(this.selectedCompanySN, payload)
        if (response.status === 200) {
          this.updateList(response.data)
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message[0],
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async deleteManagement(deleteManagement) {
      try {
        const response = await repository.deleteManagement(this.selectedCompanySN, deleteManagement.id)
        if (response.status === 200) {
          this.updateList(response.data)
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message[0],
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async deleteBoardOfDirectors(deleteBOD) {
      try {
        const response = await repository.deleteBoardOfDirectors(this.selectedCompanySN, deleteBOD.id)
        if (response.status === 200) {
          await this.fetchBoardOfDirectors()
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message[0],
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async fetchFinancials() {
      try {
        const currency = useScreenersStore().selectedCurrency
        const periodCode = useFinancialsStore().selectedPeriodCode
        this.loadingFinancials = true


        useJobsStore().runEndpoint('fetchFinancials' + this.selectedCompanySN + currency + periodCode, async () => {
          const response = await repository.fetchFinancials(this.selectedCompanySN, currency, periodCode)
          this.loadingFinancials = false
          if (response.status === 200 && response.data) {

            if(response.data.DATA) {
              this.mnemonicsToBeDeleted.map(mnemonic=> {
                delete response.data.DATA[mnemonic]
              })
            }

            this.originalFinancials = {
              ...this.originalFinancials,
              [this.selectedCompanyId]: response.data
            }
            this.originalFinancials = { ...this.originalFinancials }
          }
        }, 1000 * 60 * 5, true)
        } catch (e) {
          this.loadingFinancials = false

          useSnackbarStore().snack({
            type: 'error',
            e: e,
            show: true,
            autoclose: true
          })
        }
    },
    async fetchMarketCap() {

      const override = !this.originalMarketCap[this.selectedCompanyId] || this.originalMarketCap[this.selectedCompanyId].length === 0

      useJobsStore().runEndpoint('fetchMarketCap' + this.selectedCompanySN, async () => {
        if (!this.loadingMarketCap) {
          this.loadingMarketCap = true
          try {
            const currency = useScreenersStore().selectedCurrency
            const response = await repository.fetchMarketCap(this.selectedCompanySN, currency)
            this.loadingMarketCap = false
            if (response.status === 200 && response.data) {
              this.originalMarketCap = {
                ...this.originalMarketCap,
                [this.selectedCompanyId]: response.data
              }
              this.originalMarketCap = { ...this.originalMarketCap }
            }
          } catch (e) {
            this.loadingMarketCap = false

            useSnackbarStore().snack({
              type: 'error',
              e: e,
              show: true,
              autoclose: true
            })
          }
        }
      }, 1000 * 60 * 10, override)
    },
    async uploadLogo(payload) {
      try {
        this.loadingLogo = true
        const response = await repository.uploadLogo(payload.slugUrl, payload)
        if (response.status === 200) {
          // console.log('Image uploaded successfully')
          payload = {
            logo: response.data.logo,
            color: response.data.color,
            slug_url: payload.slugUrl
          }

          let companyIndex = this.companies.findIndex((company) => payload.slug_url === company.slug_url)
          if (companyIndex > -1) {
            console.log('UPDATED LOGO OMS')
            this.companies[companyIndex].logo = payload.logo
            this.companies[companyIndex].color = payload.color
            this.companies = [...this.companies]
          }
          useUsersStore().setLogo(payload)
        }
        this.loadingLogo = false
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async uploadBanner(payload) {
      try {
        this.loadingBanner = true
        const response = await repository.uploadBanner(payload.slugUrl, payload)
        if (response.status === 200) {
          // console.log('Image uploaded successfully')
          payload = {
            banner: response.data.banner,
            slug_url: payload.slugUrl
          }

          let companyIndex = this.companies.findIndex((company) => payload.slug_url === company.slug_url)
          if (companyIndex > -1) {
            console.log('UPDATED BANNER OMS')
            this.companies[companyIndex].banner = payload.logo
            this.companies = [...this.companies]
          }
          useUsersStore().setBanner(payload)
        }
        this.loadingBanner = false
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    reset() {
      this.companies = [...[]]
      this.originalUserRequests = [...[]]
      this.filteredCompanyList = [...[]]
    },
    async updateHiddenPages(page) {
      try {
        const response = await repository.saveHiddenPage(this.selectedCompanySN, page)
        if (response.status === 200) {
          this.updateList(response.data)
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message[0],
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async updateCompanyList(company) {
      try {
        this.updateList(company)
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message[0],
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async fetchRelatedPremiumCompanies() {
      
      try {
        
        const response = await repository.relatedPremiumCompanies(this.selectedCompanySN)

        if(response.status === 200 && response.data?.length > 0) {
          this.relatedPremiumCompanies = [...response.data]
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async updateUSPDescription(companyUrl, payload) {
      try {
        const response = await repository.updateUSPDescription(companyUrl, payload)
        if (response.status === 200 && response.data) {
          this.updateList(response.data)
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async updateReasonToInvestDescription(companyUrl, payload) {
      try {
        const response = await repository.updateReasonToInvestDescription(companyUrl, payload)
        if (response.status === 200 && response.data) {
          this.updateList(response.data)
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async fetchTopFollowedCompanies() {
      if(useUsersStore().accessToken)
      {
        try {
          this.loadingTopFollowedCompanies = true
          const response = await repository.fetchTopFollowedCompanies()
          if (response.status === 200 && response.data) {
            this.topFollowedCompanies = response.data.data
          }
          this.loadingTopFollowedCompanies = false
        } catch (e) {
          this.loadingTopFollowedCompanies = false
          useSnackbarStore().snack({
            type: 'error',
            e: e,
            show: true,
            autoclose: true
          })
        }
      }
    },
	async fetchOtherUserTopFollowedCompanies(payload) {
      try {
		this.loadingOtherUserTopFollowedCompanies = true
        const response = await repository.fetchOtherUserTopFollowedCompanies(payload)
        if (response.status === 200 && response.data) {
          this.otherUserTopFollowedCompanies = response.data.data
        }
		this.loadingOtherUserTopFollowedCompanies = false
      } catch (e) {
		this.loadingOtherUserTopFollowedCompanies = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    }
  }
})
