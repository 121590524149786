import { RepositoryFactory } from "@/api/repositoryFactory"
const repository = RepositoryFactory.get("screeners")
import { defineStore } from "pinia"
import { useSnackbarStore } from "./snackbar"
import { useCompaniesStore } from "./companies"

export const useScreenersStore = defineStore("screeners", {
  state: () => ({
    selectedCurrency: "EUR",
    relevant: true,
    inUniverse: "in-universe",
    screenerFilterDefault: [
      {
        code: "sectors",
        group: "sectors",
        type: "multiselect",
        data: {},
        selected: null,
        preselect: null,
        suffix: false,
      },
      {
        code: "countries",
        group: "countries",
        type: "multiselect",
        data: {},
        selected: null,
        preselect: null,
        suffix: false,
      },
      {
        code: "marketcap",
        group: "financials",
        type: "range",
        data: { defaultRange: [0, 0] },
        selected: null,
        preselect: [0, 0],
        suffix: "M€",
      },
      {
        code: "price",
        group: "financials",
        type: "range",
        data: { defaultRange: [0, 0] },
        selected: null,
        preselect: [0, 0],
        suffix: "€",
      },
      {
        code: "shares",
        group: "financials",
        type: "range",
        data: { defaultRange: [0, 0] },
        selected: null,
        preselect: [0, 0],
        suffix: "M",
      },
    ],
    originalScreenerFilters: [],
    sectors: [],
    countries: [],
    marketCapMax: 0,
    priceMax: 0,
    sharesMax: 0,
    showFilter: false,
    loadingFilterData: false,
    searchCompaniesPhrase: "",
    searchCompaniesSortable: "default",
    searchCompaniesOrder: "asc",
    filterList: [],
    filterListLoading: false,
  }),
  getters: {
    screenerFilters(state) {
      const filters = state.screenerFilterDefault.map((filterDefault) => {
        const index = state.originalScreenerFilters.findIndex(
          (f) => f.code === filterDefault.code
        )
        return index > -1 ? state.originalScreenerFilters[index] : filterDefault
      })

      filters.map((f) => {
        if (f.code === "sectors") {
          f.data = { ...f.data, list: state.sectors }
        }

        if (f.code === "countries") {
          f.data = { ...f.data, list: state.countries }
        }

        if (f.code === "marketcap") {
          f.data.defaultRange = [0, state.marketCapMax]
          if (f.preselect[1] === 0) {
            f.preselect = [0, state.marketCapMax]
          }
        }
        if (f.code === "shares") {
          f.data.defaultRange = [0, state.sharesMax]
          if (f.preselect[1] === 0) {
            f.preselect = [0, state.sharesMax]
          }
        }
        if (f.code === "price") {
          f.data.defaultRange = [0, state.priceMax]
          if (f.preselect[1] === 0) {
            f.preselect = [0, state.priceMax]
          }
        }
      })

      return filters
    },
    selectedScreenerFilters() {
      const selected = []

      this.originalScreenerFilters.map((f) => {
        if (f.selected) {
          if (f.type === "multiselect") {
            f.selected.map((listOption) => {
              const filterOption = {
                label: listOption.name,
                group: f.group,
                type: f.type,
                code: listOption.name + "-" + listOption.id,
                pics: f.pics_code,
                data: listOption,
                default: f,
                cleanable: true,
              }

              selected.push(filterOption)
            })
          } else if (f.type === "range") {
            if (
              f.data.defaultRange[0] !== f.selected[0] ||
              f.data.defaultRange[1] !== f.selected[1]
            ) {
              const filterOption = {
                label: f.code + " " + f.selected[0] + " - " + f.selected[1],
                group: f.group,
                type: f.type,
                suffix: f.suffix,
                code: f.code,
                data: f.selected,
                default: f,
                cleanable: true,
              }

              selected.push(filterOption)
            }
          }
        }
      })

      return selected
    },
    // SELECTED FILTERS HAS A DEFAULT KEY WHICH CONTAINS ALL DATA. DO NOT NEED TO SEND
    // THEM WHEN SEARCHING COMPANIES, SO SLIM IT DOWN. IS USED IN COMPANIES STORE
    slimSelectedScreenerFilters() {
      const selected = []

      this.selectedScreenerFilters.map((f) => {
        const newFilter = { ...f, default: null }
        selected.push(newFilter)
      })

      return selected
    },
  },
  actions: {
    setRelevant(boolean) {
      this.relevant = boolean
      localStorage.relevant = JSON.stringify(this.relevant)
    },
    setUniverse(universe) {
      this.inUniverse = universe
      localStorage.inUniverse = JSON.stringify(this.inUniverse)
    },
    async setMyFilters(payload) {
      this.originalScreenerFilters.forEach((filter) => {
        if (filter.code === "countries") {
          filter.selected = JSON.parse(payload.countries)
          filter.preselect = JSON.parse(payload.countries)
        } else if (filter.code === "sectors") {
          filter.selected = JSON.parse(payload.sectors)
          filter.preselect = JSON.parse(payload.sectors)
        } else if (filter.code === "shares") {
          if (payload.shares !== null) {
            filter.selected = JSON.parse(payload.shares)
            filter.preselect = JSON.parse(payload.shares)
          } else {
            filter.selected = null
            filter.preselect = [0, 0]
          }
        } else if (filter.code === "price") {
          if (payload.price !== null) {
            filter.selected = JSON.parse(payload.price)
            filter.preselect = JSON.parse(payload.price)
          } else {
            filter.selected = null
            filter.preselect = [0, 0]
          }
        } else if (filter.code === "marketcap") {
          if (payload.marketcap !== null) {
            filter.selected = JSON.parse(payload.marketcap)
            filter.preselect = JSON.parse(payload.marketcap)
          } else {
            filter.selected = null
            filter.preselect = [0, 0]
          }
        }
      })
      this.originalScreenerFilters = [...this.originalScreenerFilters]
    },
    async fetchCompanyFilters() {
      try {
        const response = await repository.fetchCompanyFilters()
        if (response.status === 200 && response.data) {
          this.filterList = response.data
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async saveFilters(payload) {
      try {
        const response = await repository.saveCompanyFilters(payload)
        if (response.status === 200 && response.data) {
          this.filterList = response.data
          useSnackbarStore().snack({
            type: "success",
            text: "filters saved successfully",
            show: true,
            autoclose: true,
          })
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async deleteFilter(filterId) {
      try {
        this.filterListLoading = true
        const response = await repository.deleteFilter(filterId)
        if (response.status === 200 && response.data) {
          this.filterList = response.data
        }
        this.filterListLoading = false
      } catch (e) {
        this.filterListLoading = false

        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async updateFilters(payload) {
      try {
        const response = await repository.updateCompanyFilters(
          payload.filterId,
          payload
        )
        if (response.status === 200 && response.data) {
          this.filterList = response.data
          useSnackbarStore().snack({
            type: "success",
            text: "filters saved successfully",
            show: true,
            autoclose: true,
          })
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    toggleShowFilter(payload) {
      this.showFilter = payload
    },
    async fetchFilterData() {
      if (localStorage.inUniverse) {
        this.inUniverse = JSON.parse(localStorage.inUniverse)
      }

      if (localStorage.relevant) {
        this.relevant = JSON.parse(localStorage.relevant)
      }

      if (localStorage.selectedCurrency) {
        this.selectedCurrency = JSON.parse(localStorage.selectedCurrency)
      }

      this.loadingFilterData = true
      try {
        const response = await repository.fetchFilterData()
        if (response.status === 200 && response.data) {
          this.sectors = response.data.sectors
          this.countries = response.data.countries
          this.marketCapMax =
            Math.ceil(Number(response.data.max_valuation) / 100) * 100
          this.priceMax = Math.ceil(Number(response.data.price) / 100) * 100
          this.sharesMax = Math.ceil(Number(response.data.shares) / 100) * 100
        }
        this.loadingFilterData = false
      } catch (e) {
        this.loadingFilterData = false
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async resetScreenerFilter() {
      this.originalScreenerFilters = [...[]]
    },
    async addScreenerFilter(payload) {
      this.originalScreenerFilters = [...payload]
    },
    async setSelectedCurrency(payload) {
      this.selectedCurrency = payload
      localStorage.selectedCurrency = JSON.stringify(this.selectedCurrency)
    },
    async resetSearchCompaniesSortable() {
      clearTimeout(this.searchCompaniesDebounce)
      this.setSearchCompaniesSortable({
        searchCompaniesPhrase: "",
        debounce: null,
        sortable: "default",
        order: "asc",
      })
    },
    async setSearchCompaniesSortable(payload) {
      if (
        payload.searchCompaniesPhrase ||
        payload.searchCompaniesPhrase === ""
      ) {
        this.searchCompaniesPhrase = payload.searchCompaniesPhrase
      }
      if (payload.debounce) {
        this.searchCompaniesDebounce = payload.debounce
      }
      if (payload.sortable) {
        this.searchCompaniesSortable = payload.sortable
      }
      if (payload.order) {
        this.searchCompaniesOrder = payload.order
      }
      if (payload.relevant === true || payload.relevant === false) {
        this.setRelevant(payload.relevant)
      }
    },
    async updateScreenerFilterData(payload) {
      return new Promise((resolve, reject) => {
        try {
          // DEFINE A FILTER OPTION
          let filter = null
          // IF PREVIOUS FILTER EXIST, USE THAT ONE
          let index = this.originalScreenerFilters.findIndex(
            (f) => f.code === payload.screenerFilter.code
          )
          if (index > -1) {
            filter = this.originalScreenerFilters[index]
          } else {
            // ELSE USE THE DEFAULT FILTER OBJECTS. WITHOUT THIS FIRST SELECTION OF A FILTER WILL NOT WORK
            index = this.originalScreenerFilters.length
            const indexDefault = this.screenerFilterDefault.findIndex(
              (f) => f.code === payload.screenerFilter.code
            )
            filter = this.screenerFilterDefault[indexDefault]
          }

          let screenerFilter = { ...filter, preselect: payload.preselect }
          let screenerFilters = [...this.originalScreenerFilters]
          screenerFilters[index] = screenerFilter
          resolve((this.originalScreenerFilters = [...screenerFilters]))
        } catch (e) {
          reject(e)
        }
      })
    },
    async delayedSearchCompanies(payload) {
      if (payload.searchCompanyPhrase === "") {
        this.resetSearchCompaniesSortable()
      } else {
        clearTimeout(this.searchCompaniesDebounce)
        this.searchCompaniesPhrase = payload.searchCompaniesPhrase
        this.searchCompaniesDebounce = setTimeout(() => {
          useCompaniesStore().searchCompanies(payload)
        }, 600)
      }
    },
  },
  namespaced: true,
})
