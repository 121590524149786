<template>
  <div class="w-full relative">
    <div
      class="absolute inset-0 bg-center bg-no-repeat"
      :class="[type, backgroundColor, backgroundColor !== '' ? 'p-4' : '']"
      :style="backgroundColor ? '' : 'background-image: url(' + src + ')'"
    >
      <img
        class="w-full h-full bg-center bg-no-repeat"
        :class="[type]"
        :src="src"
      >
    </div>
    <div
      :class="{
        'aspect-ratio-none': ratio === 'none',
        'aspect-ratio-square': ratio === 'square',
        'aspect-ratio-16/9': ratio === '16/9',
        'aspect-ratio-16/7': ratio === '16/7',
        'aspect-ratio-16/5': ratio === '16/5',
        'aspect-ratio-16/4': ratio === '16/4',
        'aspect-ratio-16/3': ratio === '16/3',
        'aspect-ratio-4/3': ratio === '4/3',
        'aspect-ratio-6/3': ratio === '6/3',
        'aspect-ratio-4/6': ratio === '4/6',
        'aspect-ratio-21/9': ratio === '21/9'
      }"
    />
  </div>
</template>

<script>
export default {
  name: 'BannerImage',
  props: {
    src: { type: String, default: '' },
    backgroundColor: { type: String, default: '' },
    ratio: {
      type: String,
      default: '16/4'
    },
    type: {
      type: String,
      default: 'bg-cover'
    }
  }
}
</script>
