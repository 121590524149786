import { get, post , } from '../helpers/axiosHelper'

const url = `${process.env.VUE_APP_API_URL}`

const fetch = async (companySN) => get(`${url}/companies/${companySN}/holders`)
const fetchHolder = async (holderId) => get(`${url}/holders/${holderId}`)
const saveNewHolder = async (companySN,data) => post(`${url}/companies/${companySN}/holders/new-holder`, data)
const fetchNewHolder = async (companySN) => get(`${url}/companies/${companySN}/user-holders/fetch`)
const updateHolder = async (data) => post(`${url}/holders/${data.id}/update-holder`, data)
const fetchHolderShares = async (holderId) => get(`${url}/holders/${holderId}/holder-shares`)
const deleteHolder = async (id) => post(`${url}/${id}/delete-holder`)


export default {
  fetch,
  fetchHolder,
  fetchHolderShares,
  saveNewHolder,
  fetchNewHolder,
  updateHolder,
  deleteHolder
  
}
