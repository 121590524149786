import { RepositoryFactory } from "@/api/repositoryFactory"
const repository = RepositoryFactory.get("companies")
import { defineStore } from "pinia"
import { useSnackbarStore } from "./snackbar"
import { useCompaniesStore } from "./companies"

export const useCalendarEventsStore = defineStore("calendarEvents", {
  state: () => ({
    selectedCompanyId: null,
    companyEvents: {},
    loading: false,
    newLoading: false,
    alert: {
      title: "",
      create: false,
      body: "",
      type: "",
      showConfirmButton: false,
    },
  }),
  getters: {
    events(state) {
      return state.companyEvents[state.selectedCompanyId]
    },
  },
  actions: {
    async fetch() {
      try {
        this.selectedCompanyId = useCompaniesStore().selectedCompanyId
        this.loading = true
        const response = await repository.eventList(
          useCompaniesStore().selectedCompanySN
        )
        this.loading = false
        if (response.status === 200 && response.data) {
          this.companyEvents = {
            ...this.companyEvents,
            [this.selectedCompanyId]: response.data,
          }
        }
      } catch (e) {
        this.loading = false
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async refetch() {
      try {
        this.selectedCompanyId = useCompaniesStore().selectedCompanyId
        this.newLoading = true
        const response = await repository.eventList(
          useCompaniesStore().selectedCompanySN
        )
        this.newLoading = false
        if (response.status === 200 && response.data) {
          this.companyEvents = {
            ...this.companyEvents,
            [this.selectedCompanyId]: response.data,
          }
        }
      } catch (e) {
        this.newLoading = false
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async deleteRequest(eventId) {
      try {
        const response = await repository.eventDeleteRequest(
          useCompaniesStore().selectedCompanyId,
          eventId
        )
        if (response.status === 204) {
          useSnackbarStore().snack({
            text: "Delete request added successfully",
            type: "success",
            show: true,
            autoclose: true,
          })
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message,
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    resetAlert() {
      this.alert = {
        title: "",
        create: false,
        body: "",
        type: "",
        showConfirmButton: false,
      }
    },
    setAlert(payload) {
      this.alert.title = payload.title
      this.alert.body = payload.body
      this.alert.create = payload.create
      this.alert.type = payload.type
      this.alert.showConfirmButton = payload.showConfirmButton
    },
    async delete(eventId) {
      try {
        const response = await repository.eventDelete(
          useCompaniesStore().selectedCompanyId,
          eventId
        )
        if (response.status === 204) {
          const index = this.companyEvents[this.selectedCompanyId].findIndex(
            (event) => event.id === eventId
          )
          this.companyEvents[this.selectedCompanyId].splice(index, 1)
          this.companyEvents = { ...this.companyEvents }
          // useSnackbarStore().snack({ text: 'Delete request added successfully', type: 'success', show: true, autoclose: true })
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message,
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async addEvent(payload) {
      try {
        this.newLoading = true
        const response = await repository.addEvent(
          useCompaniesStore().selectedCompanyId,
          payload
        )
        if (response.status === 204) {
          useSnackbarStore().snack({
            text: "Event added successfully",
            type: "success",
            show: true,
            autoclose: true,
          })
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    reset() {
      this.companyEvents = {}
    },
  },
})
