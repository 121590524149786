<template>
  <div>
    <CompanyNameHeader
      v-if="header"
      :company="isEpp ? theExchange : theCompany"
      :company-details="isEpp ? theExchange : theCompanyDetails"
      :is-epp="isEpp"
      @click="$emit('click', $event)"
    />
    <CompanyName
      v-else
      :edit-mode="editMode"
      :company="isEpp ? theExchange : theCompany"
      :company-details="isEpp ? theExchange : theCompanyDetails"
      :minified="minified"
      :subtitle="subtitle"
      :font="font"
      :edit="editLogo && editMode"
      :image-url="imageUrl"
      :is-epp="isEpp"
      :is-mobile="isMobile"
      @go-to-explore-companies="goToExploreCompanies"
      @click="$emit('click', $event)"
      @edit-logo="editLogo = !editLogo"
      @on-profile-image="onProfileImage"
      @on-cancel="onCancel"
      @on-save="onSave"
    />
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import { uploadFiles } from '@/js/helpers/aws'
import CompanyName from './_components/CompanyName'
import CompanyNameHeader from './_components/CompanyNameHeader'
import { useCompaniesStore } from '../../stores/companies'
import { useExchangesStore } from '../../stores/exchanges'
import { isMobile } from '../../js/helpers/mobile'

export default {
  name: 'CompanyNameModule',
  components: {
    CompanyName,
    CompanyNameHeader
  },
  props: {
    company: {
      type: Object,
      default: () => {}
    },
    companyDetails: {
      type: Object,
      default: () => {}
    },
    subtitle: {
      type: String,
      default: ''
    },
    minified: {
      type: Boolean,
      default: false
    },
    font: {
      type: String,
      default: 'font-semibold'
    },
    header: {
      type: Boolean,
      default: false
    },
    editMode: {
      type: Boolean,
      default: false
    },
	isEpp: {
      type: Boolean,
      default: false
	}
  },
  emits: [
    'click'
  ],
  setup() {
    const companiesStore = useCompaniesStore()
    const exchangesStore = useExchangesStore()

    return { companiesStore, exchangesStore }
  },
  data () {
    return {
      editLogo: false,
      profileImage: null,
      imageUrl: null,
      path: 'media/logo/',
    } 
  },
  computed: {
    isMobile() {
      return isMobile()
    },
    selectedCompany() {
      return this.companiesStore.selectedCompany
    },
    selectedExchange() {
      return this.exchangesStore.exchange.exchange
    },
    selectedCompanyDetails() {
      return this.companiesStore.companyDetails
    },
    theCompany () {
      if (this.company) {
        return this.company
      }

      return this.selectedCompany
    },
    theExchange () {
      if (this.company) {
        return this.company
      }

      return this.selectedExchange
    },
    theCompanyDetails () {
      if (this.companyDetails) {
        return this.companyDetails
      }

      return this.selectedCompanyDetails
    }
  },
  methods: {
    onProfileImage (payload) {
      this.profileImage = payload.profileImage
      this.imageUrl = payload.imageUrl
      // this.onSave()
    },
    onCancel () {
      console.log('onCancel')
      this.imageUrl = null
      this.profileImage = null
      this.edit = false
    },
    async onSave () {
      console.log('onSave')
      let update = {}
      if (!this.profileImage) {
        this.onCancel()
        return
      }
      if (this.profileImage) {
        
        const uuid = uuidv4()
        const filename = this.path + uuid + '/' + this.profileImage.name.split(' ').join('_')
        await uploadFiles([
          {
            filename,
            type: 'image',
            uuid,
            data: this.profileImage,
            exist: false
          }
        ])
        update.filename = filename
      }
      if (update) {
		this.isEpp ? update.type = 'logo' : update.slugUrl = this.theCompany.slug_url
        this.isEpp ? await this.exchangesStore.uploadLogo(update) : await this.companiesStore.uploadLogo(update)
        this.onCancel()
      }
    },
    goToExploreCompanies(tag) {
      if (!tag) return
      let dataObj = {
        selectedTags: [
          {
            label: `#${tag.toLowerCase()}`,
            group: 'tags',
            type: 'multiselect',
            isCustomTag: true
          }
        ]
      }
      this.$router.push({ name: 'exploreCompanies', query: { filtration: JSON.stringify(dataObj) }  })
    }
  }
  
}
</script>
