<template>
  <div
    class="flex font-semibold justify-end px-1 py-1 rounded-lg"
    :class="secondary ? 'bg-custom-purple-400 dark:bg-custom-purple-600' : 'bg-gray-200 dark:bg-custom-purple-400'"
  >
    <CurrencyBadge
      first
      :active="active"
      :secondary="secondary"
      @click="$emit('choose-relevant', true)"
    >
      Relevant
    </CurrencyBadge>
    <CurrencyBadge
      :active="!active"
      :secondary="secondary"
      @click="$emit('choose-relevant', false)"
    >
      Random
    </CurrencyBadge>
  </div>
</template>

<script>

export default {
  name: 'RelevantMenu',
  props: {
    active: {
      type: Boolean,
      default: true
    },
    secondary: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'choose-relevant'
  ]
}
</script>
