import { RepositoryFactory } from '@/api/repositoryFactory'
import i18n from '@/js/language/'
import router from '@/js/router'
import { defineStore } from 'pinia'
import { useSnackbarStore } from './snackbar'

const repository = RepositoryFactory.get('blogs')
const _ = require('lodash')

export const useBlogsStore = defineStore('blogs', {
  state: () => ({
    loading: false,
    slug: '',
    blogsList: [],
    footer:''
  }),
  getters: {
    currentBlog: (state) => {
      let currentBlog = state.blogsList.find((blog) => blog.slug_url === state.slug)
      if (currentBlog) {
        currentBlog.blog_blocks = _.orderBy(currentBlog.blog_blocks, 'sorting', 'asc')
      }
      return currentBlog
    }
  },
  actions: {
    async fetchBlog(slugUrl) {
      try {
        this.loading = true

        const blogIndex = this.blogsList.findIndex((b) => b.slug === slugUrl)
        if (blogIndex === -1) {
          const response = await repository.fetchBlog(slugUrl)
          this.loading = false
          if (response.status === 200 && response.data) {
            const blogsList = this.blogsList

            const blogIndex = blogsList.findIndex((b) => Number(b.id) === Number(response.data.id))
            if (blogIndex > -1) {
              blogsList[blogIndex] = response.data
              this.blogsList = [...blogsList]
            } else {
              this.blogsList.push(response.data)
            }
          }
        }
      } catch (e) {
        console.log(e)
        this.loading = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async fetchFooter() {
      try {
        this.loading = true

        const response = await repository.fetchFooter()
        this.loading = false
        if (response.status === 200 && response.data) {
          this.footer=response.data.footer
        }
      } catch (e) {
        this.loading = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async fetchBlogs() {
      try {
        this.loading = true

        const response = await repository.fetchBlogs()
        this.loading = false
        if (response.status === 200 && response.data) {
          this.blogsList = [...response.data]
        }
      } catch (e) {
        console.log(e)
        this.loading = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async updateBlog(payload) {
      try {
        this.loading = true
        const response = await repository.updateBlog(payload)
        if (response.status === 201) {
          useSnackbarStore().snack({
            type: 'success',
            text: i18n.global.t('The Blog has been edited'),
            show: true,
            autoclose: true
          })
          router.push({ name: 'blogs' })
        }
        this.loading = false
      } catch (e) {
        console.log(e)
        this.loading = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async deleteBlog(payload) {
      try {
        this.loading = true
        const response = await repository.deleteBlog(payload)
        if (response.status === 201) {
          useSnackbarStore().snack({
            type: 'success',
            text: i18n.global.t('The Blog has been deleted'),
            show: true,
            autoclose: true
          })
          router.push({ name: 'blogs' })
        }
        this.loading = false
      } catch (e) {
        console.log(e)
        this.loading = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },

    async setSlug(payload) {
      this.slug = payload
    },
    async setLoading(payload) {
      this.loading = payload
    },

    async createBlog(payload) {
      try {
        this.loading = true
        const response = await repository.createBlog(payload.blocks)
        if (response.status === 200) {
          useSnackbarStore().snack({
            type: 'success',
            text: i18n.global.t('The Blog has been created successfully'),
            show: true,
            autoclose: true
          })
          router.push({ name: 'blogs' })
        }
        this.loading = false
      } catch (e) {
        console.error(e)

        this.loading = false
        if ('response' in e && e.response.status === 409) {
          useSnackbarStore().snack({
            type: 'error',
            text: i18n.global.t('Blog title already exists'),
            show: true,
            autoclose: true
          })
        } else {
          useSnackbarStore().snack({
            type: 'error',
            e: e,
            show: true,
            autoclose: true
          })
        }
      }
    }
  }
})
