import i18n from '@/js/language/'
import { RepositoryFactory } from '@/api/repositoryFactory'
const repository = RepositoryFactory.get('pressRelease')
const postrepository = RepositoryFactory.get('posts')
import { defineStore } from 'pinia'
import { useSnackbarStore } from './snackbar'
import { useCompaniesStore } from './companies'
import { useUsersStore } from './users'
import { useJobsStore } from './jobs'

export const usePressReleasesStore = defineStore('pressReleases', {
  state: () => ({
    selectedCompanyId: null,
    companyPressReleases: {},
    typedCompanyPressReleases: {},
    loading: false,
    loadingTranslate: false,
    nextPageUrl: null,
    loadingNew: false,
    hasError: false,
    runningQueue: {}
  }),
  getters: {
    pressReleases(state) {
      if (!state.companyPressReleases[state.selectedCompanyId]) {
        return []
      }
      return state.companyPressReleases[state.selectedCompanyId]
    },
    typedPressReleases(state) {
      if (!state.typedCompanyPressReleases) {
        return []
      }
      return state.typedCompanyPressReleases
    }
  },
  actions: {
    async fetchRoomPostId (pressReleaseId) {
      try {
        const response = await repository.fetchRoomPostId(pressReleaseId)
        if (response.status === 200 && response.data) {
          return Number(response.data);
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    setTypedList(payload) {
      if (payload.data) {
        payload.data.filter((pressRelease) => {
          if (pressRelease && pressRelease.content) {
            if (pressRelease.content.indexOf('<body>') > -1) {
              pressRelease.content = pressRelease.content.replace('<body>', '<div>')
              pressRelease.content = pressRelease.content.replace('</body>', '</div>')
            } else {
              pressRelease.content = `<div>${pressRelease.content}</div>`
            }
          }
        })
        if (!this.typedCompanyPressReleases[payload.identifier]) {
          this.typedCompanyPressReleases[payload.identifier] = {}
        }
        if (!this.typedCompanyPressReleases[payload.identifier][payload.type]) {
          this.typedCompanyPressReleases[payload.identifier][payload.type] = []
        }

        this.typedCompanyPressReleases = {
          ...this.typedCompanyPressReleases,
          [payload.identifier]: { [payload.type]: payload.data }
        }
        this.typedCompanyPressReleases = { ...this.typedCompanyPressReleases }
      }
    },
    setList(payload) {
      if (payload) {
        console.log('PR PAYLOAD', payload)
        payload.filter((pressRelease) => {
          if (pressRelease && pressRelease.content) {
            if (pressRelease.content.indexOf('<body>') > -1) {
              pressRelease.content = pressRelease.content.replace('<body>', '<div>')
              pressRelease.content = pressRelease.content.replace('</body>', '</div>')
            } else {
              pressRelease.content = `<div>${pressRelease.content}</div>`
            }
          }
        })
        if (!this.companyPressReleases && !this.companyPressReleases[this.selectedCompanyId]) {
          this.companyPressReleases[this.selectedCompanyId] = []
        }

        this.companyPressReleases = {
          ...this.companyPressReleases,
          [this.selectedCompanyId]: payload
        }
        this.companyPressReleases = { ...this.companyPressReleases }
      }
    },
    setOneByPressReleaseId(pressRelease) {
      if (pressRelease && pressRelease.content) {
        if (pressRelease.content.indexOf('<body>') > -1) {
          pressRelease.content = pressRelease.content.replace('<body>', '<div>')
          pressRelease.content = pressRelease.content.replace('</body>', '</div>')
        }
      }

      if (this.companyPressReleases[this.selectedCompanyId]) {
        const prPostIndex = this.companyPressReleases[this.selectedCompanyId].findIndex(
          (pr) =>
            pr.postable_type === 'App\\Models\\CompanyPressRelease' &&
            Number(pr.postable_id) === Number(pressRelease.id)
        )
        let prPost = {
          ...this.companyPressReleases[this.selectedCompanyId][prPostIndex]
        }

        prPost.postable = pressRelease
        this.companyPressReleases[this.selectedCompanyId][prPostIndex] = prPost
        this.companyPressReleases = { ...this.companyPressReleases }
      }
    },
    async translate(id) {
      this.loadingTranslate = true
      const response = await repository.translate(useCompaniesStore().selectedCompanySN, id)
      if (response.status === 200 && response.data) {
        this.setOneByPressReleaseId(response.data)
      }
      this.loadingTranslate = false
    },
    showMenu(payload) {
      let result = this.companyPressReleases[this.selectedCompanyId].findIndex(
        (pressRelease) => Number(pressRelease.id) === Number(payload.id)
      )
      this.companyPressReleases[this.selectedCompanyId][result].show =
        !this.companyPressReleases[this.selectedCompanyId][result].show
      this.companyPressReleases = { ...this.companyPressReleases }
    },
    async one(id) {
      try {
        this.selectedCompanyId = Number(useCompaniesStore().selectedCompanyId)

        let pressRelease

        if (this.companyPressReleases[Number(useCompaniesStore().selectedCompanyId)]) {
          pressRelease = this.companyPressReleases[Number(useCompaniesStore().selectedCompanyId)].find(
            (pressRelease) => Number(pressRelease.id) === Number(id)
          )
        }

        if (!pressRelease || !pressRelease.content) {
          this.loading = true
          const response = await repository.one(useCompaniesStore().selectedCompanySN, id)
          console.log(response)
          this.loading = false
          if (response.status === 200 && response.data) {
            let pressReleaseData = response.data

            if (pressReleaseData && pressReleaseData.content) {
              if (pressReleaseData.content.indexOf('<body>') > -1) {
                pressReleaseData.content = pressReleaseData.content.replace('<body>', '<div>')
                pressReleaseData.content = pressReleaseData.content.replace('</body>', '</div>')
              }
            }

            let pr

            if (this.companyPressReleases[this.selectedCompanyId]) {
              pr = this.companyPressReleases[this.selectedCompanyId].find(
                (pr) => Number(pr.id) === Number(pressReleaseData.id)
              )
            }

            if (!pr) {
              if (!this.companyPressReleases[this.selectedCompanyId]) {
                this.companyPressReleases = {
                  ...this.companyPressReleases,
                  [this.selectedCompanyId]: []
                }
              }
              this.companyPressReleases[this.selectedCompanyId].push(pressReleaseData)
            }
          }
        }
      } catch (e) {
        this.loading = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async fetch(payload = null) {
      try {
        let identifier = 'global'
        if (payload.companyId) {            

          identifier = payload.companyId
          this.selectedCompanyId = Number(payload.companyId)
        }
        this.loading = true
        //For jobs to cache 
       useJobsStore().runEndpoint('fetch' + JSON.stringify(payload) + identifier, async () => {
          // if (this.companyPressReleases[Number(useCompaniesStore().selectedCompanyId)] === undefined) {

          this.loading = true
          const response = await repository.list(payload)
          this.loading = false

          if (response.status === 200 && response.data) {
            const responsePayload = {
              data: response.data.data,
              identifier,
              type: payload.type
            }

            this.setTypedList(responsePayload)

            this.nextPageUrl = response.data.next_page_url
          }
       }, 1000 * 60 * 2, true)
      } catch (e) {
        this.loading = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async scroll() {
      try {
        if (this.nextPageUrl !== null && !this.loadingNew) {
          this.loadingNew = true
          const response = await repository.scroll(this.nextPageUrl)
          if (response.status === 200 && response.data) {
            if (this.companyPressReleases[this.selectedCompanyId] === undefined) {
              this.companyPressReleases[this.selectedCompanyId] = response.data
            } else {
              response.data.find((response) => {
                const flag = this.companyPressReleases[this.selectedCompanyId].findIndex((companyPressRelease) => {
                  return response.id === companyPressRelease.id
                })
                if (flag > -1) {
                  this.companyPressReleases[this.selectedCompanyId][flag] = response
                } else {
                  this.companyPressReleases[this.selectedCompanyId].push(response)
                }
              })
            }

            this.companyPressReleases = { ...this.companyPressReleases }

            this.nextPageUrl = response.data.next_page_url
            this.loadingNew = false
          }
        }
      } catch (e) {
        this.loadingNew = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    internalUpdate(payload) {
      this.companyPressReleases[this.selectedCompanyId][payload.index] = payload.pressRelease
      this.companyPressReleases[this.selectedCompanyId] = [...this.companyPressReleases[this.selectedCompanyId]]
    },
    async refetch() {
      try {
        this.selectedCompanyId = Number(useCompaniesStore().selectedCompanyId)
        this.loadingNew = true
        const response = await repository.list(useCompaniesStore().selectedCompanySN)
        this.loadingNew = false
        if (response.status === 200 && response.data) {
          this.setList(response.data.data)
          if (response.data.data) {
            response.data.data.filter((pressRelease) => {
              if (pressRelease && pressRelease.content) {
                if (pressRelease.content.indexOf('<body>') > -1) {
                  pressRelease.content = pressRelease.content.replace('<body>', '<div>')
                  pressRelease.content = pressRelease.content.replace('</body>', '</div>')
                } else {
                  pressRelease.content = `<div>${pressRelease.content}</div>`
                }
              }
            })
            if (!this.companyPressReleases && this.companyPressReleases[this.selectedCompanyId]) {
              this.companyPressReleases[this.selectedCompanyId] = []
            }

            this.companyPressReleases = {
              ...this.companyPressReleases,
              [this.selectedCompanyId]: response.data.data
            }
            this.companyPressReleases = { ...this.companyPressReleases }
          }
        }
      } catch (e) {
        this.loadingNew = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async like(payload) {
      try {
        const companyId = payload.companyId ? payload.companyId : useCompaniesStore().selectedCompanyId

        if (this.companyPressReleases && this.companyPressReleases[Number(companyId)]) {
          const pressReleases = this.companyPressReleases[Number(companyId)]
          const index = pressReleases.findIndex((pressRelease) => {
            return Number(pressRelease.press_release_id) === Number(payload.pressReleaseId)
          })
          console.log('ps lslkjf  : ', pressReleases[index])
          const likeIndex = pressReleases[index].likes.findIndex(
            (like) =>
              Number(like.stock_user_id) === Number(useUsersStore().user.profile_id) &&
              like.reaction === payload.reaction
          )

          if (likeIndex > -1) {
            pressReleases[index].likes.splice(likeIndex, 1)
          } else {
            pressReleases[index].likes.push({
              likeable_id: payload.postId,
              likeable_type: 'App\\Models\\RoomPost',
              reaction: payload.reaction,
              stock_user: useUsersStore().user.profile,
              stock_user_id: useUsersStore().user.profile.id
            })
          }
        }

        const response = await postrepository.postLike(payload.postId, {
          reaction: payload.reaction
        })
        if (response.status === 204) {
          useSnackbarStore().snack({
            text: 'Press release liked successfully',
            type: 'success',
            show: true,
            autoclose: true
          })
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async pushPressRelease(payload) {
      payload = [payload]
      this.companyPressReleases = {
        ...this.companyPressReleases,
        [payload[0].company.id]: payload
      }
    },
    async addPressRelease(payload) {
      try {
        const response = await repository.add(this.selectedCompanyId, payload)
        if (response.status === 204) {
          useSnackbarStore().snack({
            text: 'Press Release added successfully',
            type: 'success',
            show: true,
            autoclose: true
          })
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async save(pressReleaseId) {
      try {
        const response = await repository.save(useCompaniesStore().selectedCompanyId, pressReleaseId)
        if (response.status === 204) {
          useSnackbarStore().snack({
            text: 'Press release saved successfully',
            type: 'success',
            show: true,
            autoclose: true
          })
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async shareWithAll(payload) {
      try {
        const response = await repository.sharePressReleaseWithAll(
          useCompaniesStore().selectedCompanyId,
          payload.press_release_id,
          { message: payload.message }
        )
        if (response.status === 204) {
          useSnackbarStore().snack({
            text: 'Press Release shared successfully',
            type: 'success',
            show: true,
            autoclose: true
          })
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async shareWithCompany(payload) {
      try {
        const response = await repository.sharePressReleaseWithCompany(payload.company_id, payload.press_release_id, {
          message: payload.message
        })
        if (response.status === 204) {
          useSnackbarStore().snack({
            text: 'Press Release shared successfully',
            type: 'success',
            show: true,
            autoclose: true
          })
        } else {
          // console.log('failed to share Press Release')
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    reset() {
      this.companyPressReleases = {}
    },
    setLoading(boolean) {
      this.loading = boolean
    },
    async uploadImage(payload) {
      try {
        this.loading = true
        const response = await repository.uploadImage(payload.companyId, payload.pressReleaseId, {
          image: payload.image
        })
        console.log('response', response)
        if (response.status === 200 && response.data) {
          this.setOneByPressReleaseId(response.data)
          useSnackbarStore().snack({
            text: i18n.global.t('Image Uploaded Successfully'),
            type: 'success',
            show: true,
            autoclose: true
          })
          this.hasError = false
        } else {
          this.hasError = true
        }
        this.loading = false
      } catch (e) {
        this.hasError = true
        this.loading = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    }
  }
})
