// import { specificLanguage, i18n } from "@/js/language"
import { defineStore } from "pinia"

export const useLanguagesStore = defineStore("languages", {
  state: () => ({
    active: "en",
    list: ["en"],
  }),
  actions: {
    setLanguage(language) {
      this.active = language
      localStorage.language = language
      // i18n.global.locale.value = this.active
    },
    init() {
      this.setLanguage("en")
    },
    async set(language) {
      this.setLanguage(language)
    },
  },
})
