
import { PushNotifications } from '@capacitor/push-notifications'
import router from '@/js/router'
import { useUsersStore } from '../stores/users'
import { useNotificationsStore } from '../stores/notifications'
import { Capacitor } from '@capacitor/core'

if (Capacitor.isNativePlatform()) {
  PushNotifications.checkPermissions().then((res) => {
    if (res.receive !== 'granted') {
   

      PushNotifications.requestPermissions().then((res) => {
        if (res.receive === 'denied') {
          // console.log('Push Notification permission denied')
        } else {
          // console.log('Push Notification permission granted')
          register()
        }
      })
    } else {
      register()
    }
  })

  const register = () => {

    // Register with Apple / Google to receive push via APNS/FCM
    PushNotifications.register()

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration', (token) => {
      useUsersStore().setFCMToken({ token: token.value })
      localStorage.setItem('fcmToken', token.value)
    })

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError', (error) => {
      alert('Error on registration: ' + JSON.stringify(error))
    })

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived', (notification) => {
      useNotificationsStore().inAppPushNotification(notification)
    })

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
      if (notification.notification.data.link) {
        let url = notification.notification.data.link.replace('axyer://', '/')
        // check it we are not in same url
        if (router.history.current.path.substring(1) !== url) {
          router.push(url)
        }
      }
    })
  }
}

export default PushNotifications
