<template>
  <BaseButton
    class=" flex justify-center items-center  font-semibold focus:outline-none hover:border-gray-500 dark:text-custom-purple-100 text-custom-purple-500 border rounded-3xl space-x-1"
    :class="hoverWhite? 'hover:text-white' : 'hover:text-gray-500'"
  >
    <slot />
  </BaseButton>
</template>
  
  <script>
  
  export default {
    name: 'RoundedButton',
    props: {
      hoverWhite: {
        type: Boolean,
        default: false
      }
    }
  }
  </script>
  
