<template>
  <div :class="(reverse) ? 'flex justify-end pr-2' : 'flex flex-1 justify-between'">
    <div :class="(reverse) ?'flex justify-end w-10/12' : 'w-9/12 flex flex-col justify-center'">
      <div
        :class="(textSize) ? `${textSize} font-semibold text-left` : 'text-base font-semibold text-left'"
      >
        <!-- <p
          v-if="roomName"
          class="flex"
        >
          {{ roomName }}
        </p> -->
        <div
          class="flex items-center flex-grow-0 dark:text-custom-purple-100"
        >
          <span
            class="flex cursor-pointer sm:whitespace-nowrap"
            :class="nameClass"
            @click="$emit('click')"
          >{{ (name?.length > 60) ? name.slice(0,60) + '...' : name }}<VerifiedIcon
            class="ml-2"
            :stock-user="stockUser"
            :use-reputation="useReputation"
          />
          </span> 
          <IPOBadge
            v-if="company && company.hasOwnProperty('company_type') && company.company_type === 'ipo'"
            class="ml-2"
          />
          <PrivateBadge
            v-if="company && company.hasOwnProperty('company_type') && company.company_type === 'private'"
            class="ml-2"
          />
        </div>
        <span class="inline dark:text-custom-purple-200"><slot /></span>
      </div>
      <p
        v-if="subtitle?.length > 0"
        class="text-gray-400 font-medium -mt-1 cursor-pointer dark:text-custom-purple-100"
        :class="[subtitleClass, (subtitleSize) ? `${subtitleSize}` : (textSize) ? `${textSize}` : 'text-sm']"
        @click="$emit('click-subtitle')"
      >
        <v-runtime-template :template="template" />
      </p>
    </div>
  </div>
</template>

<script>
import VRuntimeTemplate from "vue3-runtime-template"

export default {
  name: 'NameSubTitle',
  components: {
    VRuntimeTemplate
  },
  props: {
    name: { type: String, default: '' },
    reverse: { type: Boolean, default: false },
    stockUser: { type: Object, default: () => {} },
    company: { type: Object, default: () => {} },
    subtitle: { type: String, default: '' },
    subtitleSize: { type: String, default: '' },
    subtitleClass: { type: String, default: '' },
    nameClass: { type: String, default: '' },
    textSize: { type: String, default: '' },
    roomName: { type: String, default: '' },
    useReputation: { type: Boolean, default: false }
  },
  emits: [
  'redirect', 'click','click-subtitle'
  ],
  computed: {
    template () {
      return `<div>${this.subtitle}</div>`
    }
  },
  methods: {
    redirect (data) {
      this.$emit('redirect', data)
    }
  }
}
</script>
