import { initializeApp } from 'firebase/app'
import PushNotifications from './firebase-native'
import { Capacitor } from '@capacitor/core'

const firebaseConfig = {
  apiKey: 'AIzaSyCnlvfcFQfY-UOta_8zoKOobkVHtbBNvbI',
  authDomain: 'axyer-c30d7.firebaseapp.com',
  projectId: 'axyer-c30d7',
  storageBucket: 'axyer-c30d7.appspot.com',
  messagingSenderId: '1052147864723',
  appId: '1:1052147864723:web:3d4fc452310b9a756e2966',
  measurementId: 'G-M4YSV9ZESY'
}

const firebase = initializeApp(firebaseConfig)


async function setTokenAtLocalStorage() {
  if (Capacitor.isNativePlatform()) {
    PushNotifications.checkPermissions()
  }

}

setTokenAtLocalStorage()

export default { firebase }
