<template>
  <div
    :class="upperClasses"
  >
    <select
      :id="id"
      :required="required"
      :value="modelValue"
      :class="[classes, border, appearanceNone ? 'appearance-none' : '',hasDarkMode ? 'dark:bg-custom-purple-450 dark:text-custom-purple-100':'']"

      @update:model-value="$emit('update:model-value', $event)"
      @change="$emit('update:model-value', $event)"
    >
      <slot />
    </select>
  </div>
</template>

<script>

export default {
  name: 'BaseSelect',
  props: {
    id: { type: String, default: '' },
    required: { type: Boolean, default: false },
    modelValue: { type: String, default: '', required: true },
    classes: { type: String, default: 'px-4 py-2 w-full rounded-md bg-transparent' },
    border: { type: String, default: 'border-none' },
    appearanceNone: { type: Boolean, default: true },
    upperClass: { type: Boolean, default: true },
    hasDarkMode: { type: Boolean, default: true },
  },
  emits: [
    'update:model-value'
  ],
  computed: {

    upperClasses(){
      if(this.upperClass){
        if(this.hasDarkMode){
        return 'relative w-full border border-gray-300 dark:border-custom-purple-300 rounded-sm bg-white'
      }else{
        return 'relative w-full border border-gray-300 rounded-sm bg-white'
      }
      }else{
        return ''
      }
      
    }

  }
}
</script>
<style scoped></style>
