<template>
  <ShareNetwork
    :network="network"
    :url="url"
    :title="title"
    :description="description"
    :hashtags="hashtags"
    class="flex"
    @click="$emit('clicked')"
  >
    <FontAwesomeIcon
      class="text-3xl"
      :icon="['fab', network]"
    />
  </ShareNetwork>
</template>
  
  <script>
  
  export default {
    name: 'SocialShareButton',
    props: {
        network: { type: String, default: null },
        url: { type: String, default: null },
        title: { type: String, default: null },
        description: { type: String, default: null },
        hashtags: { type: String, default: 'eucaps' },
    },
    emits: ['clicked']
  }
  </script>

  