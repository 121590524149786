<template>
  <portal
    v-if="visible"
    to="dialog"
  >
    <FadeInOut
      :duration="50"
    >
      <div
        v-show="visible"
        ref="modal"
        tabindex="0"
        class="modal fixed inset-0 flex items-end justify-center z-50"
        @keyup.esc="toggle"
      >
        <div
          class="modal-overlay absolute w-full h-full bg-gray-900 opacity-30"
          @click="toggle"
        >
          <div
            class="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-white text-sm z-50"
            @click="toggle"
          >
            <svg
              class="fill-current text-white"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
            </svg>
            <span class="text-sm">{{ $t("(Esc)") }}</span>
          </div>
        </div>
        <SlideInOut
          tag="div"
          class="flex absolute inset-0 justify-center items-end w-full z-10"
          group
          :duration="200"
          @click="toggle"
        >
          <div
            v-show="show"
            key="modal-container"
            class="modal-container absolute dark:bg-custom-purple-500 dark:text-primary-dark bg-white w-full md:max-w-md mx-auto shadow-lg dark:shadow-lg-dark bottom-0 z-50 h-3/4 lg:max-w-xl"
            @click.stop
          >
            <!-- Add margin if you want to see some of the overlay behind the modal-->

            <div
              class="modal-content px-6 w-full absolute overflow-scroll inset-0"
            >
              <!--Title-->
              <div class="flex justify-between items-center pb-3 relative">
                <slot
                  v-if="title !== ''"
                  name="title"
                >
                  <p class="text-2xl font-bold">
                    {{ title }}
                  </p>
                </slot>
                <div
                  class="modal-close cursor-pointer z-50"
                  @click="toggle"
                />
              </div>

              <!--Body-->
              <slot id="scrollingModal" />
              <div class="flex justify-end pt-2">
                <slot name="footer" />
              </div>
            </div>
          </div>
        </SlideInOut>
      </div>
    </FadeInOut>
  </portal>
</template>

<script>

import { SlideInOut, FadeInOut } from 'vue3-transitions'
import hashPushMixin from '@/mixins/hashPushMixin'

export default {
  name: 'ModalMenu',
  components: {
    SlideInOut,
    FadeInOut
  },
  mixins: [hashPushMixin],
  props: {
    title: { type: String, default: '' },
    show: { type: Boolean, default: false }
  },
  emits: [
    'focus',
    'toggle'
  ],
  watch: {
    show (newVal) {
      if (newVal) {
        this.$emit('focus')
        // console.log('MODALMENU SHOW TRUE')
        this.pushRandomHashUrl()
      } else {
        // console.log('MODALMENU SHOW FALSE')
        this.resetRandomHashUrl()
      }
    }
  },
  mounted () {
    if (this.show) {
      // console.log('MODALMENU MOUNTED')
      this.pushRandomHashUrl()
    }
  },
  unmounted () {
    // document.body.style.overflow = 'visible'
    // console.log('MODALMENU DESTROYED')
    this.toggle()
    this.resetRandomHashUrl()
  },
  methods: {
    toggle () {
      this.$emit('toggle')
    }
  }
}
</script>
