<template>
  <button
    class="flex justify-start font-medium text-sm leading-6 focus:outline-none dark:text-custom-purple-100"
    :class="design"
  >
    <div class="relative">
      <!-- <Icon
        name="plus"
        class="text-sm bg-white dark:bg-custom-purple-400 top-0 right-0 absolute h-2 w-3 -mr-1 mt-1 flex items-center justify-center pb-1 z-20"
      /> -->
      <Icon
        name="like"
        class="text-xl ml-1 relative"
      />
    </div>
    <div class="ml-1 mt-1 text-sm font-semibold">
      Like
    </div>
  </button>
</template>

<script>

export default {
  name: 'LikeButton',
  props: {
    design: { type: String, default: 'w-20 text-left' },
    numberOfLikes: { type: Number, default: 0 }
  }
}
</script>
