<template>
  <div class="flex flex-col w-full max-w-sm">
    <VerifiedUserCard
      v-if="user.profile.is_verified"
      :user="user"
      class="mb-8"
    />
    <div
      v-if="loadingContact"
      class="p-4 flex justify-center"
    >
      <CircleLoader />
    </div>
    <div v-else-if="salesContacted">
      <div class="mb-4">
        Thanks for reaching out! Sales has been notified and we will reach out to you as soon as possible.
      </div>
      <ActiveButton
        class="bg-custom-orange-400 h-12 self-center rounded-xl w-full"
        @click="$emit('go-to-home', $event)"
      >
        <span class="text-white">
          Go back to Eucaps
        </span>
      </ActiveButton>
    </div>
    <div v-else>
      <div class="mb-2">
        Hi! Please specify your phone number below and
        we will contact you regarding {{ company.name }}.
      </div>

      <BaseInput
        placeholder="Phone number"
        :model-value="phone"
        class="w-full mb-8"
        @update:model-value="$emit('phone', $event.target.value)"
      />
      <ActiveButton
        class="bg-custom-orange-400 h-12 self-center rounded-xl w-full disabled:opacity-50"
        :disabled="phone.length < 7"
        @click="$emit('contact-me', $event)"
      >
        <span class="text-white">
          Contact me
        </span>
      </ActiveButton>
      <SquareButton
        class=" bg-gray-400 w-full rounded-xl text-base text-custom-purple-500 font-bold border-none px-6 py-2 mt-8"
        @click="$emit('go-back', $event)"
      >
        <span class="text-white">
          Cancel
        </span>
      </SquareButton>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CompanyAddress',
  props: {
    phone: {
      type: String,
      default: ''
    },
    loadingContact: {
      type: Boolean,
      default: false
    },
    salesContacted: {
      type: Boolean,
      default: false
    },
    user: {
      type: Object,
      default: () => {}
    },
    company: {
      type: Object,
      default: () => {}
    }
  },
emits: [
'go-to-home',
'phone',
'contact-me',
'go-back'
]
}
</script>
