import { RepositoryFactory } from "@/api/repositoryFactory"
const repository = RepositoryFactory.get("companies")
import { defineStore } from "pinia"
import { useSnackbarStore } from "./snackbar"
import { useCompaniesStore } from "./companies"

export const useDocumentsStore = defineStore("documents", {
  state: () => ({
    documents: [],
    loading: false,
    newLoading: false,
  }),
  actions: {
    setLoading(loading) {
      this.loading = loading
    },
    async fetch(companyUrl) {
      try {
        this.loading = true
        const response = await repository.documentList(companyUrl)
        this.loading = false
        if (response.status === 200 && response.data) {
          this.documents = response.data
        }
      } catch (e) {
        this.loading = false
      }
    },
    async save(documentId) {
      try {
        const response = await repository.saveDocument(
          useCompaniesStore().selectedCompanyId,
          documentId
        )
        if (response.status === 204) {
          useSnackbarStore().snack({
            text: "Document added successfully",
            type: "success",
            show: true,
            autoclose: true,
          })
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async refetch() {
      try {
        this.selectedCompanyId = useCompaniesStore().selectedCompanyId
        this.newLoading = true
        const response = await repository.documentList(
          useCompaniesStore().selectedCompanySN
        )
        this.newLoading = false
        if (response.status === 200 && response.data) {
          this.documents = response.data
        }
      } catch (e) {
        this.newLoading = false
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async pushDocuments(payload) {
      payload = [payload]

      this.documents = this.documents.push(payload)
    },
    async addDocument(payload) {
      try {
        this.loading = true
        const response = await repository.addDocument(
          payload.companyUrl,
          payload.formData
        )
        if (response.status === 200 && response.data) {
          this.documents = response.data
          this.loading = false
          // useSnackbarStore().snack({ text: 'Document added successfully', type: 'success', show: true, autoclose: true })
        }
      } catch (e) {
        this.loading = false
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async updateDocument(payload) {
      try {
        this.loading = true
        const response = await repository.updateDocument(
          payload.companyUrl,
          payload.documentId,
          payload.formData
        )
        if (response.status === 200 && response.data) {
          this.documents = response.data
          this.loading = false
          useSnackbarStore().snack({
            text: "Document edited successfully",
            type: "success",
            show: true,
            autoclose: true,
          })
          // useSnackbarStore().snack({ text: 'Document added successfully', type: 'success', show: true, autoclose: true })
        }
      } catch (e) {
        this.loading = false
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async deleteDocument(payload) {
      try {
        this.loading = true
        const response = await repository.deleteDocument(
          payload.companyUrl,
          payload.documentId,
        )
        if (response.status === 200 && response.data) {
          this.documents = response.data
          this.loading = false
          useSnackbarStore().snack({
            text: "Document deleted successfully",
            type: "success",
            show: true,
            autoclose: true,
          })
        }
      } catch (e) {
        this.loading = false
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    reset() {
      this.documents = {}
    },
  },
})
