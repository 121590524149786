<template>
  <div>
    <p
      v-if="showConfirmButton"
      class="text-right mt-8 text-xs"
    >
      <slot name="title">
        <p class="w-full text-center dark:text-primary-dark text-xl">
          {{ title }}
        </p>
      </slot>
    </p>

    <p class="text-sm dark:text-primary-offWhite text-center mt-2">
      {{ body }}
    </p>
    <slot
      name="message"
      class="text-sm dark:text-primary-dark text-center"
    />
    <p
      v-if="showConfirmButton"
      class="text-right mt-8 text-xs"
    >
      <BaseButton
        class="border border-custom-purple-500 ml-3 bg-custom-purple-500 text-white"
        @click="$emit('agree', type)"
      >
        <slot name="agree">
          {{ $t("Agree") }}
        </slot>
      </BaseButton>
      <BaseButton
        class="border dark:border-white border-custom-purple-500 ml-3"
        @click="$emit('toggle')"
      >
        {{ $t("Cancel") }}
      </BaseButton>
    </p>
  </div>
</template>

<script>
export default {
  name: 'AlertModal',
  props: {
    title: { type: String, default: '' },
    body: { type: String, default: '' },
    type: { type: String, default: '' },
    showConfirmButton: { type: Boolean, default: false }
  },
emits: [
'agree',
'toggle'
]
}
</script>
