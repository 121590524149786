<template>
  <div class="rounded-b-md shadow-none py-4 px-6 bg-white dark:bg-custom-purple-400 dark:text-custom-purple-100">
    <div class="grid grid-cols-3 gap-x-8 gap-y-1">
      <!-- Row 1, Column 1 -->
      <div>
        <div
          v-for="screenerFilter in screenerFilters"
          :key="screenerFilter.code"
        >
          <div v-if="screenerFilter.type === 'range' && screenerFilter.code === 'marketcap'">
            <p class="font-bold mt-3 mb-5">
              Marketcap
            </p>
            <RangeSlider
              :id="'minmax-range-' + screenerFilter.code"
              :start-at="screenerFilter.preselect ? screenerFilter.preselect[0] : 0"
              :end-at="screenerFilter.preselect ? screenerFilter.preselect[1] : 0"
              :min="screenerFilter.data.defaultRange[0]"
              :max="screenerFilter.data.defaultRange[1]"
              :suffix="screenerFilter.suffix ? screenerFilter.suffix : ''"
              @on-change="$emit('input-range', {screenerFilter, preselect: [...$event] })"
              @on-changed="$emit('search')"
            />
          </div>
        </div>
      </div>
      <!-- Row 1, Column 2 -->
      <div>
        <div
          v-for="screenerFilter in screenerFilters"
          :key="screenerFilter.code"
        >
          <div v-if="screenerFilter.type === 'range' && screenerFilter.code === 'price'">
            <p class="font-bold mt-3 mb-5">
              Price
            </p>
            <RangeSlider
              :id="'minmax-range-' + screenerFilter.code"
              :start-at="screenerFilter.preselect ? screenerFilter.preselect[0] : 0"
              :end-at="screenerFilter.preselect ? screenerFilter.preselect[1] : 0"
              :min="screenerFilter.data.defaultRange[0]"
              :max="screenerFilter.data.defaultRange[1]"
              :suffix="screenerFilter.suffix ? screenerFilter.suffix : ''"
              @on-change="$emit('input-range', {screenerFilter, preselect: [...$event] })"
              @on-changed="$emit('search')"
            />
          </div>
        </div>
      </div>
      <!-- Row 1, Column 3 -->
      <div>
        <div
          v-for="screenerFilter in screenerFilters"
          :key="screenerFilter.code"
        >
          <div v-if="screenerFilter.type === 'range' && screenerFilter.code === 'shares'">
            <p class="font-bold mt-3 mb-5">
              Shares
            </p>
            <RangeSlider
              :id="'minmax-range-' + screenerFilter.code"
              :start-at="screenerFilter.preselect ? screenerFilter.preselect[0] : 0"
              :end-at="screenerFilter.preselect ? screenerFilter.preselect[1] : 0"
              :min="screenerFilter.data.defaultRange[0]"
              :max="screenerFilter.data.defaultRange[1]"
              :suffix="screenerFilter.suffix ? screenerFilter.suffix : ''"
              @on-change="$emit('input-range', {screenerFilter, preselect: [...$event] })"
              @on-changed="$emit('search')"
            />
          </div>
        </div>
      </div>

      <!-- Row 2, Column 1 -->
      <div>
        <div
          v-for="screenerFilter in screenerFilters"
          :key="screenerFilter.code"
        >
          <div v-if="screenerFilter.type === 'multiselect' && screenerFilter.code === 'countries'">
            <Multiselect
              :model-value="screenerFilter.preselect"
              :options="screenerFilter.data.list ? screenerFilter.data.list : []"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :show-labels="false"
              :preserve-search="true"
              placeholder="Country"
              class=" text-xs f "
              label="name"
              track-by="name"
              :preselect-first="false"
              @update:model-value="$emit('update-multi-select',{screenerFilter, preselect: [...$event] })"
            >
              <template
                v-if="screenerFilter.data.list.length && !isOpen"
                #selection="{ values, isOpen }"
              >
                <div class="mb-2 flex flex-wrap">
                  <span
                    v-for="(data,index) in values"
                    :key="data.id"
                  >{{ data.name }}{{ (values.length -1 > index) ? ',' : '.' }} </span>
                </div>
              </template>
            </Multiselect>
          </div>
        </div>
      </div>
      <!-- Row 2, Column 2 -->
      <div>
        <div
          v-for="screenerFilter in screenerFilters"
          :key="screenerFilter.code"
        >
          <div v-if="screenerFilter.type === 'multiselect' && screenerFilter.code === 'sectors'">
            <Multiselect
              :model-value="screenerFilter.preselect"
              :options="screenerFilter.data.list ? screenerFilter.data.list : []"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              :show-labels="false"
              placeholder="Sector"
              class="text-xs"
              label="name"
              track-by="name"
              :preselect-first="false"
              @update:model-value="$emit('update-multi-select',{screenerFilter, preselect: [...$event] })"
            >
              <template
                v-if="screenerFilter.data.list.length && !isOpen"
                #selection="{ values, isOpen }"
              >
                <div class="mb-2">
                  <span
                    v-for="(data,index) in values"
                    :key="data.id"
                  >{{ data.name }}{{ (values.length -1 > index) ? ',' : '.' }} </span>
                </div>
              </template>
            </Multiselect>
          </div>
        </div>
      </div>
      <!-- Row 2, Column 3 -->
      <div>
        <div class="w-full h-full flex items-center justify-end gap-x-3 pr-10 md:pr-0">
          <Button
            class="w-32 h-10 font-bold rounded-full border-gray-500 dark:border-custom-purple-500 border"
            :class="appliedFilter ? 'bg-custom-purple-100 dark:bg-custom-purple-200 dark:text-custom-purple-500' : 'text-custom-purple-500 dark:bg-custom-purple-450 hover:bg-custom-purple-50 dark:hover:bg-custom-purple-450 dark:text-white' "
            @click="$emit('reset')"
          >
            Clear filter
          </Button>
          <Button
            class="w-32 h-10 font-bold rounded-full border-gray-500 dark:border-custom-purple-500 border"
            :class="(filterChanged || (!filterChanged && !appliedFilter)) ? 'bg-custom-purple-500 text-white' : 'text-custom-purple-500 dark:text-white dark:bg-custom-purple-450 hover:bg-custom-purple-50 dark:hover:bg-custom-purple-450'"
            @click="$emit('apply')"
          >
            Apply filter
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ExploreFilter',
  props: {
    showMore: {
      type: Boolean,
      default: false
    },
    screenerFilters: {
      type: Array,
      default: () => []
    },
	appliedFilter: {
      type: Boolean,
      default: false
	},
  filterChanged: { type: Boolean, default: false },
  },
  emits: [
    'toggle-show-more',
    'update-multi-select',
    'input-range',
    'search',
    'placement',
    'apply',
    'reset'
  ],
}
</script>


<style lang="scss">

.multiselect {
  @apply border-gray-300 #{!important};
  @apply dark:border-custom-purple-300 #{!important};
}

.multiselect__content-wrapper {
  @apply dark:bg-custom-purple-450 #{!important};
  @apply text-primary #{!important};
  @apply dark:text-white #{!important};
}

.multiselect__placeholder {
  color: black;
  height: 1.9rem;
  @apply dark:text-white #{!important};
}
.multiselect__tags {
  padding-top: 0.54rem;
  @apply dark:bg-custom-purple-450 #{!important};
  @apply border-gray-300 #{!important};
  @apply dark:border-custom-purple-300 #{!important};
  @apply text-primary #{!important};
  @apply dark:text-white #{!important};
}

.multiselect__single,
.multiselect__input {
  @apply dark:bg-custom-purple-450 #{!important};
  @apply text-primary #{!important};
  @apply dark:text-white #{!important};
  @apply py-1 #{!important};
}
.multiselect__option {
  @apply text-sm;
}
</style>