import { RepositoryFactory } from "@/api/repositoryFactory"
import router from "@/js/router"
const repository = RepositoryFactory.get("lists")
import { defineStore } from "pinia"
import { useSnackbarStore } from "./snackbar"
import { useUsersStore } from "./users"
import { useJobsStore } from './jobs'

export const useListsStore = defineStore("lists", {
  state: () => ({
    originalListFilters: [
      {
        code: "sectors",
        group: "sectors",
        type: "multiselect",
        data: {},
        selected: null,
        preselect: null,
      },
      {
        code: "countries",
        group: "countries",
        type: "multiselect",
        data: {},
        selected: null,
        preselect: null,
      },
      {
        code: "stats_all_time",
        group: "stats",
        type: "range",
        data: { defaultRange: [0, 0] },
        selected: null,
        preselect: [0, 0],
      },
      {
        code: "stats_six_months",
        group: "stats",
        type: "range",
        data: { defaultRange: [0, 0] },
        selected: null,
        preselect: [0, 0],
      },
      {
        code: "followers",
        group: "stats",
        type: "range",
        data: { defaultRange: [0, 0] },
        selected: null,
        preselect: [0, 0],
      },
    ],
    searchResults: [],
    lists: [],
    weeklyWinners: [],
    mostAddedCompanies: [],
    topLists: [],
    recentAddedPremiumCompanies: [],
    singleListPremiumCompanies: [],
    listCompaniesStats: {},
    otherListFollowedbyFollowers: [],
    singleListNews: [],
    creatorLists: [],
    mostFollowed: [],
    weeklyTopLists: [],
    topFiftyLists: [],
    usersLists: [],
    selectedList: {},
    loading: false,
    loadingNew: false,
    loadingListCompanies: false,
    listsLoading: {
      allList: false,
      weeklyWinners: false,
      topLists: false,
      recentPremiumCompanies: false,
      mostAdded: false,
      mostFollowed: false,
      listInfo: false
    },
    weeklyTopListsLoading: false,
    listIds: [],
    allListNextPageUrl: null,
    searchNextPageUrl: null,
    nextPageUrl: null,
    searchListsPhrase: "",
    searchListsSortable: "title",
    searchListsOrder: "asc",
    countries: [],
    sectors: [],
    loadingLists: false,
    listsTotal: 0,
    minStatsAllTime: 0,
    maxStatsAllTime: 0,
    minStatsSixMonths: 0,
    maxStatsSixMonths: 0,
    maxFollowers: 0,
    filterList: [],
    chartLoading: false,
    chartData: [],
    filterListLoading: false,
	userTopLists: [],
	userTopListsLoading: false,
	otherUserTopLists: [],
	otherUserTopListsLoading: false,
	searchTopListsSortable: "default",
    searchTopListsOrder: "asc",
  }),
  getters: {
    listFilters(state) {
      const filters = [...state.originalListFilters]

      filters.map((f) => {
        if (f.code === "sectors") {
          f.data = { ...f.data, list: state.sectors }
        }

        if (f.code === "countries") {
          f.data = { ...f.data, list: state.countries }
        }

        if (f.code === "stats_all_time") {
          f.data.defaultRange = [state.minStatsAllTime, state.maxStatsAllTime]

          if (f.preselect[1] === 0) {
            f.preselect = [state.minStatsAllTime, state.maxStatsAllTime]
          }
        }
        if (f.code === "followers") {
          f.data.defaultRange = [0, state.maxFollowers]

          if (f.preselect[1] === 0) {
            f.preselect = [0, state.maxFollowers]
          }
        }
        if (f.code === "stats_six_months") {
          f.data.defaultRange = [
            state.minStatsSixMonths,
            state.maxStatsSixMonths,
          ]

          if (f.preselect[1] === 0) {
            f.preselect = [state.minStatsSixMonths, state.maxStatsSixMonths]
          }
        }
      })

      return filters
    },
    selectedListFilters(state) {
      const selected = []

      state.originalListFilters.map((f) => {
        if (f.selected) {
          if (f.type === "multiselect") {
            f.selected.map((listOption) => {
              const filterOption = {
                label: listOption.name,
                group: f.group,
                type: f.type,
                code: listOption.name + "-" + listOption.id,
                data: listOption,
                cleanable: true,
              }

              selected.push(filterOption)
            })
          } else if (f.type === "range") {
            if (
              f.data.defaultRange[0] !== f.selected[0] ||
              f.data.defaultRange[1] !== f.selected[1]
            ) {
              const filterOption = {
                label: f.code + " " + f.selected[0] + " - " + f.selected[1],
                group: f.group,
                type: f.type,
                code: f.code,
                data: f.selected,
                cleanable: true,
              }

              selected.push(filterOption)
            }
          }
        }
      })

      return selected
    },
  },
  actions: {
    setCountryFiltersData(payload) {
      let filter = this.listFilters.find(
        (filter) => filter.code === payload.code
      )
      filter.selected = [payload.data]
      filter.preselect = [payload.data]
      this.listFilters = [...this.listFilters]
    },
	async resetSearchTopListsSortable() {
      this.setSearchTopListsSortable({
        sortable: "default",
        order: "asc",
      })
    },
	async setSearchTopListsSortable(payload) {
      if (payload.sortable) {
        this.searchTopListsSortable = payload.sortable
      }
      if (payload.order) {
        this.searchTopListsOrder = payload.order
      }
    },
    setUsersList(payload) {
      this.usersLists = payload
    },
    pushDataToWeeklyTopList(payload) {
      if (payload) {
        const findIndex = this.weeklyTopLists.findIndex(
          (list) => list.id === payload.id
        )
        if (findIndex > -1) {
          this.weeklyTopLists[findIndex] = payload
        }
        this.weeklyTopLists = [...this.weeklyTopLists]
      }
    },
    async fetchListFilters() {
      try {
        const response = await repository.fetchListFilters()
        if (response.status === 200 && response.data) {
          this.filterList = response.data
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async deleteListFilter(filterId) {
      try {
        this.filterListLoading = true
        const response = await repository.deleteListFilter(filterId)
        if (response.status === 200 && response.data) {
          this.filterList = response.data
        }
        this.filterListLoading = false
      } catch (e) {
        this.filterListLoading = false

        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async saveFilters(payload) {
      try {
        const response = await repository.saveListFilters(payload)
        if (response.status === 200 && response.data) {
          this.filterList = response.data
          // console.log("response : ", response.data)
          useSnackbarStore().snack({
            type: "success",
            text: "filters saved successfully",
            show: true,
            autoclose: true,
          })
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async updateFilters(payload) {
      try {
        const response = await repository.updateListFilters(
          payload.filterId,
          payload
        )
        if (response.status === 200 && response.data) {
          this.filterList = response.data
          // console.log("response : ", response.data)
          useSnackbarStore().snack({
            type: "success",
            text: "filters saved successfully",
            show: true,
            autoclose: true,
          })
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async resetSearchListsSortable() {
      clearTimeout(this.searchCompaniesDebounce)
      this.setSearchListsSortable({
        searchListsPhrase: "",
        debounce: null,
        sortable: "title",
        order: "asc",
      })
    },
    async removeTag(payload) {
      let selectedFilter = this.listFilters.find(
        (filter) => filter.code === payload.group
      )
      if (selectedFilter === undefined) {
        selectedFilter = this.listFilters.find(
          (filter) =>
            filter.code === payload.code && filter.group === payload.group
        )
        selectedFilter.selected = null
      } else if (
        selectedFilter.selected &&
        selectedFilter.selected.length > 0
      ) {
        const findIndex = selectedFilter.selected.findIndex(
          (f) => f.id === payload.data.id
        )
        if (findIndex > -1) {
          selectedFilter.selected.splice(findIndex, 1)
        }
      }

      this.listFilters = [...this.listFilters]

      this.searchLists()
    },
    async setMyFilters(payload) {
      this.listFilters.forEach((filter) => {
        if (filter.code === "countries") {
          filter.selected = JSON.parse(payload.countries)
          filter.preselect = JSON.parse(payload.countries)
        } else if (filter.code === "sectors") {
          filter.selected = JSON.parse(payload.sectors)
          filter.preselect = JSON.parse(payload.sectors)
        } else if (filter.code === "followers") {
          if (payload.followers !== null) {
            filter.selected = JSON.parse(payload.followers)
            filter.preselect = JSON.parse(payload.followers)
          } else {
            filter.selected = null
            filter.preselect = [0, 0]
          }
        } else if (filter.code === "stats_all_time") {
          if (payload.stats_all_time !== null) {
            filter.selected = JSON.parse(payload.stats_all_time)
            filter.preselect = JSON.parse(payload.stats_all_time)
          } else {
            filter.selected = null
            filter.preselect = [0, 0]
          }
        } else if (filter.code === "stats_six_months") {
          if (payload.stats_six_months !== null) {
            filter.selected = JSON.parse(payload.stats_six_months)
            filter.preselect = JSON.parse(payload.stats_six_months)
          } else {
            filter.selected = null
            filter.preselect = [0, 0]
          }
        }
      })
      this.listFilters = [...this.listFilters]
    },
    resetFilters() {
      this.listFilters.forEach((filter) => {
        if (filter.type === "multiselect") {
          filter.selected = null
          filter.preselect = null
        } else if (filter.type === "range") {
          filter.selected = null
          filter.preselect = [0, 0]
        }
      })
    },
    async setCountryFilters(payload) {
      let country = null
      if (this.countries.length > 0) {
        country = this.countries.find(
          (country) => country.name === payload.name
        )
      } else {
        await this.fetchSectorAndCountries()
        country = this.countries.find(
          (country) => country.name === payload.name
        )
      }
      this.setCountryFiltersData({ code: payload.code, data: country })
    },
    async setSectorFilter(payload) {
      this.setCountryFiltersData({ code: "sectors", data: payload })
    },
    updateListFilterData(payload) {
      const index = this.originalListFilters.findIndex(
        (f) => f.code === payload.listFilter.code
      )
      let listFilter = {
        ...this.originalListFilters[index],
        preselect: payload.preselect,
      }
      let listFilters = [...this.originalListFilters]
      listFilters[index] = listFilter
      this.originalListFilters = [...listFilters]
    },
    async scrollLists() {
      try {
        if (this.nextPageUrl !== null && !this.loadingNew) {
          this.loadingNew = true
          // console.log("sortable", this.searchListsSortable)
          const payload = {
            filter: this.selectedListFilters,
            searchListsPhrase: this.searchListsPhrase,
            orderBy: this.searchListsSortable,
            order: this.searchListsOrder,
          }
          const response = await repository.scroll(this.nextPageUrl, payload)
          if (response.status === 200 && response.data) {
            if (this.lists.length < 1) {
              this.lists = response.data.result.data
            } else {
              response.data.result.data.find((response) => {
                const flag = this.lists.find((list) => {
                  return Number(response.id) === Number(list.id)
                })
                if (!flag) {
                  this.lists.push(response)
                }
              })
            }
            this.listsTotal = response.data.total
            this.nextPageUrl = response.data.result.next_page_url
          }
          this.loadingNew = false
        }
      } catch (e) {
        this.loadingNew = false
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async delayedSearchLists(payload) {
      if (payload.searchCompanyPhrase === "") {
        this.resetSearchListsSortable()
      } else {
        clearTimeout(this.searchListsDebounce)
        this.searchListsPhrase = payload.searchListsPhrase
        this.searchListsDebounce = setTimeout(() => {
          this.searchLists(payload)
        }, 600)
      }
    },
    async searchLists() {
      try {
        this.loadingLists = true
        const payload = {
          filter: this.selectedListFilters,
          searchListsPhrase: this.searchListsPhrase,
          orderBy: this.searchListsSortable,
          order: this.searchListsOrder,
        }

        const response = await repository.fetchAllList(payload)
        this.loadingLists = false
        if (response.status === 200 && response.data) {
          this.lists = response.data.result.data
          this.listsTotal = response.data.total
          this.nextPageUrl = response.data.result.next_page_url
        }
      } catch (e) {
        this.loadingLists = false
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    setLoadingLists(payload) {
      this.loadingLists = payload
    },
    async addListFilter(payload) {
      this.originalListFilters = [...payload]
    },
    async setSearchListsSortable(payload) {
      if (payload.searchListsPhrase || payload.searchListsPhrase === "") {
        this.searchListsPhrase = payload.searchListsPhrase
      }
      if (payload.debounce) {
        this.searchListsDebounce = payload.debounce
      }
      if (payload.sortable) {
        this.searchListsSortable = payload.sortable
      }
      if (payload.order) {
        this.searchListsOrder = payload.order
      }
    },
    async fetchSectorAndCountries() {
      try {
        const response = await repository.fetchSectorAndCountries()
        if (response.status === 200 && response.data) {
          this.sectors = response.data.sectors
          this.countries = response.data.countries
          this.minStatsAllTime = response.data.min_stats_all_time
          this.maxStatsAllTime = response.data.max_stats_all_time
          this.minStatsSixMonths = response.data.min_stats_six_months
          this.maxStatsSixMonths = response.data.max_stats_six_months
          this.maxFollowers = response.data.max_followers
        }
      } catch (e) {
        this.loadingNew = false
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async addList(payload) {
      try {
        this.loading = true
        const response = await repository.addList(payload)
        if (response.status === 201 && response.data) {
          this.setUsersList(response.data.lists)
          router.push({
            name: "singleList",
            params: { id: response.data.new_list_id },
          })
        } else {
          useUsersStore().setShowVerificationModal(true) // verification-needed
        }
        this.loading = false
      } catch (e) {
        this.loading = false
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async updateList(payload) {
      try {
        this.loading = true
        const response = await repository.updateList(payload.id, payload)
        if (response.status === 200 && response.data) {
          this.selectedList = response.data
        }
        this.loading = false
      } catch (e) {
        this.loading = false
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async changeListStockUser(payload) {
      try {
        this.loading = true
        const response = await repository.changeListStockUser(
          payload.list_id,
          payload
        )
        if (response.status === 200 && response.data) {
          this.selectedList = response.data
        } else if (response.status === 201) {
          useSnackbarStore().snack({
            type: "info",
            text: "You don't have permission to update list.",
            show: true,
            autoclose: true,
          })
        }
        this.loading = false
      } catch (e) {
        this.loading = false
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async removeList(payload) {
      try {
        this.loading = true
        const response = await repository.removeList(payload.id)
        if (response.status === 200 && response.data) {
          this.setUsersList(response.data)
          this.$store.useSnackbarStore().snack({
            text: "List deleted successfully.",
            type: "success",
            show: true,
            autoclose: true,
          })
        } else {
          useUsersStore().setShowVerificationModal(true) // verification-needed
        }
        this.loading = false
      } catch (e) {
        this.loading = false
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async removeCompanyFromList(payload) {
      try {
        this.loading = true
        const response = await repository.removeCompanyFromList(
          payload.listId,
          { company_id: payload.companyId, type: payload.type }
        )
        if (response.status === 200 && response.data) {
          if (payload.type === "company") {
            this.listIds = response.data
          } else {
            this.selectedList = response.data
          }
        } else {
          useUsersStore().setShowVerificationModal(true) // verification-needed
        }
        this.loading = false
      } catch (e) {
        this.loading = false
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async addCompaniesToList(payload) {
      try {
        this.loading = true
        const response = await repository.addCompaniesToList(payload.listId, {
          companyList: payload.companyList,
          type: payload.type,
        })
        if (response.status === 200 && response.data) {
          if (payload.type === "company") {
            this.listIds = response.data
          } else {
            this.selectedList = response.data
          }
        } else {
          useUsersStore().setShowVerificationModal(true) // verification-needed
        }
        this.loading = false
      } catch (e) {
        this.loading = false
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async fetchListCompanies(id) {
      // console.log('test test')
      try {
        useJobsStore().runEndpoint("fetchListCompanies" + id, async () => {
          this.loadingListCompanies = true
          const response = await repository.fetchListCompanies(id)
          if (response.status === 200 && response.data) {
            // console.log(response.data)
            this.selectedList = response.data
          }
        }, 1000 * 60 * 5, true)

      } catch (e) {
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      } finally {
        this.loadingListCompanies = false
      }
    },
    async fetchFollowingLists(payload) {
      try {
        this.loading = true
        const response = await repository.fetchFollowingLists(payload)
        if (response.status === 200 && response.data) {
          this.setUsersList(response.data)
        }
        this.loading = false
      } catch (e) {
        this.loading = false
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async fetchUsersList(payload) {
      try {
        this.loading = true
        const response = await repository.fetchUsersList(payload)
        if (response.status === 200 && response.data) {
          this.setUsersList(response.data)
        }
        this.loading = false
      } catch (e) {
        this.loading = false
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },

    resetSearchResult() {
      this.searchResults = [] 
    },

    async modalSearchLists (payload) {
      this.loadingLists = true
      try {
 
        const response = await repository.modalSearchLists( {searchListsPhrase: payload} )
        if (response.status === 200 && response.data) {
          // console.log('check search result', response.data)
          this.searchResults = response.data.data
          this.searchNextPageUrl = response.data.next_page_url
          this.loadingLists = false
        }
      } catch (e) {
        this.loadingLists = false
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    removeChartData(payload) {
      delete this.chartData[payload]
    },
    async fetchChartData(payload) {
      this.chartLoading = true
      try {
        // console.log('test payload', payload)
        const response = await repository.fetchFilteredList(payload)
        if (response.status === 200 && response.data) {
          // console.log('check chartData', payload, response.data)
          this.chartLoading = false
          Object.keys(response.data).map(key => {
            if(!this.chartData)this.chartData = {key: response.data[key]}
            else if(!this.chartData[key]) this.chartData[key] = response.data[key]
          })
          // this.lists = response.data.data
        }
      } catch (e) {
        this.chartLoading = false
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    // async fetchAll (payload) {

    // },
    async fetchAllList() {
		let payload = {
          resultType: "lists",
          resultSubType: "allLists",
        }
		if (this.searchTopListsSortable !== 'default') {
          payload = {
            ...payload,
            resultOrderType: {
              type: this.searchTopListsSortable,
              order: this.searchTopListsOrder
            }
          }
        }
      this.listsLoading.allList = true
      try {
        const response = await repository.fetchFilteredList(payload)
        if (response.status === 200 && response.data) {
          this.lists = response.data.result.data
          this.listsTotal = response.data.total
          this.allListNextPageUrl = response.data.result.next_page_url
        }

      } catch (e) {
        
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      } finally {
        this.listsLoading.allList = false

      }
    },

    async fetchMoreAllLists () {
      this.listsLoading.moreAllLists = true
      try {
		let payload = {
          resultType: "lists",
          resultSubType: "allLists",
        }
		if (this.searchTopListsSortable !== 'default') {
          payload = {
            ...payload,
            resultOrderType: {
              type: this.searchTopListsSortable,
              order: this.searchTopListsOrder
            }
          }
        }
        const response = await repository.scroll(this.allListNextPageUrl, payload)
        if (response.status === 200 && response.data) {
          response.data.result.data.map(item=> {
            const flag = this.lists.findIndex(list => {
              return list.id === item.id
            })

            if (flag === -1) this.lists.push(item)
          })
          this.lists = [...this.lists]
          this.allListNextPageUrl = response.data.result.next_page_url
        }

      } catch (e) {
        
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      } finally {
        this.listsLoading.moreAllLists = false

      }
    },
    async fetchWeeklyWinners(payload) {
      this.listsLoading.weeklyWinners = true
      try {

        const response = await repository.fetchFilteredList(payload)
        if (response.status === 200 && response.data) {
          // console.log('check weekly winners', response.data)
          this.weeklyWinners = response.data
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      } finally {
        this.listsLoading.weeklyWinners = false

      }
    },
    async fetchMostAddedCompanies(payload) {
      this.listsLoading.mostAdded = true
      try {
        
        const response = await repository.fetchFilteredList(payload)
        if (response.status === 200 && response.data) {
          this.mostAddedCompanies = response.data
        }
      } catch (e) {
        
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      } finally {
        this.listsLoading.mostAdded = false
      }
    },
    async fetchTopLists(payload) {
      this.listsLoading.topLists = true
      try {
        
        const response = await repository.fetchFilteredList(payload)
        if (response.status === 200 && response.data) {
          // console.log('check top lists', response.data)
          this.topLists = response.data
        }
      } catch (e) {
        
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      } finally {
        this.listsLoading.topLists = false
      }
    },
    async fetchRecentPremiumCompanies(payload) {
      this.listsLoading.recentPremiumCompanies = true

      try {
        
        const response = await repository.fetchFilteredList(payload)
        if (response.status === 200 && response.data) {
          // console.log('check recent premium companies', response.data)
          this.recentAddedPremiumCompanies = response.data
        }
      } catch (e) {
        
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      } finally {
        this.listsLoading.recentPremiumCompanies = true

      }
    },
    async fetchMostFollowed(payload) {
      this.listsLoading.mostFollowed = true
      try {
        
        const response = await repository.fetchFilteredList(payload)
        if (response.status === 200 && response.data) {
          // console.log('check most followed', response.data)
          this.mostFollowed = response.data
        }
      } catch (e) {
        
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      } finally {
        this.listsLoading.mostFollowed = false
      }
    },
    async fetchPremiumCompaniesInList(listId) {
      this.loading = true
      try {

        const response = await repository.fetchPremiumCompaniesInList(listId)
        if(response.status === 200 && response.data) {
          // console.log('premium companies in list', response.data)
          this.singleListPremiumCompanies = response.data
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async fetchCreatorLists(listId) {
      

      try {
        const response = await repository.fetchCreatorLists(listId)
        if(response.status === 200 && response.data) {
          // console.log('other lists from creator', response.data)
          this.creatorLists = response.data
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async fetchListsFollowersAlsoFollow(listId) {
      

      try {
        const response = await repository.fetchFollowersLists(listId)
        if(response.status === 200 && response.data) {
          // console.log('other lists from followers', response.data)
          this.otherListFollowedbyFollowers = response.data
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async fetchCompaniesStats(listId) {
      
      try {
        const response = await repository.fetchListCompaniesStats(listId)
        if(response.status === 200 && response.data) {
          this.listCompaniesStats = response.data
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }

    },
    async fetchNewsAboutList(listId) {
      try {
        const response = await repository.fetchNewsAboutList(listId)
        if(response.status === 200 && response.data) {
          // console.log('News about list', response.data)
          this.singleListNews = response.data.data
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async fetchWeeklyTopList(payload) {
      this.weeklyTopListsLoading = true
      try {
        const response = await repository.fetchAllList(payload)
        if (response.status === 200 && response.data) {
          this.weeklyTopLists = response.data.result.data
          this.nextPageUrl = response.data.result.next_page_url
        }
        this.weeklyTopListsLoading = false
      } catch (e) {
        this.weeklyTopListsLoading = false
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async fetchTopFiftyList(payload) {
      this.loading = true
      try {
        const response = await repository.fetchAllList(payload)
        if (response.status === 200 && response.data) {
          this.topFiftyLists = response.data.result.data
        }
        this.loading = false
      } catch (e) {
        this.loading = false
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async showMore(payload) {
      this.loadingNew = true
      try {
        if (!this.weeklyTopListsLoading && this.nextPageUrl !== null) {
          const response = await repository.showMore(this.nextPageUrl, payload)
          if (response.status === 200 && response.data) {
            this.pushDataToWeeklyTopList(response.data.data)
            this.nextPageUrl = response.data.next_page_url
          }
        }
        this.loadingNew = false
      } catch (e) {
        this.loadingNew = false
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async followList(payload) {
      this.listsLoading.followList = true
      try {
        const response = await repository.followList(
          payload.listId,
          payload.userId
        )
        if (response.status === 200 && response.data) {
          if (payload.type === "allTimeStats") {
            const findIndex = this.topFiftyLists.findIndex(
              (list) => list.id === response.data.id
            )
            if (findIndex > -1) {
              this.topFiftyLists[findIndex] = response.data
            }
            this.topFiftyLists = [...this.topFiftyLists]
          } else if (payload.type === "listScreener") {
            const findIndex = this.lists.findIndex(
              (list) => list.id === response.data.id
            )
            if (findIndex > -1) {
              this.lists[findIndex] = response.data
            }
            this.lists = [...this.lists]
          } else if (payload.type === "singleList") {
            this.selectedList = response.data
          } else {
            this.pushDataToWeeklyTopList(response.data)
          }
          useSnackbarStore().snack({
            type: "success",
            text: "List followed/unfollowed successfully.",
            show: true,
            autoclose: true,
          })
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      } finally {
        this.listsLoading.listInfo = false
      }
    },
    async resetSingleList() {
      this.selectedList = {}
      this.listCompaniesStats = {}
      this.otherListFollowedbyFollowers = []
      this.singleListNews = []
      this.usersLists = []

    },
    async unfollowList(payload) {
      try {
        this.loading = true

        const response = await repository.unfollowList(
          payload.listId,
          payload.userId,
          payload.filters
        )
        if (response.status === 200 && response.data) {
          if (response.status === 200 && response.data) {
            this.setUsersList(response.data)
          }
        }
        this.loading = false
      } catch (e) {
        this.loading = false

        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async fetchListIds(payload) {
      try {
        const response = await repository.fetchListIds(payload.companyURL)
        if (response.status === 200 && response.data) {
          this.listIds = response.data
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
	async fetchUserTopLists() {
    if(useUsersStore().accessToken) {
      try {
        this.userTopListsLoading = true
        const response = await repository.fetchUserTopLists()
        if (response.status === 200 && response.data) {
          this.userTopLists = response.data
        }
        this.userTopListsLoading = false
      } catch (e) {
        this.userTopListsLoading = false
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    }
  },
	async fetchOtherUserTopLists(payload) {
      try {
		this.otherUserTopListsLoading = true
        const response = await repository.fetchOtherUserTopLists(payload)
        if (response.status === 200 && response.data) {
          this.otherUserTopLists = response.data
        }
		this.otherUserTopListsLoading = false
      } catch (e) {
		this.otherUserTopListsLoading = false
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
  },
})
