<template>
  <div>
    <TextMessage
      v-if="message"
      :message="message"
      class="py-3"
    />
    <div class="flex flex-wrap">
      <div
        v-for="(media, index) in gallery"
        :key="index + media.id"
        class="
          bg-white
          dark:bg-custom-purple-600
          relative
          p-1
          add
          mr-2
          and
          mb-2
          rounded-lg
          border border-gray-200 dark:border-custom-purple-800
        "
        @click="$emit('open-gallery', media)"
      >
        <SquareImage
          v-if="media.type === 'image'"
          :big="big"
          :src="media.src"
        />
        <figure
          v-if="media.type == 'audio' || media.type == 'document' || media.type == 'video'"
          class="flex flex-col items-center"
        >
          <ThumbnailVideo
            v-if="media.type === 'video'"
            :thumbnail="(media.video_thumbnail) ? media.video_thumbnail : null"
            :big="big"
          />
          <ThumbnailAudio
            v-if="media.type === 'audio'"
            :big="big"
          />
          <a
            :href="media.src"
            target="_blank"
          > <ThumbnailDocument
            v-if="media.type === 'document'"
            :big="big"
          /></a>
          <figcaption class="text-xs font-semibold text-center dark:text-custom-purple-100">
            {{ media.filename.substring(0, 10) }}
          </figcaption>
        </figure>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MediaGallery',
  props: {
    message: { type: String, default: '' },
    gallery: { type: Array, default: () => [] },
    big: {
      type: Boolean,
      default: false
    },
    tiny: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'open-gallery'
  ]
}
</script>
