<template>
  <div
    class="modal-overlay absolute w-full h-full bg-custom-purple-600 z-10"
    :class="theme === 'dark' ? 'opacity-75' : 'opacity-60'"
    @click="$emit('click')"
  >
    <slot />
  </div>
</template>

<script>

export default {
  name: 'ModalOverlay',
  props: {
    theme: {
      type: String,
      default: ''
    }
  },
  emits: [
    'click'
  ]
}
</script>
