import { RepositoryFactory } from '@/api/repositoryFactory'
const repository = RepositoryFactory.get('verifications')
import { useAppsStore } from './app'
import { defineStore } from 'pinia'
import { useUsersStore } from './users'
import { useSnackbarStore } from './snackbar'

export const useVerifysStore = defineStore('verify', {
  state: () => ({
    loading: false,
    data: null
  }),
  getters: {
    status: (state) => (state.data && state.data.status ? state.data.status : 'wait'),
    redirectUrl: (state) => (state.data && state.data.url ? state.data.url : null)
  },
  actions: {
    setData(data) {
      this.data = { ...data }
    },
    async cancelFrejaAuthentication(authRef) {
      try {
        const response = await repository.cancelFrejaAuthentication({ authRef })
        if (response.status === 200) {
          console.log('Freja authentication canceled successfully!')
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async fetchFrejaAuthenticationStatus (authRef) {
      try {
        const response = await repository.getFrejaOneResult({ authRef })
        console.log('response : ',response)
        if (response.status === 200) {
          return response.data.status
        } else {
          return false
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async fetchFrejaQRUrl () {
      try {
        this.loading = true
        const response = await repository.fetchFrejaQRUrl()
        this.loading = false
        if (response.status === 200) {
          return response.data
        } else {
          return false
        }
      } catch (e) {
        this.loading = false
        console.log(e)
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async external(solution) {
      this.setData(null)

      this.loading = true
      const response = await repository.authenticate({
        external: 1,
        native: 0,
        platform: useAppsStore().platform,
        solution,
        browser: useAppsStore().browser
      })
      this.loading = false

      if (response.status === 201 && response.data) {
        this.setData(response.data)
      }
    },
    async internal(solution) {
      this.setData(null)

      this.loading = true
      const response = await repository.authenticate({
        external: 0,
        native: 1,
        platform: useAppsStore().platform,
        solution,
        browser: useAppsStore().browser
      })
      this.loading = false
      if (response.status === 201 && response.data) {
        this.setData(response.data)
      }
    },
    async setSession(session) {
      this.setData({ session })
    },
    async setStatus(status) {
      this.setData({ status })
    },
    async verify(solution) {
      if (this.data) {
        const data = {
          reference: this.data.session,
          platform_key: useAppsStore().platformKey,
          solution
        }
        const response = await repository.verify(data)

        if (response.status === 200 && response.data) {
          
          let user = useUsersStore().user
          window.gtag('event', 'complete_registration', {
            stock_user_id: user.profile.id,
            name: user.profile.name
          })

          this.setData({ ...this.data, status: response.data.status })
        }
      }
    }
  }
})
