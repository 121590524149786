<template>
  <div
    class="bg-[#0C0C0DCC] rounded-lg my-4 text-white border  border-black flex items-center"
    :class="padding"
  >
    <FontAwesomeIcon
      icon="exclamation-circle"
      class="text-xl mr-4 custom-icon"
    /> <div><slot /></div>
  </div>
</template>

<script>

export default {
  name: 'ErrorMessage',
  props: {
    padding: {
      type: String,
      default: 'p-4'
    }
  }
}
</script>
<style>
 .custom-icon {
    color: red;
  }
</style>
