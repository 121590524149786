<template>
  <div
    class="w-full"
    :class=" mini? 'lg:w-2/12' : small ? 'lg:w-3/12' : medium ? 'lg:w-4/12' : 'lg:w-8/12'"
  >
    <slot />
  </div>
</template>

<script>

export default {
  name: 'LeftColumn',
  props: {
    small: {
      type: Boolean,
      default: false
    },
    mini: {
      type: Boolean,
      default: false
    },
    medium: {
      type: Boolean,
      default: false
    }
  }
}
</script>
