<template>
  <div>
    <div class="flex items-center justify-between hover:bg-gray-100 dark:hover:bg-custom-purple-400 cursor-pointer">
      <div
        class="w-full flex flex-grow flex-1 px-2 py-3"
        :to="{name: 'companyOverview', params: {id: id}}"
        @click="$emit('chosen-company')"
      >
        <div class="w-full items-center">
          <div class="flex items-center justify-between">
            <div class="flex flex-grow items-center">
              <CountryFlagInternal :company="company" />
              <NameSubTitle
                class="w-full ml-2"
                :name="company.name"
                :company="company"
                :subtitle="followers + ' ' + $t('followers')"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <Divider border="dark:border-custom-purple-300" />
  </div>
</template>

<script>

export default {
  name: 'TaggableCompanyCard',
  props: {
    id: { type: String, default: '' },
    name: { type: String, default: '' },
    followers: { type: Number, default: 0 },
    stats: { type: String, default: '' },
    company: { type: Object, default: () => { } }
  },
  emits: [
    'chosen-company'
  ]
}
</script>
