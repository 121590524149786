<template>
  <div
    class="bg-white dark:bg-custom-purple-800 border-b dark:border-custom-purple-450 z-60 shadow-sm dark:shadow-none"
  >
    <Container
      :padding="isMobile ? 'px-4 py-0' : 'p-0'"
      wide
    >
      <div class="relative">
        <!-- <img
          src="/bg.png"
          class=" rounded-b-lg h-60 w-full object-cover absolute"
        > -->
        <CompanyBannerContainer
          v-if="company"
          :company="company"
          :edit-mode="editMode"
          :is-epp="isEpp"
        />
        <div
          v-if="company"
          class="flex justify-between items-center"
          :class="isEpp ? 'pb-4' : ''"
        >
          <div class="">
            <CompanyNameContainer
              :company="company"
              :subtitle="isEpp ? company.name : company.subtitle"
              :minified="minified"
              :edit-mode="editMode"
              :is-epp="isEpp"
              @click="
                $route.name !== 'companyOverview' && !isEpp
                  ? $router.push({ name: 'companyOverview', params: { id: company.slug_url } })
                  : null
              "
            />
            <div
              class="ml-12 pl-4 md:hidden flex items-center space-x-2 mb-4 dark:text-custom-purple-200 text-custom-gray-400"
            >
              <div
                class="sm:flex font-semibold items-center lg:text-base text-xs"
                @click="!isEpp ? goToExploreCompanies(companyDetails?.country_name) : null"
              >
                {{ isEpp ? getCountryName(companyDetails.country) : companyDetails?.country_name }}
              </div>
              <div
                class="flex font-semibold items-center space-x-2"
                :class="minified ? 'text-sm' : 'text-base'"
              >
                <Icon
                  v-if="companyDetails && companyDetails.exchange"
                  name="recycle"
                />
                <div
                  class="lg:text-base text-xs cursor-pointer"
                  @click="
                    $route.name !== 'exchangeOverview' && !isEpp
                      ? $router.push({ name: 'exchangeOverview', params: { id: company.exchange.slug_url } })
                      : null
                  "
                >
                  {{ companyDetails && companyDetails.exchange ? companyDetails.exchange?.market_name : '' }}
                </div>
              </div>
            </div>
          </div>
          <div class="flex items-center mt-16 space-x-4 dark:text-custom-purple-200 text-custom-gray-400">
            <div
              class="hidden md:flex flex-row md:space-x-4 md:space-y-0 space-y-2"
              :class="isEpp ? 'md:space-x-0' : 'md:space-x-4'"
            >
              <div
                class="sm:flex font-semibold items-center lg:text-base md:text-sm text-xs cursor-pointer"
                :class="{ 'text-right': isEpp }"
                @click="!isEpp ? goToExploreCompanies(companyDetails?.country_name) : null"
              >
                {{ isEpp ? getCountryName(companyDetails.country) : companyDetails?.country_name }}
              </div>
              <div
                class="sm:flex font-semibold items-center"
                :class="minified ? 'text-sm' : 'text-base'"
              >
                <Icon
                  v-if="companyDetails && companyDetails.exchange"
                  class="pr-1"
                  name="recycle"
                />
                <div
                  class="lg:text-base text-xs cursor-pointer"
                  @click="
                    $route.name !== 'exchangeOverview' && !isEpp
                      ? $router.push({ name: 'exchangeOverview', params: { id: company.exchange.slug_url } })
                      : null
                  "
                >
                  {{ companyDetails && companyDetails.exchange ? companyDetails.exchange?.market_name : '' }}
                </div>
              </div>
            </div>
            <div
              v-if="companyDetails"
              class="hidden mdx:block"
            >
              |
            </div>
            <div class="flex md:flex-row space-x-0 flex-col md:space-x-2 md:space-y-0 space-y-2">
              <CompanyFollowContainer
                class="z-20 flex-grow-0 py-2"
                :company="company"
                :rounded-button="true"
                :is-epp="isEpp"
              />
              <div
                class="relative flex justify-center items-center flex-grow-0 w-full md:w-10 h-6 bg-white dark:bg-custom-purple-800 p-4 rounded-3xl border hover:border-custom-purple-500"
              >
                <div
                  class="cursor-pointer absolute inset-0 flex items-center justify-center"
                  @click="$emit('show-menu')"
                >
                  <DotMenu
                    horizontal
                    class="text-custom-purple-500 dark:text-custom-purple-100"
                  />
                </div>
                <div
                  v-if="showMenu"
                  class="z-70 absolute top-8 right-2 rounded-xl shadow-sm dark:shadow-sm-dark border dark:border-custom-purple-400 bg-white dark:bg-custom-purple-450 dark:text-custom-purple-100 text-custom-purple-500"
                  :class="hasEditAccess ? 'w-40' : 'w-52' "
                >
                  <div
                    v-if="hasEditAccess"
                    class="relative w-full"
                  >
                    <div class="flex items-center">
                      <div
                        class="text-sm h-14 cursor-pointer flex items-center z-30 px-4 w-full"
                        :class="
                          editMode
                            ? 'bg-custom-yellow-300 dark:text-custom-purple-500'
                            : 'hover:bg-gray-100 dark:hover:bg-custom-purple-400 text-custom-purple-500 dark:text-custom-purple-100'
                        "
                        @click="$emit('on-edit-mode', $event)"
                      >
                        <div class="flex items-center">
                          <Icon
                            name="pencil"
                            class="mr-2 text-custom-purple-500 dark:text-custom-purple-100 text-lg"
                            :solid="editMode ? true : false"
                          />
                          <span class="font-semibold">{{ $t('Edit') }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-else
                    class="px-4 text-md dark:text-custom-purple-100 h-14 cursor-pointer flex items-center z-30 hover:bg-gray-100 dark:hover:bg-custom-purple-400"
                    @click="hubspotCompanyLink"
                  >
                    <Icon
                      name="pencil"
                      class="mr-2 dark:text-custom-purple-100 text-lg"
                    />
                    <span class="font-semibold">Claim Your Company</span>
                  </div>
                  <div
                    v-if="hasEditAccess || isSalesPerson"
                    class="px-4 text-md dark:text-custom-purple-100 h-14 cursor-pointer flex items-center z-30 hover:bg-gray-100 dark:hover:bg-custom-purple-400"
                    @click="$emit('toggle-settings')"
                  >
                    <Icon
                      name="cog"
                      class="mr-2 dark:text-custom-purple-100 text-lg"
                    />
                    <span class="font-semibold">{{ $t('Settings') }}</span>
                  </div>
                  <div
                    v-if="!isEpp"
                    class="px-4 h-14 flex items-center text-md dark:text-custom-purple-100 cursor-pointer z-30 hover:bg-gray-100 dark:hover:bg-custom-purple-400"
                    @click="$emit('toggle-report-incident')"
                  >
                    <Icon
                      name="report"
                      class="mr-2 text-gray-600 dark:text-custom-purple-100 text-lg"
                      solid
                    />
                    <span class="font-medium">{{ $t('Report Incident') }}</span>
                  </div>
                  <div
                    class="px-4 text-md dark:text-custom-purple-100 h-14 cursor-pointer flex items-center z-30 hover:bg-gray-100 dark:hover:bg-custom-purple-400"
                    @click="shared"
                  >
                    <Icon
                      name="share"
                      class="mr-2 dark:text-custom-purple-100 text-lg"
                    />
                    <span class="font-semibold">{{ $t('Share') }}</span>
                  </div>

                  <div
                    v-if="!isEpp"
                    class="px-4 text-md h-14 cursor-pointer dark:text-custom-purple-100 flex items-center z-30 hover:bg-gray-100 dark:hover:bg-custom-purple-400"
                    @click="companyDetails?.company?.id && $emit('toggle-compare')"
                  >
                    <div
                      v-if="companyDetails?.company?.id"
                      class="flex items-center"
                    >
                      <Icon
                        name="door-open"
                        class="mr-2 dark:text-custom-purple-100 text-lg"
                      />
                      <span class="font-semibold">{{ $t('Compare') }}</span>
                    </div>

                    <div
                      v-else
                      class="flex"
                    >
                      <div class="mr-2 w-4 h-4 rounded-md bg-custom-purple-200 animate-pulse" />
                      <div class="w-14 h-4 rounded-md bg-custom-purple-200 animate-pulse" />
                    </div>
                  </div>
                  <a :href="guideLink">
                    <div
                      class="px-4 text-md dark:text-custom-purple-100 h-14 cursor-pointer flex items-center z-30 hover:bg-gray-100 dark:hover:bg-custom-purple-400"
                    >
                      <Icon
                        name="info-circle"
                        class="mr-2 dark:text-custom-purple-100 text-lg"
                      />
                      <span class="font-semibold">{{ $t('Guides and FAQ') }}</span>
                    </div>
                  </a>
                  <!-- <div
                    class="px-4 text-md dark:text-custom-purple-100 h-14 cursor-pointer flex items-center z-30 hover:bg-gray-100 dark:hover:bg-custom-purple-400"
                    @click="$emit('toggle-settings')"
                  >
                    <Icon
                      name="volume"
                      class="mr-2  dark:text-custom-purple-100 text-lg"
                    />
                    <span class="font-semibold">{{ $t('Report') }}</span>
                  </div> -->
                  <div
                    v-if="hasEditAccess && isSinglePressReleasePage"
                    class="px-4 text-md dark:text-custom-purple-100 h-14 cursor-pointer flex items-center z-30 hover:bg-gray-100 dark:hover:bg-custom-purple-400"
                    @click="$emit('toggle-press-image-upload')"
                  >
                    <Icon
                      name="image"
                      class="mr-2 text-gray-600 dark:text-custom-purple-100 text-lg"
                    />
                    <span class="font-semibold">{{ $t('Upload Image') }}</span>
                  </div>
                </div>
                <TransparentBackgroundForMenus
                  v-if="showMenu"
                  @click="$emit('show-menu', $event)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
    <ShareWithSocialMedia
      v-if="showShare"
      :title="`${company.name} @ Eucaps.com`"
      :description="`At Eucaps you can find decision based information about ${company.name}, Data, Financials, Analysis, Holdings and Latest News. \n\n Check out the company here:`"
      :url="isEpp ? `${url}/exchange/${company?.slug_url}` : `${url}/${company?.slug_url}`"
      :show-share="showShare"
      @shared="shared"
    />
  </div>
</template>

<script>
import CompanyNameContainer from '@/modules/companyName'
import CompanyFollowContainer from '@/modules/companyFollow'
import CompanyBannerContainer from '@/modules/companyBanner'
import { isMobile } from '@/js/helpers/mobile'
import { useListsStore } from '../stores/lists'
import { useCountriesStore } from '../stores/countries'
import { useExchangesStore } from '../stores/exchanges'
import { useUsersStore } from '../stores/users'
import { useSnackbarStore } from '../stores/snackbar'
import i18n from '../js/language'

export default {
  name: 'CompanyTopNameAndMenu',
  components: {
    CompanyNameContainer,
    CompanyFollowContainer,
    CompanyBannerContainer
  },
  props: {
    company: {
      type: Object,
      default: () => {}
    },
    companyDetails: {
      type: Object,
      default: () => {}
    },
    hasEditAccess: {
      type: Boolean,
      default: false
    },
    editMode: {
      type: Boolean,
      default: false
    },
    showMenu: {
      type: Boolean,
      default: false
    },
    widgetAddons: {
      type: Boolean,
      default: false
    },
    isEpp: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'show-user-lists',
    'on-edit-mode',
    'toggle-settings',
    'toggle-press-image-upload',
    'show-menu',
    'toggle-compare',
    'toggle-report-incident'
  ],
  setup() {
    const listsStore = useListsStore()
    const countriesStore = useCountriesStore()
    const exchangesStore = useExchangesStore()
    const usersStore = useUsersStore()
    const snackbarStore = useSnackbarStore()

    return { listsStore, countriesStore, exchangesStore, usersStore, snackbarStore }
  },
  data() {
    return {
      showShare: false,
      showClaimButton: false
    }
  },
  computed: {
	user() {
      return this.usersStore?.user
    },
    isSalesPerson() {
      return this.usersStore?.user?.role_list?.includes('sales-person')
    },
    minified() {
      return isMobile()
    },
    url() {
      if (process.env.VUE_APP_URL) return process.env.VUE_APP_URL
      else return ''
    },
    guideLink() {
      if (process.env.VUE_APP_FAQ_LINK) return process.env.VUE_APP_FAQ_LINK
      else return ''
    },
    isSinglePressReleasePage() {
      return this.$route.name === 'companyPressRelease'
    },
    countries() {
      return this.countriesStore.countries
    }
  },
  async mounted() {
    await this.countriesStore.fetch()
    !this.isEpp && (await this.listsStore.fetchListIds({ companyURL: this.company.slug_url }))
  },
  methods: {
    isMobile() {
      return isMobile()
    },
    shared() {
      this.showShare = !this.showShare
      this.$emit('show-menu')
    },

    getCountryName(data) {
      const country = this.countries?.find((country) => country.code === data)
      return country ? country.name : ''
    },
    goToExploreCompanies(tag) {
      if (!tag) return
      let dataObj = {
        selectedTags: [
          {
            label: `#${tag.toLowerCase()}`,
            group: 'tags',
            type: 'multiselect',
            isCustomTag: true
          }
        ]
      }
      this.$router.push({ name: 'exploreCompanies', query: { filtration: JSON.stringify(dataObj) }  })
    },
	hubspotCompanyLink() {
      if (!process.env.VUE_APP_CLAIM_COMPANY || !this.user.email || !this.company.id || !this.company.name) {
        this.snackbarStore.snack({ text: i18n.global.t('Something went wrong. Please try again'), type: 'error', show: true, autoclose: false })
        return null
      } 
      else {
		const externalUrl = process.env.VUE_APP_CLAIM_COMPANY + `?email=${this.user.email}&firstname=${this.user.first_name}&lastname=${this.user.last_name}&eucaps_id=${this.company.id}&name=${this.company.name}`
        window.location.href = externalUrl;
      }
    }
  }
}
</script>
