import { RepositoryFactory } from "@/api/repositoryFactory"
const repository = RepositoryFactory.get("payments")
import { defineStore } from "pinia"
import { useSnackbarStore } from "./snackbar"
import { useUsersStore } from "./users"

export const usePaymentsStore = defineStore("payments", {
  state: () => ({
    loading: false,
    paymentMethods: [],
    onboardingPaymentMethods: [],
    subscriptions: [],
    loadingSubscriptions: false,
    selectedSubscription: null,
  }),
  getters: {
    companySubscriptions: (state) => {
      if (!state.subscriptions || state.subscriptions.length === 0) {
        return []
      }
      let payload = [...state.subscriptions]
      payload.forEach((sub) => {
        if (state.paymentMethods.length > 0) {
          const findPaymentMethod = state.paymentMethods.find(
            (payment) => payment.id === sub.default_payment_method
          )
          if (findPaymentMethod) {
            sub.payment_method = findPaymentMethod
          } else {
            sub.payment_method = null
          }
        } else {
          sub.payment_method = null
        }
      })
      return payload
    },
  },
  actions: {
    updateSubscription(payload) {
      const index = this.subscriptions.findIndex((s) => s.id === payload.id)
      if (index > -1) {
        payload.show_popup =
          payload.show_popup !== undefined ? !payload.show_popup : false
        this.subscriptions[index] = payload
      } else {
        payload.show_popup = false
        this.subscriptions.push(payload)
      }

      this.subscriptions = [...this.subscriptions]
    },
    async fetchSubscriptions(companyId) {
      if(useUsersStore().user?.profile_type && useUsersStore().user?.profile_type !== "App\\Models\\GuestUser") {
        try {
          this.loadingSubscriptions = true
          const response = await repository.fetchSubscriptions(companyId)
          this.loadingSubscriptions = false
          const payload = response.data
          if (payload.length === 0) {
            this.subscriptions = payload
          } else {
            payload.forEach((data) => {
              data.show_pupup = false
            })
            this.subscriptions = payload
          }
        } catch (e) {
          this.loadingSubscriptions = false
          useSnackbarStore().snack({
            type: "error",
            e: e,
            show: true,
            autoclose: true,
          })
        }
      }
    },
    async setDefaultPaymentMethod(payload) {
      try {
        this.loading = true
        const response = await repository.setDefaultPaymentMethod(
          payload.subscriptionId,
          { payment_method_id: payload.paymentMethodId }
        )
        this.loading = false
        this.updateSubscription(response.data)
      } catch (e) {
        this.loading = false
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    setSelectedSubscription(payload) {
      this.selectedSubscription = payload
    },
    async cancelCompanySubscription(subscription) {
      try {
        this.loadingSubscriptions = true
        const response = await repository.cancelSubscription(
          subscription.id
        )
        this.loadingSubscriptions = false
        this.updateSubscription(response.data)
      } catch (e) {
        this.loadingSubscriptions = false
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    showPopups(payload) {
      this.updateSubscription(payload)
    },
    async fetchPaymentMethods(countryCode) {
      try {
        this.loading = true
        const response = await repository.getPaymentMethods(countryCode)
        if(response.data && response.data.length > 0 ) this.onboardingPaymentMethods = response.data
        // if(sessionStorage) sessionStorage.paymentMethods = JSON.stringify(this.onboardingPaymentMethods)
      } catch (e) {
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      } finally {
        this.loading = false
      }
    },
    async fetchCompanyPaymentMethods(id) {
      if (useUsersStore().user?.profile_type && useUsersStore().user?.profile_type !== "App\\Models\\GuestUser") {
        try {
          this.loadingSubscriptions = true
          const response = await repository.getCompanyPaymentMethods(id)
          if (response.status === 200) {
            this.paymentMethods = response.data
          } else {
            useSnackbarStore().snack({
              type: "info",
              text: 'No stripe payment method found.',
              show: true,
              autoclose: true,
            })
          }
          this.loadingSubscriptions = false
        } catch (e) {
          this.loadingSubscriptions = false
          useSnackbarStore().snack({
            type: "error",
            e: e,
            show: true,
            autoclose: true,
          })
        }
      }
    },
    async createPaymentInvoice(payload) {
      try {

        this.loading = true
        const response = await repository.createPaymentInvoice(payload)
        if(response.status === 200 && response.data) {
          return 'success'
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      } finally {
        this.loading = false
      }
    }
  },
})
