const eucapsBoard = [
  {
    id: 1,
    name: 'Henrik Wagenius',
    src: '/img/team/Henrik.png'
  },
  {
    id: 2,
    name: 'Robin Henriksson',
    src: '/img/team/Robin.jpg'
  },
  {
    id: 3,
    name: 'Christer Wagenius',
    src: '/img/team/Christer.png'
  },
  {
    id: 4,
    name: 'Anders Rynnel',
    src: '/img/team/Andres-Rynnel.png'
  },
  {
    id: 5,
    name: 'Anders Bruzelius',
    src: '/img/team/Anders.jpg'
  },
  {
    id: 6,
    name: 'Pontus Rosén',
    src: '/img/team/Pontus.png'
  }
]

const stockEvolution = [
  {
    id: 1,
    name: 'Henrik Wagenius',
    title: 'Founder & CEO',
    src: '/img/team/Henrik.png'
  },
  {
    id: 2,
    name: 'Robin Henriksson',
    title: 'Co-founder & CTO',
    src: '/img/team/Robin.jpg'
  },
  {
    id: 3,
    name: 'Kristof De Buysere',
    title: 'Co-founder Head of legal & Compliance',
    src: '/img/team/Kristof.jpg'
  },
  {
    id: 5,
    name: 'Andreas Hall',
    title: 'Partner & Head of Commerce',
    src: '/img/team/Andreas-hall.jpg'
  },
  {
    id: 6,
    name: 'Deblina Ray',
    title: 'Project Manager',
    src: '/img/team/DeblinaRay.jpg'
  },
  {
    id: 7,
    name: 'Carl-Marcus Aman',
    title: 'Partner & Key Account',
    src: '/img/team/Carl.jpg'
  },
  {
    id: 8,
    name: 'Mikael Wallenius',
    title: 'Partner & Key Account',
    src: '/img/team/Mikael.jpg'
  },
  {
    id: 9,
    name: 'Carl Berglund',
    title: 'Partner & Key Account',
    src: '/img/team/Carl-berglund.jpg'
  },
  {
    id: 10,
    name: 'Nicklas Kullman',
    title: 'Head of UX',
    src: '/img/team/Nicklas.jpg'
  },
  {
    id: 11,
    name: 'Haris Nezirevic',
    title: 'Social Media Manager',
    src: '/img/team/Harris.jpg'
  },
  {
    id: 12,
    name: 'Stefan Mattson',
    title: 'CFO',
    src: '/img/team/StefanMattsson.jpg'
  },
  {
    id: 13,
    name: 'Marie Anne Bahlenberg',
    title: 'Accounting',
    src: '/img/team/MariAnne.png'
  },
  {
    id: 14,
    name: 'Jennie Isberg',
    title: 'Partner & CMO',
    src: '/img/team/Jennie.jpg'
  },
  {
    id: 15,
    name: 'Martin Kuoppa',
    title: 'Head of Design',
    src: '/img/team/Martin.jpg'
  },
  {
    id: 16,
    name: 'Kristoffer Dahl',
    title: 'Partner Head of Digital Marketing',
    src: '/img/team/Kristoffer.jpg'
  }
]

const getEucapsBoardTeam = async () => {
  return new Promise((resolve, reject) => {
    try {
      setTimeout(() => {
        resolve({
          data: eucapsBoard
        })
      }, 2000)
    } catch (e) {
      reject(e)
    }
  })
}

const getStockEvolutionTeam = async () => {
  return new Promise((resolve, reject) => {
    try {
      setTimeout(() => {
        resolve({
          data: stockEvolution
        })
      }, 2000)
    } catch (e) {
      reject(e)
    }
  })
}

export default {
  getEucapsBoardTeam,
  getStockEvolutionTeam
}
