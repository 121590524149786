<template>
  <div class="flex justify-center">
    <div class="w-full">
      <div class="m-2">
        <p class="font-extrabold text-xl my-2 dark:text-custom-purple-100">
          {{ selectedDocument.title }}
        </p>
        <p class="font-medium dark:text-custom-purple-100">
          {{ selectedDocument.description }}
        </p>
      </div>
      <div style="scrollbar-width: none; -ms-overflow-style: none;">
        <VuePDF
          v-if="pdf"
          :pdf="pdf"
          :page="currentPage"
          fit-parent
        />
      </div>
      <div class="flex justify-between items-center mt-2">
        <div
          class="cursor-pointer border rounded-full bg-gray-300 dark:bg-custom-purple-450"
          @click="prevPage"
        >
          <Icon
            name="chevron-left"
            class="text-xl dark:text-custom-purple-100 px-1"
          />
        </div>
        <p class="text-lg font-bold text-custom-purple-300 dark:text-custom-purple-100 ">
          Page {{ currentPage }} of {{ pages }}
        </p>
        <div
          class="cursor-pointer border rounded-full bg-gray-300 dark:bg-custom-purple-450"
          @click="nextPage"
        >
          <Icon
            name="chevron-right"
            class="text-xl dark:text-custom-purple-100 px-1"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { VuePDF, usePDF } from '@tato30/vue-pdf'

export default {
  name: 'PdfModal',
  components: {
    VuePDF
  },
  props: {
	selectedDocument: {
      type: Object,
      default: () => {}
	}
  },
  setup(props) {
    const { pdf, pages } = usePDF(props.selectedDocument.path || '')
    const currentPage = ref(1)

    const nextPage = () => {
      if (currentPage.value < pages.value) {
        currentPage.value++
      }
    }

    const prevPage = () => {
      if (currentPage.value > 1) {
        currentPage.value--
      }
    }

    return {
      pdf,
      pages,
      currentPage,
      nextPage,
      prevPage,
      totalPages: pages
    }
  }
}
</script>