<template>
  <div class="flex flex-col justify-center h-full space-y-5">
    <CompanyApprovedCard
      :company="company"
    />
    <div
      class="flex justify-center"
    >
      <CircleLoader
        v-if="loading"
      />
    </div>
    <ActiveButton
      v-for="paymentMethod in paymentMethods"
      :key="paymentMethod.id"
      class="bg-custom-orange-400 h-12 rounded-xl disabled:opacity-50 "
      @click="$emit('continue', $event, paymentMethod?.name.toLowerCase())"
    >
      <span
        class="text-white"
      >
        Initiate payment by {{ paymentMethod?.name === 'invoice'? 'invoicing' : paymentMethod.name }}
      </span>
    </ActiveButton>
  </div>
</template>

<script>
import CircleLoader from '../../../components/CircleLoader.vue';
import CompanyApprovedCard from './CompanyApprovedCard'

export default {
  name: 'CompanyPaymentForm',
  components: {
    CompanyApprovedCard,
    CircleLoader
},
  props: {
    company: {
      type: Object,
      default: () => {}
    },
    paymentMethods: {
      type: Array, 
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
emits: [
'continue'
]
}
</script>
