<template>
  <div>
    <div class="text-lg font-semibold">
      {{ $t('Financial Details') }}
    </div>
    <div class="grid my-4">
      <div>
        <label>
          {{ $t('Choose Net Worth') + ' ('+baseCurrency+')' }}
        </label>
        <BaseSelect
          :appearance-none="false"
          :has-dark-mode="false"
          :required="true"
          :classes="netWorth ? baseSelectClass : baseSelectClass + ' text-gray-400 focus:text-primary !text-sm'"
          :upper-class="false"
          :model-value="netWorth"
          @change="$emit('net-worth', $event)"
        >
          <option
            value=""
            disabled
            selected
            hidden
          >
            {{ $t('Ex. Above 20 000') }}
          </option>

          <option
            v-for="netWorthRow in netWorthList"
            :key="netWorthRow.id"
            :value="netWorthRow.range_id"
            :selected="netWorth === netWorthRow.range_id ? true:false"
          >
            {{ formattedNumber(netWorthRow.label) }}
          </option>
        </BaseSelect>
      </div>
    </div>
    <div class="grid my-4">
      <div>
        <label>
          {{ $t('Choose Liquid Net Worth') + ' ('+baseCurrency+')' }}
        </label>
        <BaseSelect
          :appearance-none="false"
          :has-dark-mode="false"
          :required="true"
          :classes="liquidNetWorth ? baseSelectClass : baseSelectClass + ' text-gray-400 focus:text-primary !text-sm'"
          :upper-class="false"
          :model-value="liquidNetWorth"
          @change="$emit('liquid-net-worth', $event)"
        >
          <option
            value=""
            disabled
            selected
            hidden
          >
            {{ $t('Ex. Above 20000') }}
          </option>

          <option
            v-for="liquidNetWorthRow in liquidNetWorthList"
            :key="liquidNetWorthRow.id"
            :value="liquidNetWorthRow.range_id"
            :selected="liquidNetWorth === liquidNetWorthRow.range_id ? true:false"
          >
            {{ formattedNumber(liquidNetWorthRow.label) }}
          </option>
        </BaseSelect>
      </div>
    </div>
    <div class="grid my-4">
      <div>
        <label>
          {{ $t('Choose Annual Net Income') + ' ('+baseCurrency+')' }}
        </label>
        <BaseSelect
          :appearance-none="false"
          :has-dark-mode="false"
          :required="true"
          :classes="annualNetIncome ? baseSelectClass : baseSelectClass + ' text-gray-400 focus:text-primary !text-sm'"
          :upper-class="false"
          :model-value="annualNetIncome"
          @change="$emit('annual-net-income', $event)"
        >
          <option
            value=""
            disabled
            selected
            hidden
          >
            {{ $t('Ex. Above 20000') }}
          </option>

          <option
            v-for="annualNetIncomeRow in annualNetIncomeList"
            :key="annualNetIncomeRow.id"
            :value="annualNetIncomeRow.range_id"
            :selected="annualNetIncome === annualNetIncomeRow.range_id ? true:false"
          >
            {{ formattedNumber(annualNetIncomeRow.label) }}
          </option>
        </BaseSelect>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: 'FinancialDetailsForm',

  props: {
    baseCurrency: {
      type: String,
      default: ''
    },
    netWorthList: {
      type: Array,
      default: ()=>[]
    },
    liquidNetWorthList: {
      type: Array,
      default: ()=>[]
    },
    annualNetIncomeList: {
      type: Array,
      default: ()=>[]
    },

    netWorth: {
      type: String,
      default: ''
    },
    liquidNetWorth: {
      type: String,
      default: ''
    },
    annualNetIncome: {
      type: String,
      default: ''
    },

    baseSelectClass: {
      type: String,
      default: 'h-14 px-4 py-4 w-full bg-transparent border-none shadow-md font-semibold rounded-sm'
    }
  },

  emits: [
    'net-worth',
    'liquid-net-worth',
    'annual-net-income',
  ],
  methods: {
    formattedNumber(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
  },
}
</script>
  