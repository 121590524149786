<template>
  <div>
    <div class="text-lg font-semibold">
      {{ $t('Residence') }}
    </div>

    <div class="grid my-4">
      <div>
        <label>
          {{ $t('Street name and number') }}
        </label>
        <BaseInput
          :has-dark-mode="false"
          class="  h-14 w-full font-semibold"
          :required="true"
          :maxlength="36"
          :minlength="1"
          :placeholder="$t('Ex. Magasinsgatan 6A')"
          shadow="shadow-md"
          :model-value="streetOfResidence"
          @update:model-value="$emit('street-of-residence', $event)"
        />
      </div>
    </div>

    <div class="grid lg:grid-cols-2 gap-4 my-4">
      <div>
        <label>
          {{ $t('City') }}
        </label>
        <BaseInput
          :has-dark-mode="false"
          :maxlength="24"
          :minlength="1"
          class="  h-14 w-full  font-semibold"
          
          :required="true"
          :placeholder="$t('Ex. Soderkoping')"
          shadow="shadow-md"
          :model-value="cityOfResidence"
          @update:model-value="$emit('city-of-residence', $event)"
        />
      </div>
      <div>
        <label>
          {{ $t('Postal Code') }}
        </label>
        <BaseInput
          :has-dark-mode="false"
          class="  h-14 w-full  font-semibold"
          
          :required="true"
          :placeholder="$t('Ex. 987654')"
          shadow="shadow-md"
          :model-value="postalCodeOfResidence"
          @update:model-value="$emit('postal-code-of-residence', $event)"
        />
      </div>
    </div>

    <div class="grid lg:grid-cols-2 gap-4 my-4">
      <div>
        <label>
          {{ $t('Country') }}
        </label>
        <BaseSelect
          :appearance-none="false"
          :has-dark-mode="false"
          :required="true"
          :classes="countryOfResidence ? baseSelectClass : baseSelectClass + ' text-gray-400 focus:text-primary !text-sm'"
          :upper-class="false"
          :model-value="countryOfResidence"
          @change="$emit('country-of-residence', $event)"
        >
          <option
            value=""
            disabled
            selected
            hidden
          >
            {{ $t('Ex. Sweden') }}
          </option>

          <option
            v-for="country in countriesWithStates"
            :key="country.id"
            :value="country.ibkr_code"
            :selected="countryOfResidence===country.ibkr_code"
          >
            {{ country.name }}
          </option>
        </BaseSelect>
      </div>
      <div>
        <label>
          {{ $t('State') }}
        </label>
        <BaseSelect
          :appearance-none="false"
          :has-dark-mode="false"
          :required="true"
          :classes="stateOfResidence ? baseSelectClass : baseSelectClass + ' text-gray-400 focus:text-primary !text-sm'"
          :upper-class="false"
          :model-value="stateOfResidence"
          @change="$emit('state-of-residence', $event)"
        >
          <option
            value=""
            disabled
            selected
            hidden
          >
            {{ $t('Ex. Skane Lan') }}
          </option>

          <option
            v-for="state in residentStateList"
            :key="state.id"
            :value="state.ibkr_code"
            :selected="stateOfResidence===state.ibkr_code"
          >
            {{ state.name }}
          </option>
        </BaseSelect>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: 'ResidenceForm',
  props: {

    countriesWithStates: {
      type: Array,
      default: () => []
    },

    residentStateList: {
      type: Array,
      default: () => []
    },
    countryOfResidence: {
      type: String,
      default: ''
    },
    streetOfResidence: {
      type: String,
      default: ''
    },
    cityOfResidence: {
      type: String,
      default: ''
    },
    stateOfResidence: {
      type: String,
      default: ''
    },
    postalCodeOfResidence:{
      type: String,
      default: ''
    },

    baseSelectClass: {
      type: String,
      default: 'h-14 px-4 py-4 w-full bg-transparent border-none shadow-md font-semibold rounded-sm'
    }
  },

  emits: [
    'street-of-residence',
    'city-of-residence',
    'country-of-residence',
    'state-of-residence',
    'postal-code-of-residence',
  ]
}
</script>
  