import { defineStore } from 'pinia';

export const useAudioStore = defineStore('audio', {
  state: () => ({
    source: '',
    title: '',
    subtitle: '',
    thumbnail: '',
    playing: false,
  }),
  getters: {
    showPlayer(state) {
      return state.source !== '';
    }
  },
  actions: {
    setSource(source) {
      this.source = source;
    },
    setTitle(title) {
      this.title = title;
    },
    setSubtitle(subtitle) {
      this.subtitle = subtitle;
    },
    setThumbnail(thumbnail) {
      this.thumbnail = thumbnail;
    },
    close() {
      this.source = '';
      this.title = '';
      this.subtitle = '';
      this.thumbnail = '';
    },
  },
});
