<template>
  <CompanyLogoUploader 
    v-if="source === 'company'"
    @on-image-change="onImageChange"
  />
  <ProfileImageUploader
    v-else
    :profile-image="imageUrl"
    @on-image-change="onImageChange"
  />
</template>

<script>

import ProfileImageUploader from './_components/ProfileImageUploader'
import CompanyLogoUploader from './_components/CompanyLogoUploader'

export default {
  name: 'SquareImageUploaderModule',
  components: {
    ProfileImageUploader,
    CompanyLogoUploader
  },
  props: {
    src: {
      type: String,
      default: null
    },
    source: {
      type: String,
      default: null
    }
  },
emits: [
'on-profile-image'
],
  data () {
    return {
      loading: false,
      profileImage: null,
      imageUrl: null
    }
  },
  mounted () {
    this.imageUrl = this.src
  },
  methods: {
    onImageChange (e) {
      this.profileImage = e.target.files[0]
      this.imageUrl = URL.createObjectURL(this.profileImage)

      this.$emit('on-profile-image', {
        profileImage: this.profileImage,
        imageUrl: this.imageUrl
      })
    }
  }
}
</script>