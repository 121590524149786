<template>
  <Notification
    :pivot="pivot"
    @remove="$emit('remove', { id })"
  >
    <div @click="$emit('redirect', { name: 'companyDocuments', id: company.slug_url, notificationId: id})">
      <AvatarSlot
        :stock-user="user"
      >
        <div class="text-xs font-medium mr-6">
          <span class="font-bold">
            <RouterLink :to="{name: 'userFlow', params: {id: user.id}}">{{ user.full_name }}</RouterLink>
          </span> {{ $t('added document') }}
          <span
            v-if="company"
            class="whitespace-nowrap"
          >
            {{ $t('for') }}
            <RouterLink
              class="font-bold"
              :to="{name: 'companyOverview', params: {id: company.slug_url}}"
            > {{ company.name }}</RouterLink>
          </span>
          <div>{{ time }}</div>
        </div>
      </AvatarSlot>
    </div>
  </Notification>
</template>

<script>

import Notification from './Notification'

export default {
  name: 'NotificationCompanyDocument',
  components: {
    Notification
  },
  props: {
    user: { type: Object, default: () => {} },
    company: { type: Object, default: () => {} },
    time: { type: String, default: '' },
    id: { type: Number, default: 0 },
    pivot: { type: Object, default: () => {} }
  },
emits: [
'remove',
'redirect'
]
}
</script>
