<template>
  <div class="relative w-full">
    <div
      v-if="!isDropdownOpen"
      class="w-full py-1 border border-solid border-gray-400 rounded-lg focus:outline-none flex font-semibold justify-between items-center"
      @click="enableSearch"
    >
      <input
        type="text"
        class="grow text-left px-3 focus:outline-none cursor-default"
        :value="displayingValue"
        :placeholder="placeholder"
        :required="required"
      >
      <div class=" flex-none">
        <Icon
          name="chevron-down"
          class="text-lg text-gray-400"
        />
      </div>
    </div>

    <div v-else>
      <input
        ref="searchInput"
        v-model="searchTerm"
        type="text"
        placeholder="Search..."
        class="w-full px-3 py-2 rounded-t-lg border-t border-x border-solid border-gray-400 focus:outline-none font-semibold "
        @blur="handleBlur"
        @input="filterOptions"
      >

      <div
        v-if="isDropdownOpen"
        class="absolute w-full bg-white border-b border-x border-solid border-gray-400 rounded-b-lg shadow-lg max-h-60 overflow-auto z-10 "
      >
        <div
          v-if="filteredOptions.length === 0"
          class="px-4 py-2 text-gray-500"
        >
          No options found
        </div>

        <template v-if="isGroup">
          <template
            v-for="(group, index1) in filteredOptions"
            :key="index1"
          >
            <div class="bg-gray-200 px-3 py-1 text-sm font-semibold text-gray-700">
              {{ group.label }}
            </div>
            <div
              v-for="(option, index2) in group.options"
              :key="index2"
              class="px-4 py-2 hover:bg-blue-500 hover:text-white cursor-pointer option-class"
              @click="selectOption(option)"
            >
              {{ option.name }}
            </div>
          </template>
        </template>


        <slot
          v-else
          :class="'hover:bg-blue-500 hover:text-white font-medium text-base'"
          name="customOptions"
          :options="filteredOptions"
          :select-option="selectOption"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        options: {
            type: Array,
            default: () => []
        },
        selectedValue: {
            type: String,
            default: ''
        },
        isGroup: {
            type: Boolean,
            default: false
        },
        placeholder:{
            type:String,
            default: 'Search...'
        },
        required: {
            type: Boolean,
            default: true
        },
    },
    emits: [
        'select'
    ],
    data () {
        return {
            searchTerm: '',
            isDropdownOpen: false,
            filteredOptions: this.options
        }
    },
    computed:{
      displayingValue(){
        if (this.isGroup) {
          return this.selectedValue
        }else{

          return this.options.find(option => {
           return  (option.value.toLowerCase() === this.selectedValue.toLowerCase())
          })?.text;

        }
      }
    },
    methods: {
        filterOptions () {
            const term = this.searchTerm.toLowerCase();
            if (this.isGroup) {
                this.filteredOptions = this.options.map(group => {
                    const filteredGroup = {
                        ...group,
                        options: group.options.filter(option => option.name.toLowerCase().includes(term))
                    };
                    return filteredGroup;
                }).filter(group => group.options.length > 0);

            }
            else {
                // Filter simple options
                this.filteredOptions = this.options.filter(option => option.text.toLowerCase().includes(term));
            }
        },
        selectOption (option) {
            this.$emit('select', option);
            this.isDropdownOpen = false;
            this.searchTerm = option.name;
        },
        enableSearch () {
            this.isDropdownOpen = true
            setTimeout(() => {
                this.$refs.searchInput.focus()
            }, 100);
        },
        handleBlur () {
            setTimeout(() => {
                this.isDropdownOpen = false
            }, 100);
           
        }
    }
}
</script>

<style scoped>
/* Custom styling can go here */
</style>