<template>
  <div
    v-if="!hideClose"
    class="modal-close absolute cursor-pointer flex flex-col items-center mr-2 text-white text-sm z-50"
    :class="position"
    @click="$emit('toggle')"
  >
    <Icon
      name="x"
      class="text-4xl text-custom-purple-500 dark:text-custom-purple-100"
    />
  </div>
</template>

<script>

export default {
  name: 'Esc',
  props: {
    hideClose: {
      type: Boolean,
      default: false
    },
    hideText: {
      type: Boolean,
      default: false
    },
    position: {
      type: String,
      default: 'top-0 right-0 mt-4'
    }
  },
emits: [
'toggle'
]
}
</script>
