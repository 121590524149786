<template>
  <div>
    <div>
      <!-- <div
      v-if="attachments.length > 0"
      class="bg-main-background dark:bg-custom-purple-500 w-full text-center px-2 pt-3"
    >
      <div
        v-for="attachment in attachments"
        :key="attachment.id && attachment.linkable.id"
      >
        <AttachmentList
          :payload="attachment"
          class="py-1"
          @remove-attachment="$emit('remove-attachment', $event)"
        />
      </div>
    </div> -->

      <AttachMediaMenuContainer
        v-if="showMediaMenu"
        :gallery-key="commentId"
        :company-attachament="companyAttachament"
        preview-in-slot
        :path="path"
        :editables="editables"
        :attachment-type="'comment'"
        use-event-bus
        @on-media-change="$emit('on-media-change', $event)"
        @on-company-data="$emit('toggle-attachment-search')"
      />
      <div
        v-if="companyDataAttachments.length > 0 && showMediaMenu"
        class="bg-white py-2 dark:bg-custom-purple-400 max-h-48 px-2 overflow-auto"
      >
        <AttachmentList
          v-for="attachment in companyDataAttachments"
          :key="attachment.id"
          class="mb-2 mx-2"
          :payload="attachment"
          :show-company-name="true"
          @remove-attachment="$emit('remove-attachment', $event)"
        />
      </div>
      <div
        v-if="replyMode"
        class="
          px-4
          py-2
          flex
          justify-between
          border border-gray-200
          dark:border-custom-purple-500
          shadow-2xl
          dark:shadow-none
          bg-white
          dark:bg-custom-purple-400
        "
      >
        <span class="inline-block text-sm align-middle pt-2">{{ $t('Reply to') }}: {{ selectedComment.stock_user.full_name }}</span>
        <Icon
          class="text-2xl font-semibold text-custom-orange-400 dark:text-custom-purple-100"
          name="x"
          @click="$emit('clear')"
        />
      </div>

      <div class="p-4 dark:text-custom-purple-100  border border-gray-200 dark:border-custom-purple-300 shadow-2xl dark:shadow-none bg-white dark:bg-custom-purple-400 ">
        <div padding="p-0">
          <QuillEditor
            ref="QuillEditor"
            :content="modelValue"
            :emoji="emoji"
            :placeholder="$t('Write comment here')"
            :max-length="500"
            :edit-mode="editMode"
            class="w-full"
            :max-height="'small'"
            with-send-button
            @clear="$emit('clear')"
            @update:model-value="$emit('update:model-value', $event)"
            @emoji="$emit('emoji')"
            @submit="$emit('submit', $event)"
          >
            <Icon
              v-show="modelValue === '' && showAttachmentIcon && !showMediaMenu"
              class="text-2xl pr-2 font-semibold text-custom-purple-500 dark:text-custom-purple-100"
              name="plus"
              @click="$emit('toggle')"
            />
            <Icon
              v-show="modelValue === '' && showAttachmentIcon && showMediaMenu"
              class="text-2xl pr-2 font-semibold text-custom-orange-400 dark:text-custom-purple-100"
              name="x"
              @click="$emit('toggle')"
            />
          </QuillEditor>
        </div>
        <!-- <TextAreaWithEmoji
        :model-value="modelValue"
        :emoji="emoji"
        width="w-full"
        with-send-button
        :resize-text-area="true"
        :gallery-key="chatUuid"
        :max-length="300"
        @hide-attachment-icon="$emit('hide-attachment-icon', $event)"
        @submit="$emit('submit', $event)"
        @emoji="$emit('emoji', $event)"
        @update:model-value="$emit('update:model-value', $event)"
        @update-list="$emit('update-list', $event)"
      >
        <Icon
          v-show="value === '' && showAttachmentIcon && !showMediaMenu"
          class="text-2xl pr-2 font-semibold text-custom-purple-400 dark:text-custom-purple-100"
          name="plus"
          @click="$emit('toggle')"
        />
        <Icon
          v-show="value === '' && showAttachmentIcon && showMediaMenu"
          class="text-2xl pr-2 font-semibold text-custom-orange-400 dark:text-custom-purple-100"
          name="x"
          @click="$emit('toggle')"
        />
        <Icon
          v-if="isCompany && showAttachmentIcon"
          class="text-2xl pr-2 font-semibold text-custom-purple-400 dark:text-custom-purple-100"
          name="paperclip"
          @click="$emit('toggle-attachment-search')"
        />
      </TextAreaWithEmoji> -->
      </div>
    </div>
  </div>
</template>

<script>
import AttachMediaMenuContainer from '@/modules/attachMediaMenu'
export default {
  name: 'CommentFooter',
  components: {
    AttachMediaMenuContainer
  },
  props: {
    modelValue: { type: String, default: '' },
    emoji: { type: Boolean, default: false },
    path: { type: String, default: 'media/comments/' },
    showMediaMenu: { type: Boolean, default: false },
    editables: { type: Array, default: null },
    showAttachmentIcon: { type: Boolean, default: true },
    commentId: { type: String, default: '' },
    companyAttachament: { type: Boolean, default: true },
    replyMode: { type: Boolean, default: false },
    selectedComment: { type: Object, default: () => { } },
    companyDataAttachments: { type: Array, default: () => [] },
    editMode: { type: Boolean, default: false }
  },
  emits: [
    'on-media-change',
    'remove-attachment',
    'clear',
    'hide-attachment-icon',
    'submit',
    'update:model-value',
    'update-list',
    'toggle',
    'toggle-attachment-search',
    'emoji',
  ],
  methods: {
    eventEmoji (event) {
      console.log('event', event)
      this.$emit('emoji', event)
    }
  }
}
</script>
