<template>
  <BaseButton
    :bold="bold"
    :rounded="rounded"
    class="rounded-3xl text-white dark:text-custom-purple-100 text-base"
    :class="[extraClass,padding,textSize,border,secondary ? 'bg-custom-purple-500 dark:bg-custom-purple-500 hover:bg-custom-purple-400 dark:hover:bg-custom-purple-800' : 'bg-custom-purple-500 dark:bg-custom-purple-400 hover:bg-custom-purple-400 dark:hover:bg-custom-purple-450']"
    :no-padding="true"
    :no-shadow="true"
    :locked="locked"
    :opacity="opacity"
    @click="$emit('click', $event)"
  >
    <slot />
  </BaseButton>
</template>

<script>

export default {
  name: 'SellButton',
  props: {
    padding: {
      type: String,
      default: 'px-4 py-3'
    },
    extraClass: {
      type: String,
      default: ''
    },
    rounded:{
      type: String,
      default: 'rounded-xl'
    },
    textSize:{
      type: String,
      default: 'text-xs'
    },
    border:{
      type: String,
      default: ''
    },
    bold: {
      type: Boolean,
      default: true
    },
    secondary: {
      type: Boolean,
      default: false
    },
    locked: {
      type: Boolean,
      default: false
    },
    opacity:{
      type: String,
      default: ''
    }

  },
  emits: [
    'click'
  ]
}
</script>
