<template>
  <div
    ref="modal"
    tabindex="0"
    class="modal fixed left-0 top-0 inset-0 flex items-center z-60"
    :class="right ? 'justify-end' : 'justify-center'"
    @keyup.esc="$emit('toggle')"
  >
    <slot />
  </div>
</template>

<script>

export default {
  name: 'ModalOuter',
  props: {
    right: {
      type: Boolean,
      default: false
    }
  },
emits: [
'toggle'
]
}
</script>
