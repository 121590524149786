<template>
  <SlideInOut
    entry="down"
    exit="down"
    mode="out-in"
    :duration="500"
    :delay="100"
    class="w-full top-0 left-0 z-70"
  >
    <div
      class="flex flex-col w-full top-0 left-0 z-50 cursor-pointer"
      @click="$emit('click')"
    >
      <div
        class="bg-custom-orange-400 font-medium text-white p-2 text-xs flex justify-center"
        :class="padding"
      >
        <div class="w-full flex justify-center items-center px-2">
          <div>{{ $t(`Onboarding with ${(company) ? company[0].name : ''} is incomplete. click to continue.`) }}</div>
        </div>
      </div>
    </div>
  </SlideInOut>
</template>

<script>

import { SlideInOut } from 'vue3-transitions/esm'

export default {
  name: 'CompleteOnboardingSteps',
  components: {
    SlideInOut
  },
  props: {
    padding: {
      type: String,
      default: ''
    },
    company: {
      type: Object,
      default: () => {}
    }
  },
  emits: [
  'click'
  ]
}
</script>
