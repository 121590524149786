import { upperFirst } from "./helpers/string"

export const registerComponents = (app) => {
  var requireComponent = require.context("@/components", true, /\.vue$/)
  requireComponent.keys().forEach((fileName) => {
    let file = fileName.substring(fileName.lastIndexOf("/") + 1)
    const componentConfig = requireComponent(fileName)
    const componentName = upperFirst(file.replace(/\.\w+$/, ""))

    app.component(componentName, componentConfig.default || componentConfig)
  })

  const requireNotificationComponent = require.context(
    "@/modules/notifications/_components",
    true,
    /\.vue$/
  )
  requireNotificationComponent.keys().forEach((fileName) => {
    let file = fileName.substring(fileName.lastIndexOf("/") + 1)
    const componentConfig = requireNotificationComponent(fileName)
    const componentName = upperFirst(file.replace(/\.\w+$/, ""))

    app.component(componentName, componentConfig.default || componentConfig)
  })
}
