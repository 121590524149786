import { RepositoryFactory } from "@/api/repositoryFactory"
const repository = RepositoryFactory.get("companies")
import { defineStore } from "pinia"
import { useSnackbarStore } from "./snackbar"
import { useCompaniesStore } from "./companies"

export const useAssetsStore = defineStore("assets", {
  state: () => ({
    selectedCompanyId: null,
    companyAssets: {},
    loading: false,
    newLoading: false,
    editableId: null,
    alert: {
      title: "",
      create: false,
      body: "",
      type: "",
      showConfirmButton: false,
    },
  }),
  getters: {
    assets(state) {
      return state.companyAssets[state.selectedCompanyId]
    },
  },
  actions: {
    async fetch() {
      try {
        this.selectedCompanyId = useCompaniesStore().selectedCompanyId
        if (
          this.companyAssets[useCompaniesStore().selectedCompanyId] ===
          undefined
        ) {
          this.selectedCompanyId = useCompaniesStore().selectedCompanyId
          this.loading = true
          const response = await repository.assetList(
            useCompaniesStore().selectedCompanySN
          )
          this.loading = false
          if (response.status === 200 && response.data) {
            this.companyAssets = {
              ...this.companyAssets,
              [this.selectedCompanyId]: response.data,
            }
          }
        } else {
          this.refetch()
        }
      } catch (e) {
        this.loading = false
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async save(assetId) {
      try {
        const response = await repository.saveAsset(
          useCompaniesStore().selectedCompanyId,
          assetId
        )
        if (response.status === 204) {
          useSnackbarStore().snack({
            text: "Asset added successfully",
            type: "success",
            show: true,
            autoclose: true,
          })
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async pushAssets(payload) {
      this.companyAssets = {
        ...this.companyAssets,
        [payload[0].company.id]: [payload],
      }
    },
    async refetch() {
      try {
        this.selectedCompanyId = useCompaniesStore().selectedCompanyId
        this.newLoading = true
        const response = await repository.assetList(
          useCompaniesStore().selectedCompanySN
        )
        this.newLoading = false
        if (response.status === 200 && response.data) {
          this.companyAssets = {
            ...this.companyAssets,
            [this.selectedCompanyId]: response.data,
          }
        }
      } catch (e) {
        this.newLoading = false
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async deleteRequest(assetId) {
      try {
        const response = await repository.assetDeleteRequest(
          useCompaniesStore().selectedCompanyId,
          assetId
        )
        if (response.status === 204) {
          useSnackbarStore().snack({
            text: "Delete request added successfully",
            type: "success",
            show: true,
            autoclose: true,
          })
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    resetAlert() {
      this.alert = {
        title: "",
        create: false,
        body: "",
        type: "",
        showConfirmButton: false,
      }
    },
    setAlert(payload) {
      this.alert.title = payload.title
      this.alert.body = payload.body
      this.alert.create = payload.create
      this.alert.type = payload.type
      this.alert.showConfirmButton = payload.showConfirmButton
    },
    async delete(assetId) {
      try {
        const response = await repository.assetDelete(
          useCompaniesStore().selectedCompanyId,
          assetId
        )
        if (response.status === 204) {
          const index = this.companyAssets[this.selectedCompanyId].findIndex(
            (asset) => asset.id === assetId
          )
          this.companyAssets[this.selectedCompanyId].splice(index, 1)
          this.companyAssets = { ...this.companyAssets }
          // useSnackbarStore().snack({ text: 'Delete request generated successfully', type: 'success', show: true, autoclose: true })
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message,
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async editAsset(payload) {
      try {
        const companyId = payload.companyId
          ? payload.companyId
          : this.selectedCompanyId
        this.editableId = payload.assetId
        const response = await repository.editAsset(
          companyId,
          payload.assetId,
          payload
        )
        if (response.status === 200) {
          let responseData = [response.data]

          let assets = this.companyAssets[this.selectedCompanyId]
          responseData.find((response) => {
            const flag = assets.findIndex((asset) => {
              return response.id === asset.id
            })
            if (flag > -1) {
              assets[flag] = response
            } else {
              assets.push(response)
            }
          })
          this.companyAssets = { ...this.companyAssets }

          this.editableId = null
        }
      } catch (e) {
        this.editableId = null
        useSnackbarStore().snack({
          text: e.response.data.message,
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async addAsset(payload) {
      try {
        this.newLoading = true
        const response = await repository.addAsset(
          this.selectedCompanyId,
          payload
        )
        if (response.status === 204) {
          useSnackbarStore().snack({
            text: "Asset added successfully",
            type: "success",
            show: true,
            autoclose: true,
          })
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    reset() {
      this.companyAssets = {}
    },
  },
})
