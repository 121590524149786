import { defineStore } from "pinia"


export const useGalleriesStore = defineStore("galleries", {
  state: () => ({
    galleryFiles: {},
    galleryPreviews: {},
    galleryKey: null,
  }),
  getters: {
    selectedGalleryFiles(state) {
      if (!state.galleryFiles || !state.galleryFiles[state.galleryKey]) {
        return []
      }

      return state.galleryFiles
    },
    
    selectedGalleryPreviews(state) {
      if (!state.galleryPreviews || !state.galleryPreviews[state.galleryKey]) {
        return []
      }

      return state.galleryPreviews[state.galleryKey]
    },
  },
  actions: {
    set(data) {
      let files = []
      let previews = []
      if (this.galleryFiles[this.galleryKey]) {
        files = this.galleryFiles[this.galleryKey]
      }

      if (this.galleryPreviews[this.galleryKey]) {
        previews = this.galleryPreviews[this.galleryKey]
      }

      files.push(data.media)
      previews.push(data.preview)

      this.galleryFiles = { ...this.galleryFiles, [this.galleryKey]: files }
      this.galleryPreviews = {
        ...this.galleryPreviews,
        [this.galleryKey]: previews,
      }
    }, 
    remove(key) {
      this.galleryFiles[this.galleryKey].splice(key, 1)
      this.galleryPreviews[this.galleryKey].splice(key, 1)
      this.galleryFiles = { ...this.galleryFiles }
      this.galleryPreviews = { ...this.galleryPreviews }
    },
    
    select(galleryKey) {
      this.galleryKey = galleryKey
    },
    clear() {
      this.galleryFiles = { ...this.galleryFiles, [this.galleryKey]: [] }
      this.galleryPreviews = { ...this.galleryPreviews, [this.galleryKey]: [] }
      this.galleryKey = null
    },
  },
})
