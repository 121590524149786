import { defineStore } from 'pinia'
import { RepositoryFactory } from '@/api/repositoryFactory'
const repository = RepositoryFactory.get('filtrations')
import eventBus from '@/js/eventbus'

export const useFiltrationsStore = defineStore('filtrations', {
  state: () => ({
    selectedCurrency: 'LOCAL',
    relevant: true,
    inUniverse: 'in-universe',
    // Filter data
    filterDataLoading: false,
    screenerFilterDefault: [
      {
        code: 'sectors',
        group: 'sectors',
        type: 'multiselect',
        data: {},
        selected: null,
        preselect: null,
        suffix: false
      },
      {
        code: 'countries',
        group: 'countries',
        type: 'multiselect',
        data: {},
        selected: null,
        preselect: null,
        suffix: false
      },
      {
        code: 'marketcap',
        group: 'financials',
        type: 'range',
        data: { defaultRange: [0, 0] },
        selected: null,
        preselect: [0, 0],
        suffix: 'M€'
      },
      {
        code: 'price',
        group: 'financials',
        type: 'range',
        data: { defaultRange: [0, 0] },
        selected: null,
        preselect: [0, 0],
        suffix: '€'
      },
      {
        code: 'shares',
        group: 'financials',
        type: 'range',
        data: { defaultRange: [0, 0] },
        selected: null,
        preselect: [0, 0],
        suffix: 'M'
      }
    ],
    originalScreenerFilters: [],
    sectors: [],
    countries: [],
    marketCapMax: 0,
    priceMax: 0,
    sharesMax: 0,
    // Tag search
    searchTagsLoading: false,
    searchedTagsList: [],
    selectedTags: [],
    count: 0,
    forumAndRoomCount: 0,
    countLoading: false,
    forumAndRoomCountLoading: false,
    history: []
  }),
  getters: {
    screenerFilters(state) {
      const filters = state.screenerFilterDefault.map((filterDefault) => {
        const index = state.originalScreenerFilters.findIndex((f) => f.code === filterDefault.code)
        return index > -1 ? state.originalScreenerFilters[index] : filterDefault
      })

      filters.map((f) => {
        if (f.code === 'sectors') {
          f.data = { ...f.data, list: state.sectors }
        }

        if (f.code === 'countries') {
          f.data = { ...f.data, list: state.countries }
        }

        if (f.code === 'marketcap') {
          f.data.defaultRange = [0, state.marketCapMax]
          if (f.preselect[1] === 0) {
            f.preselect = [0, state.marketCapMax]
          }
        }
        if (f.code === 'shares') {
          f.data.defaultRange = [0, state.sharesMax]
          if (f.preselect[1] === 0) {
            f.preselect = [0, state.sharesMax]
          }
        }
        if (f.code === 'price') {
          f.data.defaultRange = [0, state.priceMax]
          if (f.preselect[1] === 0) {
            f.preselect = [0, state.priceMax]
          }
        }
      })
      return filters
    },
    selectedScreenerFilters() {
      const selected = []

      this.originalScreenerFilters.map((f) => {
        if (f.selected) {
          if (f.type === 'multiselect') {
            f.selected.map((listOption) => {
              const filterOption = {
                label: listOption.name,
                group: f.group,
                type: f.type,
                code: listOption.name + '-' + listOption.id,
                pics: f.pics_code,
                data: listOption,
                default: f,
                cleanable: true
              }

              selected.push(filterOption)
            })
          } else if (f.type === 'range') {
            if (f.data.defaultRange[0] !== f.selected[0] || f.data.defaultRange[1] !== f.selected[1]) {
              const filterOption = {
                label: f.code + ' ' + f.selected[0] + ' - ' + f.selected[1],
                group: f.group,
                type: f.type,
                suffix: f.suffix,
                code: f.code,
                data: f.selected,
                default: f,
                cleanable: true
              }

              selected.push(filterOption)
            }
          }
        }
      })

      return selected
    },
    // SELECTED FILTERS HAS A DEFAULT KEY WHICH CONTAINS ALL DATA. DO NOT NEED TO SEND
    // THEM WHEN SEARCHING COMPANIES, SO SLIM IT DOWN. IS USED IN COMPANIES STORE
    slimSelectedScreenerFilters() {
      const selected = []

      this.selectedScreenerFilters.map((f) => {
        const newFilter = { ...f, default: null }
        selected.push(newFilter)
      })

      return selected
    },
    filtrationPayload() {
      return [...this.slimSelectedScreenerFilters, ...this.selectedTags]
    },
    filtrationPayloadExplore() {
      // filter out selected tag which group is room or thread
      const selectedTags = this.selectedTags.filter((tag) => tag.type !== 'room' && tag.type !== 'thread')
      return [...this.slimSelectedScreenerFilters, ...selectedTags]
    },
    sanitizeHistory() {
      // convert filter_body to object and push an array of tags for each filter
      return this.history.map((h) => {
        let filterBody = JSON.parse(h.filter_body)
        let tags = []
        if (filterBody.length > 0) {
          filterBody.map((f) => {
            if (f.group === 'tags') {
              tags.push(f.label)
            }
          })
        }
        return { ...h, filter_body: filterBody, tags }
      })
    },
    getFiltrationUrlQuery() {
      let dataObj = {
        selectedTags: this.selectedTags
      }
      return JSON.stringify(dataObj)
    }
  },
  actions: {
    async fetchCount(resultType, resultSubType) {
      const payload = {
        filter: this.filtrationPayloadExplore,
        resultType,
        resultSubType
      }
      try {
        this.countLoading = true
        const response = await repository.getResult(payload)
        if (response.status === 200) {
          this.count = response.data.count
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.countLoading = false
      }
    },
    async fetchForumAndRoomCount(resultType, resultSubType) {
      const payload = {
        filter: this.filtrationPayload,
        resultType,
        resultSubType
      }
      try {
        this.forumAndRoomCountLoading = true
        const response = await repository.getResult(payload)
        if (response.status === 200) {
          this.forumAndRoomCount = response.data.count
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.forumAndRoomCountLoading = false
      }
    },
    async updateFilterData(payload) {
      return new Promise((resolve, reject) => {
        try {
          // DEFINE A FILTER OPTION
          let filter = null
          // IF PREVIOUS FILTER EXIST, USE THAT ONE
          let index = this.originalScreenerFilters.findIndex((f) => f.code === payload.screenerFilter.code)
          if (index > -1) {
            filter = this.originalScreenerFilters[index]
          } else {
            // ELSE USE THE DEFAULT FILTER OBJECTS. WITHOUT THIS FIRST SELECTION OF A FILTER WILL NOT WORK
            index = this.originalScreenerFilters.length
            const indexDefault = this.screenerFilterDefault.findIndex((f) => f.code === payload.screenerFilter.code)
            filter = this.screenerFilterDefault[indexDefault]
          }

          let screenerFilter = { ...filter, preselect: payload.preselect }
          let screenerFilters = [...this.originalScreenerFilters]
          screenerFilters[index] = screenerFilter
          resolve((this.originalScreenerFilters = [...screenerFilters]))
        } catch (e) {
          reject(e)
        }
      })
    },
    async fetchFilterData() {
      if (localStorage.inUniverse) {
        this.inUniverse = JSON.parse(localStorage.inUniverse)
      }

      if (localStorage.relevant) {
        this.relevant = JSON.parse(localStorage.relevant)
      }

      if (localStorage.selectedCurrency) {
        this.selectedCurrency = JSON.parse(localStorage.selectedCurrency)
      }

      this.filterDataLoading = true
      try {
        const response = await repository.fetchFilterData()
        if (response.status === 200 && response.data) {
          this.sectors = response.data.sectors
          this.countries = response.data.countries
          this.marketCapMax = Math.ceil(Number(response.data.max_valuation) / 100) * 100
          this.priceMax = Math.ceil(Number(response.data.price) / 100) * 100
          this.sharesMax = Math.ceil(Number(response.data.shares) / 100) * 100
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.filterDataLoading = false
      }
    },
    async addScreenerFilter(payload) {
      this.originalScreenerFilters = [...payload]
    },
    async setScreenerFilterFromTag(tag) {
      console.log('tag : ', tag)
      let filter = null
      let pre = []
      if (this.sectors.length === 0 || this.countries.length === 0) {
        await this.fetchFilterData()
      }

      if (tag.type === 'sector') {
        filter = this.sectors.find((sector) => sector.hash_tag === tag.label)
        pre = this.screenerFilters[0].preselect ? this.screenerFilters[0].preselect : []
        pre.push(filter)
        const screenerFilter = this.screenerFilters
        screenerFilter[0].preselect = pre
        screenerFilter[0].selected = pre
        if (this.originalScreenerFilters.length === 0) {
          this.originalScreenerFilters = screenerFilter
        } else {
          this.originalScreenerFilters[0].selected = pre
          this.originalScreenerFilters[0].preselect = pre
        }
      } else if (tag.type === 'country') {
        filter = this.countries.find((sector) => sector.hash_tag === tag.label)
        pre = this.screenerFilters[1].preselect ? this.screenerFilters[1].preselect : []
        pre.push(filter)
        const screenerFilter = this.screenerFilters
        screenerFilter[1].preselect = pre
        screenerFilter[1].selected = pre
        if (this.originalScreenerFilters.length === 0) {
          this.originalScreenerFilters = screenerFilter
        } else {
          this.originalScreenerFilters[1].selected = pre
          this.originalScreenerFilters[1].preselect = pre
        }
      }
      this.originalScreenerFilters = [...this.originalScreenerFilters]
    },
    async resetScreenerFilter() {
      this.originalScreenerFilters = [...[]]
      this.screenerFilterDefault = [
        ...[
          {
            code: 'sectors',
            group: 'sectors',
            type: 'multiselect',
            data: {},
            selected: null,
            preselect: null,
            suffix: false
          },
          {
            code: 'countries',
            group: 'countries',
            type: 'multiselect',
            data: {},
            selected: null,
            preselect: null,
            suffix: false
          },
          {
            code: 'marketcap',
            group: 'financials',
            type: 'range',
            data: { defaultRange: [0, 0] },
            selected: null,
            preselect: [0, 0],
            suffix: 'M€'
          },
          {
            code: 'price',
            group: 'financials',
            type: 'range',
            data: { defaultRange: [0, 0] },
            selected: null,
            preselect: [0, 0],
            suffix: '€'
          },
          {
            code: 'shares',
            group: 'financials',
            type: 'range',
            data: { defaultRange: [0, 0] },
            selected: null,
            preselect: [0, 0],
            suffix: 'M'
          }
        ]
      ]
      this.selectedTags = []
      // eventBus.$emit('on-filtration-change')
    },
    // Tags search
    async searchTags(searchParams) {
      this.searchTagsLoading = true
      try {
        const response = await repository.searchTags(searchParams)
        this.searchedTagsList = response.data.data
      } catch (error) {
        console.log(error)
      } finally {
        this.searchTagsLoading = false
      }
    },
    clearSearchedTags() {
      this.searchedTagsList = []
      this.searchTagsLoading = false
    },
    isTagAlreadySelected(tagLabel) {
      return this.selectedTags.some((tag) => tag.label === tagLabel)
    },
    addTag(tagLabel) {
      if (!this.isTagAlreadySelected(tagLabel)) {
        let tag = {
          label: tagLabel,
          group: 'tags',
          type: 'multiselect',
          isCustomTag: false
        }
        this.selectedTags = [...this.selectedTags, tag]
      }
      eventBus.$emit('on-filtration-change')
    },
    async resetTags() {
      let newTags = []
      this.selectedTags.forEach((tag) => {
        if (tag.isCustomTag) {
          newTags.push(tag)
        }
      })
      this.selectedTags = [...newTags]
    },
    async setTags() {
      if (this.selectedScreenerFilters.length > 0) {
        await this.selectedScreenerFilters.forEach((filter) => {
          if (filter.group !== 'financials' && !this.isTagAlreadySelected(filter.data.hash_tag)) {
            let tag = {
              label: filter.data.hash_tag,
              group: 'tags',
              type: 'multiselect',
              isCustomTag: false
            }
            this.selectedTags = [...this.selectedTags, tag]
          }
        })
      }
    },
    addForumTag(tagLabel, type) {
      if (!this.isTagAlreadySelected(tagLabel)) {
        let tag = {
          label: tagLabel,
          group: 'tags',
          type: type,
          isCustomTag: false
        }
        this.selectedTags = [...this.selectedTags, tag]
      }
      eventBus.$emit('on-filtration-change')
    },
    addCustomTag(tagLabel) {
      if (!this.isTagAlreadySelected(tagLabel)) {
        let tag = {
          label: tagLabel,
          group: 'tags',
          type: 'multiselect',
          isCustomTag: true
        }
        this.selectedTags = [...this.selectedTags, tag]
      }
      eventBus.$emit('on-filtration-change')
    },
    removeTag(tagLabel) {
      this.selectedTags = this.selectedTags.filter((tag) => tag.label !== tagLabel)
      if (this.selectedScreenerFilters.length > 0 && this.originalScreenerFilters.length > 0) {
        this.originalScreenerFilters.filter((filter) => {
          if (filter.code === 'sectors' && filter.preselect !== null && filter.selected !== null) {
            if (filter.preselect.length > 0 && filter.selected.length > 0) {
              const findpi = filter.preselect.findIndex((pre) => pre.hash_tag === tagLabel)
              if (findpi > -1) filter.preselect.splice(findpi, 1)
              const findsi = filter.selected.findIndex((selected) => selected.hash_tag === tagLabel)
              if (findsi > -1) filter.selected.splice(findsi, 1)
            }
          } else if (filter.code === 'countries' && filter.preselect !== null && filter.selected !== null) {
            if (filter.preselect.length > 0 && filter.selected.length > 0) {
              const findpi = filter.preselect.findIndex((pre) => pre.hash_tag === tagLabel)
              if (findpi > -1) filter.preselect.splice(findpi, 1)
              const findsi = filter.selected.findIndex((selected) => selected.hash_tag === tagLabel)
              if (findsi > -1) filter.selected.splice(findsi, 1)
            }
          }
        })
      }
      eventBus.$emit('on-filtration-change')
    },
    // Set History
    async setHistory() {
      if (this.selectedTags.length > 0) {
        const payload = {
          filter: this.filtrationPayload
        }
        try {
          await repository.setHistory(payload)
        } catch (e) {
          console.log(e)
        }
      }
    },
    async fetchHistory(userId) {
      try {
        const response = await repository.getHistory(userId)
        if (response.status === 200 && response.data) {
          this.history = response.data
        }
      } catch (e) {
        console.log(e)
      }
    },
    setFilterDataFromUrlQuery(query) {
      try {
        // Parse the URL query
        const dataObj = JSON.parse(query)
        const { selectedTags } = dataObj

        // Update store state
        if (selectedTags && selectedTags.length > 0) {
          this.selectedTags = selectedTags
        }
      } catch (error) {
        console.error('Error setting filter data from URL query:', error)
      }
    },
    setSelectedTagsFromHistory(tags) {
      this.selectedTags = tags
    }
  }
})
