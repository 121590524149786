import { RepositoryFactory } from "@/api/repositoryFactory"
const repository = RepositoryFactory.get("meta")
import { defineStore } from "pinia"

export const useMetasStore = defineStore("metas", {
  state: () => ({
    links: {},
  }),
  mutations: {},
  actions: {
    setLoading(payload) {
      this.links = {
        ...this.links,
        [payload.link]: { ...payload.link, loading: payload.loading },
      }
    },
    async check(url) {
      // commit('SET_LINK', { link: url })
      this.setLoading({ link: url, loading: true })

      const response = await repository.check({ links: [url] })

      this.setLoading({ link: url, loading: false })
      if (response.status === 200 && response.data) {
        this.links = {
          ...this.links,
          [url]: { ...response.data[url], loading: false },
        }
      }
    },
  },
})
