<template>
  <div
    class="px-4 text-custom-purple-500 dark:text-white"
  >
    <div class="flex flex-row items-center mt-2">
      <CountryFlagInternal
        :company="company"
        class="mt-1"
      />
      <p
        class="  font-extrabold cursor-pointer text-3xl"
        @click="$router.push({ name: 'companyOverview', params: { id: company.slug_url } })"
      >
        {{ company.name }}
      </p>
    </div>
    <p class="font-semibold mb-4">
      {{ company.followers_count }} followers
    </p>
  </div>
</template>

<script>
export default {
  name: 'CompanyNameAndFollowers',
  props: {
    company: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
