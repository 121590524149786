import { defineStore } from "pinia"

export const useModalsStore = defineStore("modals", {
  state: () => ({
    active: [],
  }),
  actions: {
    newModal(id) {
      this.active.push(id)
    },
    removeLatestModal() {
      if (this.active.length > 0) {
        this.active.splice(this.active.length - 1, 1)
      }
    },
  },
})
