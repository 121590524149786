import { RepositoryFactory } from "@/api/repositoryFactory"
const repository = RepositoryFactory.get("companies")
import { defineStore } from "pinia"
import { useSnackbarStore } from "./snackbar"
import { useCompaniesStore } from "./companies"

export const useContactsStore = defineStore("contacts", {
  state: () => ({
    selectedCompanyId: null,
    companyContacts: {},
    loading: false,
    newLoading: false,
    editableId: null,
    alert: {
      title: "",
      create: false,
      body: "",
      type: "",
      showConfirmButton: false,
    },
  }),
  getters: {
    contacts(state) {
      return state.companyContacts[state.selectedCompanyId]
    },
  },
  actions: {
    async fetch() {
      try {
        this.selectedCompanyId = useCompaniesStore().selectedCompanyId
        this.loading = true
        const response = await repository.contactList(
          useCompaniesStore().selectedCompanySN
        )
        this.loading = false
        if (response.status === 200 && response.data) {
          this.companyContacts = {
            ...this.companyContacts,
            [this.selectedCompanyId]: response.data,
          }
        }
      } catch (e) {
        this.loading = false
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async refetch() {
      try {
        this.selectedCompanyId = useCompaniesStore().selectedCompanyId
        this.newLoading = true
        const response = await repository.contactList(
          useCompaniesStore().selectedCompanySN
        )
        this.newLoading = false
        if (response.status === 200 && response.data) {
          this.companyContacts = {
            ...this.companyContacts,
            [this.selectedCompanyId]: response.data,
          }
        }
      } catch (e) {
        this.newLoading = false
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async deleteRequest(contactId) {
      try {
        const response = await repository.contactDeleteRequest(
          useCompaniesStore().selectedCompanyId,
          contactId
        )
        if (response.status === 204) {
          useSnackbarStore().snack({
            text: "Delete request added successfully",
            type: "success",
            show: true,
            autoclose: true,
          })
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    resetAlert() {
      this.alert = {
        title: "",
        create: false,
        body: "",
        type: "",
        showConfirmButton: false,
      }
    },
    setAlert(payload) {
      this.alert.title = payload.title
      this.alert.body = payload.body
      this.alert.create = payload.create
      this.alert.type = payload.type
      this.alert.showConfirmButton = payload.showConfirmButton
    },
    async delete(contactId) {
      try {
        const response = await repository.contactDelete(
          useCompaniesStore().selectedCompanyId,
          contactId
        )
        if (response.status === 204) {
          const index = this.companyContacts[this.selectedCompanyId].findIndex(
            (member) => member.id === contactId
          )
          this.companyContacts[this.selectedCompanyId].splice(index, 1)
          this.companyContacts = { ...this.companyContacts }
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message,
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async pushContacts(payload) {
      payload = [payload]
      this.companyContacts = {
        ...this.companyContacts,
        [payload[0].company.id]: payload,
      }
    },
    async editContact(payload) {
      try {
        this.editableId = payload.contactId
        const response = await repository.editContact(
          this.selectedCompanyId,
          payload.contactId,
          payload
        )
        if (response.status === 200) {
          payload = [response.data]

          let contacts = this.companyContacts[this.selectedCompanyId]
          payload.find((response) => {
            const flag = contacts.findIndex((media) => {
              return response.id === media.id
            })
            if (flag > -1) {
              contacts[flag] = response
            } else {
              contacts.push(response)
            }
          })
          this.companyContacts = { ...this.companyContacts }

          this.editableId = null
          // useSnackbarStore().snack({ text: 'Contact added successfully', type: 'success', show: true, autoclose: true })
        }
      } catch (e) {
        this.editableId = null
        useSnackbarStore().snack({
          text: e.response.data.message,
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async addContact(payload) {
      try {
        this.newLoading = true
        const response = await repository.addContact(
          this.selectedCompanyId,
          payload
        )
        if (response.status === 204) {
          useSnackbarStore().snack({
            text: "Contact added successfully",
            type: "success",
            show: true,
            autoclose: true,
          })
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    reset() {
      this.companyContacts = {}
    },
  },
})
