<template>
  <div class="mt-4 w-11/12">
    <div
      :class="type === 'App\\Models\\Comment' ? 'pl-4' : ''"
    >
      <AvatarSlot
        :stock-user="stockUser"
        linkable-avatar
        linkable-avatar-only
        :size="size"
        alignment="relative"
      >
        <BaseCard
          class="rounded-lg w-full relative"
          :padding="'px-4 py-2'"
          shadow="shadow-none"
          :background="'bg-gray-100 dark:bg-custom-purple-450'"
        >
          <template #body>
            <RouterLink :to="{ name: 'userFlow', params: { id: stockUser.id } }">
              <NameSubTitle
                :name="name"
                :stock-user="stockUser"
              />
            </RouterLink>
            <div>
              <p class="text-xs">
                <TextMessage
                  v-if="comment"
                  :message="comment"
                  :message-english="commentEnglish"
                  :mentions-array="mentionsArray"
                />
                <MediaGallery
                  v-if="gallery && gallery.length > 0"
                  :gallery="gallery"
                  big
                />
              </p>
            </div>
            <div
              v-if="linkables.length > 0"
              class="mb-2"
            >
              <AttachedData
                v-for="attachment in linkables"
                :key="attachment.id"
                :border="'border dark:border-custom-purple-400'"
                :background="'rounded rounded-lg bg-gray-300 dark:bg-custom-purple-400'"
                class="mt-2 pr- 2"
                :attachment="attachment"
              />
            </div>
          </template>
        </BaseCard>
      </AvatarSlot>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PostComment',
  props: {
    stockUser: { type: Object, default: () => {} },
    name: { type: String, default: '' },
    comment: { type: String, default: '' },
    commentEnglish: { type: String, default: '' },
    mentionsArray: { type: Array, default: () => [] },
    gallery: { type: Array, default: () => [] },
    type: { type: String, default: null },
    linkables: { type: Array, default: () => [] },
    size: { type: Number, default: 40 },
  },
}
</script>

<style lang="scss">
.post-comment {
  span {
    background-color: inherit !important;
    color: inherit !important;
  }
}
</style>
