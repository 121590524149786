<template>
  <TaggableCompanySearch
    :search="search"
    :show-search="!useToggle || (useToggle && showSearch)"
    :selected-list="internalSelectedList"
    :searched-companies="searchedCompanies"
    :loading-companies="loadingCompanies"
    :has-pre-list="preList.length > 0"
    :hide-options="hideOptions"
    :input-class="inputClass"
    @input-company="searchCompany"
    @select-company="selectCompany"
    @remove-company-from-array="removeCompanyFromArray"
    @add-companies-to-list="addCompaniesToList"
    @show-search="showSearch = true"
    @cancel="cancel"
  >
    <slot />
    <template #options>
      <slot name="options" />
    </template>
  </TaggableCompanySearch>
</template>

<script>

import TaggableCompanySearch from './_components/TaggableCompanySearch'
import i18n from '@/js/language'
import { useCompaniesStore } from '../../stores/companies'
import { useSnackbarStore } from '../../stores/snackbar'

export default {
  name: 'TaggableCompanySearchModule',
  components: {
    TaggableCompanySearch
  },
  props: {
    companyUser: {
      type: Boolean,
      default: false
    },
    inUniverse: {
      type:Boolean,
      default: false
    },
    preList: {
      type: Array,
      default: () => []
    },
    withDetails: {
      type: Boolean,
      default: false
    },
    useToggle: {
      type: Boolean,
      default: false
    },
    hideOptions: {
      type: Boolean,
      default: false
    },
    inputClass: {
      type: String,
      default: ''
    },
    creditCheckModal: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'selected-company-list',
    'add-company-list'
  ],
  setup() {
    const companiesStore = useCompaniesStore()
    const snackbarStore = useSnackbarStore()

    return { companiesStore, snackbarStore }
  },
  data () {
    return {
      internalSelectedList: [],
      search: '',
      showSearch: false
    }
  },
  computed: {
    searchedCompanies() {
      return this.companiesStore.searchedCompanies
    },
    loadingCompanies() {
      return this.companiesStore.loadingCompanies 
    }
  },
  mounted () {
    const self = this
    setTimeout(() => {
      console.log("MOUNTED TIMEOUT")
      if (self.preList.length > 0) {
        self.internalSelectedList = [...self.preList]
      }
    }, 700)
  },
  methods: {
    cancel () {
      this.showSearch = false
      this.internalSelectedList = [...this.preList]
    },
    selectCompany (company) {
      // console.log("SELECT COMPANY", company)
      if (this.internalSelectedList.length > 0) {
        const flag = this.internalSelectedList.findIndex(c => c.id === company.id)
        if (flag === -1) {
          this.internalSelectedList.push(company)
        } else {
          this.snackbarStore.snack({ text: i18n.global.t('Company already selected.'), type: 'info', show: true, autoclose: true })
        }
      } else {
        this.internalSelectedList.push(company)
      }
      this.search = ''
      this.$emit('selected-company-list', this.internalSelectedList)

      // TODO: Use this when the credit check modal supprot multiple companies
      // if (this.internalSelectedList.length > 0) {
      //   const flag = this.internalSelectedList.findIndex(c => c.id === company.id)
      //   if (flag === -1) {
      //     this.internalSelectedList.push(company)
      //     if (this.creditCheckModal) {
      //       this.$store.dispatch('creditCheck/setCompaniesToBuyCreditReport', this.internalSelectedList)
      //     }
      //   } else {
      //     this.snackbarStore.snack({ text: i18n.global.t('Company already selected.'), type: 'info', show: true, autoclose: true })
      //   }
      // } else {
      //   this.internalSelectedList.push(company)
      //   if (this.creditCheckModal) {
      //     this.$store.dispatch('creditCheck/setCompaniesToBuyCreditReport', this.internalSelectedList)
      //   }
      // }
    },
    searchCompany ($event) {
      // console.log("SEARCH COMPANY, ", $event)
      this.companiesStore.setLoadingCompanies(true)
      this.search = $event.target.value
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.fetchCompany(this.search)
      }, 600)
    },
    async fetchCompany (search) {
      // console.log("FETCH COMPANY", search)

      if (search !== '') {
        if (this.companyUser) {
          this.companiesStore.fetchUsersCompanies({ search })
        } else if (this.inUniverse) {
          this.companiesStore.fetchInUniverseCompanies({search})
        } else {
          this.companiesStore.fetchAll({ search, withDetails: this.withDetails })
        }
      } else {
        this.companiesStore.setLoadingCompanies(false)
      }
    },
    addCompaniesToList () {
      // console.log("addCompaniesToList", this.internalSelectedList)
      // console.log("addCompaniesToList length", this.internalSelectedList.length)

      if (this.internalSelectedList.length > 0) {
        this.$emit('add-company-list', this.internalSelectedList)
        this.cancel()
      } else {
        const confirm = window.confirm(this.$t('Add cannot be empty, want to abort?'))
        if (confirm) {
          this.cancel()
        }
      }
    },
    removeCompanyFromArray (id) {
      if (this.internalSelectedList.length > 0) {
        const index = this.internalSelectedList.findIndex(company => company.id === id)
        if (index > -1) {
          this.internalSelectedList.splice(index, 1)
        }
      }

      this.$emit('selected-company-list', this.internalSelectedList)
    }
  }
}
</script>
