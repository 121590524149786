import { defineStore } from 'pinia'

export const useJobsStore = defineStore('jobs', {
  state: () => ({
    runningQueue: {}
  }),
  actions: {
    async runEndpoint(endpoint, callback, time = 10000, override = false) {
      // IF runningqueue for endpoint is set and has been called within 10 seconds
      if (!override && this.runningQueue[endpoint] && new Date().getTime() - this.runningQueue[endpoint] < time) {
        console.log('JOB ALREADY RUNNING, SKIP THIS TRIGGER')
        return
      }

      this.runningQueue[endpoint] = new Date().getTime()

      return callback()
    }
  }
})
