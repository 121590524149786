import { RepositoryFactory } from "@/api/repositoryFactory"
const repository = RepositoryFactory.get("companies")
import { defineStore } from "pinia"
import { useSnackbarStore } from "./snackbar"
import { useCompaniesStore } from "./companies"

export const useOwnersStore = defineStore("owners", {
  state: () => ({
    selectedCompanyId: null,
    companyOwners: {},
    loading: false,
    newLoading: false,
    editableId: null,
    alert: {
      title: "",
      create: false,
      body: "",
      type: "",
      showConfirmButton: false,
    },
  }),
  getters: {
    owners(state) {
      return state.companyOwners[state.selectedCompanyId]
    },
  },
  actions: {
    setAlert(payload) {
      this.alert.title = payload.title
      this.alert.body = payload.body
      this.alert.create = payload.create
      this.alert.type = payload.type
      this.alert.showConfirmButton = payload.showConfirmButton
    },
    resetAlert() {
      this.alert = {
        title: "",
        create: false,
        body: "",
        type: "",
        showConfirmButton: false,
      }
    },
    setList(payload) {
      this.companyOwners = {
        ...this.companyOwners,
        [this.selectedCompanyId]: payload,
      }
    },
    async fetch() {
      try {
        this.selectedCompanyId = useCompaniesStore().selectedCompanyId
        this.loading = true
        const response = await repository.ownersList(
          useCompaniesStore().selectedCompanySN
        )
        this.loading = false
        if (response.status === 200 && response.data) {
          this.setList(response.data)
        }
      } catch (e) {
        this.loading = false
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async refetch() {
      try {
        this.selectedCompanyId = useCompaniesStore().selectedCompanyId
        this.newLoading = true
        const response = await repository.ownersList(
          useCompaniesStore().selectedCompanySN
        )
        this.newLoading = false
        if (response.status === 200 && response.data) {
          this.setList(response.data)
        }
      } catch (e) {
        this.newLoading = false
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async deleteRequest(contactId) {
      try {
        const response = await repository.ownerDeleteRequest(
          useCompaniesStore().selectedCompanyId,
          contactId
        )
        if (response.status === 204) {
          useSnackbarStore().snack({
            text: "Delete request added successfully",
            type: "success",
            show: true,
            autoclose: true,
          })
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message,
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async delete(ownerId) {
      try {
        const response = await repository.ownerDelete(
          useCompaniesStore().selectedCompanyId,
          ownerId
        )
        if (response.status === 204) {
          const index = this.companyOwners[this.selectedCompanyId].findIndex(
            (owner) => owner.id === ownerId
          )
          this.companyOwners[this.selectedCompanyId].splice(index, 1)
          this.companyOwners = { ...this.companyOwners }
          // useSnackbarStore().snack({ text: 'Delete request generated successfully', type: 'success', show: true, autoclose: true })
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message,
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async editOwner(payload) {
      try {
        this.editableId = payload.ownerId
        const response = await repository.editOwner(
          this.selectedCompanyId,
          payload.ownerId,
          payload
        )
        if (response.status === 200) {
          const payload = [response.data]
          let owners = this.companyOwners[this.selectedCompanyId]
          payload.find((response) => {
            const flag = owners.findIndex((media) => {
              return response.id === media.id
            })
            if (flag > -1) {
              owners[flag] = response
            } else {
              owners.push(response)
            }
          })
          this.companyOwners = { ...this.companyOwners }
          this.editableId = null
        }
      } catch (e) {
        this.editableId = null
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async addOwner(payload) {
      try {
        this.newLoading = true
        const response = await repository.addOwner(
          this.selectedCompanyId,
          payload
        )
        if (response.status === 204) {
          useSnackbarStore().snack({
            text: "Owner added successfully",
            type: "success",
            show: true,
            autoclose: true,
          })
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    reset() {
      this.companyOwners = {}
    },
  },
})
