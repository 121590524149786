<template>
  <ModalContainer
    v-if="usersStore.showBecomePremium"
    :hide-close="true"
    full-size
    @close="becomePremiumSeen"
  >
    <BecomePremiumModal
      @close="becomePremiumSeen"
      @confirm="confirm"
      @cancel="becomePremiumSeen"
    >
      <slot />
    </BecomePremiumModal>
  </ModalContainer>
</template>
<script>
import BecomePremiumModal from './_components/BecomePremiumModal.vue'
import ModalContainer from '@/modules/modal'
import { useUsersStore } from '../../stores/users'

export default {
  name: 'BecomePremiumModalModule',
  components: {
    BecomePremiumModal,
    ModalContainer
  },
  setup() {
    const usersStore = useUsersStore()

    return { usersStore }
  },
  methods: {
    becomePremiumSeen () {
      this.usersStore.setShowBecomePremium(false)
    },
    confirm () {
      this.$router.push({ name: 'premium' })
      this.becomePremiumSeen()
    }
  }
}
</script>
