<template>
  <div>
    <div
      class="relative aspect-video"
    >
      <video
        id="videoPlayer"
        class="w-full max-h-[580px] rounded-lg"
        :controls="true"
        preload="metadata"
        :poster="selectedDocument.thumbnail"
      >
        <source :src="selectedDocument.path">
      </video>
      <PlayButton
        v-if="show"
        @click="$emit('toggle-video')"
      />
    </div>
    <div class="flex items-center justify-between mt-4">
      <div>
        <p class="font-extrabold text-xl my-2 dark:text-custom-purple-100">
          {{ selectedDocument.title }}
        </p>
        <p class="font-medium dark:text-custom-purple-100">
          {{ selectedDocument.description }}
        </p>
      </div>
      <!-- <div>
        <span class="text-custom-gray-400 text-sm font-medium">
          There {{ videos.length > 2 ? 'are' : 'is' }} {{ videos.length > 1 ? videos.length - 1 : videos.length }} more {{ videos.length > 1 ? 'videos' : 'video' }} 
        </span>
        <RoundedButton
          class="px-6"
          no-shadow
          @click="$emit('more')"
        >
          <p class="font-bold text-sm text-custom-purple-500 dark:text-custom-purple-100">
            {{ $t('More videos') }} 
          </p>
        </RoundedButton>
      </div> -->
    </div>
  </div>
</template>

<script>

export default {
  name: 'VideoModal',
  props: {
	selectedDocument: {
      type: Object,
      default: () => {}
	},
	show: {
      type: Boolean,
      default: true
	}
  },
  emits: [
	'toggle-video'
  ]
}
</script>
