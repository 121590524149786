// import { allTypes } from '@/enums/mediaTypes'
import { defineStore } from "pinia"

export const useMediaFiltersStore = defineStore("mediaFilters", {
  state: () => ({
    filters: {
      by_all: 1,
      by_image: 1,
      by_video: 1,
      by_audio: 1,
      by_document: 1,
      // types: allTypes
    },
  }),
  actions: {
    async init() {
      if (localStorage.filters) {
        this.filters = { ...JSON.parse(localStorage.filters) }
      }
    },
    async setFilter(payload) {
      this.filters = { ...this.filters, ...payload }
      localStorage.filters = JSON.stringify(this.filters)
    },
  },
})
