import socket from "@/js/socket"
import { defineStore } from "pinia"
import { useUsersStore } from "./users"

export const useSocketsStore = defineStore("sockets", {
  actions: {
    connect() {
      if (!socket.connected) {
        if (useUsersStore().user && useUsersStore().user.profile) {
          socket.auth = { userId: useUsersStore().user.profile.id }

          socket.connect()
          socket.on("connect_error", (err) => {
            if (err.message === "invalid username") {
              console.log("Invalid user name")
            }
          })
        } else {
          console.log("not logged in yet")
        }
      } else {
        console.log("already connected, do nothing")
      }
    },
    disconnect() {
      console.log("socket disconnect")
      socket.disconnect()
    },
    clean() {
      socket.off("connect_error")
    },
  },
})
