<template>
  <div class="main">
    <div
      class="dropzone-container w-full"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
      <input
        :id="'imageDrop' + mode "
        :ref="'file' + mode "
        type="file"
        name="file"
        multiple="multiple"
        class="hidden-input"
        :accept="pdf ? 'application/pdf' : '.png, .jpg, .jpeg'"
        @change="change"
      >

      <label
        :for="'imageDrop' + mode "
        class="file-label"
      >
        <div
          v-if="isDragging"
          class=" dark:text-white"
        >Release to drop files here.</div>
        <div
          v-else
          class=" dark:text-white"
        >Drag or drop files here or <span class="text-custom-blue-800  dark:text-white">browse</span></div>
        <div class="flex justify-center mt-6">
          <Icon
            name="cloud-upload"
            class="text-5xl dark:text-white"
          />
        </div>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pdf: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: ''
    }
  },
  emits: [
    'on-image-change'
  ],
  data () {
    return {
      isDragging: false,
      files: [],
    };
  },
  methods: {
    dragover (e) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave () {
      this.isDragging = false;
    },
    drop (e) {
      e.preventDefault();
      if(this.mode === 'csr'){
      this.$refs.filecsr.files = e.dataTransfer.files;

      }
      if(this.mode === 'annual'){
      this.$refs.fileannual.files = e.dataTransfer.files;

      }
      if(this.mode === 'growth'){
      this.$refs.filegrowth.files = e.dataTransfer.files;

      }
      if(this.mode === 'rnd'){
      this.$refs.filernd.files = e.dataTransfer.files;

      }
      //this.$refs.file.files = e.dataTransfer.files;
      this.onChange();
    },
    change (e) {
      console.log('jello')
      this.$emit('on-image-change', e)

    }
  }
};
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  flex-grow: 1;
  align-items: center;
  height: 14vh;
  justify-content: center;
  text-align: center;
}

.dropzone-container {
  padding: 2rem;
  border: 2px dashed #e2e8f0;
}

.hidden-input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
}

.file-label {
  font-size: 16px;
  font-weight: normal;
  display: block;
  cursor: pointer;
}

.preview-container {
  display: flex;
  margin-top: 2rem;
}

.preview-card {
  display: flex;
  border: 1px solid #a2a2a2;
  padding: 5px;
  margin-left: 5px;
}

.preview-img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #a2a2a2;
  background-color: #a2a2a2;
}
</style>