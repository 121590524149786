<template>
  <ModalContainer
    popup
    :hide-close="true"
    :disable-close="true"
    :padding="false"
    width="max-w-full"
    extra-class="rounded-xl"
    @toggle="$emit('toggle')"
  >
    <div class="flex relative">
      <div class="w-full z-10">
        <TradingEmailVerification
          :pin1="pin1"
          :pin2="pin2"
          :pin3="pin3"
          :email="user.email"
          :loading-otp="loadingOTPCheck"
          :is-mobile="isMobile"
          :otp-error-message="otpErrorMessage"
          @pin1="pin1 = $event"
          @pin2="pin2 = $event"
          @pin3="pin3 = $event"
          @submit="pinVerification"
          @resend-otp="resendOtp"
          @paste-code="pasteCode"
        />
      </div>
    </div>
  </ModalContainer>
</template>
  
  <script>
  
  import TradingEmailVerification from './_components/TradingEmailVerification.vue'
  import { isMobile } from '@/js/helpers/mobile'
  import { useUsersStore } from '../../stores/users'
  import { useSnackbarStore } from '../../stores/snackbar'
  import ModalContainer from '@/modules/modal'

  
  export default {
    name: 'EmailVerificationModule',
    components: {
      ModalContainer,
      TradingEmailVerification
    },
    props: {
      show: {
        type: Boolean,
        default: false
      },
      hideBack: {
        type: Boolean,
        default: false
      },
      email: {
        type: String,
        default: ''
      },
      userType: {
        type: String,
        default: ''
      }
    },
  emits: [
  'resend-otp', 'toggle'
  ],
    setup(){
      const usersStore = useUsersStore()
      const snackbarStore = useSnackbarStore()
      return {usersStore, snackbarStore }
    },
    data () {
      return {
        pin1: null,
        pin2: null,
        pin3: null,
        otpErrorMessage: '',
      }
    },
    computed: {
      loadingOTPCheck(){
        return this.usersStore.loadingOTPCheck
      },
      user(){
        return this.usersStore.user
      },
      loading(){
        return this.usersStore.loading
      },
      isMobile () {
        return isMobile()
      }
    },
    methods: {
      pasteCode (event) {
        let pin = event.clipboardData.getData('text/plain')
        if (pin.length >= 3) {
          this.pin1 = pin[0]
          this.pin2 = pin[1]
          this.pin3 = pin[2]
        } else if (pin.length === 2) {
          this.pin1 = pin[0]
          this.pin2 = pin[1]
        } else this.pin1 = pin[0]
      },
      resendOtp () {
        this.usersStore.resendTradingOtp()
      },
      async pinVerification () {
        this.otpErrorMessage = ''
        if (this.pin1 > 9 || this.pin2 > 9 || this.pin3 > 9) {
          this.snackbarStore.snack({ type: 'error', e: 'please enter a valid varification pin', show: true, autoclose: true })
        } else {
            let code = String(this.pin1) + String(this.pin2) + String(this.pin3)
            const response = await this.usersStore.verifyTradingEmail({ token: code })
            if (response.status === 'error') {
              this.pin1 = null
              this.pin2 = null
              this.pin3 = null
              this.otpErrorMessage = response.message
              // this.snackbarStore.snack({ type: 'error', e: response.message, show: true, autoclose: true })
            }
        }
      }
    }
  }
  </script>
  