<template>
  <ModalContainer
    v-if="maintenance"
    :hide-close="true"
    full-size
    class="z-70"
  >
    <MaintenanceModal
      :maintenance-to="maintenanceTo"
    />
  </ModalContainer>
</template>

<script>

import { useAppsStore } from '../../stores/app'
import MaintenanceModal from './_components/MaintenanceModal'
import ModalContainer from '@/modules/modal'

export default {
  name: 'MaintenanceModalModule',
  components: {
    MaintenanceModal,
    ModalContainer
  },
  setup() {
    const appsStore = useAppsStore()
    
    return { appsStore }
  },
  data () {
    return {
      maintenanceTimer: null
    }
  },
  computed: {
    loading() {
      return this.usersStore.loading
    },
    user() {
      return this.usersStore.user
    }
  },
  mounted () {
    this.getMaintenance()

    this.maintenanceTimer = setInterval(() => {
      this.getMaintenance()
    }, 5 * 60 * 1000)
  },
  beforeUnmount () {
    clearInterval(this.maintenanceTimer)
  },
  methods: {
    async getMaintenance () {
      await this.appsStore.getMaintenance()
    }
  }
}
</script>
