import { RepositoryFactory } from "@/api/repositoryFactory"
const repository = RepositoryFactory.get("exchanges")
const repositoryFiltrations = RepositoryFactory.get('filtrations')
import { useFiltrationsStore } from "./filtrations"
import { defineStore } from "pinia"
import { useSnackbarStore } from "./snackbar"

export const useExchangesStore = defineStore("exchanges", {
  state: () => ({
    mostVisitedExchanges: {},
    topTrendingExchanges: {},
    exchangeMap: {},
    exchangeCard: [],
    exchange: {},
    exchangeNews: [],
	exchangeBestCompanies: {},
	exchangeCurrentYearCompanies: {},
	exchangeMarketCap: {},
  exchangeMarketCapForCompany: [],
	loadingEpp: false,
    searchedExchanges: [],
    selectedExchangeId: null,
    loading: false,
    loadingExchangeMap: false,
	loadingMostVisitedExchanges: false,
	loadingTopTrendingExchanges: false,
	loadingExchangeCard: false,
	loadingExchangeNews: false,
    servicesLoading: false,
    saveExchangeFeesLoading: false,
    segmentsLoading: false,
	exchangeCompanies: [],
	loadingExchangeCompanies: false,
	exchangeCompaniesNextPageUrl: null,
	loadingNewExchangeCompanies: false,
    followingExchanges: [],
	loadingFollowingExchanges: false
  }),
  actions: {
    setLoadingExchanges(boolean) {
      this.loading = boolean
    },
    async setList(payload) {
      if (this.exchanges.length < 1) {
        this.exchanges = payload
      } else {
        payload.find((response) => {
          const flag = this.exchanges.find((exchange) => {
            return Number(response.id) === Number(exchange.id)
          })
          if (!flag) {
            this.exchanges.push(response)
          }
        })
      }
	},
    async fetchExchanges() {
      try {
        this.loading = true
        const response = await repository.fetchExchanges()
        this.loading = false
        if (response.status === 200 && response.data) {
          this.exchanges = { ...response.data}
          this.searchedExchanges=Object.values(response.data.exchanges_list)
        }
      } catch (e) {
        this.loading = false
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
	async fetchAll() {
      await Promise.all([
        // this.fetchExchanges(),
        this.fetchExchangeMap(),
        this.fetchMostVisitedExchanges(),
        this.fetchTopTrendingExchanges(),
      ])
    },
	async fetchEpp(exchangeSlugUrl) {
      this.loadingEpp = true
      await Promise.all([
        this.fetchExchange(exchangeSlugUrl),
		this.fetchExchangeBestCompanies(exchangeSlugUrl),
		this.fetchExchangeCurrentYearCompanies(exchangeSlugUrl),
		this.fetchExchangeMarketCap(exchangeSlugUrl)
      ])
      this.loadingEpp = false
	},
	async fetchExchangeMap() {
      try {
		this.loadingExchangeMap = true
		const payload = {
          filter: useFiltrationsStore().filtrationPayloadExplore,
          resultType: "exchanges",
          resultSubType: "exchangeMap",
		}
		const response = await repositoryFiltrations.getResult(payload)
		if (response.status === 200 && response.data) {
          this.exchangeMap = response.data
        }
		this.loadingExchangeMap = false
      } catch (e) {
		this.loadingExchangeMap = false
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
	async fetchMostVisitedExchanges() {
      try {
		this.loadingMostVisitedExchanges = true
		const payload = {
          filter: useFiltrationsStore().filtrationPayloadExplore,
          resultType: "exchanges",
          resultSubType: "mostVisitedExchanges",
		}
		const response = await repositoryFiltrations.getResult(payload)
		if (response.status === 200 && response.data) {
          this.mostVisitedExchanges = response.data
        }
		this.loadingMostVisitedExchanges = false
      } catch (e) {
		this.loadingMostVisitedExchanges = false
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
	async fetchTopTrendingExchanges() {
      try {
		this.loadingTopTrendingExchanges = true
		const payload = {
          filter: useFiltrationsStore().filtrationPayloadExplore,
          resultType: "exchanges",
          resultSubType: "topTrendingExchanges",
		}
		const response = await repositoryFiltrations.getResult(payload)
		if (response.status === 200 && response.data) {
          this.topTrendingExchanges = response.data
        }
		this.loadingTopTrendingExchanges = false
      } catch (e) {
		this.loadingTopTrendingExchanges = false
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
	async fetchExchangeCard(payload) {
      try {
        this.loadingExchangeCard = true
		const response = await repository.fetchExchangeCard(payload)
		if (response.status === 200 && response.data) {
          this.exchangeCard = response.data
        }
        this.loadingExchangeCard= false
      } catch (e) {
		this.loadingExchangeCard= false
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async fetchExchange(exchangeSlugUrl) {
      try {
        const response = await repository.fetchExchange(exchangeSlugUrl)
        if (response.status === 200 && response.data) {
          this.exchange = response.data
          this.selectedExchangeId = response.data.exchange.id
		}
      } catch (error) {
        console.log(error)
      }
    },
    async fetchExchangeNews(exchangeSlugUrl) {
      try {
          this.loadingExchangeNews = true
          const response = await repository.fetchExchangeNews(exchangeSlugUrl)
          if (response.status === 200 && response.data) {
            this.exchangeNews = response.data.data.slice(0,4)
          }
          this.loadingExchangeNews = false
      } catch (error) {
          this.loadingExchangeNews = false
          console.log(error)
      }
    },
	async fetchExchangeBestCompanies(exchangeSlugUrl) {
      try {
        const response = await repository.fetchExchangeBestCompanies(exchangeSlugUrl)
        if (response.status === 200 && response.data) {
          this.exchangeBestCompanies = response.data
        }
      } catch (error) {
        console.log(error)
      }
    },
	async fetchExchangeMarketCap(exchangeSlugUrl) {
      try {
        const response = await repository.fetchExchangeMarketCap(exchangeSlugUrl)
        if (response.status === 200 && response.data) {
          this.exchangeMarketCap = response.data
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchExchangeMarketCapForCompany(exchangeSlugUrl) {
      try {
        const response = await repository.fetchExchangeMarketCap(exchangeSlugUrl)
        if (response.status === 200 && response.data) {
          this.exchangeMarketCapForCompany = response.data.exchangeMarketCap?? {}
        }
      } catch (error) {
        console.log(error)
      }
    },
	async fetchExchangeCurrentYearCompanies(exchangeSlugUrl) {
      try {
        const response = await repository.fetchExchangeCurrentYearCompanies(exchangeSlugUrl)
        if (response.status === 200 && response.data) {
          this.exchangeCurrentYearCompanies = response.data
        }
      } catch (error) {
        console.log(error)
      }
    },
	async uploadLogo(payload) {
      try {
        const response = await repository.uploadLogo(this.selectedExchangeId, payload)
        if (response.status === 200) {
          this.fetchExchange(this.selectedExchangeId)
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async newAdvisor(newAdvisor) {
      try {
        const response = await repository.saveNewAdvisor(this.selectedExchangeId, newAdvisor)
        if (response.status === 200) {
          this.fetchExchange(this.selectedExchangeId)
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message,
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async updateAdvisor(updateAdvisor) {
      try {
        const response = await repository.updateAdvisor(
          this.selectedExchangeId,
          updateAdvisor.id,
          updateAdvisor
        )
        if (response.status === 200) {
          this.fetchExchange(this.selectedExchangeId)
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message,
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async deleteAdvisor(deleteAdvisor) {
      try {
        const response = await repository.deleteAdvisor(this.selectedExchangeId, deleteAdvisor.id)
        if (response.status === 200) {
          this.fetchExchange(this.selectedExchangeId)
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message,
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async newMember(newMember) {
      try {
        const response = await repository.saveNewMember(this.selectedExchangeId, newMember)
        if (response.status === 200) {
          this.fetchExchange(this.selectedExchangeId)
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message,
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async updateMember(updateMember) {
      try {
        const response = await repository.updateMember(
          this.selectedExchangeId,
          updateMember.id,
          updateMember
        )
        if (response.status === 200) {
          this.fetchExchange(this.selectedExchangeId)
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message,
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async deleteMember(deleteMember) {
      try {
        const response = await repository.deleteMember(this.selectedExchangeId, deleteMember.id)
        if (response.status === 200) {
          this.fetchExchange(this.selectedExchangeId)
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message,
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async newManagement(newManagement) {
      try {
        const response = await repository.saveNewManagement(this.selectedExchangeId, newManagement)
        if (response.status === 200) {
          this.fetchExchange(this.selectedExchangeId)
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message,
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async updateManagement(updateManagement) {
      try {
        const response = await repository.updateManagement(
          this.selectedExchangeId,
          updateManagement.id,
          updateManagement
        )
        if (response.status === 200) {
          this.fetchExchange(this.selectedExchangeId)
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message,
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async deleteManagement(deleteManagement) {
      try {
        const response = await repository.deleteManagement(this.selectedExchangeId, deleteManagement.id)
        if (response.status === 200) {
          this.fetchExchange(this.selectedExchangeId)
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message,
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async newDocumentList(newDocument) {
      try {
        const response = await repository.newDocumentListFile(this.selectedExchangeId, { document: newDocument })
        if (response.status === 200) {
          this.fetchExchange(this.selectedExchangeId)
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message,
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async updateDocumentListFile(document) {
      try {
        const response = await repository.updateDocumentListFile(this.selectedExchangeId, document.id, document)
        if (response.status === 200) {
          this.fetchExchange(this.selectedExchangeId)
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message[0],
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async deleteDocumentListFile(document) {
      try {
        const response = await repository.deleteDocumentListFile(this.selectedExchangeId, document.id)
        if (response.status === 200) {
          this.fetchExchange(this.selectedExchangeId)
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message,
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async updateExchangeFees(updateFees) {
      try {
        this.saveExchangeFeesLoading = true
        const response = await repository.updateExchangeFees(
          this.selectedExchangeId,
          updateFees
        )
        if (response.status === 200) {
          this.fetchExchange(this.selectedExchangeId)
          this.saveExchangeFeesLoading = false
        }
      } catch (e) {
        this.saveExchangeFeesLoading = false
        useSnackbarStore().snack({
          text: e.response.data.message,
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async newSMEService(newService) {
      try {
        this.servicesLoading = true
        const response = await repository.saveNewSMEService(this.selectedExchangeId, newService)
        if (response.status === 200) {
          this.fetchExchange(this.selectedExchangeId)
          this.servicesLoading = false
        }
      } catch (e) {
        this.servicesLoading = false
        useSnackbarStore().snack({
          text: e.response.data.message,
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async updateSMEService(updateService) {
      try {
        this.servicesLoading = true
        const response = await repository.updateSMEService(
          this.selectedExchangeId,
          updateService.id,
          updateService
        )
        if (response.status === 200) {
          this.fetchExchange(this.selectedExchangeId)
          this.servicesLoading = false
        }
      } catch (e) {
        this.servicesLoading = false
        useSnackbarStore().snack({
          text: e.response.data.message,
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async deleteSMEService(deleteService) {
      try {
        this.servicesLoading = true
        const response = await repository.deleteSMEService(this.selectedExchangeId, deleteService.id)
        if (response.status === 200) {
          this.fetchExchange(this.selectedExchangeId)
          this.servicesLoading = false
        }
      } catch (e) {
        this.servicesLoading = false
        useSnackbarStore().snack({
          text: e.response.data.message,
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async updateContactInfo(updateContactInfo) {
      try {
        const response = await repository.updateContactInfo(
          this.selectedExchangeId,
          updateContactInfo
        )
        if (response.status === 200) {
          this.fetchExchange(this.selectedExchangeId)
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message,
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async updateLocation(updateLocation) {
      try {
        const response = await repository.updateLocation(
          this.selectedExchangeId,
          { locations: updateLocation }
        )
        if (response.status === 200) {
          this.fetchExchange(this.selectedExchangeId)
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message,
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async deleteLocation(deleteLocation) {
      try {
        const response = await repository.deleteLocation(
          this.selectedExchangeId,
          deleteLocation.id
        )
        if (response.status === 200) {
          this.fetchExchange(this.selectedExchangeId)
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message,
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async newSegment(newSegment) {
      try {
        this.segmentsLoading = true
        const response = await repository.saveNewSegment(this.selectedExchangeId, newSegment)
        if (response.status === 200) {
          this.fetchExchange(this.selectedExchangeId)
          this.segmentsLoading = false
        }
      } catch (e) {
        this.segmentsLoading = false
        useSnackbarStore().snack({
          text: e.response.data.message,
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async updateSegment(updateSegment) {
      try {
        this.segmentsLoading = true
        const response = await repository.updateSegment(
          this.selectedExchangeId,
          updateSegment.id,
          updateSegment
        )
        if (response.status === 200) {
          this.fetchExchange(this.selectedExchangeId)
          this.segmentsLoading = false
        }
      } catch (e) {
        this.segmentsLoading = false
        useSnackbarStore().snack({
          text: e.response.data.message,
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async deleteSegment(deleteSegment) {
      try {
        this.segmentsLoading = true
        const response = await repository.deleteSegment(this.selectedExchangeId, deleteSegment.id)
        if (response.status === 200) {
          this.fetchExchange(this.selectedExchangeId)
          this.segmentsLoading = false
        }
      } catch (e) {
        this.segmentsLoading = false
        useSnackbarStore().snack({
          text: e.response.data.message,
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async addVideo(payload) {
      try {
        const response = await repository.addVideo(this.selectedExchangeId, payload)
        if (response.status === 200 && response.data) {
          this.fetchExchange(this.selectedExchangeId)
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async editVideo(payload) {
      try {
        const response = await repository.editVideo(this.selectedExchangeId, payload.video.id, payload)
        if (response.status === 200 && response.data) {
          this.fetchExchange(this.selectedExchangeId)
        }
      } catch (e) {
        this.loading = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async deleteVideo(payload) {
      try {
        const response = await repository.deleteVideo(this.selectedExchangeId, payload.id)
        if (response.status === 200) {
          this.fetchExchange(this.selectedExchangeId)
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
	updateFollowers(payload) {
      let exchangeIndex = this.exchangeMap.findIndex((exchange) => Number(payload.exchange_id) === Number(exchange.id))
      if (exchangeIndex > -1) {
        this.exchangeMap[exchangeIndex].is_follower = payload.is_follower
        this.exchangeMap = [...this.exchangeMap]
      }
    },
    async followExchange(exchangeId) {
      try {
        const response = await repository.followExchange(exchangeId)
        if (response.status === 200) {
          this.fetchExchange(exchangeId)
          this.updateFollowers(response.data)
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async unfollowExchange(exchangeId) {
      try {
        const response = await repository.unfollowExchange(exchangeId)
        if (response.status === 200) {
          this.fetchExchange(exchangeId)
          this.updateFollowers(response.data)
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async newRuleDocumentListFile(newDocument) {
      try {
        const response = await repository.newRuleDocumentListFile(this.selectedExchangeId, { document: newDocument })
        if (response.status === 200) {
          this.fetchExchange(this.selectedExchangeId)
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message,
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async updateRuleDocumentListFile(document) {
      try {
        const response = await repository.updateRuleDocumentListFile(this.selectedExchangeId, document.id, document)
        if (response.status === 200) {
          this.fetchExchange(this.selectedExchangeId)
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message[0],
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async deleteRuleDocumentListFile(document) {
      try {
        const response = await repository.deleteRuleDocumentListFile(this.selectedExchangeId, document.id)
        if (response.status === 200) {
          this.fetchExchange(this.selectedExchangeId)
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message,
          type: 'error',
          show: true,
          autoclose: true
        })
      }
    },
    async share() {
      try {
        const response = await repository.share(this.selectedExchangeId)
        if (response.status === 200) {
          this.fetchExchange(this.selectedExchangeId)
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
	async fetchExchangeCompanies(exchangeSlugUrl) {
      try {
		this.loadingExchangeCompanies = true
        const response = await repository.fetchExchangeCompanies(exchangeSlugUrl)
        if (response.status === 200 && response.data) {
          this.exchangeCompanies = response.data.data
          this.exchangeCompaniesNextPageUrl = response.data.next_page_url
        }
		this.loadingExchangeCompanies = false
      } catch (e) {
		this.loadingExchangeCompanies = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
		})
      }
	},
	async scrollExchangeCompanies() {
      try {
        this.loadingNewExchangeCompanies = true
        const response = await repository.scroll(this.exchangeCompaniesNextPageUrl)
        if (response.status === 200 && response.data) {
          this.exchangeCompanies.push(...response.data.data)
          this.exchangeCompaniesNextPageUrl = response.data.next_page_url
        }
        this.loadingNewExchangeCompanies = false
      } catch (e) {
		this.loadingNewExchangeCompanies = false
		useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
		})
      }
	},
	async fetchFollowingExchanges() {
      try {
        this.loadingFollowingExchanges = true
        const response = await repository.fetchFollowingExchanges()
        this.loadingFollowingExchanges = false
        if (response.status === 200 && response.data) {
          this.followingExchanges = [ ...response.data]
        }
      } catch (e) {
        this.loadingFollowingExchanges = false
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
  }
})
