<template>
  <ModalContainer
    popup
    :padding="false"
    height="h-auto"
    width="max-w-full"
    extra-class="rounded-xl"
    @toggle="$emit('toggle', $event)"
    @on-back-button="$emit('toggle', $event)"
  >
    <div class="flex relative overflow-hidden">
      <Gradient />
      <div class="absolute w-full z-10">
        <p class="text-center font-extrabold text-3xl text-custom-purple-500 dark:text-custom-purple-100 p-6 pt-14">
          Oh snap! <br>
          You are not verified yet.
        </p>
        <p class="px-10 text-center text-base font-semibold dark:text-custom-purple-200">
          To do this you need to be verified. Verify your account now!
        </p>
      </div>
    </div>
    <div class="flex items-center justify-center py-8 dark:bg-custom-purple-400">
      <RouterLink :to="{ name: 'signup' }">
        <button class="px-6 py-3 cursor-pointer font-semibold bg-custom-orange-400 text-white dark:text-custom-purple-100 rounded-xl">
          {{ $t('Get verified now!') }}
        </button>
      </RouterLink>
    </div>
  </ModalContainer>
</template>

<script>
import ModalContainer from '@/modules/modal'

export default {
  name: 'BecomePremiumUser',
  components: {
    ModalContainer
  },
  emits: [
  'toggle'
  ]
}
</script>
