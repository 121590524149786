const COMPANY_TAG = '$'
const USER_TAG = '@'
const COMPANY_PREFIX = COMPANY_TAG + 'C'
const USER_PREFIX = USER_TAG + 'U'
// const MATCHING = new RegExp([COMPANY_TAG, USER_TAG].join('|'), 'g')

export default {
  simpleParse (message, mentions) {
    return this.parse(message, mentions, 'simple')
  },
  parse (message, mentions = [], type = 'default') {
    if (mentions.length === 0) {
      return message
    }

    if (!message) {
      return message
    }

    const companyButton = `<button data-id='[[__SHORT_NAME__]]' data-name='companyOverview' data-mention='[[__COMPANY_NAME__]]' class='company-mention-tag font-semibold text-gray-600 px-1 dark:text-white ${(type==='chat') ? 'text-custom-darkGray-500' : 'text-custom-light-header'}  dark:text-custom-dark-header focus:outline-none rounded-sm'>[[__COMPANY_NAME__]]</button>`
    const userButton = `<button data-id='[[__ID__]]' data-name='userFlow' data-mention='[[__USERNAME__]]' class='user-mention-tag text-gray-600 px-1 dark:text-white font-semibold ${(type==='chat') ? 'text-custom-darkGray-500' : 'text-custom-light-header'} dark:text-custom-dark-header focus:outline-none rounded-sm'>[[__USERNAME__]]</button>`

    mentions.map(mention => {
      if (mention.mentionable_type.indexOf('Company') > -1) {
        const tag = '\\' + COMPANY_PREFIX
        const mentionRegex = `${tag}${mention.mentionable.id}`
        const regexReplace = new RegExp(mentionRegex, 'g')
        let replaceWith
        if (type === 'simple') {
          replaceWith = `[[__COMPANY_NAME__]]`.replace(/\[\[__COMPANY_NAME__\]\]/g, COMPANY_TAG + mention.mentionable.name)
        } else {
          replaceWith = companyButton.replace(/\[\[__SHORT_NAME__\]\]/g, mention.mentionable.slug_url).replace(/\[\[__COMPANY_NAME__\]\]/g, COMPANY_TAG + mention.mentionable.name)
        }
        message = message.replace(regexReplace, replaceWith)
      } else {
        const tag = '\\' + USER_PREFIX
        const mentionRegex = `${tag}${mention.mentionable.id}`
        const regexReplace = new RegExp(mentionRegex, 'g')
        let replaceWith
        if (type === 'simple') {
          replaceWith = `[[__USERNAME__]]`.replace(/\[\[__USERNAME__\]\]/g, USER_TAG + mention.mentionable.username)
        } else {
          replaceWith = userButton.replace('[[__ID__]]', mention.mentionable.id).replace(/\[\[__USERNAME__\]\]/g, USER_TAG + mention.mentionable.username)
        }

        message = message.replace(regexReplace, replaceWith)
      }
    })

    return message
  },

  tag (message, mentions, mentionList = []) {
    const createElement = (str) => {
      let frag = document.createDocumentFragment()

      var elem = document.createElement('div')
      elem.innerHTML = str

      while (elem.childNodes[0]) {
        frag.appendChild(elem.childNodes[0])
      }
      return frag
    }

    if (message.indexOf('@') === -1 && message.indexOf('$') === -1) {
      return { message, mentions }
    }
    // const regexReplace = new RegExp(mentionRegex, 'g')

    let count = message.split('<span class="mention" data-index="').length - 1
    const mentionWrapper = createElement(message)

    for (let i = 0; i < count; i++) {
      const mention = mentionWrapper.querySelector('.mention')
      const tag = mention.attributes['data-denotation-char'].nodeValue
      const id = mention.attributes['data-id'].nodeValue

      const replacement = ((tag === '@') ? USER_PREFIX : COMPANY_PREFIX) + id

      mentions.push({ id: id, type: (tag === '@') ? 'stockUser' : 'company' })
      mention.replaceWith(replacement)
    }

    const div = document.createElement('div')
    div.appendChild(mentionWrapper.cloneNode(true))

    message = div.innerHTML

    return { message, mentions }
  }
}
