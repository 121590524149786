<template>
  <div
    class="py-1 px-2 rounded-md cursor-pointer"
    :class="[colors]"
    @click="$emit('click', $event)"
  >
    <slot />
  </div>
</template>

<script>

export default {
  name: 'FilterMenuBadge',
  props: {
    secondary: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'click'
  ],
  computed: {
    colors () {
      if (this.secondary) {
        return 'bg-custom-purple-500 dark:bg-custom-purple-400 text-white dark:text-custom-purple-100 dark:hover:bg-custom-purple-450 hover:text-custom-purple-100'
      } else {
        return 'bg-custom-purple-500 dark:bg-custom-purple-450 text-white dark:text-custom-purple-100 dark:hover:bg-custom-purple-500 hover:text-custom-purple-100'
      }
    }
  }
}
</script>
