<template>
  <div v-if="notification.notifiable">
    <div>
      <Notification
        :pivot="notification.pivot"
        @remove="$emit('remove', { id: notification.id })"
      >
        <RouterLink :to="{name: 'companyOverview', params: {id: notification.notifiable.company?.slug_url}}">
          <AvatarSlot
            :company="notification.notifiable.company"
          >
            <div class="text-xs mr-6">
              <span class="font-bold">
                {{ notification.notifiable.company.name }}
                :</span> {{ notification.notifiable.stock_user.full_name }} has granted you access to manage {{ notification.notifiable.company.name }} page.
              <div>{{ time }}</div>
            </div>
          </AvatarSlot>
        </RouterLink>
      </Notification>
    </div>
  </div>
</template>

<script>

import Notification from './Notification'

export default {
  name: 'NotificationCompanyRollAssigned',
  components: {
    Notification
  },
  props: {
    notification: { type: Object, default: () => {} },
    time: { type: String, default: '' }
  },
  emits: ['remove']
}
</script>