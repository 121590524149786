<template>
  <div
    class="relative"
    :class="width"
  >
    <Mentionable
      :keys="['@', '$']"
      :items="list"
      :loading="loading"
      offset="6"
      class="z-50 flex flex-col"
      @open="loadUsers($event)"
      @close="onClose"
      @search="loadUsers($event)"
      @apply="apply"
    >
      <div class="flex items-start">
        <div
          class="flex items-center z-20 mt-1"
        >
          <slot />
        </div>
        <div class="relative z-30 flex flex-col justify-center items-end w-full">
          <textarea-autosize
            ref="textarea"
            :model-value="modelValue"
            :rows="noOfRows"
            :maxlength="maxLength"
            :disabled="locked"
            :class="`w-full pr-9 whitespace-pre-wrap rounded-xl shadow-2xl dark:shadow-lg-dark border border-gray-200 dark:bg-custom-purple-500 dark:border-custom-dark-header focus:outline-none text-sm overflow-hidden`"
            :placeholder="placeholder"
            :max-height="maxLength > 1500 ? 320 : maxLength > 500 ? 220 : 110"
            @click="onClick()"
            @focus="onFocus()"
            @blur="offFocus()"
            @paste="paste"
            @update:model-value="$emit('update:model-value', $event)"
            @keydown.enter="submitOnCtrlEnter"
          />
          <Icon
            class="text-2xl absolute top-1 right-2 font-semibold text-custom-purple-400 dark:text-primary-dark"
            name="happy"
            @click="$emit('emoji', !emoji)"
          />
          <div class="text-2xs mr-1">
            {{ modelValue.length }} {{ $t('of') }} {{ maxLength }}
          </div>
        </div>
        <div class="flex items-center">
          <Icon
            v-if="withSendButton"
            class="text-2xl mt-1 cursor-pointer pl-2 font-semibold text-custom-purple-400 dark:text-custom-dark-header"
            name="send"
            @click="submit"
          />
          <Icon
            v-if="editMode"
            class="text-2xl cursor-pointer font-semibold text-custom-purple-400 dark:text-primary-dark"
            name="x"
            @click="$emit('clear')"
          />
        </div>
      </div>
      <Picker
        v-show="emoji"
        :data="emojiIndex"
        title="Pick your emoji..."
        emoji="point_up"
        :class="`width ${pickerPosition}`"
        set="apple"
        :emoji-size="36"
        class="mt-4"
        theme="dark"
        @select="addEmoji"
      />
    </Mentionable>
  </div>
</template>

<script>
import data from 'emoji-mart-vue-fast/data/all.json'
import 'emoji-mart-vue-fast/css/emoji-mart.css'
import { Picker, EmojiIndex } from 'emoji-mart-vue-fast/src'
import eventBus from '@/js/eventbus'
import { differenceInSeconds } from 'date-fns'
import { useUsersStore } from '@/stores/users'
import { useCompaniesStore } from '@/stores/companies'

let emojiIndex = new EmojiIndex(data)

export default {
  name: 'TextAreaWithEmoji',
  components: {
    Picker: Picker
  },
  props: {
    width: { type: String, default: 'w-full' },
    modelValue: { type: String, default: '' },
    emoji: { type: Boolean, default: false },
    rows: { type: Number, default: 2 },
    withSendButton: { type: Boolean, default: false },
    editMode: { type: Boolean, default: false },
    resizeTextArea: { type: Boolean, default: false },
    placeholder: { type: String, default: 'Write here' },
    pickerPosition: { type: String, default: '' },
    maxLength: { type: Number, default: 3000 }
  },
  emits: [
    'emoji',
    'clear',
    'on-tag-end',
    'on-image-paste',
    'update-list',
    'emoji',
    'emoji-textarea-focus',
    'hide-attachment-icon',
    'emoji-textarea-blur',
    'hide-attachment-icon',
    'input',
    'update:model-value',
    'submit',
  ],
  setup () {
    const usersStore = useUsersStore()
    const companiesStore = useCompaniesStore()

    return { usersStore, companiesStore }
  },
  data () {
    return {
      debounce: '',
      emojiIndex: emojiIndex,
      emojisOutput: '',
      noOfRows: this.rows,
      textAreaWidth: 'w-11/12 rounded-xl',
      show: true,
      isCompany: false,
      loading: false
    }
  },
  computed: {
    locked () {
      if (this.usersStore.user.profile && this.usersStore.user.profile.is_verified === false) return true
      else {
        const difference = differenceInSeconds(new Date(this.usersStore.locked_to), new Date())
        return (difference > 0)
      }
    },
    list () {
      if (this.isCompany) {
        return this.companiesStore.filteredCompanyList
      } else {
        return this.usersStore.filteredUserList
      }
    }
  },
  mounted () {
    if (this.resizeTextArea) {
      this.textAreaWidth = 'w-11/12 rounded-xl'
      this.noOfRows = 1
    } else {
      this.textAreaWidth = 'w-full rounded-xl'
    }

    eventBus.$on('on-user-tag', () => {
      this.addText('@')
      eventBus.$emit('on-tag-end')
    })

    eventBus.$on('on-company-tag', () => {
      this.addText('$')
      eventBus.$emit('on-tag-end')
    })
  },
  methods: {
    paste (e) {
      if (e.clipboardData) {
        const items = e.clipboardData.items
        if (!items) return

        let isImage = false
        for (var i = 0; i < items.length; i++) {
          if (items[i].type.indexOf('image') !== -1) {
            var blob = items[i].getAsFile()
            eventBus.$emit('on-image-paste', blob)
            isImage = true
          }
        }

        if (isImage === true) {
          e.preventDefault()
        }
      }
    },
    apply (event) {
      this.$emit('update-list', event)
      this.isCompany = false
      setTimeout(() => {
        const textarea = this.$refs.textarea.$el
        textarea.focus()
      }, 100)
    },
    onClick () {
      if (this.emoji) {
        this.$emit('emoji', false)
      }
    },
    onFocus () {
      eventBus.$emit('emoji-textarea-focus')
      if (this.resizeTextArea) {
        this.$emit('hide-attachment-icon', false)
        this.noOfRows = 1
        this.textAreaWidth = 'w-full rounded-lg'
      }
    },
    offFocus () {
      eventBus.$emit('emoji-textarea-blur')

      if (this.resizeTextArea) {
        this.$emit('hide-attachment-icon', true)
        this.noOfRows = 1
        this.textAreaWidth = 'w-11/12 rounded-xl'
      }
    },
    addText (newInput) {
      if (this.$refs.textarea) {
        const textarea = this.$refs.textarea.$el
        textarea.focus()
        const cursorPosition = textarea.selectionEnd
        const start = this.modelValue.substring(0, textarea.selectionStart)
        const end = this.modelValue.substring(textarea.selectionStart)
        const text = start + newInput + end
        this.$emit('update:model-value', text)
        textarea.focus()
        this.$nextTick(() => {
          textarea.selectionEnd = cursorPosition + newInput.length
        })
      }
    },
    addEmoji (emoji) {
      // THIS ONE IS A BIT BUGGY ON TWO EMOJIS
      const textarea = this.$refs.textarea.$el
      const cursorPosition = textarea.selectionEnd
      const start = this.modelValue.substring(0, textarea.selectionStart)
      const end = this.modelValue.substring(textarea.selectionStart)
      const text = start + emoji.native + end
      this.$emit('update:model-value', text)
      textarea.focus()
      this.$nextTick(() => {
        textarea.selectionEnd = cursorPosition + emoji.native.length
      })
      textarea.blur()
    },
    onClose () {
      this.isCompany = false
    },
    async loadUsers (searchText = null) {
      this.loading = true
      if (searchText.length === 1) {
        if (searchText === '$') {
          this.isCompany = true
        }

        if (searchText === '$' || searchText === '@') {
          searchText = ''
        }
      }

      clearTimeout(this.debounce)
      this.debounce = await setTimeout(() => {
        this.fetch(searchText)
        this.loading = false
      }, 400)
    },
    async fetch (searchText) {
      if (this.isCompany) {
        this.companiesStore.fetchAll({ search: searchText })
      } else {
        this.usersStore.fetchAll({ search: searchText })
      }
    },
    submitOnCtrlEnter (event) {
      if (event.ctrlKey && event.keyCode === 13) {
        this.$emit('submit')
        event.preventDefault()
      }
    },
    submit () {
      // this.$refs.textarea.$el.focus()
      this.$emit('submit')
    }
  }
}
</script>

<style lang="scss">
  .width {
    width: 100% !important;
  }
.row { display: flex; }
.row > * { margin: auto; }

.dark .emoji-mart {
  @apply bg-custom-purple-600 border-custom-purple-500;

  .emoji-mart-bar {
    @apply border-custom-purple-500;
  }

  input {
    @apply bg-custom-purple-500 border-custom-purple-400;
  }

  .emoji-mart-category-label h3 {
    @apply bg-custom-purple-500 border-custom-purple-400 text-white;
  }
}

.dark {
  .mentionable-popover {
    @apply bg-custom-purple-600 text-primary-dark;
  }
}

.mentionable-popover {
  @apply bg-white text-primary;
}

</style>
