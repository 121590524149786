import { get, post } from '../helpers/axiosHelper'

const url = `${process.env.VUE_APP_API_URL}`

const countries = async () => get(`${url}/countries`)
const userCountries = async () => get(`${url}/user-countries`)
const deviceIp = async () => get(`${url}/user-ip`)

const sortCountries = async (id) => post(`${url}/sort-countries`, id)
const countriesWithStates = async () => get(`${url}/country-with-states`)
const countriesWithIsd = async () => get(`${url}/country-isd-codes`)
export default {
  countries,
  sortCountries,
  userCountries,
  deviceIp,
  countriesWithStates,
  countriesWithIsd
}
