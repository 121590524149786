<template>
  <BaseButton
    :bold="false"
    :class="'bg-white dark:text-primary-dark dark:border-custom-purple-300 hover:underscore dark:shadow-node border border-gray-200 rounded-md' + textColor"
    @click="$emit('click', $event)"
  >
    <slot />
  </BaseButton>
</template>

<script>

export default {
  name: 'NeutralButton',
  props: {
    textColor: { type: String, default: 'text-purple-400 dark:bg-custom-purple-500' }
  },
  emits: [
    'click'
  ],
  computed: {
    isSecondary () {
      return (this.secondary !== false)
    }
  }
}
</script>
