<template>
  <VersionBanner
    v-show="!maintenance && newVersionAvailable"
    :padding="globalPadding"
    @click="goToAxyer"
  />
</template>

<script>
import VersionBanner from './_components/VersionBanner'
import headerPaddingMixin from '@/mixins/headerPaddingMixin'
import { useAppsStore } from '../../stores/app'

export default {
  name: 'VersionBannerModule',
  components: {
    VersionBanner
  },
  mixins: [headerPaddingMixin],
  props: {
    padding: {
      type: String,
      default: ''
    }
  },
  setup() {
  const appStore = useAppsStore()

return { appStore }
},
  computed: {
    newVersionAvailable() {
        return this.appStore.newVersionAvailable 
    },
    maintenance() {
        return this.appStore.maintenance 
    },
    globalPadding () {
      return this.platformPadding
    }
  },
  methods: {
    goToAxyer () {
      window.open('https://get.eucaps.com')
    }
  }
}
</script>
