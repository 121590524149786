<template>
  <div>
      <NotificationsContainer @toggle-notification-menu="$emit('menu-clicked')" />
    <TransparentBackgroundForMenus @click="$emit('menu-clicked', $event)" />
  </div>
</template>

<script>
import NotificationsContainer from '@/modules/notifications'

export default {
  name: 'NotificationsMenu',
  components: {
    NotificationsContainer
  },
  emits: ['menu-clicked']
}
</script>
