import { io } from "socket.io-client"
import { useUsersStore } from "../stores/users"
import { useChatsStore } from "../stores/chats"
import { useNotificationsStore } from "../stores/notifications"
import { useAchievementsStore } from "../stores/achievements"
import { useTrulioosStore } from "../stores/trulioo"

const URL = `${process.env.VUE_APP_CHAT_URL}`
// const socket = io(URL, { autoConnect: false, secure: true })
const socket = io(URL,
  { 
    autoConnect: false,
    secure: true,
    transports: ['websocket'],
    upgrade: false 
  })

socket.on("user-online", (data) => {
  useChatsStore().setActiveUsers(data)
})

socket.on("join-chat", (data) => {
  useChatsStore().joinChat(data)
})

socket.on("join-chats", () => {
  // console.log('JOIN CHATS')
  useUsersStore().setOnline()
  useChatsStore().joinChats()
})

socket.on("new-message", (payload) => {
  useChatsStore().receiveMessage(payload)
})

socket.on("update-bubbles", () => {
  useUsersStore().fetch()
})

socket.on("delete-message", (payload) => {
  useChatsStore().messageDeleted(payload)
})

socket.on("renew-message", (payload) => {
  useChatsStore().receiveMessage(payload, false)
})

socket.on("new-notification", (payload) => {
  useNotificationsStore().newNotification(payload)
})

socket.on("seen-by", (data) => {
  useChatsStore().setSeenBy(data)
})

socket.on("new-achievement", (data) => {
  useAchievementsStore().openAchievement(JSON.parse(data))
})

socket.on("trulioo-event", (data) => {
  console.log(JSON.parse(data));
  // TODO: check for the correct user to receive data and then redirect user according to the status
  useTrulioosStore().setVerificationStatus(JSON.parse(data))
})

export default socket
