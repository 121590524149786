<template>
  <span
    class="inline bg-custom-yellow-300 text-custom-purple-500 rounded-2xl text-xs py-2 px-4 font-medium text-xs"
  >
    <div><slot /></div>
    <div
      v-if="editMode && edit"
      class="flex cursor-pointer"
      @click="$emit('tag-remove')"
    >
      <span class="pl-2">|</span> <Icon
        name="x"
        class="pl-2"
      />
    </div></span>
</template>

<script>

export default {
  name: 'SocialBadge',
  props: {
    editMode: {
      type: Boolean,
      default: false
    },
    edit: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'tag-remove'
  ]
}
</script>
