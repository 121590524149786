<template>
  <button
    class="bg-white border px-2 border-black hover:underscore text-black rounded-md focus:outline-none"
    @click="$emit('click', $event)"
  >
    <slot />
  </button>
</template>

<script>

export default {
  name: 'SquareButton',
  props: {
    secondary: { type: Boolean, default: false }
  },
  emits: [
    'click'
  ],
  computed: {
    isSecondary () {
      return (this.secondary !== false)
    }
  }
}
</script>
