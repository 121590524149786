<template>
  <div class="flex flex-col md:w-96 w-80">
    <VerifiedUserCard
      v-if="user.profile.is_verified"
      :user="user"
      class="mb-8"
    />
    <CompanyApprovedCard
      v-for="company in verifiedCompanies"
      :key="'verified-' + company.id"
      small
      :company="company"
    />
    <TaggableCompanySearchContainer
      :pre-list="preList"
      with-details
      hide-options
      input-class=" w-full mb-2 mt-4 relative shadow-none sm:w-96 w-80 rounded-md sm:h-14 h-13 border-gray-300 placeholder-custom-purple-500 font-semibold "
      @selected-company-list="$emit('select-pre-list', $event)"
      @continue="$emit('continue', $event)"
    >
      <template #options>
        <DisabledButton
          v-if="preList && preList.length === 0"
          class="bg-custom-orange-400 sm:h-12 h-11 rounded-xl opacity-50  hover:bg-custom-orange-400 mt-2 sm:w-96 w-80"
        >
          <span class="text-white">
            Continue
          </span>
        </DisabledButton>
        <ActiveButton
          v-else
          class="bg-custom-orange-400 sm:h-12 h-11 rounded-xl disabled:opacity-50  hover:disabled:bg-custom-orange-400 mt-2 sm:w-96 w-80"
          @click="$emit('continue', preList)"
        >
          <span class="text-white">
            Continue
          </span>
        </ActiveButton>
      </template>
    </TaggableCompanySearchContainer>
  </div>
</template>

<script>
import TaggableCompanySearchContainer from '@/modules/taggableCompanySearch'
import CompanyApprovedCard from './CompanyApprovedCard.vue'

export default {
  name: 'CompanySelectForm',
  components: {
    TaggableCompanySearchContainer,
    CompanyApprovedCard
  },
  props: {
    preList: {
      type: Array,
      default: () => []
    },
    verifiedCompanies: {
      type: Array,
      default: () => []
    },
    user: {
      type: Object,
      default: () => {}
    }
  },
emits: [
'select-pre-list',
'continue'
]
}
</script>
