<template>
  <ModalContainer
    v-if="warnings.length > 0"
    :hide-close="true"
    full-size
  >
    <WarningModal
      :warnings="warnings"
      @close="warningSeen"
    />
  </ModalContainer>
</template>
<script>
import WarningModal from './_components/WarningModal.vue'
import ModalContainer from '@/modules/modal'
import { useUsersStore } from '../../stores/users'

export default {
  name: 'Warning',
  components: {
    WarningModal,
    ModalContainer
  },
  setup() {
    const usersStore = useUsersStore()

    return { usersStore }
  },
  computed: {
    userProfile() {
        return this.usersStore.profile 
    },
    warnings () {
      if (!this.userProfile || !this.userProfile.warnings) return []
      //   let warningList = []
      //   this.userProfile.warnings.filter(warning => {
      //     if (warningList.length > 0) {
      //       let data = warningList.findIndex(data => data.type === warning.type)
      //       console.log(data)
      //       if (data > -1) {
      //         warningList[data].count += 1
      //       } else {
      //         warningList.push({ type: warning.type, count: 1 })
      //       }
      //     } else {
      //       warningList.push({ type: warning.type, count: 1 })
      //     }
      //   })
      //   return warningList
      return this.userProfile.warnings
    }
  },
  methods: {
    warningSeen () {
      let list = []
      this.userProfile.warnings.filter(warning => {
        list.push(warning.id)
      })
      this.usersStore.warningSeen({ warning_list: list })
    }
  }
}
</script>
