<template>
  <div
    class="rounded-2xl text-xs py-1 px-3 text-center"
    :class="[
      orange ? 'bg-custom-orange-400 dark:bg-custom-orange-400 text-white' : '',
      gray ? 'bg-gray-300 dark:bg-custom-purple-500 dark:text-custom-purple-100 text-gray-800' : '',
    ]"
    @click="$emit('click', $event)"
  >
    <slot />
  </div>
</template>

<script>

export default {
  name: 'Badge',
  props: {
    orange: {
      type: Boolean,
      default: false
    },
    gray: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'click'
  ]
}
</script>
