<template>
  <div
    v-html="footerData"
  />
</template>
<script>

export default {
  name: 'FrontFooter',
  props: { 
    footerData: {
      type: String,
      default: ''
    },
  },
}
</script>
