<template>
  <div class="flex flex-col justify-center space-y-20">
    <CompanyApprovedCard
      :company="company"
    />

    <span class="font-medium text-lg">
      Congratulations! Your've successfully onboarded with us.
    </span>

    <ActiveButton
      class="bg-custom-orange-400 h-12 rounded-xl disabled:opacity-50 "
      @click="$emit('continue', $event)"
    >
      <span class="text-white">
        Finish 
      </span>
    </ActiveButton>
  </div>
</template>
  
  <script>
  import CompanyApprovedCard from './CompanyApprovedCard'
  
  export default {
    name: 'CompanyCongratsForm',
    components: {
      CompanyApprovedCard
    },
    props: {
      company: {
        type: Object,
        default: () => {}
      }
    },
  emits: [
  'continue'
  ]
  }
  </script>
  