<template>
  <div class="deprecated" />
</template>

<script>

export default {
  name: 'Deprecated'
}
</script>
