<template>
  <div>
    <ModalContainer
      v-if="showLocked"
      :hide-close="true"
      full-size
      @toggle="close"
    >
      <div class="py-4 lg:px-12 sm:px-5 max-w-lg mx-auto ">
        <div class="sm:grid-cols-1">
          <div class=" p-3 bg-white dark:bg-custom-purple-500 shadow rounded-lg px-5 py-6">
            <div class="text-md my-10 text-center text-gray-900 font-light">
              {{ $t('Account Blocked') }}
            </div>
            <div class="text-center mt-8">
              <FontAwesomeIcon
                icon="ban"
                class="mb-3 text-8xl text-custom-orange-400"
              />
            </div>
            <p class="mt-8 text-center text-sm">
              {{ $t('Your account has been locked for 24 hours. Please contact our support team for any inquiries.', {'Date': usersStore.user.profile.locked_to}) }}
            </p>
            <p class="text-center text-xs" />
            <div class="font-normal my-8 text-center">
              <NeutralButton
                class="z-100"
                @click="close"
              >
                {{ $t('Continue') }}
              </NeutralButton>
            </div>
          </div>
        </div>
      </div>
    </ModalContainer>
  </div>
</template>
<script>

import ModalContainer from '@/modules/modal'
import { useUsersStore } from '@/stores/users'

export default {
  name: 'UserBlockedModal',
  components: {
    ModalContainer
  },
  setup () {
    const usersStore = useUsersStore()

    return { usersStore }
  },
  data () {
    return {
      confirmedLockedTo: false
    }
  },
  
  computed: {
    showLocked () {
      if (!this.usersStore.user || !this.usersStore.user.profile) {
        return false
      }

      if (this.usersStore.user.profile.locked_to) {
        if (!this.confirmedLockedTo) {
          return true
        }

        if (this.confirmedLockedTo !== this.usersStore.user.profile.locked_to) {
          return true
        }
      }

      return false
    }
  },
  mounted () {
    this.confirmedLockedTo = localStorage.confirmedLockedTo
  },
  methods: {
    close () {
      localStorage.confirmedLockedTo = this.usersStore.user.profile.locked_to
      this.confirmedLockedTo = localStorage.confirmedLockedTo
    }
  }
}
</script>
