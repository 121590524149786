<template>
  <div
    class="mb-2"
    :class="wrapper"
  >
    <BaseCard
      :shadow="noShadow && 'shadow-none'"
      class="p-4 cursor-pointer"
    >
      <template #body>
        <h2
          class="flex text-primary text-sm items-center justify-between m-0"
          @click="open = !open"
        >
          <slot name="question" />
          <div class="flex px-4 dark:text-custom-purple-100">
            <FontAwesomeIcon
              v-if="marker && open"
              icon="caret-up"
            />
            <FontAwesomeIcon
              v-if="marker && !open"
              icon="caret-down"
            />
          </div>
        </h2>
        <div
          class="answer"
          :class="{'hidden': !open}"
        >
          <slot />
        </div>
      </template>
    </BaseCard>
  </div>
</template>

<script>
export default {
  name: 'Accordion',
  props: {
    wrapper: { type: String, default: 'accordion-wrapper' },
    container: { type: String, default: 'accordion-inner' },
    marker: { type: Boolean, default: true },
    opened: { type: Boolean, default: false },
	noShadow: { type: Boolean, default: false },
  },
  data () {
    return {
      open: this.opened
    }
  }
}
</script>
