<template>
  <div class="bg-gray-100 dark:bg-custom-purple-450 dark:text-custom-purple-100 flex w-full items-center justify-around rounded-t-md border-b dark:border-custom-purple-300 ">
    <div
      class="py-5 px-2 cursor-pointer flex items-center "
      :class="[parentRoute === 'explore' ? 'border-b-4 border-b-custom-purple-500 dark:border-custom-purple-300' : '', source === 'mobile' ? 'w-1/2 justify-center border-r ' : '']"
      @click="$emit('change', 'exploreResults')"
    >
      <Icon
        name="globe"
        class="text-3xl font-normal"
      />
      <p class="ml-3 text-lg">
        Explore
      </p>
    </div>
    <div
      class="py-5 px-2 cursor-pointer flex items-center"
      :class="[parentRoute === 'forumAndDiscussions' ? 'border-b-4 border-custom-purple-500 dark:border-custom-purple-300' : '', source === 'mobile' ? 'w-1/2 justify-center' : '']"
      @click="$emit('change', 'roomResults')"
    >
      <Icon
        name="chat"
        class="text-3xl font-normal"
      />
      <p class="ml-3 text-lg">
        {{ source === 'mobile' ? 'Forum' : 'Forum & discussions' }}
      </p>
    </div>
    <div
      v-if="source !== 'mobile'"
      class="py-5 px-2 cursor-pointer flex items-center"
      :class="parentRoute === 'history' ? 'border-b-4 border-custom-purple-500 dark:border-custom-purple-300' : ''"
      @click="$emit('change', 'history')"
    >
      <Icon
        name="history"
        class="text-3xl font-normal"
      />
      <p class="ml-3 text-lg">
        History
      </p>
    </div>
  </div>
</template>
<script>

export default {
  name: 'TabButton',
  props: {
    selectedTab: { type: String, default: 'explore' },
    parentRoute: { type: String, default: null },
    source: { type: String, default: '' }
  },
  emits: ['change']
}
</script>