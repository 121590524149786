<template>
  <div v-if="notification.notifiable">
    <div>
      <Notification
        :pivot="notification.pivot"
        @remove="$emit('remove', { id: notification.id })"
      >
        <div @click="$parent.redirectProfile({ notificationId: notification.id, query: { navigate: 'tradingRef' } })">
          <div>
            <div v-if="notification.notifiable.status === 'Rejected'">
              {{ $t('Need to Resubmit Document') }}
            </div>
            <div v-else>
              <div>
                {{ $t('Your document is been ') }} {{ notification.notifiable.status }}
              </div>
            </div>
          </div>

          <div class="text-xs mr-6">
            <span class="font-bold">

              <div>{{ time }}</div>
            </span>
          </div>
        </div>
      </Notification>
    </div>
  </div>
</template>

<script>

import Notification from './Notification'

export default {
  name: 'NotificationUploadDocument',
  components: {
    Notification
  },
  props: {
    notification: { type: Object, default: () => { } },
    time: { type: String, default: '' }
  },
  emits: [
    'remove'
  ],
}
</script>
