<template>
  <div class="pb-10">
    <div
      v-if="step === 2"
      class="lg:space-y-10 hidden lg:block"
    >
      <div class=" font-medium sm:text-2xl text-xl ">
        Congrats - you're in!
      </div>
      <div class="xl:text-6xl xl:leading-tight sm:leading-tight leading-tight sm:text-5xl text-3xl font-extrabold pr-10">
        A few more steps to get <span class="text-custom-orange-400">full access</span>
      </div>
    </div>
    <!-- <div
      v-if="(step === 3)"
      class="lg:space-y-10 space-y-5 mt-4"
    >
      <div class="xl:text-6xl xl:leading-tight sm:leading-tight leading-tight sm:text-5xl text-4xl font-extrabold pr-10">
        Let's get to <span class="text-custom-orange-400">know</span> you.
      </div>
    </div> -->
    <div
      v-if="(step === 3 || step === 4)"
      class="lg:space-y-10 space-y-5"
    >
      <div class=" font-medium sm:text-2xl text-xl ">
        Tell us about yourself!
      </div>
      <div class="xl:text-6xl xl:leading-tight sm:leading-tight leading-tight sm:text-5xl text-4xl font-extrabold pr-10">
        Let's set up your <span class="text-custom-orange-400">trading</span> account.
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'UserText',
  props: {
    step: {
      type: Number,
      default: 1
    }
  }
}
</script>
