<template>
  <div
    class="p-4 text-center"
  >
    <div v-if="joinRequested">
      <div class="my-8">
        We have now sent a request to the company users.
      </div>
      <SquareButton
        class=" bg-gray-400 w-full rounded-xl text-base text-custom-purple-500 font-bold border-none px-6 py-2 "
        @click="$emit('go-to-home', $event)"
      >
        <span class="text-white">
          Meanwhile, continue browse Eucaps
        </span>
      </SquareButton>
    </div>
    <div
      v-else-if="loadingRequested"
      class="p-4 flex justify-center"
    >
      <CircleLoader />
    </div>
    <template v-else>
      <p class="py-4 font-semibold font-lg">
        {{ company.name }} already has a subscription.
      </p>
      <ActiveButton
        class="bg-custom-orange-400 h-12 self-center rounded-xl w-full"
        @click="$emit('request-join', $event)"
      >
        <span class="text-white">
          Request to join the company
        </span>
      </ActiveButton>
      <div class="p-4">
        or
      </div>
      <SquareButton
        class=" bg-gray-400 w-full rounded-xl text-base text-custom-purple-500 font-bold border-none px-6 py-2 "
        @click="$emit('contact-sales', $event)"
      >
        <span class="text-white">
          Contact sales
        </span>
      </SquareButton>
    </template>
  </div>
</template>

<script>

export default {
  name: 'CompanyHasSubscription',
  props: {
    company: {
      type: Object,
      default: () => {}
    },
    contactSales: {
      type: Boolean,
      default: false
    },
    joinRequested: {
      type: Boolean,
      default: false
    },
    loadingRequested: {
      type: Boolean,
      default: false
    }
  },
emits: [
'go-to-home',
'request-join',
'contact-sales'
]
}
</script>
