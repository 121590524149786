<template>
  <div
    class="flex flex-shrink-0 justify-center items-center mr-2 relative"
    :class="defaultClass"
  >
    <CompanyLogoContainer
      v-if="(company && company.logo) || imageUrl"
      :size="size"
      :header="header"
      :company="company"
      :image-url="imageUrl"
      :minified="minified"
      :list="list? true : false"
      :premium-card="premiumCard"
      :rounded="list? 'rounded-md' : header? 'rounded-xl' : 'rounded-3xl'"
    />
    <AvatarInitials
      v-else
      class="  backdrop-blur-lg  ring-inset "
      :class="[defaultClass, header? 'ring-1 ring-offset-1 ring-offset-white' : list? 'ring-0 ring-offset-0' : ticker? 'ring-offset-0': ' ring-4 ring-offset-2 ring-offset-white', list? '' : ticker? 'ring-offset-0' :'ring-white shadow-md dark:shadow-md-dark' ]"
      :name="company?.name"
      :size="size"
      :rounded="list? 'rounded-lg' : header? 'rounded-xl' : 'rounded-3xl'"
    />
  </div>
</template>

<script>

import CompanyLogoContainer from '@/modules/companyLogo'
export default {
  name: 'CompanyAvatar',
  components: {
    CompanyLogoContainer
  },
  props: {
    company: {
      type: Object,
      default: () => {}
    },
    defaultClass: {
      type: String,
      default: 'w-10 h-10 '
    },
    size: {
      type: Number,
      default: 40
    },
    minified: {
      type: Boolean,
      default: false
    },
    header: {
      type: Boolean,
      default: false
    },
    list: {
      type: Boolean,
      default: false
    },
    ticker: {
      type: Boolean,
      default: false
    },
    imageUrl: {
      type: String,
      default: ''
    },
    premiumCard: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
  .star-margin {
    padding-top: 2px;
  }
</style>
