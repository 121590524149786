import { get } from '../helpers/axiosHelper'

const baseUrl = `${process.env.VUE_APP_API_URL}`
const url = `${process.env.VUE_APP_API_URL}/landing-page`

const listCategories = async () => get(`${url}/list/categories`)
const winnersCorp = async (data) => get(`${url}/list/winners-corp`,data)
const losersCorp = async (data) => get(`${url}/list/losers-corp`,data)
const randomPremiumCorp = async (params) => get(`${url}/list/random-prem-corp`, params)
const mostVisitedCorp = async (data) => get(`${url}/list/most-visited-corp`,data)
const inderesFeed = async () =>
  get(`${baseUrl}/inderes/feeds/filter`, {
    types: ['video', 'webcast'],
    isLive: true
  })
const pressRelease = async (payload) => get(`${baseUrl}/press-releases`, payload)
const trendingCorp = async (params) => get(`${url}/list/trending-corp`, params)
const premiumVideos = async (payload) => get(`${url}/list/premium-companies-videos?limit=${payload}`)
const premiumElevatorPitches = async (payload) => get(`${url}/list/elevator-pitch-videos?limit=${payload}`)
const latestPremiumVideos = async (payload) => get(`${url}/list/latest-premium-companies-videos?limit=${payload}`)
const relatedPremiumCompanies = async (limit) => get(`${url}/list/related-premium-companies?limit=${limit}`)
const scroll = async (link, limit) => get(`${link}&limit=${limit}`)


export default {
  listCategories,
  winnersCorp,
  losersCorp,
  randomPremiumCorp,
  mostVisitedCorp,
  inderesFeed,
  pressRelease,
  trendingCorp,
  premiumVideos,
  latestPremiumVideos,
  premiumElevatorPitches,
  relatedPremiumCompanies,
  scroll
}
