<template>
  <div class="  flex items-center">
    <CompanyAvatar
      :company="company"
      :size="48"
      :minified="true"
      header
      default-class="w-14 h-14"
      @click="$emit('click', $event)"
    />
    <div class="flex items-center space-x-2">
      <span class=" leading-5 lg:text-2xl md:text-xl sm:text-lg text-sm font-semibold flex dark:text-custom-purple-100">
        {{ isEpp ? company.market_name : company.name }}
        <!-- <Icon
          name="check-circle"
          solid
          class="text-custom-blue-700 text-xl pl-1 "
        /> -->
      </span>
    </div>
  </div>
</template>
  
<script>

export default {
  name: 'CompanyNameHeader',
  components: { 
  },
  props: {
    company: {
      type: Object,
      default: () => { }
    },
    companyDetails: {
      type: Object,
      default: () => { }
    },
	isEpp: {
      type: Boolean,
      default: false
	}
  },
};
</script>
  
