<template>
  <div class="flex flex-col space-y-15 items-center mt-72 lg:mt-88">
    <div
      v-if="!selectedCompanies && !selectedCompanies[selectedCompanyIndexForAgreement]"
      class="flex items-center justify-center w-full p-4"
    >
      <CircleLoader />
    </div>
    <div v-if="!otpVerified">
      <VerifiedUserCard
        v-if="user.profile.is_verified"
        :user="user"
        class="mb-8"
      />
      <div
        class="border rounded-md py-2 px-4 flex justify-center relative cursor-pointer hover:bg-gray-100"
        @click="$emit('toggle-selection')"
      >
        <div class="flex items-center justify-between">
          <div class="flex items-center">
            <Icon
              name="check-shield"
              class="text-2xl mr-2"
            />
            <div>Agreement for {{ selectedCompanies[selectedCompanyIndexForAgreement].name }}</div>
          </div>
          <Icon
            v-if="selectedCompanies.length > 1"
            :name="selectCompanyOpen ? 'chevron-up' : 'chevron-down'"
            class="text-2xl ml-8"
          />
        </div>
        <div
          v-if="selectedCompanies.length > 1 && selectCompanyOpen"
          class="absolute w-full top-12 left-0 z-30"
        >
          <div
            v-for="(company, index) in selectedCompanies"
            :key="'company-agreement-list-' +company.id"
          >
            <div
              class="border bg-white cursor-pointer hover:bg-gray-100 dark:bg-custom-purple-400 dark:border-custom-purple-500 dark:hover:border-custom-purple-400 dark:text-white rounded-md p-2 flex items-center"
              @click="$emit('select-company-index', index)"
            >
              <Icon
                :name="selectedCompanyIndexForAgreement === index ? 'check-square' : 'square'"
                class="text-2xl mr-2"
                :class="selectedCompanyIndexForAgreement === index ? '' : 'text-gray-500'"
                :solid="selectedCompanyIndexForAgreement === index"
              /> <div>{{ company.name }}</div>
            </div>
          </div>
        </div>
      </div>
      <CompanyHasSubscription
        v-if="hasCompanyUsers"
        :company="selectedCompanies[selectedCompanyIndexForAgreement]"
        class="p-4 text-center"
        :contact-sales="contactSales"
        :loading-requested="loadingRequested"
        :join-requested="joinRequested"
        @contact-sales="$emit('contact-sales', $event)"
        @request-join="$emit('request-join', $event)"
        @go-to-home="$emit('go-to-home', $event)"
      />
      <EmailMismatch
        v-else-if="!emailMatch"
        :company="selectedCompanies[selectedCompanyIndexForAgreement]"
        :website="selectedCompanies[selectedCompanyIndexForAgreement].company_detail.website"
        :email="user.email"
        :contact-sales="contactSales"
        :secondary-email-sent="secondaryEmailSent"
        :add-secondary-email="addSecondaryEmail"
        :secondary-email="secondaryEmail"
        :valid-secondary-email="validSecondaryEmail"
        class="p-4 text-center"
        @contact-sales="$emit('contact-sales', $event)"
        @add-secondary-email="$emit('add-secondary-email', $event)"
        @secondary-email="$emit('secondary-email', $event)"
        @send-secondary-email="$emit('send-secondary-email', $event)"
      />
      <div
        v-else-if="!selectedCompanies[selectedCompanyIndexForAgreement].agreement_required"
        class="mt-12"
      >
        <ActiveButton
          class="bg-custom-orange-400 h-12 self-center flex justify-center items-center rounded-xl w-full disabled:opacity-50"
          @click="$emit('assign-user-role', $event)"
        >
          <CircleLoader
            v-if="loadingNew"
            small
          />
          <span
            v-else
            class="text-white"
          >
            Aggrement signed manually, goto next step.
          </span>
        </ActiveButton>
      </div>
      <template v-else>
        <div
          v-if="!agreementSent"
          class="border rounded-md py-2 px-4 flex flex-col relative mt-4"
        >
          <Icon
            name="pencil"
            solid
            class="text-2xl absolute top-0 right-0 p-2 cursor-pointer z-20"
            @click="$emit('edit-address')"
          />
          <div class="font-bold z-10 relative">
            {{ selectedCompanies[selectedCompanyIndexForAgreement].name }}
          </div>
          <CompanyAddress
            :address="
              customAddress[selectedCompanyIndexForAgreement] ? customAddress[selectedCompanyIndexForAgreement]
              :
              selectedCompanies[selectedCompanyIndexForAgreement].company_detail &&
              selectedCompanies[selectedCompanyIndexForAgreement].company_detail.full_address
                ? selectedCompanies[selectedCompanyIndexForAgreement].company_detail.full_address.split(',')
                : []
            "
            :edit="editAddress"
            @update="$emit('custom-address', $event)"
          />
        </div>
      </template>
    </div>
    <template v-if="!hasCompanyUsers && selectedCompanies[selectedCompanyIndexForAgreement].agreement_required && emailMatch">
      <div v-if="!otpVerified">
        <div
          v-if="!agreementSent"
          class="flex flex-col items-center"
        >
          <div class="text-custom-purple-500 font-semibold w-9/12 text-center">
            Written agreements are important in detailing a specific transaction
            made between two or more parties. Despite not always being legally
            enforceable in a court of law, they can often prevent disputes. From
            partnership agreements to separation agreements, Jotform's selection
            of Agreement PDF Templates will guide you in creating a paper trail for
            any type of business agreement. Your formal agreements will
            automatically be saved as secure PDFs that can easily be downloaded,
            shared with all involved parties, or printed for future reference.
            These ready-made templates are formatted to provide

            <div class="font-bold pt-10">
              Upon pressing send, a pdf containing terms and condition along with a 3
              digit code will be sent to your email address. You need to download it and manually
              sign and reupload it to My Eucaps section within 14 days.
            </div>
            <div class="space-x-3 pt-5">
              <input
                id="agreement"
                class="rounded-sm checked:bg-green-600 checked:border-transparent "
                type="checkbox"
                :checked="agreement"
                @input="$emit('agreement', $event) "
              >

              <span class="text-custom-purple-500 font-medium text-sm">I agree to the privacy policy and the terms and conditions</span>
            </div>
          </div>
        </div>
        <div v-else>
          <EmailVerificationContainer
            :hide-back="true"
            :loading="loading"
            process="onboarding"
            @email-verified="$emit('email-verified')"
            @resend-otp="$emit('resend-otp', $event)"
          />
        </div>
      </div>
      <div
        v-else
        class="text-custom-purple-500 font-semibold w-9/12 text-center"
      >
        <CompanyApprovedCard
          :company="selectedCompanies[selectedCompanyIndexForAgreement]"
        />
        <p>
          You've confirmed receiving the pdf. You need to download, print and
          sign the pdf, then reupload it to My Eucaps section within 14 days.
          Click continue to proceed to the next stage.
        </p>
      </div>
      <div class="pb-32">
        <ActiveButton
          v-if="!agreementSent || otpVerified"
          class="bg-custom-orange-400 h-12 self-center rounded-xl disabled:opacity-50 md:w-96 w-80"
          :disabled="!agreement && !otpVerified"
          @click="$emit( otpVerified ? 'continue' : 'send-pdf', $event)"
        >
          <div class="text-white flex justify-center items-center">
            <span v-if="loading">Please wait...</span>
            <div v-else>
              <span v-if="!agreementSent && !otpVerified">
                Send
              </span>
              <Span v-else-if="otpVerified">
                Continue
              </Span>
            </div>
          </div>
        </ActiveButton>
      </div>
    </template>
  </div>
</template>

<script>
import EmailVerificationContainer from '@/modules/emailVerification'
import CompanyAddress from './CompanyAddress'
import CompanyApprovedCard from './CompanyApprovedCard'
import CompanyHasSubscription from './CompanyHasSubscription'
import EmailMismatch from './EmailMismatch'

export default {
  name: 'CompanyAgreementForm',
  components: {
    EmailVerificationContainer,
    CompanyAddress,
    EmailMismatch,
    CompanyHasSubscription,
    CompanyApprovedCard
  },
  props: {
    loadingNew: {
      type: Boolean,
      default: false
    },
    agreement: {
      type: Boolean,
      default: false
    },
    addSecondaryEmail: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    editAddress: {
      type: Boolean,
      default: false
    },
    agreementSent: {
      type: Boolean,
      default: false
    },
    selectCompanyOpen: {
      type: Boolean,
      default: false
    },
    emailMatch: {
      type: Boolean,
      default: false
    },
    otpVerified: {
      type: Boolean,
      default: false
    },
    secondaryEmail: {
      type: String,
      default: ''
    },
    secondaryEmailSent: {
      type: Boolean,
      default: false
    },
    validSecondaryEmail: {
      type: Boolean,
      default: false
    },
    hasCompanyUsers: {
      type: Boolean,
      default: false
    },
    contactSales: {
      type: Boolean,
      default: false
    },
    joinRequested: {
      type: Boolean,
      default: false
    },
    loadingRequested: {
      type: Boolean,
      default: false
    },
    selectedCompanyIndexForAgreement: {
      type: Number,
      default: 0
    },
    customAddress: {
      type: Object,
      default: () => {}
    },
    user: {
      type: Object,
      default: () => {}
    },
    selectedCompanies: {
      type: Array,
      default: () => []
    }
  },
emits: [
'email-verified',
'toggle-selection',
'select-company-index',
'contact-sales',
'request-join',
'go-to-home',
'add-secondary-email',
'secondary-email',
'send-secondary-email',
'assign-user-role',
'edit-address',
'custom-address',
'agreement',
'resend-otp',
'otpVerified'
]
}
</script>
