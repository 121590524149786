import axios from 'axios'
import { useUsersStore } from '../../stores/users'
import { useAppsStore } from '../../stores/app'

export default {
  token: null,
  dsecret: null,
  storeDSecret(dsecret) {
    if (dsecret) {
      localStorage.setItem('dsecretV2', dsecret)
      this.setDSecretHeader(dsecret)
      return true
    }
    return false
  },
  storeToken(token) {
    if (token !== undefined) {
      localStorage.setItem('accessToken', token)
      this.setAuthorizationHeader(token)
      return true
    }
    return false
  },
  storeTemporaryToken(token) {
    if (token !== undefined) {
      this.token = token
      this.setAuthorizationHeader(token)
      return true
    }
    return false
  },
  isAuthenticated() {
    const token = this.getAccessToken()
    return token != null && token !== undefined && token !== 'undefined'
  },
  hasDSecret() {
    return this.getDSecret() !== null
  },
  getAccessToken() {
    if (
      localStorage.getItem('accessToken') !== '' &&
      localStorage.getItem('accessToken') !== null &&
      localStorage.getItem('accessToken') !== undefined
    ) {
      return localStorage.getItem('accessToken')
    } else {
      if (!sessionStorage.getItem('accessToken')) {
        return this.token
      } else {
        return sessionStorage.getItem('accessToken')
      }
    }
  },
  getDSecret() {
    const dsecret =
      localStorage.getItem('dsecretV2') !== '' &&
      localStorage.getItem('dsecretV2') !== null &&
      localStorage.getItem('dsecretV2') !== undefined
        ? localStorage.getItem('dsecretV2')
        : null

    return dsecret
  },
  setAuthorizationHeader(token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
  },
  clearAuthorizationHeader() {
    axios.defaults.headers.common['Authorization'] = ``
  },
  setDSecretHeader(dsecret) {
    useUsersStore().setDSecret(dsecret)

    axios.defaults.headers.common['DSecret'] = `${dsecret}`
    axios.defaults.headers.common['PlatformKey'] = useAppsStore().platformKey
  },
  clearToken() {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('user')
    sessionStorage.removeItem('onboarding')
    localStorage.removeItem('kycPayload')

    this.clearAuthorizationHeader()
  },
  refreshHeader() {
    this.setDSecretHeader(this.getDSecret())
    if (this.isAuthenticated()) {
      this.setAuthorizationHeader(this.getAccessToken())
    }
  }
}
