import { RepositoryFactory } from '@/api/repositoryFactory'
const repository = RepositoryFactory.get('financials')
import { defineStore } from 'pinia'
import { useSnackbarStore } from './snackbar'

export const useFinancialsStore = defineStore('financials', {
  state: () => ({
    originalSelectedMnemonics: {
      1: ['IQ_TOTAL_REV', 'IQ_NI'],
      3: [
        'IQ_TOTAL_RECEIV',
        'IQ_TOTAL_ASSETS',
        'IQ_TOTAL_CL',
        'IQ_TOTAL_LIAB',
        'IQ_TOTAL_DEBT',
        'IQ_TOTAL_EQUITY',
        'IQ_TOTAL_LIAB_EQUITY'
      ],
      5: ['IQ_CASH_OPER', 'IQ_CASH_INVEST', 'IQ_CASH_FINAN', 'IQ_NET_CHANGE']
    },
    loading: false,
    filterList: [],
    filterListLoading: false,
    selectedGroupsKey: 1,
    selectedPeriodCode: 'annual',
    financialRanges:[],
    groupList: {
      1: [1, 2],
      3: [3, 4],
      5: [5]
    },
    periods: [
      {
        code: 'annual'
      },
      {
        code: 'quarterly'
      }
    ]
  }),
  getters: {
    selectedMnemonics(state) {
      let originalSelectedMnemonics = [...state.originalSelectedMnemonics[state.selectedGroupsKey]]

      return originalSelectedMnemonics
    },
    selectedGroups(state) {
      let groupList = [...state.groupList[state.selectedGroupsKey]]

      return groupList
    },
  
  },
  actions: {
    selectGroup(groupId) {
      localStorage.selectedGroupsKey = groupId
      this.selectedGroupsKey = groupId
      localStorage.selectedMnemonics = JSON.stringify(this.originalSelectedMnemonics[this.selectedGroupsKey])
    },
    selectPeriod(periodCode) {
      localStorage.selectedPeriodCode = periodCode
      this.selectedPeriodCode = periodCode
    },
    setData(selectedMnemonics) {
      this.originalSelectedMnemonics[this.selectedGroupsKey] = [...selectedMnemonics]
      localStorage.selectedMnemonics = JSON.stringify(selectedMnemonics)
      this.originalSelectedMnemonics = { ...this.originalSelectedMnemonics }
    },
    setFilterList(payload) {
      this.filterList = payload
    },
    async setMyFilters(payload) {
      const selectedGroups = JSON.parse(payload.financial_groups)
      const selectedLabels = JSON.parse(payload.financial_labels)

      this.selectGroup(selectedGroups[0])
      this.setData(selectedLabels)
    },
    async fetchCompanyFilters() {
      try {
        const response = await repository.fetchFilters()
        if (response.status === 200 && response.data) {
          this.setFilterList(response.data)
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async saveFilters(payload) {
      try {
        const response = await repository.saveFilters(payload)
        if (response.status === 200 && response.data) {
          this.setFilterList(response.data)
          useSnackbarStore().snack({
            type: 'success',
            text: 'filters saved successfully',
            show: true,
            autoclose: true
          })
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async deleteFilter(filterId) {
      try {
        this.filterListLoading = true
        const response = await repository.deleteFilter(filterId)
        if (response.status === 200 && response.data) {
          this.setFilterList(response.data)
        }
        this.filterListLoading = false
      } catch (e) {
        this.filterListLoading = false

        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async updateFilters(payload) {
      try {
        const response = await repository.updateFilters(payload.filterId, payload)
        if (response.status === 200 && response.data) {
          this.setFilterList(response.data)
          useSnackbarStore().snack({
            type: 'success',
            text: 'filters saved successfully',
            show: true,
            autoclose: true
          })
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async setSelectedMnemonics(data) {
      this.setData(data)
    },
    async toggleSelectedMnemonics(payload) {
      let data = this.originalSelectedMnemonics[this.selectedGroupsKey]
      const index = data.findIndex((data) => data === payload)
      if (index > -1) {
        data.splice(index, 1)
      } else {
        data.push(payload)
      }
      this.setData(data)
    },
    async downloadFile(payload) {
      try {
        const fileExtension = payload.fileName.slice(payload.fileName.lastIndexOf('.') + 1)
        const response = await repository.downloadDocument(payload.fileName)
        if (response.data && fileExtension === 'pdf') {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `${payload.fileName}`
          link.click()
          link.remove()
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async fetchFinancialRanges() {
      try {
        this.loading = true
        const response = await repository.fetchRanges()
        this.loading = false
        if (response.status === 200 && response.data) {
        
          this.financialRanges = response.data
          
        }
      } catch (e) {
        this.loading = false
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
  }
})
