<template>
  <SquareImage
    src="/img/audio.png"
    :big="big"
    @click="$emit('click', $event)"
  />
</template>

<script>
export default {
  name: 'ThumbnailAudio',
  props: {
    big: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'click'
  ]
}
</script>
