import { RepositoryFactory } from "@/api/repositoryFactory"
const repository = RepositoryFactory.get("comparables")
import { defineStore } from "pinia"
import { useSnackbarStore } from "./snackbar"
import { useScreenersStore } from "./screeners"
import { useFinancialsStore } from "./financials"
import { useJobsStore } from "./jobs"

export const useComparablesStore = defineStore("comparables", {
  state: () => ({
    showCompare: false,
    loading: false,
    comparables: [],
    compareList: {}
  }),
  actions: {
    async setShowCompare(payload) {
      this.showCompare = payload
    },
    toggleCompare(companyId) {
      const comparables = [...this.comparables]
      const exist = comparables.findIndex(
        (compare) => Number(compare) === Number(companyId)
      )

      if (exist > -1) {
        comparables.splice(exist, 1)
      } else {
        comparables.push(companyId)
      }

      this.comparables = [...comparables]
    },
    async compare(companyIds) {
      console.log("COMPARE", companyIds)
      try {

        if (!this.loading) {
          const currency = useScreenersStore().selectedCurrency
          const periodCode = useFinancialsStore().selectedPeriodCode
          
          useJobsStore().runEndpoint('compare' + JSON.stringify(companyIds) + periodCode + currency, async () => {
          
            this.loading = true
            const response = await repository.compare({
              comparables: companyIds,
              currency,
              period: periodCode,
            })
            // console.log('test comparable response', response)
            if (response.status === 200 && response.data) {
              
              this.compareList = { ...response.data }
            }
            this.loading = false
          }, 1000 * 60 * 5)
        }
      } catch (e) {
        this.loading = false
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
  },
})
