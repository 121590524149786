<template>
  <div
    class="slider"
  >
    <div class="absolute text-xs font-medium right-0 -top-2 flex items-center justify-between w-full">
      <span><span class="prefix">{{ prefix }}</span>{{ internalMinValue }}<span class="ml-1 suffix">{{ suffix }}</span></span>
      <span><span class="prefix">{{ prefix }}</span>{{ internalMaxValue }}<span class="ml-1 suffix">{{ suffix }}</span></span>
    </div>
    <div class="absolute inset-0 flex items-center">
      <div class="slide slider-touch-left">
        <span />
      </div>
      <div class="slide slider-touch-right">
        <span />
      </div>
      <div class="slider-line bg-gray-300">
        <span />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'RangeSlider',
  props: {
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 0
    },
    steps: {
      type: Number,
      default: 1
    },
    startAt: {
      type: Number,
      default: 0
    },
    endAt: {
      type: Number,
      default: 0
    },
    prefix: {
      type: String,
      default: ''
    },
    suffix: {
      type: String,
      default: ''
    },
    activeClass: {
      type: String,
      default: 'bg-custom-orange-400 dark:bg-custom-orange-400'
    }
  },
  emits: [
    'on-changed',
    'on-change'
  ],
  data () {
    return {
      startX: 0,
      x: 0,
      step: 0.0,
      minValue: 0,
      maxValue: 0,
      normalizeFact: 26,
      initialValue: 0,
      slider: null,
      touchLeft: null,
      touchRight: null,
      lineSpan: null,
      maxX: null,
      selectedTouch: null,
      default: false,
      resizeListener: null,
      resizeTimer: null
    }
  },
  computed: {
    internalMinValue () {
      if (this.default) {
        if (this.startAt !== 0) {
          return this.startAt
        }
      }

      return this.minValue
    },
    internalMaxValue () {
      if (this.default) {
        if (this.endAt !== 0) {
          return this.endAt
        }
      }

      return this.maxValue
    }
  },
  watch: {
    endAt (newValue) {
      this.setMaxValue(newValue)
    },
    startAt (newValue) {
      this.setMinValue(newValue)
    }
  },
  mounted () {
    this.resize()
    // link events
    this.touchLeft.addEventListener('mousedown', this.onStart)
    this.touchRight.addEventListener('mousedown', this.onStart)
    this.touchLeft.addEventListener('touchstart', this.onStart)
    this.touchRight.addEventListener('touchstart', this.onStart)
    this.resizeListener = window.addEventListener('resize', this.resize)
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.resizeListener)
  },
  methods: {
    resize () {
      this.startX = 0
      this.x = 0
      // retrieve touch button
      this.slider = this.$el
      this.touchLeft = this.slider.querySelector('.slider-touch-left')
      this.touchRight = this.slider.querySelector('.slider-touch-right')
      this.lineSpan = this.slider.querySelector('.slider-line span')

      // get some properties
      let defaultMinValue = this.min
      if (this.startAt) {
        defaultMinValue = parseFloat(this.startAt)
      }
      let defaultMaxValue = this.max

      if (this.endAt) {
        defaultMaxValue = parseFloat(this.endAt)
      }

      // check values are correct
      if (defaultMinValue < this.min) {
        defaultMinValue = this.min
      }

      if (defaultMaxValue > this.max) {
        defaultMaxValue = this.max
      }

      if (defaultMinValue > defaultMaxValue) {
        defaultMinValue = defaultMaxValue
      }

      if (this.steps) {
        this.step = Math.abs(this.steps)
      }

      if (this.step > defaultMaxValue) {
        defaultMaxValue = this.step
      }

      // initial reset
      this.reset()

      // usefull values, min, max, normalize fact is the width of both touch buttons
      this.maxX = this.slider.offsetWidth - this.touchRight.offsetWidth
      this.selectedTouch = null
      this.initialValue = (this.lineSpan.offsetWidth - this.normalizeFact)

      // set defualt values
      this.setMinValue(defaultMinValue)
      this.setMaxValue(defaultMaxValue)

      this.default = true
    },
    reset () {
      this.touchLeft.style.left = '0px'
      this.touchRight.style.left = (this.slider.offsetWidth - this.touchLeft.offsetWidth) + 'px'
      this.lineSpan.style.marginLeft = '0px'
      this.lineSpan.style.width = (this.slider.offsetWidth - this.touchLeft.offsetWidth) + 'px'
      this.startX = 0
      this.x = 0
    },
    setMaxValue (maxValue) {
      this.default = false
      const ratio = ((maxValue - this.min) / (this.max - this.min))
      this.touchRight.style.left = Math.ceil(ratio * (this.slider.offsetWidth - (this.touchLeft.offsetWidth + this.normalizeFact)) + this.normalizeFact) + 'px'
      this.lineSpan.style.marginLeft = this.touchLeft.offsetLeft + 'px'
      this.lineSpan.style.width = (this.touchRight.offsetLeft - this.touchLeft.offsetLeft) + 'px'
      this.maxValue = maxValue
    },
    setMinValue (minValue) {
      this.default = false
      const ratio = ((minValue - this.min) / (this.max - this.min))
      this.touchLeft.style.left = Math.ceil(ratio * (this.slider.offsetWidth - (this.touchLeft.offsetWidth + this.normalizeFact))) + 'px'
      this.lineSpan.style.marginLeft = this.touchLeft.offsetLeft + 'px'
      this.lineSpan.style.width = (this.touchRight.offsetLeft - this.touchLeft.offsetLeft) + 'px'
      this.minValue = minValue
    },
    onStart (event) {
      // Prevent default dragging of selected content
      // console.log('onStart', event)
      event.preventDefault()
      let eventTouch = event

      if (event.touches) {
        eventTouch = event.touches[0]
      }
      this.selectedTouch = event.target.closest('.slide')

      if (this.selectedTouch === this.touchLeft) {
        this.x = this.touchLeft.offsetLeft
      } else {
        this.x = this.touchRight.offsetLeft
      }

      this.startX = eventTouch.pageX - this.x

      document.addEventListener('mousemove', this.onMove)
      document.addEventListener('mouseup', this.onStop)
      document.addEventListener('touchmove', this.onMove)
      document.addEventListener('touchend', this.onStop)
    },
    onStop () {
      document.removeEventListener('mousemove', this.onMove)
      document.removeEventListener('mouseup', this.onStop)
      document.removeEventListener('touchmove', this.onMove)
      document.removeEventListener('touchend', this.onStop)

      this.selectedTouch = null

      // write new value
      this.calculateValue()

      // call did changed
      this.$emit('on-changed', [this.minValue, this.maxValue])
    },
    onMove (event) {
      let eventTouch = event

      if (event.touches) {
        eventTouch = event.touches[0]
      }

      this.x = eventTouch.pageX - this.startX

      if (this.selectedTouch === this.touchLeft) {
        if (this.x > (this.touchRight.offsetLeft - this.selectedTouch.offsetWidth + 10)) {
          this.x = (this.touchRight.offsetLeft - this.selectedTouch.offsetWidth + 10)
        } else if (this.x < 0) {
          this.x = 0
        }

        this.selectedTouch.style.left = this.x + 'px'
      } else if (this.selectedTouch === this.touchRight) {
        if (this.x < (this.touchLeft.offsetLeft + this.touchLeft.offsetWidth - 10)) {
          this.x = (this.touchLeft.offsetLeft + this.touchLeft.offsetWidth - 10)
        } else if (this.x > this.maxX) {
          this.x = this.maxX
        }
        this.selectedTouch.style.left = this.x + 'px'
      }
      // update line span
      this.lineSpan.style.marginLeft = this.touchLeft.offsetLeft + 'px'
      this.lineSpan.style.width = (this.touchRight.offsetLeft - this.touchLeft.offsetLeft) + 'px'

      // write new value
      this.calculateValue()

      this.$emit('on-change', [this.minValue, this.maxValue])
    },
    calculateValue () {
      const newValue = (this.lineSpan.offsetWidth - this.normalizeFact) / this.initialValue
      let minValue = this.lineSpan.offsetLeft / this.initialValue
      let maxValue = minValue + newValue

      minValue = minValue * (this.max - this.min) + this.min
      maxValue = maxValue * (this.max - this.min) + this.min

      if (this.step !== 0.0) {
        let multi = Math.floor((minValue / this.step))
        minValue = this.step * multi

        multi = Math.floor((maxValue / this.step))
        maxValue = this.step * multi
      }

      // Ensure the values stay within the specified range
      minValue = Math.max(this.min, Math.min(this.max, minValue))
      maxValue = Math.max(this.min, Math.min(this.max, maxValue))

      this.minValue = (minValue <= maxValue) ? minValue : maxValue
      this.maxValue = (maxValue >= minValue) ? maxValue : minValue
    },
  }
}
</script>

<style lang="scss">

.content {
  width: 320px;
}

.slider {
  display: block;
  position: relative;
  height: 36px;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select:none;
  -o-user-select:none;
  user-select:none;

  .slider-touch-left, .slider-touch-right {
    @apply box-border;
    display: block;
    position: absolute;
    height: 18px;
    width: 18px;
    z-index: 2;

    span {
      @apply border-2 border-custom-orange-400 dark:border-custom-orange-400 bg-white dark:bg-custom-purple-400;
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  .slider-line {
    @apply box-border;
    position: absolute;
    width: 100%;
    height: 3px;
    border-radius: 3px;
    z-index: 0;
    overflow: hidden;

    span {
      @apply bg-custom-orange-400 dark:bg-custom-orange-400;
      display: block;
      height: 100%;
      width: 0%;
    }
  }
}
</style>
