import { get, post, destroy, downloadBlob } from '../helpers/axiosHelper'

const url = `${process.env.VUE_APP_API_URL}`

const fetchFilters = async () => get(`${url}/users/fetch-financial-filters`)
const saveFilters = async (data) => post(`${url}/users/add-financial-filters`, data)
const deleteFilter = async (id) => destroy(`${url}/users/${id}/delete-financial-filters`)
const updateFilters = async (id, data) => post(`${url}/users/${id}/update-financial-filters`, data)
const downloadDocument = async (fileName) => downloadBlob(`${url}/download-document/${fileName}`)
const fetchRanges =  async () => get(`${url}/financial-ranges`)
export default {
  fetchFilters,
  saveFilters,
  deleteFilter,
  updateFilters,
  downloadDocument,
  fetchRanges
}
