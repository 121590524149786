<template>
  <SwiperModal
    :current-step="currentStep"
    :steps="steps"
    :show-modal="showModal"
    @continue="progressStep"
    @skip="skipStep"
  />
</template>

<script>
import SwiperModal from './_components/SwiperModal'
import { useUsersStore } from '../../stores/users'
export default {
  name: 'InitialUserWizardModal',
  components: {
    SwiperModal
  },
  setup() {
    const usersStore = useUsersStore()
    
  return { usersStore }
  },
  data () {
    return {
      showModal: true,
      currentStep: 0,
      steps: [
        {
          id: 0,
          name: '',
          description: 'Let’s take a couple of minutes to get familiar with our features.'
        },
        {
          id: 1,
          name: 'My Eucaps',
          description: 'Click on your avatar in the menu to access all features connected to you.'
        },
        {
          id: 2,
          name: 'Screener',
          description: 'Find the companies that interests you. We have them all.'
        },
        {
          id: 3,
          name: 'Company page',
          description: 'All information about a specific company on one page.'
        },
        {
          id: 4,
          name: 'Financials & Analytics',
          description: 'Get financial details and analysis on any company you want to invest.'
        },
        {
          id: 5,
          name: 'Lists',
          description: 'Save companies and track the development for a whole list.'
        }
      ]
    }
  },
  computed: {
    user() {
      return this.usersStore.user
    }
  },
  mounted () {
    this.currentStep = 0
    // console.log('wizard called ---')
  },
  methods: {
    progressStep () {
      if (this.currentStep === 5) {
        this.skipStep()
      } else this.currentStep = this.currentStep + 1
    },
    skipStep () {
      this.usersStore.seenBubbleType({ tag: 'initial_user_wizard', type: 'initial_user_wizard' })

      localStorage.introBubbleSeen = true
      this.showModal = false
    }
  }

}

</script>
