<template>
  <BaseButton
    class="bg-gray-500 hover:bg-gray-700 text-gray-200"
    @click="$emit('click', $event)"
  >
    <slot />
  </BaseButton>
</template>

<script>

export default {
  name: 'DisabledButton',
  emits: [
    'click'
  ]
}
</script>
