import { RepositoryFactory } from '@/api/repositoryFactory'
import mentions from '@/js/helpers/mentions'
import i18n from '@/js/language'
const repository = RepositoryFactory.get('posts')
const repo = RepositoryFactory.get('companies')
import { defineStore } from 'pinia'
import { useSnackbarStore } from './snackbar'
import { useCompaniesStore } from './companies'
import { useUsersStore } from './users'
import { usePostsStore } from './posts'
// import { useTriggersStore } from "./triggers"

export const useCommentsStore = defineStore('comments', {
  state: () => ({
    comments: [],
    loading: false,
    nextPageUrl: '',
    loaded: false,
    scrollLoading: false,
    canScrollMore: false,
    commentMenuLoading: false
  }),
  actions: {
    setComments(payload) {
      payload.data.map((comment) => {
        if (comment.text && comment.text.match(mentions.MATCHING)) {
          comment.text = mentions.parse(comment.text, comment.message.mentions)
        }
        if (comment.comments && comment.comments.length > 0) {
          comment.comments.map((subComment) => {
            if (subComment.text && subComment.text.match(mentions.MATCHING)) {
              subComment.text = mentions.parse(subComment.text, subComment.message.mentions)
            }
          })
        }
      })

      payload.data.map((comment) => {
        this.comments = { ...this.comments, [comment.id]: comment }
      })
    },
    pushCommentData(payload) {
      payload.filter((comment) => {
        if (comment.text && comment.text.match(mentions.MATCHING)) {
          comment.text = mentions.parse(comment.text, comment.message.mentions)
        }
        if (comment.comments && comment.comments.length > 0) {
          comment.comments.filter((subComment) => {
            if (subComment.text && subComment.text.match(mentions.MATCHING)) {
              subComment.text = mentions.parse(subComment.text, subComment.message.mentions)
            }
          })
        }
      })

      payload.find((comment) => {
        const flag = Object.values(this.comments).find((data) => {
          return comment.id === data.id
        })

        if (!flag || flag === undefined) {
          this.comments = { ...this.comments, [comment.id]: comment }
        } else {
          this.comments = { ...this.comments, [comment.id]: comment}
        }
      })

      this.comments = { ...this.comments }
    },
    async fetch(postId) {
      try {
        this.loading = true
        this.canScrollMore = true

        const response = await repository.commentList(postId)

        this.loading = false
        if (response.status === 200 && response.data) {
          this.nextPageUrl = response.data.next_page_url
          this.setComments({ data: response.data.data })
          this.loaded = true
        }
        return response
      } catch (e) {
        this.loading = false
        if(e.response.status !== 404) {
          useSnackbarStore().snack({
            type: 'error',
            e: e,
            show: true,
            autoclose: true
          })
        }
      }
    },
    async fetchMediaComments(mediaId) {
      try {
        this.loading = true
        this.canScrollMore = true

        const response = await repo.commentList(mediaId, useCompaniesStore().selectedCompanySN)
        this.loading = false
        if (response.status === 200 && response.data) {
          this.nextPageUrl = response.data.next_page_url
          this.setComments({ data: response.data.data })
          this.loaded = true
        }
      } catch (e) {
        this.loading = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async addMediaComment(payload) {
      try {
        const response = await repo.addComment(payload.mediaId, useCompaniesStore().selectedCompanyId, payload.formData)
        if (response.status === 200) {
          this.nextPageUrl = response.data.next_page_url
          this.setComments({ data: response.data.data })
          useSnackbarStore().snack({
            text: i18n.global.t('Comment added successfully'),
            type: 'success',
            show: true,
            autoclose: true
          })
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async scroll() {
      try {
        if (this.nextPageUrl === null) {
          this.scrollLoading = false
          this.canScrollMore = false
        } else if (this.nextPageUrl !== null && !this.loading && !this.scrollLoading) {
          this.scrollLoading = true
          const response = await repository.scrollComment(this.nextPageUrl)
          this.scrollLoading = false

          if (response.status === 200 && response.data && response.data.data) {
            this.pushCommentData(response.data.data)
            // payload.infiniteLoader.loaded()
            this.nextPageUrl = response.data.next_page_url
          }
        }
      } catch (e) {
        // commit('SET_LOADING_NEW', false)
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async addPressReleaseComment(payload) {
      try {
        const companyId = payload.companyId ? payload.companyId : useCompaniesStore().selectedCompanyId
        const response = await repository.addPressReleaseComment(companyId, payload.pressReleaseId, {
          text: payload.message,
          mentions: payload.mentions
        })
        if (response.status === 200) {
          this.nextPageUrl = response.data.next_page_url
          this.setComments({ data: response.data.data })
          useSnackbarStore().snack({
            text: i18n.global.t('Comment added successfully'),
            type: 'success',
            show: true,
            autoclose: true
          })
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async fetchPressReleaseComment(payload) {
      try {
        this.loading = true
        const companyId = payload.companyId ? payload.companyId : useCompaniesStore().selectedCompanySN
        const response = await repository.pressReleaseCommentList(companyId, payload.pressReleaseId)
        this.loading = false
        if (response.status === 200 && response.data) {
          this.setComments({ data: response.data.data })
        }
      } catch (e) {
        this.loading = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async fetchTriggerComment(triggerId) {
      try {
        // console.log('FETCHING TRIGGER COMMENTS')
        this.loading = true
        this.canScrollMore = true
        const response = await repository.triggerCommentList(useCompaniesStore().selectedCompanySN, triggerId)
        this.loading = false
        if (response.status === 200 && response.data) {
          this.setComments({ data: response.data.data })
          this.nextPageUrl = response.data.next_page_url
          this.setComments({ data: response.data.data })
          this.loaded = true
        }
      } catch (e) {
        this.loading = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async addComment(payload) {
      try {
        const response = await repository.addComment(payload.postId, payload.formData)
        if (response.status === 200) {
          this.nextPageUrl = response.data.next_page_url
          this.setComments({ data: response.data.data })
          usePostsStore().fetchPost({ checkState: false, silent: true, postId: payload.postId }, { root: true })

          useSnackbarStore().snack({
            text: i18n.global.t('Comment added successfully'),
            type: 'success',
            show: true,
            autoclose: true
          })
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async updateComment(payload) {
      try {
        // this.commentMenuLoading = true
        const response = await repository.updateComment(payload.commentId, payload.formData)
        // this.commentMenuLoading = false
        if (response.status === 200) {
          let comment = response.data

          this.pushCommentData([comment])
          // if (comment.text && comment.text.match(mentions.MATCHING)) {
          //   comment.text = mentions.parse(comment.text, comment.message.mentions)
          // }
          // this.comments[comment.id] = { ...comment }

          // useSnackbarStore().snack({
          //   text: 'Comment updated successfully',
          //   type: 'success',
          //   show: true,
          //   autoclose: true
          // })
        }
      } catch (e) {
        this.commentMenuLoading = false
        // useSnackbarStore().snack({
        //   type: 'error',
        //   e: e,
        //   show: true,
        //   autoclose: true
        // })
      }
    },
    async updateSubComment(payload) {
      try {
        // this.commentMenuLoading = true
        const response = await repository.updateComment(payload.commentId, {
          text: payload.message,
          mentions: payload.mentions
        })
        // this.commentMenuLoading = false
        if (response.status === 200) {
          let responsePayload = {
            parentId: payload.parentId,
            data: response.data
          }

          if (responsePayload.data.text && responsePayload.data.text.match(mentions.MATCHING)) {
            responsePayload.data.text = mentions.parse(responsePayload.data.text, responsePayload.data.message.mentions)
          }
          const index = this.comments[responsePayload.parentId].comments.findIndex(
            (comment) => comment.id === responsePayload.data.id
          )
          this.comments[responsePayload.parentId].comments[index] = responsePayload.data
          this.comments = { ...this.comments }

          // useSnackbarStore().snack({
          //   text: 'Comment updated successfully',
          //   type: 'success',
          //   show: true,
          //   autoclose: true
          // })
        }
      } catch (e) {
        // this.commentMenuLoading = false
        // useSnackbarStore().snack({
        //   type: 'error',
        //   e: e,
        //   show: true,
        //   autoclose: true
        // })
      }
    },
    async addTriggerComment(payload) {
      try {
        const response = await repository.addTriggerComment(
          useCompaniesStore().selectedCompanyId,
          payload.trigger_id,
          payload.formData
        )
        if (response.status === 200) {
          this.nextPageUrl = response.data.next_page_url
          this.setComments({ data: response.data.data })
          // useTriggersStore().updateTrigger(response.data.data[0].commentable, {
          // root: true,
          // })
          useSnackbarStore().snack({
            text: i18n.global.t('Comment added successfully'),
            type: 'success',
            show: true,
            autoclose: true
          })
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async addTriggerSubComment(payload) {
      try {
        const response = await repository.addTriggerSubComment(
          useCompaniesStore().selectedCompanyId,
          payload.triggerId,
          payload.commentId,
          payload.formData
        )
        if (response.status === 200) {
          this.setComments({ data: [response.data.commentable] })
          useSnackbarStore().snack({
            text: i18n.global.t('Comment added successfully'),
            type: 'success',
            show: true,
            autoclose: true
          })
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async addPressReleaseSubComment(payload) {
      try {
        const response = await repository.addPressReleaseSubComment(
          useCompaniesStore().selectedCompanyId,
          payload.pressReleaseId,
          payload.commentId,
          { text: payload.message, mentions: payload.mentions }
        )
        if (response.status === 200) {
          this.setComments({ data: [response.data.commentable] })
          useSnackbarStore().snack({
            text: i18n.global.t('Comment added successfully'),
            type: 'success',
            show: true,
            autoclose: true
          })
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async addSubComment(payload) {
      try {
        const response = await repository.addSubComment(payload.postId, payload.commentId, payload.formData)
        if (response.status === 200) {
          let comment = response.data
          console.log('response : ', response.data)
          if (comment.likes === undefined) comment.likes = []
          this.setComments({ data: [comment] })
          
          // useSnackbarStore().snack({
          //   text: i18n.global.t('Comment added successfully'),
          //   type: 'success',
          //   show: true,
          //   autoclose: true
          // })
        }
      } catch (e) {
        console.log(e)
        // useSnackbarStore().snack({
        //   type: 'error',
        //   e: e,
        //   show: true,
        //   autoclose: true
        // })
      }
    },
    async addMediaSubComment(payload) {
      try {
        const response = await repo.addSubComment(
          useCompaniesStore().selectedCompanyId,
          payload.commentId,
          payload.formData
        )
        if (response.status === 200) {
          this.setComments({ data: [response.data.commentable] })
          useSnackbarStore().snack({
            text: i18n.global.t('Comment added successfully'),
            type: 'success',
            show: true,
            autoclose: true
          })
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async fetchMediaSubComment(payload) {
      try {
        const response = await repo.fetchSubComment(payload.commentId)
        if (response.status === 200 && response.data) {
          this.setComments({ data: response.data.data })
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async fetchSubComment(payload) {
      try {
        const response = await repository.fetchSubComment(payload.commentId)
        if (response.status === 200 && response.data) {
          this.setComments({ data: response.data.data })
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async pushComment(payload) {
      await this.pushCommentData([payload])
    },
    async likeSubComment(payload) {
      try {
        const response = await repository.commentLike(payload.comment_id, {
          reaction: payload.reaction
        })
        if (response.status === 204) {
          const comment = this.comments[payload.parent_id].comments
          const subComment = comment.find(cmt => cmt.id === payload.comment_id)
          // .comments[payload.comment_id]
          console.log('comments : ', comment)
          const likeIndex = subComment.likes.findIndex(
            (like) => like.stock_user_id === useUsersStore().user.profile_id && like.reaction === payload.reaction
          )

          if (likeIndex > -1) {
            subComment.likes.splice(likeIndex, 1)
          } else {
            subComment.likes.push({
              likeable_id: payload.comment_id,
              reaction: payload.reaction,
              stock_user_id: useUsersStore().user.profile_id,
              stock_user: useUsersStore().user.profile
            })
          }
          useSnackbarStore().snack({
            text: 'Comment liked successfully',
            type: 'success',
            show: true,
            autoclose: true
          })
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async like(payload) {
      try {
        const response = await repository.commentLike(payload.comment_id, {
          reaction: payload.reaction
        })
        if (response.status === 204) {
          const comment = this.comments[payload.comment_id]
          const likeIndex = comment.likes.findIndex(
            (like) => like.stock_user_id === useUsersStore().user.profile_id && like.reaction === payload.reaction
          )

          if (likeIndex > -1) {
            comment.likes.splice(likeIndex, 1)
          } else {
            comment.likes.push({
              likeable_id: payload.comment_id,
              reaction: payload.reaction,
              stock_user_id: useUsersStore().user.profile_id,
              stock_user: useUsersStore().user.profile
            })
          }
          useSnackbarStore().snack({
            text: 'Comment liked successfully',
            type: 'success',
            show: true,
            autoclose: true
          })
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async report(payload) {
      try {
        // this.commentMenuLoading = true
        const response = await repository.reportComment(payload)

        // this.commentMenuLoading = false
        if (response.status === 200) {
          useSnackbarStore().snack({
            text: 'Comment reported successfully',
            type: 'success',
            show: true,
            autoclose: true
          })
        } else {
          useSnackbarStore().snack({
            text: 'Comment already reported',
            type: 'info',
            show: true,
            autoclose: true
          })
        }
      } catch (e) {
        this.commentMenuLoading = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async deleteSubComment(payload) {
      try {
        // this.commentMenuLoading = true
        const response = await repository.deleteComment(payload.subCommentId)

        // this.commentMenuLoading = false
        if (response.status === 204) {
          const index = this.comments[payload.commentId].comments.findIndex(
            (comment) => comment.id === payload.subCommentId
          )
          this.comments[payload.commentId].comments.splice(index, 1)
          this.comments = { ...this.comments }

          useSnackbarStore().snack({
            text: 'Comment deleted successfully',
            type: 'success',
            show: true,
            autoclose: true
          })
        }
      } catch (e) {
        this.commentMenuLoading = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async delete(commentId) {
      try {
        this.commentMenuLoading = true
        const response = await repository.deleteComment(commentId)

        this.commentMenuLoading = false
        if (response.status === 204) {
          delete this.comments[commentId]
          this.comments = { ...this.comments }

          useSnackbarStore().snack({
            text: 'Comment deleted successfully',
            type: 'success',
            show: true,
            autoclose: true
          })
        }
      } catch (e) {
        this.commentMenuLoading = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    reset() {
      this.comments = {}
    }
  }
})
