<template>
  <div class="rounded-b-md shadow-none bg-white dark:bg-custom-purple-400 dark:text-custom-purple-100 p-8">
    <div class="flex justify-between w-full gap-6">
      <div class="flex w-1/3 flex-col">
        <p class="text-base font-semibold py-3">
          Room Type
        </p>
        <div class="flex justify-between">
          <span class="flex items-center">
            <BaseInput
              type="radio"
              :locked="true"
              class="mr-2"
            /> Paid
          </span>
          <span class="flex items-center">
            <BaseInput
              type="radio"
              class="mr-2"
              :locked="true"
            /> Free
          </span>
          <span class="flex items-center">
            <BaseInput
              type="radio"
              class="mr-2"
              checked
              :locked="true"
            /> Both
          </span>
        </div>
      </div>
      <div class="flex w-1/4 flex-col">
        <p class="text-base font-semibold py-3">
          Time Span
        </p>
        <RangeSlider
          :start-at="0"
          :end-at="0"
          :min="0"
          :max="0"
          :suffix="'days'"
        />
      </div>
    </div>
    <!-- <div class="w-full text-purple-800 font-semibold pt-2 flex items-center justify-center">
      <Icon
        name="down-arrow-alt "
        class="text-2xl dark:text-custom-purple-100"
      />
      <span class="text-purple-800 dark:text-custom-purple-100">
        Show more
      </span>
    </div> -->
    <div class="flex justify-center gap-x-3 pr-10 md:pr-0 mt-5">
      <Button
        class="w-32 h-10 font-bold rounded-full text-custom-purple-500 dark:text-custom-purple-100 border-gray-500 dark:border-custom-purple-500 border hover:bg-custom-purple-50 dark:bg-custom-purple-450 dark:hover:bg-custom-purple-450"
        @click="$emit('reset')"
      >
        Clear filter
      </Button>
      <Button
        class="w-32 h-10 font-bold rounded-full bg-custom-purple-500 text-white"
        @click="$emit('apply')"
      >
        Apply filter
      </Button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ForumFilter',
  emits: ['reset', 'apply']
}
</script>