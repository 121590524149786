<template>
  <div class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-20 ">
    <div
      class="bg-transparent backdrop-blur-md rounded-full shadow-sm-dark flex items-center justify-center "
      :class="extraSmall? 'w-12 h-12' : small? 'w-8 h-8 sm:w-14 sm:h-14' : medium? 'w-16 h-16' : 'w-20 h-20'"
    >
      <div
        class="bg-custom-orange-400 hover:bg-custom-purple-500 rounded-full shadow-sm-dark flex items-center justify-center cursor-pointer "
        :class="extraSmall? 'w-9 h-9' : small? 'w-6 h-6 sm:w-11 sm:h-11' : medium? 'w-13 h-13' : 'w-16 h-16'"
        @click="$emit('click', $event)"
      >
        <Icon
          name="play"
          class="text-white "
          :class="extraSmall? 'text-2xl pl-1' : small? 'text-2xl sm:text-4xl pl-1' : 'text-6xl pl-2'"
        />  
      </div>  
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlayButton',
  props: {
    small: {
      type: Boolean,
      default: false
    },
    medium: {
      type: Boolean,
      default: false
    },
    extraSmall: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click']
}
</script>
    
