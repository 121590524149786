import { get, post } from '../helpers/axiosHelper'

const url = `${process.env.VUE_APP_API_URL}/inderes`

const fetchCompanyFeed = async (payload) => get(`${url}/feeds/filter`, payload)
const fetchExchangeFeed = async (slugUrl) => get(`${process.env.VUE_APP_API_URL}/exchange/${slugUrl}/inderes-video`)
const fetchExploreFeed = async (payload) => get(`${process.env.VUE_APP_API_URL}/search-filter/get/result`, payload)
const fetchPremiumCompanyReports = async() => get(`${url}/get/premium-companies-inderes-reports`)
const fetchHiddenMediaList = async (id) => get(`${url}/feeds/get/preference/company/${id}`)
const hideInderesMedia = async (payload, companyId) => post(`${url}/feeds/preference/company/${companyId}`, payload)
const scroll = async (link, payload) => get(`${link}`, payload)
const scrollPremiumCompanyReports = async(link) => get(`${link}`)

export default {
    fetchCompanyFeed,
	fetchExchangeFeed,
    fetchExploreFeed,
    fetchHiddenMediaList,
    hideInderesMedia,
    fetchPremiumCompanyReports,
    scroll,
    scrollPremiumCompanyReports
}
