<template>
  <div
    class="flex flex-row items-center justify-center py-1 rounded-full w-20"
    :class="value > 0 ? 'bg-custom-green-500' : 'bg-custom-orange-400'"
  >
    <Icon
      :name="value > 0 ? 'up-arrow-alt' : 'down-arrow-alt'"
      class="text-white mr-0.5"
      :class="value > 0 ? 'rotate-45' : '-rotate-45'"
    />
    <p class="text-white text-xs font-medium">
      {{ $percentage(value) }}
    </p>
  </div>
</template>
<script>
export default {
  name: 'PercentageBox',
  props: {
    value: {
      type: Number,
      default: 1
    },
  }
}
</script>