<template>
  <div class="flex justify-center items-center p-2 sm:p-8 overflow-hidden w-full">
    <AvatarSlot
      :alignment="'flex w-full items-center'"
      :stock-user="stockUser"
      :company="company"
      :big-avatar="bigAvatar"
      :linkable-avatar="linkableAvatar"
      :size="size"
      use-premium
      :default-class="defaultClass"
    >
      <div class="text-left dark:text-custom-purple-100 ml-6 md:ml-2">
        <div class="font-semibold w-full lg:w-40 truncate text-base sm:text-lg -mb-1">
          {{ name }}
        </div>
        <div class="font-medium mb-1 text-gray-400 dark:text-custom-purple-200">
          {{ subtitle }}
        </div>
        <div class="flex items-start dark:text-custom-purple-100">
          <VerifiedIcon
            :stock-user="stockUser"
            :use-reputation="useReputation"
          />
        </div>
      </div>

      <slot />
    </AvatarSlot>
  </div>
</template>

<script>

export default {
  name: 'AvatarWithReputation',

  props: {
    stockUser: { type: Object, default: () => { } },
    company: { type: Object, default: () => { } },
    defaultClass: { type: String, default: 'w-10 h-10' },
    name: { type: String, default: '' },
    linkableAvatar: { type: Boolean, default: false },
    bigAvatar: { type: Boolean, default: false },
    subtitle: { type: String, default: '' },
    size: { type: Number, default: 40 },
    textSize: { type: String, default: '' },
    useReputation: { type: Boolean, default: true }
  }
}
</script>
