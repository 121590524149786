<template>
  <div>
    <div class="flex items-center">
      <div class="flex w-full mb-2">
        <div
          class="w-full items-center cursor-pointer mb-2"
        >
          <p
            v-if="businessDetail?.title"
            class="w-full dark:text-white"
            :class="(!minifiedText)?' font-semibold text-lg mb-2':'text-base'"
          >
            {{ businessDetail?.title }}
          </p>
          <TextMessage
            v-if="!minifiedText"
            :message="businessDetail?.description"
          />
        </div>
      </div>
    </div>
    <div class="flex relative w-full ">
      <div
        v-if="businessDetail?.gallery?.length > 1"
        class="absolute top-1/2 left-2 z-10 "
      >
        <div
          class="cursor-pointer rounded-full border-2 bg-white"
          @click="getPrevPage"
        >
          <Icon
            name="left-arrow-alt"
            class="text-base px-1 text-black "
          />
        </div>
      </div>
      <div class=" w-full overflow-hidden h-full items-end text-xs">
        <Swiper
          :modules="modules"
          :slides-per-view="1"
          :space-between="50"
          :pagination="{ clickable: true }"
          @swiper="onSwiper"
        >
          <SwiperSlide
            v-for="(photo, index) in businessDetail?.gallery"
            :key="index"
          >
            <div class="w-full h-full relative -pt-4">
              <div class="">
                <img
                  class="rounded-lg  w-full"
                  :src="photo.image"
                >
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <div
        v-if="businessDetail?.gallery?.length > 1"
        class="absolute top-1/2 right-2 z-10 "
      >
        <div
          class="cursor-pointer rounded-full bg-white border-2 border-2 "
          @click="getNextPage"
        >
          <Icon
            name="right-arrow-alt"
            class="text-base text-black px-1 "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination, Navigation } from 'swiper'

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default {
  name: 'BusinessGrowthDetail',
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    businessDetail: { type: Object, default: () => { } },
    minifiedText: { type: Boolean, default: false },
  },
  setup () {
    return {
      modules: [Pagination, Navigation],
    }
  },
  data () {
    return {
      swiper: null,
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 30,
        slideToClickedSlide: true,
        loop: true
      },
    }
  },
  methods: {
    onSwiper (swiper) {
      this.swiper = swiper;
    },
    getPrevPage () {
      this.swiper.slidePrev()
    },
    getNextPage () {
      this.swiper.slideNext()
    },
  },
}
</script>
<style scoped>
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  @apply absolute bottom-0;
}

/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  .height {
    height: 200px;
  }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {
  .height {
    height: 220px;
  }
}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) {
  .height {
    height: 180px;
  }
}
</style>