import { createI18n } from 'vue-i18n'

// let globalI18n = null

// export const installLanguages = (app) => {
var requireLanguageFile = require.context('./languages/', true, /\.json$/)

let allLanguages = []

requireLanguageFile.keys().forEach((fileName) => {
  const moduleConfig = requireLanguageFile(fileName)
  const file = fileName.substring(fileName.lastIndexOf('/') + 1)
  const moduleName = file.replace(/\.\w+$/, '')

  allLanguages[moduleName] = moduleConfig
})

// const browserLanguage = (navigator.language || navigator.userLanguage).split('-')[0]
const defaultLocale = 'en'
//  const specificLanguage = (localStorage.language !== undefined && localStorage.language !== '') ? localStorage.language : browserLanguage
//  const specificLanguage = (localStorage.language !== undefined && localStorage.language !== '') ? localStorage.language : browserLanguage

const languages = allLanguages

const messages = Object.assign(languages)

const i18n = createI18n({
  legacy: false,
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
  allowComposition: true,
  globalInjection: true,
  messages
})

export default i18n
// app.use(i18n)

// return i18n
// }

// export const assignGlobalI18n = (i18n) => {
//   globalI18n = i18n
// }

// export const getGlobalI18n = () => {
//   return globalI18n
// }
