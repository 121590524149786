<template>
  <div
    v-if="small"
    class="flex items-center"
  >
    <Icon
      name="check-shield"
      class="text-2xl mr-2"
    />
    <div>
      Approved for <RouterLink
        class="text-custom-orange-400"
        :to="{name: 'companyOverview', params: {id: company?.slug_url} }"
      >
        {{ company?.name }}
      </RouterLink>
    </div>
  </div>
  <div
    v-else
    class="flex flex-col items-center mb-6 text-xl"
  >
    <Icon
      name="check-shield"
      class="text-6xl mb-2"
      solid
    />
    <div>Approved {{ company?.name }}</div>
  </div>
</template>

<script>

export default {
  name: 'CompanyApprovedCard',
  props: {
    company: {
      type: Object,
      default: () => {}
    },
    small: {
      type: Boolean,
      default: false
    }
  }
}
</script>
