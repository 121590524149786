<template>
  <ModalContainer
    v-if="showModal"
    popup
    :padding="false"
    height="h-auto"
    width="max-w-full"
    extra-class="rounded-xl"
    @toggle="$emit('close', $event)"
    @on-back-button="$emit('close', $event)"
  >
    <div class="flex relative">
      <Gradient />
      <div class="absolute w-full z-10">
        <p class="text-center font-extrabold text-3xl text-custom-purple-500 p-6 pt-14">
          Congrats, you are now a premium user!
        </p>
        <p class="px-10 text-center text-base font-semibold">
          Hey {{ fullName }} ! You just became a premium member! Please enjoy the benefits and we hope you have a great experience!
        </p>
      </div>
    </div>
    <div class="flex flex-col items-center justify-center my-8">
      <RouterLink
        :to="{ name: 'premium' }"
        @click="skipStep"
      >
        <button class="px-6 py-3 mb-2 cursor-pointer font-semibold bg-custom-orange-400 text-white rounded-xl">
          {{ $t('Read more about benefits') }}
        </button>
      </RouterLink>
      <button
        class="px-6 py-3 cursor-pointer font-semibold rounded-xl"
        @click="skipStep"
      >
        {{ $t('Close') }}
      </button>
    </div>
  </ModalContainer>
</template>

<script>
// import i18n from '@/js/language/'
import ModalContainer from '@/modules/modal'
import { useUsersStore } from '../../stores/users'

export default {
  name: 'AfterPremiumUser',
  components: {
    ModalContainer
  },
  emits: ['close'],
  setup() {
    const usersStore = useUsersStore()

    return { usersStore }
  },
  data() {
    return {
      showModal: true
    }
  },
  computed:{
    fullName() {
      return this.usersStore?.profile?.full_name || ''
    }
  },
  methods: {
    skipStep() {
      this.usersStore.seenBubbleType({ tag: 'you_are_now_premium', type: 'you_are_now_premium' })
      this.showModal = false
    }
  }
}
</script>
