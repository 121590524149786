<template>
  <button
    class="inline-block flex justify-end items-center font-medium text-sm leading-6 focus:outline-none "
    :class="[size, disabled ? '' : '']"
    :disabled="disabled"
  >
    <img
      class="ml-1 w-4 share-icon"
      src="/svg/share-arrow.svg"
    >
    <div class="ml-2 text-sm font-semibold">
      Share
    </div>
  </button>
</template>

<script>

export default {
  name: 'ShareButton',
  props: {
    size: { type: String, default: 'ml-4 text-right' },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.light .share-icon {
  filter: invert(0%) sepia(1%) saturate(12%) hue-rotate(319deg) brightness(101%) contrast(101%);
}
.dark .share-icon {
  filter: invert(98%) sepia(76%) saturate(7316%) hue-rotate(182deg) brightness(109%) contrast(98%);
}
</style>
