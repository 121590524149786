// import { allTypes } from '@/enums/postTypes'
import { isMobile } from "@/js/helpers/mobile"
import { defineStore } from "pinia"

export const useFiltersStore = defineStore("filters", {
  state: () => ({
    sorting: "latest",
    filters: {
      by_general: 1,
      by_companies: 1,
      by_rooms: 1,
      by_followers: 1,
      by_pr: 1,
      by_users: 1,
      // by_follower: 1,
      companiesOnly: false,
      // types: allTypes,
      company_id: null,
      stock_user_id: null,
    },
    toggleFilterSection: !isMobile(),
  }),
  actions: {
    async init() {
      if (localStorage.filterVersion !== "4") {
        // console.log('RENSA OMS')
        localStorage.removeItem("filters")
        localStorage.filterVersion = "4"
      }

      if (localStorage.filters) {
        this.filters = { ...JSON.parse(localStorage.filters) }
      }
    },
    async setFilter(payload) {
      if (payload.override) {
        this.filters = { ...payload }
      } else {
        this.filters = { ...this.filters, ...payload }
      }
    },
    async toggleFilterSection(payload) {
      this.toggleFilterSection = payload
    },
    async setSorting(payload) {
      this.sorting = payload
    },
    async setLocalStorage(payload) {
      localStorage.filters = JSON.stringify(payload)
    },
  },
})
