<template>
  <FooterComponent
    :is-logged-in="isLoggedin"
    :menu="useMenu"
    :use-footer="useFooter"
    :ios-padding="iosPadding"
  />
</template>

<script>

import { useAppsStore } from '../../stores/app'
import { useUsersStore } from '../../stores/users'
import FooterComponent from './_components/FooterComponent'

export default {
  name: 'FooterModule',
  components: {
    FooterComponent
  },
  props: {
    padding: {
      type: String,
      default: ''
    }
  },
  setup(){
    const usersStore = useUsersStore()
    const appStore = useAppsStore()
    return { usersStore, appStore }
  },
  computed: {
    isLoggedin(){
      return this.usersStore.isLoggedin
    },
    useFooter(){
      return this.appStore.useFooter
    },
    useMenu(){
      return this.appStore.menu
    },
    iosPadding () {
      return (this.padding !== '')
    }
  }
}
</script>
