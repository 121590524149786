<template>
  <div>
    <!-- <div
      v-if="preview.loading"
      class="mt-2 flex border border-gray-200 dark:border-custom-purple-400 p-2 rounded-md"
    >
      <CircleLoader
        small
      />
    </div> -->
    <!-- <div> -->
    <a
      :href="url"
      target="_blank"
      class="cursor-pointer mt-2 flex items-center border border-gray-200 dark:border-custom-purple-400 p-2 rounded-md bg-white dark:bg-custom-purple-400"
      @click="$emit('click')"
    >
      <div
        v-if="preview.loading"
        class="flex items-center"
      >
        <Icon
          name="link-external"
          class="text-xl text-custom-orange-400 mr-2"
        />
        <div class="text-xs">
          <p class="break-all dark:text-custom-purple-100">{{ url }}</p>
        </div>
      </div>
      <template v-else>
        <img
          v-if="preview.image"
          class="w-16 mr-2 self-center"
          :src="preview.image"
          :alt="preview.title"
          @error="imageLoadError(preview)"
        >
        <Icon
          v-else
          name="link-external"
          class="text-xl text-custom-orange-400 mr-2"
        />
        <div class="text-xs dark:text-custom-purple-100">
          <p>{{ preview.title }}</p>
          <p class="text-2xs">
            {{ preview.description }}
          </p>
          <a
            :href="url"
            target="_blank"
            class="text-2xs break-word"
          >
            {{ preview.domain }}
          </a>
        </div>
      </template>
    </a>
  </div>
</template>

<script>
import { useMetasStore } from '@/stores/meta'

export default {
  name: 'LinkPreview',
  props: {
    url: {
      type: String,
      default: ''
    }
  },
  emits: [
  'click'
  ],
  setup () {
    const metasStore = useMetasStore()

    return { metasStore }
  },
  computed: {
    preview () {
      if (!this.metasStore.links || !this.metasStore.links[this.url]) {
        return { title: this.url }
      }

      if (!this.metasStore.links[this.url]) {
        return { loading: true }
      }
      return this.metasStore.links[this.url]
    },
    empty () {
      return (
        this.preview.image === '' &&
        this.preview.title === '' &&
        this.preview.description === '' &&
        this.preview.domain === ''
      )
    }
  },
  mounted () {
    this.metasStore.check(this.url)
  },
  methods: {
    imageLoadError (preview) {
      preview.image = ''
    }
  }
}
</script>
