<template>
  <BigLogo
    v-if="big"
    :is-logged-in="isLoggedIn"
    :color="color"
  />
  <SmallLogo
    v-else
    :is-logged-in="isLoggedIn"
    :color="color"
  />
</template>

<script>

import SmallLogo from './_components/SmallLogo'
import BigLogo from './_components/BigLogo'
import { useUsersStore } from '../../stores/users'

export default {
  name: 'LogoModule',
  components: {
    SmallLogo,
    BigLogo
  },
  props: {
    mode: { default: 'light', type: String },
    big: { type: Boolean, default: false },
    eucaps: { type: Boolean, default: false }
  },
  setup() {
    const usersStore = useUsersStore()
    
    return { usersStore }
  },
  computed: {
    color () {
      if (this.mode === 'light') {
        return 'white'
      }
      return 'gray-500'
    },
    user() {
      return this.usersStore.user
    },
    isLoggedIn () {
      return this.user && this.user.id && this.user.profile_type !== 'App\\Models\\GuestUser'
    }
  }
}
</script>
