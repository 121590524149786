<template>
  <div
    v-if="!src"
    :class="big ? 'w-40 h-28' : 'w-14 h-14'"
    class="relative max-w-14"
  >
    <slot name="square-text" />
    <slot />
  </div>
  <div
    v-else
    class="w-32 h-20 flex items-center justify-center"
  >
    <!-- <BannerImage
      :src="src"
      ratio="16/9"
      type="bg-contain"
      @click="$emit('click', $event)"
    /> -->
    <img
      v-if="src"
      :src="src"
      class="object-cover p-[7px] rounded-3xl  " 
    >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'RectangleImage',
  props: {
    src: {
      type: String,
      default: ''
    },
    big: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'click'
  ]
}
</script>
