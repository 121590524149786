<template>
  <div class="w-full flex flex-col bg-white">
    <div
      class="user flex flex-col items-center lg:justify-center justify-start "
    >
      <div 
        :class="!isVerified ? 'pb-10' : ''"
        class="lg:hidden lg:pb-20  block"
      >
        <slot name="contentSlot" />
      </div>
      <slot name="componentSlot" />
    </div>
  </div>
</template>
<script>

export default {
  name: 'OnboardingForm',
  props: {
    username: {
      type: String,
      default: ''
    },
    email: {
      type: String,
      default: ''
    },
    isVerified: {
      type: Boolean,
      default: false
    },
    password: {
      type: String,
      default: ''
    },

  },
emits: [
'clicks'
]
}
</script>
