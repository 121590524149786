<template>
  <BaseButton
    :bold="bold"
    :rounded="rounded"
    class="rounded-3xl bg-custom-green-600 hover:bg-custom-green-700 dark:bg-custom-green-700 hover:dark:bg-custom-green-900 text-base p-3 text-white"
    :class="[textSize,padding]"
    :opacity="opacity"
    :no-padding="true"
    :no-shadow="true"
    @click="$emit('click', $event)"
  >
    <slot />
  </BaseButton>
</template>

<script>

export default {
  name: 'BuyButton',
  props: {
    rounded:{
      type: String,
      default: 'rounded-xl'
    },
    textSize:{
      type: String,
      default: 'text-xs'
    },
    bold: {
      type: Boolean,
      default: true
    },
    padding: {
      type: String,
      default: 'p-4'
    },
    opacity:{
      type: String,
      default: ''
    }

  },
  emits: [
    'click'
  ]
}
</script>
