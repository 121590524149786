<template>
  <div
    class="w-full"
    :class="'lg:w-6/12'"
  >
    <slot />
  </div>
</template>

<script>

export default {
  name: 'MiddleColumn',
  props: {
    big: {
      type: Boolean,
      default: false
    }
  }
}
</script>
