<template>
  <div
    :class="{
      'flex space-x-4': variant === 'horizontal',
    }"
  >
    <ul
      class="list-none border-custom-purple-300 text-sm border-b-2 text-center overflow-auto whitespace-nowrap"
      :class="{
        'flex items-center mb-6': variant === 'vertical',
      }"
    >
      <li
        v-for="(tab, index) in tabList"
        :key="index"
        class="w-full py-1"
        :class="{
          'text-white font-semibold bg-custom-purple-300': index + 1 === activeTab,
          '': index + 1 !== activeTab,
        }"
        @click="$emit('fetch-linkables', tab)"
      >
        <label
          :for="`${_uid}${index}`"
          class="cursor-pointer block"
          v-text="tab"
        />
        <input
          :id="`${_uid}${index}`"
          v-model="activeTab"
          type="radio"
          :name="`${_uid}-tab`"
          :value="index + 1"
          class="hidden"
        >
      </li>
    </ul>
    <template v-for="(tab, index) in tabList">
      <div
        v-if="index + 1 === activeTab"
        :key="index"
        class="flex-grow bg-white rounded-lg shadow-xl p-4"
      >
        <slot :name="`tabPanel-${index + 1}`" />
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    tabList: {
      type: Array,
      required: true
    },
    variant: {
      type: String,
      required: false,
      default: () => 'vertical',
      validator: (value) => ['horizontal', 'vertical'].includes(value)
    }
  },
  emits: [
  'fetch-linkables',
  ],
  data () {
    return {
      activeTab: 1
    }
  }
}
</script>
