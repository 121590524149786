import { mapState } from "pinia"
import { useCompaniesStore } from "../stores/companies"
import { useUsersStore } from "../stores/users"

export default {
  setup() {
    const usersStore = useUsersStore()
    const companiesStore = useCompaniesStore()

    return { usersStore, companiesStore }
  },
  computed: {
    ...mapState(useUsersStore, ["user"]),
    ...mapState(useCompaniesStore, ["selectedCompany"]),
    isGuest() {
      return (
        !this.user ||
        !this.user.profile ||
        this.user.profile_type === "App\\Models\\GuestUser" ||
        this.user.profile.profile_type === "App\\Models\\GuestUser"
      )
    },
    user() {
      return this.usersStore?.user
    },
    isVerified() {
      return (
        !this.isGuest &&
        this.user &&
        this.user.profile &&
        this.user.profile.is_verified
      )
    },
    isModerator() {
      if (!this.user.profile) return false
      if (!this.user.profile.role_list) return false
      return this.user.profile.role_list.includes("moderator")
    },
    isAdmin() {
      if (!this.user) return false
      if (!this.user.role_list) return false
      return this.user.role_list.includes("admin")
    },
    isCompanyUser() {
      if (!this.user) return false
      if (!this.user.role_list) return false
      if (!this.selectedCompany) return false
      return this.user.role_list.includes(
        this.selectedCompany.slug_url.toLowerCase()
      )
    },
    isMarketer() {
      if (!this.user) return false
      if (!this.user.role_list) return false
      return this.user.role_list.includes("marketer")
    },
  },
}
