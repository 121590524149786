<template>
  <BaseButton
    :class="[isSecondary ? 'border border-black dark:border-custom-purple-400 bg-gray-900 dark:bg-custom-purple-500 dark:hover:bg-custom-purple-450 hover:border-gray-500 dark:hover:border-custom-purple-450 hover:bg-gray-800 text-white hover:text-gray-100' : 'bg-custom-purple-500 dark:bg-custom-purple-400 dark:hover:bg-custom-purple-450 hover:underscore text-white dark:text-custom-purple-100']"
    :opacity="opacity"
    :no-padding="noPadding"
    :rounded="rounded"
    :no-shadow="noShadow"
    :bold="bold"
    :locked="locked"
    @click="$emit('click', $event)"
  >
    <slot />
  </BaseButton>
</template>

<script>

export default {
  name: 'NeutralButton',
  props: {
    eucapsTheme: { type: Boolean, default: false },
    secondary: { type: Boolean, default: false },
    noPadding: { type: Boolean, default: false },
    noShadow: { type: Boolean, default: false },
    bold: { type: Boolean, default: true },
    locked: { type: Boolean, default: false },
    rounded: { type: String, default: 'rounded-md' },
    opacity: { type: String, default: '' },
  },
  emits: [
    'click'
  ],
  computed: {
    isSecondary () {
      return (this.secondary !== false)
    }
  }
}
</script>
