<template>
  <div class="relative">
    <ModalContainer
      v-if="showFilter"
      full-size
      right
      hide-close
      :padding="false"
      :extra-class="mt-16 "
      @toggle="$emit('toggle')"
      @on-back-button="$emit('toggle')"
    >
      <div class="absolute bg-white  dark:bg-custom-purple-500 w-full">
        <div class="p-4 text-custom-purple-500 dark:text-custom-purple-100 flex justify-center sm:hidden items-center ">
          <span class=" text-lg font-medium">
            {{ title }}
          </span>
        </div>
        <Icon
          name="left-arrow-alt"
          class="text-2xl cursor-pointer absolute top-4 left-2"
          @click="$emit(showSingleFilterMenu? 'toggleSingleMenu' : 'toggle' )"
        />
      </div>
      <div
        v-if="showSingleFilterMenu"
        class="pt-12"
      >
        <div
          v-if="loading"
          class=" flex items-center justify-center pt-10"
        >
          <CircleLoader />
        </div>
        <div v-else>
          <div
            v-if="selectedOption.type === 'slider' && tabName=== 'explore'"
            class="pt-6"
          >
            <div
              v-for="screenerFilter in screenerFilters"
              :key="screenerFilter.code"
            >
              <div
                v-if="screenerFilter.type === 'range' && screenerFilter.code === title.toLowerCase() "
                class="space-y-4 px-4"
              >
                <BaseInput 
                  placeholder="Min"
                  type="number" 
                  :value="minValue?? ''" 
                  :min="screenerFilter.data.defaultRange[0]"
                  :max="screenerFilter.data.defaultRange[1]"  
                  class="w-full h-11 text-center remove-arrow"
                  @input="$emit('set-range-value', {screenerFilter}, $event.target.value, 'min')"
                />        
                <BaseInput       
                  placeholder="Max"
                  type="number" 
                  :value="maxValue ?? ''"
                  :min="screenerFilter.data.defaultRange[0]"
                  :max="screenerFilter.data.defaultRange[1]" 
                  class="w-full h-11 text-center remove-arrow"
                  @input="$emit('set-range-value', {screenerFilter}, $event.target.value, 'max')"
                />
                <div class="pt-4">
                  <RangeSlider
                    :id="'minmax-range-' + screenerFilter.code"
                    :start-at="screenerFilter.preselect ? screenerFilter.preselect[0] : 0"
                    :end-at="screenerFilter.preselect ? screenerFilter.preselect[1] : 0"
                    :min="screenerFilter.data.defaultRange[0]"
                    :max="screenerFilter.data.defaultRange[1]"
                    :suffix="screenerFilter.suffix ? screenerFilter.suffix : ''"
                    @on-change="$emit('input-range', {screenerFilter, preselect:[...$event]})"
                    @on-changed="$emit('search')"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="selectedOption.type === 'slider' && tabName=== 'forumAndDiscussions'"
            class="space-y-4 px-4 pt-6"
          >
            <BaseInput 
              placeholder="Min"
              type="number" 
              :value="minvalue ?? ''" 
              disabled  
              class="w-full h-11 text-center remove-arrow"
            />        
            <BaseInput 
              disabled      
              placeholder="Max"
              type="number" 
              :value="minvalue ?? ''" 
              class="w-full h-11 text-center remove-arrow"
            />
            <RangeSlider
              :start-at="0"
              :end-at="0"
              :min="0"
              :max="0"
              :suffix="'days'"
            />
          </div>
          <div
            v-if="selectedOption.type === 'radio'"
            class="space-y-4 text-lg font-medium py-4 px-4"
          >
            <span class="flex items-center">
              <BaseInput
                type="radio"
                :locked="true"
                class="mr-4"
              /> Paid
            </span>
            <span class="flex items-center">
              <BaseInput
                type="radio"
                class="mr-4"
                :locked="true"
              /> Free
            </span>
            <span class="flex items-center">
              <BaseInput
                type="radio"
                class="mr-4"
                checked
                :locked="true"
              /> Both
            </span>
          </div>
          <div
            v-if="selectedOption.type === 'checkbox'" 
            class="space-y-4 text-lg font-medium "
          >
            <div
              v-for="screenerFilter in screenerFilters"
              :key="screenerFilter.code"
            >
              <div v-if="screenerFilter.type === 'multiselect' && screenerFilter.code === title.toLowerCase()">
                <div
                  v-for=" (item, index) in screenerFilter.data.list"
                  :key="item.id"
                  class="py-4 px-4 flex"
                  :class="index === 0 ? '' : 'border-t dark:border-custom-purple-400'"
                  @click="$emit('update-checkbox',{screenerFilter}, item)"
                >
                  <Icon
                    :name="screenerFilter.preselect && screenerFilter.preselect.includes(item)? 'check-square' : 'square'"
                    :solid="screenerFilter.preselect && screenerFilter.preselect.includes(item)"
                    class="text-xl rounded-lg ml-1"
                    :class="screenerFilter.preselect && screenerFilter.preselect.includes(item)? 'text-custom-green-500' : ''"
                  />
                  <span class="ml-2">  
                    {{ item.name }}
                  </span>
                </div>    
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="pt-16"
      >
        <div
          v-for="option in filterOptions"
          :key="option.id"
          @click="$emit('toggle-single-menu', option)"
        >
          <div class="border-b dark:border-custom-purple-400 text-lg font-medium py-4 px-4 hover:bg-custom-purple-500 dark:bg-custom-purple-500 dark:text-custom-purple-100 hover:text-custom-purple-100 ">
            {{ option.name }}
          </div>
        </div>
      </div>
      <div
        class="absolute flex items-center justify-center  border-t dark:border-custom-purple-400 bottom-0 w-full py-6 text-base dark:text-custom-purple-100 text-custom-purple-500 font-semibold bg-white dark:bg-custom-purple-500 space-x-4"
        @click="$emit('toggle')"
      >
        <Button
          class="w-32 h-10 font-semibold text-sm rounded-full dark:text-custom-purple-100 border-gray-500 dark:border-custom-purple-400 border"
          :class="appliedFilter ? 'bg-custom-purple-100 dark:bg-custom-purple-200 dark:text-custom-purple-500' : 'text-custom-purple-500 dark:bg-custom-purple-450'"
          :disabled="loading"
          @click="$emit('reset')"
        >
          Clear filter
        </Button>
        <Button
          class="w-40 h-10 font-semibold text-sm rounded-full"
          :disabled="loading"
          :class="(filterChanged || (!filterChanged && !appliedFilter)) ? 'bg-custom-purple-500 text-white dark:bg-custom-purple-400' : 'text-custom-purple-500 dark:text-white dark:bg-custom-purple-450'"
          @click="$emit('apply')"
        >
          Apply filter
        </Button>
      </div>
    </ModalContainer>
  </div>
</template>

<script>

import ModalContainer from '@/modules/modal'

export default {
  name: 'MobileFilterMenu',
  components: {
    ModalContainer
  },
  props: {
    showFilter: { type: Boolean, default: false },
    showSingleFilterMenu: { type: Boolean, default: false },
    title: { type: String, default: '' },
    filterOptions: { type: Array, default: () => [] },
    screenerFilters: { type: Array, default: () => [] },
    selectedOption: { type: Array, default: () => {} },
    minValue: { type: Number, default: null },
    maxValue: { type: Number, default: null },
    tabName: { type: String, default: '' }, 
    count: { type: Number, default: 0 },
    appliedFilter: { type: Boolean, default: false },
    filterChanged: { type: Boolean, default: false },
    loading: { type: Boolean, default: false }
  },
  emits: ['explore', 'reset', 'apply', 'toggle', 'toggle-single-menu', 'input-range', 'search', 'set-range-value', 'update-checkbox'],
}
</script>
<style lang="scss">
.dark {
  .screener-filter-menu {
    .multiselect__tags,
    .multiselect__content-wrapper {
      @apply bg-custom-purple-450 border-custom-purple-300 text-white;
    }
    .multiselect__input {
      background: inherit;
      @apply text-custom-purple-100 placeholder-custom-purple-200;
    }
    .multiselect__placeholder {
      @apply text-custom-purple-100;
    }
  }
}
.multiselect__option {
  @apply text-sm;
}
</style>