<template>
  <div class="main">
    <div
      class="dropzone-container w-full"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
      <input
        :id="'DropFile' + mode "
        :ref="'file' + mode "
        type="file"
        name="file"
        class="hidden-input"
        :accept="isElevatorPitch ? '.pdf,.mp4' : '.pdf,.jpg,.jpeg,.png,.mp4'"
        @change="change"
      >

      <label
        :for="'DropFile' + mode "
        class="file-label"
      >
        <div
          v-if="isDragging"
          class="dark:text-custom-purple-100"
        >Release to drop files here.</div>
        <div
          v-else
          class="dark:text-custom-purple-100"
        >Drag or drop document here or <span class="text-custom-blue-800">browse</span></div>
        <div class="flex justify-center mt-6">
          <Icon
            name="cloud-upload"
            class="text-5xl dark:text-custom-purple-100"
          />
        </div>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
	isElevatorPitch: {
      type: Boolean,
      default: false
	},
	mode: {
      type: String,
      default: ''
    }
  },
  emits: [
	'handle-change'
  ],
  data() {
    return {
      isDragging: false,
      files: [],
    };
  },
  methods: {
    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e) {
      e.preventDefault();
      if(this.mode === 'productPortfolio'){
        this.$refs.fileproductPortfolio.files = e.dataTransfer.files;
      }
      if(this.mode === 'marketAnalysis'){
        this.$refs.filemarketAnalysis.files = e.dataTransfer.files;
      }

      if(this.isElevatorPitch) this.$emit('handle-change', {files: e.dataTransfer.files, source: 'drag'})

      // this.onChange();
    },
	change (e) {
    this.$emit('handle-change', e)

    }
  }
};
</script>

<style lang="scss" scoped>
.main {
    display: flex;
    flex-grow: 1;
    align-items: center;
    height: 14vh;
    justify-content: center;
    text-align: center;
}
.dropzone-container {
    padding: 2rem;
    border: 2px dashed #e2e8f0;
}
.hidden-input {
    opacity: 0;
    overflow: hidden;
    position: absolute;
    width: 1px;
    height: 1px;
}
.file-label {
    font-size: 16px;
	font-weight: normal;
    display: block;
    cursor: pointer;
}
.preview-container {
    display: flex;
    margin-top: 2rem;
}
.preview-card {
    display: flex;
    border: 1px solid #a2a2a2;
    padding: 5px;
    margin-left: 5px;
}
.preview-img {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    border: 1px solid #a2a2a2;
    background-color: #a2a2a2;
}
</style>