<template>
  <div class="m-8 md:2m-12">
    <h1 class="text-2xl text-custom-purple-700 dark:text-custom-purple-100 font-bold flex gap-2">
      <Icon
        name="info-circle"
        class="text-2xl"
      />  {{ $t('Error') }}
    </h1>
    
    <p class="mt-4 dark:text-custom-purple-100 text-justify">
      {{ $t(`Unfortunately, based on the information you have provided, we cannot cater to you at the moment, best regards the Eucaps team.`) }}
    </p>

   
    <div class="flex justify-center gap-6 mx-2 md:mx-8 mt-20">
      <button
        class="border border-custom-purple-500 dark:border-custom-purple-450 font-bold rounded-full h-12 w-2/4"
        @click="$emit('close')"
      >
        {{ $t('Back') }}
      </button>
      <button
        class="bg-custom-purple-500 dark:bg-custom-purple-450 text-custom-purple-100 font-bold rounded-full w-2/4 h-12"
        @click="$emit('back-to-profile')"
      >
        {{ $t('Go to Profile') }}
      </button>
    </div>
  </div>
</template>
  
  <script>
  export default {
    name: 'InvalidCountryAlert',

    emits: [
      'close', 'back-to-profile',
    ],
  
  }
  
  </script>
  