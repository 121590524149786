import { RepositoryFactory } from '@/api/repositoryFactory'
import mentions from '@/js/helpers/mentions'
const repository = RepositoryFactory.get('companies')
import { defineStore } from 'pinia'
import { useSnackbarStore } from './snackbar'
import { useCompaniesStore } from './companies'
import { useMediaFiltersStore } from './mediaFilters'
import { useUsersStore } from './users'

export const useMediasStore = defineStore('medias', {
  state: () => ({
    selectedCompanyId: null,
    originalMedia: {},
    loading: false,
    loadingNewMedia: false,
    addMediaLoading: false,
    pollLoading: false,
    selectedMedia: '',
    nextPageUrl: '',
    editableId: null,
    alert: {
      title: '',
      create: false,
      body: '',
      type: '',
      showConfirmButton: false
    }
  }),
  getters: {
    media(state) {
      if (!useCompaniesStore().selectedCompanyId || !state.media[useCompaniesStore().selectedCompanyId]) {
        return []
      }
      return state.originalMedia[useCompaniesStore().selectedCompanyId]
    }
  },
  actions: {
    appendMedia(payload) {
      payload.media.filter((media) => {
        if (media.mediable === null) return
        if (media.mediable.message && media.mediable.message.match(mentions.MATCHING)) {
          media.mediable.message = mentions.parse(media.mediable.message, media.message.mentions)
        }
      })
      if (this.media[payload.selectedCompanyId] === undefined) {
        this.originalMedia = {
          ...this.originalMedia,
          [payload.selectedCompanyId]: payload.media
        }
      } else {
        payload.media.find((response) => {
          const flag = this.media[payload.selectedCompanyId].findIndex((media) => {
            return response.id === media.id
          })
          if (flag > -1) {
            this.originalMedia[payload.selectedCompanyId][flag] = response
          } else {
            this.originalMedia[payload.selectedCompanyId].push(response)
          }
        })
      }

      this.originalMedia = { ...this.originalMedia }
    },
    setMedia(payload) {
      payload.filter((media) => {
        if (media.mediable === null) return
        if (media.mediable.message && media.mediable.message.match(mentions.MATCHING)) {
          media.mediable.message = mentions.parse(media.mediable.message, media.message.mentions)
        }
      })
      this.orginalMedia = { ...this.orginalMedia, [payload.selectedCompanyId]: payload }
    },
    async fetch() {
      try {
        this.loading = true
        const response = await repository.listMedia(
          useCompaniesStore().selectedCompanySN,
          useMediaFiltersStore().filters
        )
        this.loading = false
        if (response.status === 200) {
          response.data.data.selectedCompanyId = useCompaniesStore().selectedCompanyId
          this.setMedia(response.data.data)
          this.nextPageUrl = response.data.next_page_url
        }
      } catch (e) {
        this.loading = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    mediaLoading(boolean) {
      this.addMediaLoading = boolean
    },
    resetAlert() {
      this.alert = {
        title: '',
        create: false,
        body: '',
        type: '',
        showConfirmButton: false
      }
    },
    setAlert(payload) {
      // console.log(payload)
      this.alert.title = payload.title
      this.alert.body = payload.body
      this.alert.create = payload.create
      this.alert.type = payload.type
      this.alert.showConfirmButton = payload.showConfirmButton
    },
    async report(id) {
      try {
        const response = await repository.reportMedia(id, useCompaniesStore().selectedCompanyId)
        if (response.status === 200) {
          useSnackbarStore().snack({
            text: 'Media reported successfully',
            type: 'success',
            show: true,
            autoclose: true
          })
        } else {
          useSnackbarStore().snack({
            text: 'Media already reported',
            type: 'info',
            show: true,
            autoclose: true
          })
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message,
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async deleteRequest(id) {
      try {
        const response = await repository.mediaDeleteRequest(id, useCompaniesStore().selectedCompanyId)
        if (response.status === 204) {
          useSnackbarStore().snack({
            text: 'media deleted request added successfully',
            type: 'success',
            show: true,
            autoclose: true
          })
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message,
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async delete(id) {
      try {
        const response = await repository.deleteMedia(id, useCompaniesStore().selectedCompanyId)
        if (response.status === 204) {
          const payload = {
            companyId: useCompaniesStore().selectedCompanyId,
            id
          }
          this.originalMedia[payload.companyId].splice(
            this.originalMedia[payload.companyId].findIndex((data) => data.id === payload.id),
            1
          )
          this.originalMedia = { ...this.originalMedia }

          useSnackbarStore().snack({
            text: 'media deleted successfully',
            type: 'success',
            show: true,
            autoclose: true
          })
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: e.response.data.message,
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async refetch() {
      try {
        if (!this.loading) {
          this.addMediaLoading = true
          const response = await repository.listMedia(
            useCompaniesStore().selectedCompanySN,
            useMediaFiltersStore().filters
          )
          this.addMediaLoading = false
          if (response.status === 200 && response.data) {
            response.data.data.selectedCompanyId = useCompaniesStore().selectedCompanyId
            this.setMedia(response.data.data)
            this.nextPageUrl = response.data.next_page_url
          }
        }
      } catch (e) {
        this.addMediaLoading = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async like(payload) {
      try {
        const media = this.originalMedia[useCompaniesStore().selectedCompanyId].find(
          (media) => media.id === payload.media_id
        )

        const likeIndex = media.likes.findIndex(
          (like) => like.stock_user.id === useUsersStore().user.profile_id && like.reaction === payload.reaction
        )

        if (likeIndex > -1) {
          media.likes.splice(likeIndex, 1)
        } else {
          media.likes.push({
            likeable_id: payload.media_id,
            reaction: payload.reaction,
            stock_user: useUsersStore().user.profile,
            stock_user_id: useUsersStore().user.profile.id
          })
        }

        const response = await repository.mediaLike(payload.media_id, useCompaniesStore().selectedCompanyId, {
          reaction: payload.reaction
        })
        if (response.status === 204) {
          // useSnackbarStore().snack({ text: 'media added to liked list', type: 'success', show: true, autoclose: true })
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async shareWithGeneral(payload) {
      try {
        const response = await repository.shareWithGeneral(payload.media_id, {
          message: payload.message
        })
        if (response.status === 204) {
          useSnackbarStore().snack({
            text: 'Media shared.',
            type: 'success',
            show: true,
            autoclose: true
          })
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async fetchMedia(mediaId) {
      try {
        this.addMediaLoading = true
        const response = await repository.fetchMedia(mediaId)
        if (response.status === 200 && response.data) {
          this.appendMedia({
            selectedCompanyId: response.data.company.id,
            media: [response.data]
          })
          this.addMediaLoading = false
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async pushMedia(media) {
      try {
        this.appendMedia({
          selectedCompanyId: media.company_id,
          media: [media]
        })
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async shareWithCompany(payload) {
      try {
        const response = await repository.shareWithCompany(payload.media_id, payload.company_id, {
          message: payload.message
        })
        if (response.status === 204) {
          useSnackbarStore().snack({
            text: 'Media shared.',
            type: 'success',
            show: true,
            autoclose: true
          })
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    updateInternalMedia(payload) {
      this.originalMedia[useCompaniesStore().selectedCompanyId][payload.index] = payload.media
      this.originalMedia[useCompaniesStore().selectedCompanyId] = [
        ...this.originalMedia[useCompaniesStore().selectedCompanyId]
      ]
    },
    async scroll() {
      try {
        if (this.nextPageUrl !== null && !this.loadingNewMedia) {
          this.loadingNewMedia = true
          const response = await repository.scrollMedia(this.nextPageUrl, useMediaFiltersStore().filters)
          this.loadingNewMedia = false
          if (response.status === 200 && response.data) {
            this.appendMedia({
              selectedCompanyId: useCompaniesStore().selectedCompanyId,
              media: response.data.data
            })
            this.nextPageUrl = response.data.next_page_url
          }
        }
      } catch (e) {
        this.loadingNewMedia = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async addMedia(payload) {
      try {
        this.loading = true
        const response = await repository.store(payload, useCompaniesStore().selectedCompanyId)
        this.loading = false
        if (response.status === 201) {
          this.appendMedia({
            selectedCompanyId: useCompaniesStore().selectedCompanyId,
            media: [response.data]
          })
          await useSnackbarStore().snack({
            text: ' Media created successfully',
            type: 'success',
            show: true,
            autoclose: true
          })
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async editMedia(payload) {
      try {
        this.editableId = payload.mediaId
        const response = await repository.updateMedia(
          payload.formData,
          useCompaniesStore().selectedCompanyId,
          payload.mediaId
        )
        if (response.status === 200) {
          this.appendMedia({
            selectedCompanyId: useCompaniesStore().selectedCompanyId,
            media: [response.data]
          })
          this.editableId = null
          await useSnackbarStore().snack({
            text: ' Media updated successfully',
            type: 'success',
            show: true,
            autoclose: true
          })
        }
      } catch (e) {
        this.editableId = null
        if (e.response) {
          await useSnackbarStore().snack({
            text: e.response.data.message,
            type: 'error',
            e: e,
            show: true,
            autoclose: true
          })
        } else {
          await useSnackbarStore().snack({
            text: e,
            type: 'error',
            e: e,
            show: true,
            autoclose: true
          })
        }
      }
    },
    reset() {
      this.originalMedia = []
    }
  }
})
