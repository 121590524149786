<template>
  <AvatarSlot
    :stock-user="stockUser"
    :company="company"
    :post-id="postId"
    :rounded="rounded"
    :big-avatar="bigAvatar"
    :linkable-avatar="linkableAvatar"
    :size="size"
    use-premium
    :default-class="defaultClass"
  >
    <NameSubTitle
      :name="name"
      :room-name="roomName"
      :stock-user="stockUser"
      :use-reputation="useReputation"
      :subtitle="subtitle"
      :text-size="textSize"
      @click="$emit('click')"
    >
      <slot />
    </NameSubTitle>
  </AvatarSlot>
</template>

<script>

import NameSubTitle from './NameSubTitle'

export default {
  name: 'AvatarNameSubTitle',
  components: {
    NameSubTitle
  },
  props: {
    stockUser: { type: Object, default: () => {} },
    company: { type: Object, default: () => {} },
    defaultClass: { type: String, default: 'w-10 h-10' },
    name: { type: String, default: '' },
    linkableAvatar: { type: Boolean, default: false },
    rounded: { type: String, default: 'rounded-full' },
    bigAvatar: { type: Boolean, default: false },
    subtitle: { type: String, default: '' },
    size: { type: Number, default: 40 },
    textSize: { type: String, default: '' },
    roomName: { type: String, default: '' },
    postId: { type: Number, default: null },
    useReputation: { type: Boolean, default: true }
  },
  emits: ['click']
}
</script>
