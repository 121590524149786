<template>
  <div>
    
    <BaseCard class="p-6">
      <template #body>
        <h1 class="text-xl text-center text-custom-purple-700 dark:text-custom-purple-100 font-bold">
          {{ $t('Email Verification') }}
        </h1>
        <p class="text-sm text-center mt-2 dark:text-custom-purple-100">
          {{ $t('Please verify your email address.') }}
        </p>
        <div class="py-2 px-4 items-center border-dashed border-2 border-gray-900 flex flex-col rounded-sm mt-6 dark:text-custom-purple-100">
          <span class="text-xs">{{ $t('Code sent to') }}</span>
          <div
            v-if="!editEmail"
            class="inline-flex"
          >
            <div v-if="loadingEmail">
              <UpdateLoader />
            </div>
            <p
              v-else
              class="font-bold"
            >
              {{ email }}
            </p>

            <i
              class="bx bx-edit ml-1 mt-1 cursor-pointer"
              @click="$emit('edit-email', $event)"
            />
          </div>
          <div
            v-else
            class=" flex flex-col md:inline-flex md:flex-row w-full justify-center items-center space-x-2 md:space-y-0 space-y-2"
          >
            <BaseInput
              id="email"
              class="placeholder-custom-purple-500 w-3/4 text-center placeholder-shown: font-semibold h-7 placeholder-opacity-50 "
              placeholder="Email"
              shadow="shadow-none"
              :model-value="email"
              @update:model-value="$emit('update-email', $event)"
            />
            <div class="space-x-1">
              <BaseButton
                class=" bg-custom-orange-400 w-8 h-7 text-base p-0 m-0"
                @click="$emit('save-email', $event)"
              >
                <div class="flex justify-center items-center m-0 p-0">
                  <i
                    class="bx bx-check"
                    style="color:#ffffff"
                  />
                </div>
              </BaseButton>
              <BaseButton
                class=" bg-gray-400 w-8 h-7 text-base "
                @click="$emit('edit-email', $event)"
              >
                <div class="flex justify-center items-center ">
                  <i
                    class="bx bx-x"
                    style="color:#ffffff"
                  />
                </div>
              </BaseButton>
            </div>
          </div>

          <span class="text-xs">({{ $t('Also check junk mail') }})</span>
        </div>
        <div class="text-center mt-4 dark:text-custom-purple-100">
          {{ $t('Write code here') }}:
        </div>
        <div
          v-if="loadingOtp || onboardingLoading"
          class="flex justify-center py-4"
        >
          <Loader />
        </div>
        <div
          v-else
          id="otp"
          class="flex justify-center text-center px-2 mt-5"
        >
          <input
            ref="first"
            class="h-12 w-12 border-2 border-custom-purple-700 text-center form-control rounded-md dark:bg-custom-purple-500 dark:text-custom-purple-100"
            :type="isMobile ? 'number' : 'text'"
            max="9"
            maxlength="1"
            :value="pin1"
            @paste="$emit('paste-code', $event)"
            @input="first"
          >
          <input
            ref="second"
            class="h-12 w-12 border-2 mx-4 border-custom-purple-700 text-center form-control rounded-md dark:bg-custom-purple-500 dark:text-custom-purple-100"
            :type="isMobile ? 'number' : 'text'"
            max="9"
            maxlength="1"
            :value="pin2"
            @paste="$emit('paste-code', $event)"
            @input="second"
            @keyup="secondKey"
          >
          <input
            ref="third"
            class="h-12 w-12 border-2 border-custom-purple-700 text-center form-control rounded-md dark:bg-custom-purple-500 dark:text-custom-purple-100"
            :type="isMobile ? 'number' : 'text'"
            max="9"
            maxlength="1"
            :value="pin3"
            @paste="$emit('paste-code', $event)"
            @input="third"
            @keyup="thirdKey"
          >
        </div>
        <div
          v-if="otpErrorMessage !== ''"
          class="text-center mt-4 text-sm font-semibold text-red-600"
        >
          {{ $t(otpErrorMessage) }}
        </div>
        <div class="flex mt-5">
          <button
            class="flex w-1/2 justify-start items-center focus:outline-none "
            :class="hideBack? 'text-white cursor-default pointer-events-none' : 'text-custom-purple-700 dark:text-custom-purple-100'"
            @click="$emit('go-back')"
          >
            <span class="font-medium flex item-center">
              <Icon name="chevron-left" />{{ $t('Back') }}
            </span>
          </button>
          <button
            class="flex w-1/2 justify-end items-center focus:outline-none text-custom-purple-700 dark:text-custom-purple-100"
            @click="$emit('resend-otp')"
          >
            <span class="font-medium">{{ $t('Resend code') }}</span>
          </button>
        </div>
      </template>
    </BaseCard>
    <NeutralButton
      class="w-full text-md mt-8 h-12 focus:outline-none bg-custom-orange-400"
      @click="$emit('submit')"
    >
      {{ $t('Verify') }}
    </NeutralButton>
    <div class="max-w-md">
      <ErrorMessage
        v-if="alreadyRegistered"
        class="text-sm"
      >
        <p>{{ $t('Email or username is already taken, please go to login or reset your password. If not your account, please try another username') }}</p>
      </ErrorMessage>
    </div>
  </div>
</template>

<script>

export default {
  name: 'EmailVerification',
  props: {
    pin1: { type: String, default: null },
    pin2: { type: String, default: null },
    pin3: { type: String, default: null },
    email: { type: String, default: null },
    loadingOtp: { type: Boolean, default: false },
    loadingEmail: { type: Boolean, default: false },
    isMobile: { type: Boolean, default: false },
    hideBack: { type: Boolean, default: false },
    editEmail: { type: Boolean, default: false },
    alreadyRegistered: { type: Boolean, default: false },
    onboardingLoading: { type: Boolean, default: false },
    otpErrorMessage: { type: String, default: '' }
  },
emits: [
'edit-email',
'update-email',
'save-email',
'paste-code',
'go-back',
'resend-otp',
'submit',
'pin1',
'pin2',
'submit',
'pin3'
],
  methods: {
    first (event) {
      if (!event.data) {
        return
      }

      if (Number(event.target.value) >= 0 && Number(event.target.value) < 10) {
        this.$refs.second.focus()
      }
      this.$emit('pin1', event.target.value)
    },
    second (event) {
      if (!event.data) {
        this.$refs.first.focus()
      } else if (Number(event.target.value) >= 0 && Number(event.target.value) < 10) {
        this.$refs.third.focus()
      }
      this.$emit('pin2', event.target.value)
    },
    secondKey (event) {
      if (!event.target.value && event.key === 'Backspace') {
        this.$emit('pin2', null)
        this.$refs.first.focus()
      }

      if (event.key === 'Enter') {
        this.$emit('submit')
      }
    },
    thirdKey (event) {
      if (!event.target.value && event.key === 'Backspace') {
        this.$emit('pin3', null)
        this.$refs.second.focus()
      }

      if (event.key === 'Enter') {
        this.$emit('submit')
      }
    },
    third (event) {
      if (!event.data) {
        this.$refs.second.focus()
      } else if (Number(event.target.value) >= 0 && Number(event.target.value) < 10) {
        this.$refs.third.focus()
      }
      this.$emit('pin3', event.target.value)

      if (event.key === 'Enter') {
        this.$emit('submit')
      }
    }
  }

}
</script>
