<template>
  <div>
    <div
      class="w-72 sm:w-96 min-h-min overflow-hidden overflow-y-auto z-70 bg-white text-custom-purple-500 dark:text-custom-purple-100 dark:bg-custom-purple-500 pt-0 rounded-xl shadow-sm dark:shadow-sm-dark absolute top-10 right-0 sm:right-2"
    >
      <NavProfileMenu
        @toggle="$emit('toggle', $event)"
      />
    </div>
    <TransparentBackgroundForMenus @click="$emit('toggle', $event)" />
  </div>
</template>

<script>
import NavProfileMenu from '@/modules/navProfileMenu'

export default {
  name: 'NotificationsMenu',
  components: {
    NavProfileMenu
  },
  props: {
    profile: {
      type: Object,
      required: true
    }
  },
  emits: ['toggle']
}
</script>
